import { useTheme } from '../contexts/ThemeContext';

export const useThemedStyles = (p0: { calendar: { root: { width: string; }; months: { width: string; }; caption: { color: string; fontWeight: number; }; caption_label: { color: string; fontWeight: number; }; head_cell: { color: string; fontWeight: number; }; nav_button: { color: string; }; table: { width: string; tableLayout: string; }; cell: { position: string; padding: string; }; day: { color: string; alignItems: string; justifyContent: string; width: string; height: string; borderRadius: string; margin: string; '&:hover': { backgroundColor: string; }; }; day_today: { backgroundColor: string; color: string; }; day_selected: { backgroundColor: string; color: string; '&:hover': { backgroundColor: string; }; }; day_outside: { color: string; opacity: number; }; day_disabled: { color: string; opacity: number; }; }; }) => {
  const { colors } = useTheme();

  return {
    // Layout styles
    layout: {
      page: {
        backgroundColor: colors.background,
        color: colors.text,
      },
      card: {
        backgroundColor: colors.surface,
        borderColor: colors.border,
        color: colors.text,
      },
      header: {
        backgroundColor: colors.primary,
        color: 'white',
      },
      navigation: {
        backgroundColor: colors.surface,
        borderColor: colors.border,
      },
    },

    // Component styles
    components: {
      navItem: (isActive: boolean) => ({
        backgroundColor: isActive ? colors.primary : 'transparent',
        color: isActive ? 'white' : colors.textSecondary,
        padding: '0.75rem',
        borderRadius: '0.75rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        gap: '0.25rem',
      }),
      button: {
        primary: {
          backgroundColor: colors.primary,
          color: 'white',
          '&:hover': {
            backgroundColor: `${colors.primary}dd`,
          },
        },
        secondary: {
          backgroundColor: colors.secondary,
          color: 'white',
          '&:hover': {
            backgroundColor: `${colors.secondary}dd`,
          },
        },
      },
      card: {
        primary: {
          backgroundColor: `${colors.primary}15`,
          color: colors.primary,
          borderColor: colors.border,
        },
        secondary: {
          backgroundColor: `${colors.secondary}15`,
          color: colors.secondary,
          borderColor: colors.border,
        },
      },
    },

    // Text styles
    text: {
      primary: {
        color: colors.primary,
      },
      secondary: {
        color: colors.secondary,
      },
      body: {
        color: colors.text,
      },
      muted: {
        color: colors.textSecondary,
      },
    },

    container: {
      backgroundColor: colors.background,
      color: colors.text
    },
    
    card: {
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: `${colors.surface}`,
        transform: 'translateY(-2px)',
        boxShadow: `0 4px 6px ${colors.textSecondary}15`
      }
    }
  };
}; 

export { useTheme };