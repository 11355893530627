import CryptoJS from 'crypto-js'
import { AppError } from '../utils/errors'

// Constants for encryption
const ENCRYPTION_VERSION = 1
const ENCRYPTION_PREFIX = `ENC_V${ENCRYPTION_VERSION}_`

interface LegacyEncryptedData {
  data: string
  version: number
}

declare global {
  interface Window {
    __NEXT_DATA__?: {
      props?: {
        pageProps?: {
          masterKey?: string
        }
      }
    }
  }
}

export const encryptionService = {
  /**
   * Generate a unique encryption key for a user
   * This should be done during user creation and stored securely
   */
  generateUserKey(): string {
    return CryptoJS.lib.WordArray.random(256 / 8).toString()
  },

  /**
   * Encrypt data using user's encryption key
   */
  encrypt(data: string | number, key: string): string {
    try {
      const stringData = typeof data === 'number' ? data.toString() : data
      const markedData = JSON.stringify({
        v: 1,
        d: stringData,
        t: Date.now()
      })
      return CryptoJS.AES.encrypt(markedData, key).toString()
    } catch (error) {
      console.error('Encryption error:', error)
      throw new AppError('ENCRYPTION_FAILED', 'Failed to encrypt data')
    }
  },

  /**
   * Decrypt data using user's encryption key
   */
  decrypt(encryptedData: string | number, key: string): string {
    try {
      // Handle non-string data
      if (typeof encryptedData !== 'string') {
        return encryptedData.toString()
      }

      // Return empty string for falsy values
      if (!encryptedData) {
        return ''
      }

      // Handle legacy format
      if (encryptedData.startsWith(ENCRYPTION_PREFIX)) {
        try {
          const legacyJson = encryptedData.slice(ENCRYPTION_PREFIX.length)
          const legacyData = JSON.parse(legacyJson) as LegacyEncryptedData

          // If the key is encrypted (starts with ENC_), we need to decrypt it first
          let decryptionKey = key
          if (key.startsWith('ENC_V1_')) {
            try {
              // Get master key from runtime config
              const masterKey = window.__NEXT_DATA__?.props?.pageProps?.masterKey || 'default-master-key'
              
              // Parse the legacy format - remove ENC_V1_ prefix and parse JSON
              const legacyJson = key.substring(7) // Skip "ENC_V1_"
              const legacyData = JSON.parse(legacyJson) as LegacyEncryptedData
              
              // Try to decrypt the key data
              try {
                // Parse the Base64 data
                const ciphertext = CryptoJS.enc.Base64.parse(legacyData.data)
                const ciphertextWords = ciphertext.words
                
                // Extract salt and encrypted data (Salted__ = 8 bytes = 2 words)
                const salt = CryptoJS.lib.WordArray.create(ciphertextWords.slice(2, 4))
                const encryptedData = CryptoJS.lib.WordArray.create(ciphertextWords.slice(4))
                
                // Generate key material using OpenSSL's method with MD5 (old OpenSSL default)
                const keyMaterial = this.EVP_BytesToKey(masterKey, salt.words)
                
                // Try multiple decryption approaches
                let decryptedBytes: CryptoJS.lib.WordArray | null = null
                
                // Approach 1: Standard OpenSSL format
                try {
                  decryptedBytes = CryptoJS.AES.decrypt(
                    { ciphertext: encryptedData },
                    keyMaterial.key,
                    {
                      iv: keyMaterial.iv,
                      mode: CryptoJS.mode.CBC,
                      padding: CryptoJS.pad.Pkcs7
                    }
                  )
                } catch (e) {
                  console.warn('Standard decryption failed:', e)
                }
                
                // Approach 2: Try with Base64 encoded data
                if (!decryptedBytes || !decryptedBytes.toString(CryptoJS.enc.Utf8)) {
                  try {
                    decryptedBytes = CryptoJS.AES.decrypt(
                      legacyData.data,
                      keyMaterial.key,
                      {
                        iv: keyMaterial.iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                      }
                    )
                  } catch (e) {
                    console.warn('Base64 decryption failed:', e)
                  }
                }
                
                // Approach 3: Try with direct master key
                if (!decryptedBytes || !decryptedBytes.toString(CryptoJS.enc.Utf8)) {
                  try {
                    decryptedBytes = CryptoJS.AES.decrypt(
                      legacyData.data,
                      masterKey,
                      {
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7,
                        iv: CryptoJS.enc.Utf8.parse('0000000000000000')
                      }
                    )
                  } catch (e) {
                    console.warn('Direct decryption failed:', e)
                  }
                }
                
                // Try to decode the result
                if (decryptedBytes) {
                  try {
                    decryptionKey = decryptedBytes.toString(CryptoJS.enc.Utf8)
                    if (decryptionKey) {
                      console.log('Decryption successful with key length:', decryptionKey.length)
                      return decryptionKey
                    }
                  } catch (e) {
                    console.warn('UTF8 decoding failed:', e)
                  }
                }
                
                throw new Error('All decryption attempts failed')
              } catch (e) {
                console.warn('Key decryption failed:', e)
                return encryptedData
              }
            } catch (e) {
              console.error('Failed to decrypt key:', e)
              return encryptedData
            }
          }

          console.log('Attempting to decrypt with key:', {
            keyLength: decryptionKey.length,
            keyStart: decryptionKey.substring(0, 4)
          })

          // Try legacy decryption with decrypted key
          try {
            const bytes = CryptoJS.AES.decrypt(
              legacyData.data,
              decryptionKey,
              {
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                iv: CryptoJS.enc.Utf8.parse('0000000000000000')
              }
            )

            const result = bytes.toString(CryptoJS.enc.Utf8)
            if (result) {
              try {
                return JSON.parse(result)
              } catch {
                return result
              }
            }
          } catch (e) {
            console.warn('Legacy decryption failed:', e)
          }

          console.warn('All legacy decryption attempts failed')
          return encryptedData
        } catch (error) {
          console.error('Legacy decryption error:', error)
          return encryptedData
        }
      }

      // Handle new format
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, key)
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8)
        
        if (!decryptedString) {
          return encryptedData
        }

        try {
          const parsed = JSON.parse(decryptedString)
          if (!parsed?.v || !('d' in parsed)) {
            return decryptedString
          }
          const value = parsed.d
          return typeof value === 'number' ? value.toString() : value
        } catch {
          return decryptedString
        }
      } catch {
        return encryptedData
      }
    } catch {
      return encryptedData
    }
  },

  /**
   * Check if data is encrypted
   */
  isEncrypted(data: string | number): boolean {
    if (typeof data !== 'string' || !data) return false
    
    // Check for legacy format
    if (data.startsWith(ENCRYPTION_PREFIX)) {
      try {
        const legacyJson = data.slice(ENCRYPTION_PREFIX.length)
        const legacyData = JSON.parse(legacyJson)
        return legacyData?.data && typeof legacyData.data === 'string'
      } catch {
        return false
      }
    }

    // Check for new format
    return /^[A-Za-z0-9+/=]+$/.test(data) && data.length > 20
  },

  // OpenSSL's EVP_BytesToKey implementation
  EVP_BytesToKey(password: string, salt: number[]) {
    const keySize = 256 / 32
    const ivSize = 128 / 32
    
    // Convert password to bytes
    const passwordBytes = CryptoJS.enc.Utf8.parse(password)
    const saltWords = CryptoJS.lib.WordArray.create(salt)

    // Generate key material
    let keyMaterial = CryptoJS.lib.WordArray.create()
    let prev = CryptoJS.lib.WordArray.create()
    
    let keyAndIv = CryptoJS.lib.WordArray.create()
    
    while (keyAndIv.words.length < (keySize + ivSize)) {
      prev = CryptoJS.MD5(prev.concat(passwordBytes).concat(saltWords))
      keyAndIv = keyAndIv.concat(prev)
    }

    return {
      key: CryptoJS.lib.WordArray.create(keyAndIv.words.slice(0, keySize)),
      iv: CryptoJS.lib.WordArray.create(keyAndIv.words.slice(keySize, keySize + ivSize))
    }
  }
} 