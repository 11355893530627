import { Editor } from '@tiptap/react'
import { useTheme } from '../../contexts/ThemeContext'
import { PiTrash } from 'react-icons/pi'
import { imageService } from '../../services/imageService'
import { useErrorFeedback } from '../../hooks/useErrorFeedback'

interface ImageToolbarProps {
  editor: Editor
  imageUrl: string
  position: { top: number; left: number; width: number; height: number } | null
}

export function ImageToolbar({ editor, imageUrl, position }: ImageToolbarProps) {
  const { colors } = useTheme()
  const { showError } = useErrorFeedback()

  if (!position) return null

  const handleDelete = async () => {
    try {
      const img = editor.view.dom.querySelector(`img[src="${imageUrl}"]`)
      if (img) {
        const figure = img.closest('figure')
        if (figure) {
          figure.remove()
          await imageService.deleteImage(imageUrl)
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'ImageToolbar.handleDelete')
      }
    }
  }

  return (
    <div
      className="image-toolbar"
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        zIndex: 50,
      }}
    >
      <button
        onClick={handleDelete}
        className="p-1.5 rounded-full transition-all hover:scale-110 shadow-sm"
        style={{
          backgroundColor: colors.error,
          color: 'white',
        }}
        title="Delete image"
      >
        <PiTrash className="w-3.5 h-3.5" />
      </button>
    </div>
  )
} 