import { useTheme } from '../../contexts/ThemeContext'
import { SubscriptionTier } from '../../types/subscription'
import { PiCrown } from 'react-icons/pi'

interface SubscriptionBadgeProps {
  tier: SubscriptionTier
  size?: 'sm' | 'md' | 'lg'
}

export function SubscriptionBadge({ tier, size = 'md' }: SubscriptionBadgeProps) {
  const { colors: themeColors } = useTheme()

  const getBadgeColors = () => {
    switch (tier) {
      case 'unlimited':
        return {
          bg: `${themeColors.primary}20`,
          text: themeColors.primary,
          icon: themeColors.primary
        }
      case 'plus':
        return {
          bg: `${themeColors.success}20`,
          text: themeColors.success,
          icon: themeColors.success
        }
      default:
        return {
          bg: `${themeColors.textSecondary}20`,
          text: themeColors.textSecondary,
          icon: themeColors.textSecondary
        }
    }
  }

  const getSizeClasses = () => {
    switch (size) {
      case 'sm':
        return 'text-xs px-2 py-0.5 gap-1'
      case 'lg':
        return 'text-base px-4 py-2 gap-2'
      default:
        return 'text-sm px-3 py-1 gap-1.5'
    }
  }

  const badgeColors = getBadgeColors()

  return (
    <div
      className={`inline-flex items-center rounded-full font-medium ${getSizeClasses()}`}
      style={{
        backgroundColor: badgeColors.bg,
        color: badgeColors.text
      }}
    >
      <PiCrown
        className={size === 'sm' ? 'w-3 h-3' : size === 'lg' ? 'w-5 h-5' : 'w-4 h-4'}
        style={{ color: badgeColors.icon }}
      />
      {tier.charAt(0).toUpperCase() + tier.slice(1)}
    </div>
  )
} 