import { FinanceCategory } from '../types/finance';
import { 
  PiHouse,
  PiCar,
  PiBuilding,
  PiGasCan,
  PiFirstAid,
  PiShoppingCart,
  PiTelevision,
  PiGraduationCap,
  PiAirplaneTilt,
  PiCreditCard,
  PiGift,
  PiCookie,
  PiPaintBrush,
  PiCurrencyDollar,
  PiReceipt,
  PiHeartbeat,
  PiCoffee,
  PiLightning,
  PiBarbell,
  PiBriefcase,
  PiHandshake,
  PiChartLine,
  PiHeart,
  PiDotsThree,
} from 'react-icons/pi';

export const defaultCategories: FinanceCategory[] = [
  // Income Categories
  {
    id: 'income-salary',
    name: 'Salary',
    type: 'income',
    icon: PiBriefcase,
    color: '#34D399', // green
    isDefault: true
  },
  {
    id: 'income-investments',
    name: 'Investments',
    type: 'income',
    icon: PiChartLine,
    color: '#10B981', // emerald
    isDefault: true
  },
  {
    id: 'income-other',
    name: 'Other Income',
    type: 'income',
    icon: PiHandshake,
    color: '#059669', // green
    isDefault: true
  },

  // Transfers
  {
    id: 'transfer-in',
    name: 'Transfer In',
    type: 'income',
    icon: PiHandshake,
    color: '#4ADE80', // green light
    isDefault: true

  },
  {
    id: 'transfer-out',
    name: 'Transfer Out',
    type: 'expense',
    icon: PiHandshake,
    color: '#F87171', // red
    isDefault: true
  },

  // Loan Payments
  {
    id: 'loan-payments',
    name: 'Loan Payments',
    type: 'expense',
    icon: PiCreditCard,
    color: '#F59E0B', // amber
    isDefault: true
  },

  // Bank Fees
  {
    id: 'bank-fees',
    name: 'Bank Fees',
    type: 'expense',
    icon: PiReceipt,
    color: '#9CA3AF', // gray
    isDefault: true
  },

  // General Merchandise
  {
    id: 'general-merchandise',
    name: 'General Merchandise',
    type: 'expense',
    icon: PiShoppingCart,
    color: '#8B5CF6', // violet
    isDefault: true
  },

  // Home Improvement
  {
    id: 'home-improvement',
    name: 'Home Improvement',
    type: 'expense',
    icon: PiPaintBrush,
    color: '#60A5FA', // blue
    isDefault: true
  },

  // Medical
  {
    id: 'medical',
    name: 'Medical',
    type: 'expense',
    icon: PiFirstAid,
    color: '#EC4899', // pink
    isDefault: true
  },

  // Food & Dining
  {
    id: 'restaurants-fast-food',
    name: 'Restaurants + Fast Food',
    type: 'expense',
    icon: PiCookie,
    color: '#F59E0B', // amber
    isDefault: true
  },
  {
    id: 'groceries',
    name: 'Groceries',
    type: 'expense',
    icon: PiShoppingCart,
    color: '#F87171', // red
    isDefault: true
  },
  {
    id: 'coffee-snacks',
    name: 'Coffee & Snacks',
    type: 'expense',
    icon: PiCoffee,
    color: '#92400E', // amber dark
    isDefault: true
  },

  // Personal
  {
    id: 'shopping',
    name: 'Shopping',
    type: 'expense',
    icon: PiShoppingCart,
    color: '#8B5CF6', // violet
    isDefault: true
  },
  {
    id: 'personal-care',
    name: 'Personal Care',
    type: 'expense',
    icon: PiHeartbeat,
    color: '#D946EF', // fuchsia
    isDefault: true
  },
  {
    id: 'fitness',
    name: 'Fitness',
    type: 'expense',
    icon: PiBarbell,
    color: '#EC4899', // pink
    isDefault: true
  },

  // Entertainment & Leisure
  {
    id: 'entertainment',
    name: 'Entertainment',
    type: 'expense',
    icon: PiTelevision,
    color: '#F472B6', // pink
    isDefault: true
  },
  {
    id: 'hobbies',
    name: 'Hobbies',
    type: 'expense',
    icon: PiPaintBrush,
    color: '#C084FC', // purple
    isDefault: true
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    type: 'expense',
    icon: PiCurrencyDollar,
    color: '#A855F7', // purple
    isDefault: true
  },

  // Education & Development
  {
    id: 'education',
    name: 'Education',
    type: 'expense',
    icon: PiGraduationCap,
    color: '#8B5CF6', // purple
    isDefault: true
  },

  // Government and Non-Profit
  {
    id: 'government-nonprofit',
    name: 'Government & Non-Profit',
    type: 'expense',
    icon: PiBuilding,
    color: '#34D399', // green
    isDefault: true
  },

  // Transportation
  {
    id: 'transportation',
    name: 'Transportation',
    type: 'expense',
    icon: PiCar,
    color: '#FBBF24', // yellow
    isDefault: true
  },
  {
    id: 'gas',
    name: 'Gas',
    type: 'expense',
    icon: PiGasCan,
    color: '#F59E0B', // amber
    isDefault: true
  },

  // Travel
  {
    id: 'travel',
    name: 'Travel',
    type: 'expense',
    icon: PiAirplaneTilt,
    color: '#6366F1', // indigo
    isDefault: true
  },

  // Rent and Utilities
  {
    id: 'rent',
    name: 'Rent',
    type: 'expense',
    icon: PiHouse,
    color: '#60A5FA', // blue
    isDefault: true
  },
  {
    id: 'utilities',
    name: 'Utilities',
    type: 'expense',
    icon: PiLightning,
    color: '#818CF8', // indigo
    isDefault: true
  },

  // Gifts & Donations
  {
    id: 'gifts',
    name: 'Gifts',
    type: 'expense',
    icon: PiGift,
    color: '#6EE7B7', // emerald
    isDefault: true
  },
  {
    id: 'donations',
    name: 'Donations',
    type: 'expense',
    icon: PiHeart,
    color: '#34D399', // emerald
    isDefault: true
  },

  // Other
  {
    id: 'other',
    name: 'Other',
    type: 'expense',
    icon: PiDotsThree,
    color: '#9CA3AF', // gray
    isDefault: true
  }
];

// Add these types for custom categories
export interface CustomCategory extends FinanceCategory {
  userId: string;
  createdAt: Date;
  updatedAt: Date;
} 