import { useState } from 'react';
import { ThemePreset } from '../../types/theme';
import { HexColorPicker } from 'react-colorful';
import { useTheme } from '../../contexts/ThemeContext';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface ThemeEditorProps {
  theme?: ThemePreset;
  onSave: (theme: ThemePreset) => void;
  onClose: () => void;
}

type EditableThemeColors = {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
  accent1: string;
  accent2: string;
};

export const ThemeEditor = ({ theme, onSave, onClose }: ThemeEditorProps) => {
  const { colors } = useTheme();
  const [activeColor, setActiveColor] = useState<keyof EditableThemeColors | null>(null);
  const [editingTheme, setEditingTheme] = useState<ThemePreset>({
    name: theme?.name || '',
    description: theme?.description || '',
    colors: (theme?.colors as EditableThemeColors) || {
      primary: '#007AFF',
      secondary: '#8E8E93',
      tertiary: '#5856D6',
      quaternary: '#34C759',
      accent1: '#FF9500',
      accent2: '#FF2D55'
    },
    isCustom: true
  });

  const handleColorChange = (color: string) => {
    if (activeColor) {
      setEditingTheme(prev => ({
        ...prev,
        colors: {
          ...prev.colors,
          [activeColor]: color
        }
      }));
    }
  };

  const handleSave = () => {
    if (editingTheme.name.trim()) {
      onSave(editingTheme);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div 
        className="rounded-lg p-6 max-w-2xl w-full"
        style={{ backgroundColor: colors.background }}
      >
        <div className="flex justify-between items-center mb-6">
          <h3 
            className="text-xl font-medium"
            style={{ color: colors.primary }}
          >
            {theme ? 'Edit Theme' : 'Create New Theme'}
          </h3>
          <button onClick={onClose}>
            <XMarkIcon className="h-6 w-6" style={{ color: colors.textSecondary }} />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label 
              className="block text-sm font-medium mb-1"
              style={{ color: colors.textSecondary }}
            >
              Theme Name
            </label>
            <input
              type="text"
              value={editingTheme.name}
              onChange={(e) => setEditingTheme(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 rounded-md border"
              style={{
                backgroundColor: colors.surface,
                borderColor: colors.border,
                color: colors.text
              }}
              placeholder="My Custom Theme"
            />
          </div>

          <div>
            <label 
              className="block text-sm font-medium mb-1"
              style={{ color: colors.textSecondary }}
            >
              Description
            </label>
            <input
              type="text"
              value={editingTheme.description || ''}
              onChange={(e) => setEditingTheme(prev => ({ ...prev, description: e.target.value }))}
              className="w-full px-3 py-2 rounded-md border"
              style={{
                backgroundColor: colors.surface,
                borderColor: colors.border,
                color: colors.text
              }}
              placeholder="Optional theme description"
            />
          </div>

          <div>
            <label 
              className="block text-sm font-medium mb-2"
              style={{ color: colors.textSecondary }}
            >
              Colors
            </label>
            <div className="grid grid-cols-3 gap-3">
              {Object.entries(editingTheme.colors).map(([key, value]) => (
                <button
                  key={key}
                  onClick={() => setActiveColor(key as keyof EditableThemeColors)}
                  className="flex items-center gap-2 p-2 rounded-md border"
                  style={{
                    backgroundColor: colors.surface,
                    borderColor: colors.border
                  }}
                >
                  <div 
                    className="w-6 h-6 rounded-md border"
                    style={{ 
                      backgroundColor: value,
                      borderColor: colors.border
                    }}
                  />
                  <span 
                    className="flex-1 text-left text-sm"
                    style={{ color: colors.text }}
                  >
                    {key}
                  </span>
                </button>
              ))}
            </div>
          </div>

          {activeColor && (
            <div className="mt-4">
              <HexColorPicker
                color={editingTheme.colors[activeColor]}
                onChange={handleColorChange}
              />
              <input
                type="text"
                value={editingTheme.colors[activeColor]}
                onChange={(e) => handleColorChange(e.target.value)}
                className="mt-2 w-full px-3 py-2 rounded-md border"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border,
                  color: colors.text
                }}
              />
            </div>
          )}

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-md"
              style={{
                backgroundColor: colors.surface,
                color: colors.text,
                border: `1px solid ${colors.border}`
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 rounded-md"
              style={{
                backgroundColor: colors.primary,
                color: 'white'
              }}
            >
              Save Theme
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}; 