import { 
  collection, 
  doc, 
  getDoc, 
  query, 
  where, 
  getDocs,
  Timestamp,
  orderBy,
  DocumentData,
  QueryDocumentSnapshot 
} from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import type { AdminUser, AnalyticsDashboardData } from '../types/admin';
import { startOfDay, subDays, startOfWeek, startOfMonth } from 'date-fns';
import type { AnalyticsEvent } from './analyticsService';
import type { User } from '../types/user';
import { AppError } from '../utils/errors';

const ALLOWED_ADMIN_EMAILS = [
  // Add your email here
  'superman50196@gmail.com'
];

export const adminService = {
  async isAdmin(userId?: string): Promise<boolean> {
    try {
      // If no userId provided, use current user
      const currentUserId = userId || auth.currentUser?.uid;
      
      if (!currentUserId) {
        return false;
      }

      const userRef = doc(db, 'users', currentUserId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        return false;
      }

      const userData = userDoc.data() as User;
      
      // Check if user has admin role
      const hasAdminRole = userData.role === 'admin' || userData.role === 'superadmin';
      
      // If checking current user, also verify email
      if (!userId && auth.currentUser?.email) {
        return hasAdminRole && ALLOWED_ADMIN_EMAILS.includes(auth.currentUser.email);
      }

      return hasAdminRole;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  },

  async getDashboardData(): Promise<AnalyticsDashboardData> {
    try {
      if (!await this.isAdmin()) {
        throw new AppError('UNAUTHORIZED', 'Unauthorized access');
      }

      const now = new Date();
      const today = startOfDay(now);
      const lastWeek = startOfWeek(now);
      const lastMonth = startOfMonth(now);

      // Get analytics events with error handling
      const analyticsRef = collection(db, 'analytics');
      let events: AnalyticsEvent[] = [];
      
      try {
        const recentEventsQuery = query(
          analyticsRef,
          where('timestamp', '>=', Timestamp.fromDate(subDays(now, 30))),
          orderBy('timestamp', 'desc')
        );
        const eventsSnapshot = await getDocs(recentEventsQuery);
        events = eventsSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          timestamp: doc.data().timestamp?.toDate()
        })) as AnalyticsEvent[];
      } catch (error) {
        console.error('Error fetching analytics:', error);
        events = []; // Fallback to empty array
      }

      // Get users with error handling
      const usersRef = collection(db, 'users');
      let users: QueryDocumentSnapshot<DocumentData>[] = [];
      
      try {
        const usersSnapshot = await getDocs(usersRef);
        users = usersSnapshot.docs;
      } catch (error) {
        console.error('Error fetching users:', error);
        users = []; // Fallback to empty array
      }

      // Calculate statistics
      const newUsersToday = users.filter(user => {
        const createdAt = user.data().createdAt?.toDate();
        return createdAt && createdAt >= today;
      }).length;

      const activeUsers = new Set(
        events
          .filter(event => event.timestamp >= subDays(now, 7))
          .map(event => event.userId)
      ).size;

      // Aggregate events by day/week/month
      const daily: Record<string, number> = {};
      const weekly: Record<string, number> = {};
      const monthly: Record<string, number> = {};
      const featureUsage: Record<string, number> = {};
      const devices: Record<string, number> = {};
      const platforms: Record<string, number> = {};

      events.forEach(event => {
        // Daily stats
        const dateStr = event.timestamp.toISOString().split('T')[0];
        daily[dateStr] = (daily[dateStr] || 0) + 1;

        // Feature usage
        if (event.eventType) {
          featureUsage[event.eventType] = (featureUsage[event.eventType] || 0) + 1;
        }

        // Device stats
        if (event.deviceType) {
          devices[event.deviceType] = (devices[event.deviceType] || 0) + 1;
        }
        if (event.platform) {
          platforms[event.platform] = (platforms[event.platform] || 0) + 1;
        }
      });

      return {
        overview: {
          totalUsers: users.length,
          activeUsers,
          totalEvents: events.length,
          newUsersToday
        },
        userActivity: {
          daily,
          weekly,
          monthly
        },
        featureUsage,
        deviceStats: {
          devices,
          platforms
        }
      };
    } catch (error) {
      console.error('Error in getDashboardData:', error);
      throw error;
    }
  }
}; 