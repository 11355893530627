import { getAIResponse } from '../openai'
import type { AIAssistantSettings } from '../../types/ai'

interface ProcessEditorTextParams {
  text: string
  action: 'improve' | 'rephrase' | 'translate' | 'summarize' | 'expand' | 'tone'
  customPrompt?: string
  apiKey: string
  assistantSettings: AIAssistantSettings
}

// Add the translation options
const translateOptions = [
  { value: 'es', label: 'Spanish' }, // In the future, we should change these to use the translation.json's options
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'zh', label: 'Chinese' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'en', label: 'English' },
] as const

export async function processEditorText({
  text,
  action,
  customPrompt,
  apiKey,
  assistantSettings
}: ProcessEditorTextParams): Promise<string> {
  if (!text) {
    throw new Error('No text provided')
  }

  const basePrompt = getPromptForAction(action, assistantSettings)
  let fullPrompt = basePrompt

  // Make action-specific prompts more direct
  switch (action) {
    case 'translate':
      const targetLanguage = translateOptions.find(opt => opt.value === customPrompt)?.label
      if (!targetLanguage) throw new Error('Invalid target language')
      fullPrompt = `${basePrompt}\nTarget language: ${targetLanguage}\nInput text: ${text}`
      break
      
    case 'tone':
      if (!customPrompt) throw new Error('Tone style is required')
      fullPrompt = `${basePrompt}\nTarget tone: ${customPrompt}\nInput text: ${text}`
      break
      
    case 'rephrase':
      if (!customPrompt) throw new Error('Rephrase style is required')
      fullPrompt = `${basePrompt}\nRephrase style: ${customPrompt}\nInput text: ${text}`
      break
      
    case 'improve':
      fullPrompt = customPrompt 
        ? `${basePrompt}\nSpecific instructions: ${customPrompt}\nInput text: ${text}`
        : `${basePrompt}\nInput text: ${text}`
      break
      
    case 'summarize':
    case 'expand':
      fullPrompt = `${basePrompt}\nInput text: ${text}`
      break
  }

  try {
    const response = await getAIResponse({
      model: 'gpt-4o-mini',
      systemPrompt: fullPrompt,
      messages: [{ role: 'user', content: text }],
      apiKey
    })

    if (!response) {
      throw new Error('No response from AI service')
    }

    return response.trim()
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`AI processing failed: ${error.message}`)
    }
    throw new Error('AI processing failed')
  }
}

function getPromptForAction(action: string, settings: AIAssistantSettings): string {
  const prompts = {
    improve: `You are a writing assistant. Improve the following text for clarity and style while maintaining its core message. Return only the improved version without explanations.`,
    
    rephrase: `You are a text transformation assistant. Rephrase the text according to the specified style while keeping the core meaning. Return only the rephrased text.`,
    
    translate: `You are a translation assistant. Translate the following text accurately to the target language. Return only the translated text.`,
    
    summarize: `You are a summarization assistant. Create a concise summary that captures the key points. Return only the summary.`,
    
    expand: `You are a content expansion assistant. Elaborate on the given text with relevant details while maintaining the original style. Return only the expanded text.`,
    
    tone: `You are a tone adjustment assistant. Rewrite the text in the specified tone while preserving the core message. Return only the rewritten text.`
  }

  return prompts[action as keyof typeof prompts] || prompts.improve
} 