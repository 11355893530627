import { type Editor } from '@tiptap/react'
import { useState, useRef, useEffect } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { createPortal } from 'react-dom'

// Use DOM Range instead of Tiptap Range
interface LinkPopupProps {
  editor: Editor
  showLinkPopup: boolean
  setShowLinkPopup: (show: boolean) => void
  linkSelection: globalThis.Range | null
  setLinkSelection: (selection: globalThis.Range | null) => void
}

export function LinkPopup({
  editor,
  showLinkPopup,
  setShowLinkPopup,
  linkSelection,
  setLinkSelection
}: LinkPopupProps) {
  const { colors } = useTheme()
  const [url, setUrl] = useState('')
  const popupRef = useRef<HTMLDivElement>(null)

  const handleSubmit = () => {
    if (!url.trim()) {
      handleCancel()
      return
    }

    const finalUrl = url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `https://${url}`

    if (linkSelection) {
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(linkSelection)
      }
    }

    editor.chain().focus().setLink({ href: finalUrl }).run()
    setShowLinkPopup(false)
    setLinkSelection(null)
  }

  const handleCancel = () => {
    setShowLinkPopup(false)
    setLinkSelection(null)
    editor.chain().focus().unsetLink().run()
  }

  useEffect(() => {
    if (showLinkPopup && linkSelection && popupRef.current) {
      const rect = linkSelection.getBoundingClientRect()
      const popupElement = popupRef.current
      
      const top = rect.bottom + 10
      const left = rect.left + (rect.width / 2)
      
      popupElement.style.top = `${top}px`
      popupElement.style.left = `${left}px`
    }
  }, [showLinkPopup, linkSelection])

  return createPortal(
    <div 
      ref={popupRef}
      className="fixed p-4 rounded-lg shadow-lg transform -translate-x-1/2"
      style={{
        backgroundColor: colors.surface,
        minWidth: '300px',
        zIndex: 9999,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="flex items-center gap-2">
        <input
          type="url"
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="flex-1 px-2 py-1 text-sm border rounded dark:bg-gray-700 dark:border-gray-600"
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSubmit()
            if (e.key === 'Escape') handleCancel()
          }}
          autoFocus
        />
        <button
          onClick={handleSubmit}
          className="px-2 py-1 text-sm text-white rounded"
          style={{ backgroundColor: colors.primary }}
        >
          {url ? 'Update' : 'Add'}
        </button>
        <button
          onClick={handleCancel}
          className="px-2 py-1 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
        >
          Cancel
        </button>
      </div>
    </div>,
    document.body
  )
} 