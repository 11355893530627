import { useTheme } from '../../contexts/ThemeContext';
import { moodTypes } from '../../types/mood';
import { 
  PiKey,
  PiSmileyWink,
  PiSmileySad,
  PiSmiley,
  PiSmileyStickerLight,
  PiSmileyMeh,
  PiSmileyNervous
} from 'react-icons/pi';
import { motion } from 'framer-motion';

export const MoodKey = () => {
  const { colors } = useTheme();

  // Map mood types to icons
  const moodIcons = {
    amazing: <PiSmileyWink size={20} />,
    happy: <PiSmiley size={20} />,
    neutral: <PiSmileyMeh size={20} />,
    meh: <PiSmileyStickerLight size={20} />,
    sad: <PiSmileySad size={20} />,
    terrible: <PiSmileyNervous size={20} />
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="rounded-lg p-4 sm:p-6 shadow-sm"
      style={{ 
        backgroundColor: colors.surface,
        borderColor: colors.border,
        borderWidth: '1px'
      }}
    >
      <motion.h3 
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2 }}
        className="text-lg font-medium mb-4 flex items-center gap-2"
        style={{ color: colors.primary }}
      >
        <motion.span
          animate={{ rotate: [0, -10, 10, -10, 0] }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <PiKey size={24} />
        </motion.span>
        Mood Key
      </motion.h3>

      <motion.div 
        variants={container}
        initial="hidden"
        animate="show"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3"
      >
        {Object.entries(moodTypes).map(([key, mood]) => (
          <motion.div 
            key={key}
            variants={item}
            whileHover={{ 
              scale: 1.02,
              backgroundColor: `${mood.color}20`
            }}
            className="flex items-center gap-3 p-3 rounded-lg transition-all"
            style={{ 
              backgroundColor: `${mood.color}10`,
              border: `1px solid ${mood.color}20`
            }}
          >
            <div className="flex items-center gap-2">
              <motion.div 
                whileHover={{ rotate: 20 }}
                className="w-8 h-8 rounded-full flex items-center justify-center"
                style={{ 
                  backgroundColor: mood.color,
                  color: 'white'
                }}
              >
                {moodIcons[key as keyof typeof moodIcons]}
              </motion.div>
              <div>
                <div 
                  className="font-medium"
                  style={{ color: colors.text }}
                >
                  {mood.label}
                </div>
                <div 
                  className="text-xs"
                  style={{ color: colors.textSecondary }}
                >
                  {mood.description}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}; 