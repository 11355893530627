import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useState, useEffect, useRef } from 'react';
import { interestService } from '../services/interestService';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { Logo } from '../components/shared/Logo';
import { DarkModeToggle } from '../components/shared/DarkModeToggle';
import { lazy } from 'react'
import { AppError } from '../utils/errors';
import { useNotificationStore } from '../store/useNotificationStore';
import { ThemeColors } from '../types/theme';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { ENV, shouldAllowAccess, addUnlockParam, setUnlockState } from '../utils/environment'
import { usePageTracking } from '../hooks/usePageTracking'
import {
  PiArrowRight,
  PiCheckCircle,
  PiSparkle,
  PiNotebook,
  PiListChecks,
  PiChartLine,
  PiChatCircleText,
  PiSmileySad,
  PiSmileyStickerBold,
  PiLightning,
  PiTrendUp,
  PiRobot,
  PiMagicWand,
  PiGithubLogo,
  PiDiscordLogo,
  PiTwitterLogo,
  PiEnvelope,
  PiCaretDown,
  PiPiggyBank,
  PiPaintBrush,
  PiTrophy,
  PiHeart,
  PiCalendar
} from 'react-icons/pi'
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../hooks/useLocalStorage'

interface InterestFormData {
  email: string;
  name?: string;
  useCase?: string;
}

interface ThemePreview {
  id: number
  image: string
  colors: {
    primary: string
    accent: string
    background: string
  }
}

const themePreviews: ThemePreview[] = [
  {
    id: 1,
    image: '/app/preview-default.png',
    colors: {
      primary: '#3B82F6',
      accent: '#6D28D9',
      background: '#ffffff'
    }
  },
  {
    id: 2,
    image: '/app/preview-forest.png',
    colors: {
      primary: '#10B981',
      accent: '#0D9488',
      background: '#f0fdf4'
    }
  },
  {
    id: 3,
    image: '/app/preview-royal.png',
    colors: {
      primary: '#7C3AED',
      accent: '#6D28D9',
      background: '#f5f3ff'
    }
  },
  {
    id: 4,
    image: '/app/preview-ruby.png',
    colors: {
      primary: '#DC2626',
      accent: '#991B1B',
      background: '#fef2f2'
    }
  },
  {
    id: 5,
    image: '/app/preview-citrus.png',
    colors: {
      primary: '#F59E0B',
      accent: '#D97706',
      background: '#fafafa'
    }
  }
];

const IS_TESTING = import.meta.env.VITE_IS_TESTING === 'true';

const buttonStyles = {
  base: "px-6 py-3 rounded-lg text-lg font-medium transition-all hover:scale-105 relative",
  nav: (colors: ThemeColors) =>
    `text-sm font-medium transition-colors hover:text-[${colors.primary}]`,
  headerButton: (colors: ThemeColors) =>
    `text-sm px-4 py-2 rounded-lg font-medium transition-all hover:scale-105
    bg-gradient-to-r from-[${colors.primary}30] to-[${colors.accent1}30]
    border border-[${colors.primary}40]
    shadow-sm shadow-[${colors.primary}20]`,
  gradient: (colors: ThemeColors) =>
    `bg-gradient-to-r from-[${colors.primary}] via-[${colors.accent1}] to-[${colors.primary}] 
    bg-[length:300%_100%] animate-subtleGradient hover:opacity-90 
    border border-[${colors.primary}40] 
    shadow-lg shadow-[${colors.primary}20]`
}

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
}

const featureCardVariants = {
  hidden: { opacity: 0, scale: 0.95, y: 20 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  }
}

const heroImageVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
      ease: "easeOut"
    }
  }
}

const scrollReveal = {
  hidden: { 
    opacity: 0, 
    y: 50,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30
    }
  },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30
    }
  }
}

const sectionTitleVariants = {
  hidden: { 
    opacity: 0, 
    y: 30,
  },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25
    }
  }
}

interface LandingPageProps {
  allowAppAccess: boolean;
}

export const LandingPage = ({ allowAppAccess }: LandingPageProps) => {
  const { colors, isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<InterestFormData>({ email: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const { addNotification } = useNotificationStore();
  const [currentPreview, setCurrentPreview] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false)
  const { t } = useTranslation();
  usePageTracking()
  const [userInteracted, setUserInteracted] = useState(false);
  const [heartClicks, setHeartClicks] = useState(0);
  const heartAnimation = useAnimation();
  const resetTimer = useRef<NodeJS.Timeout>();

  const features = [
    {
      icon: PiListChecks,
      title: t('landing.features.lists'),
      description: t('landing.features.listsDescription'),
      color: colors.success,
    },
    {
      icon: PiPiggyBank,
      title: t('landing.features.finance'),
      description: t('landing.features.financeDescription'),
      color: colors.info,
    },
    {
      icon: PiNotebook,
      title: t('landing.features.notebooks'),
      description: t('landing.features.notebooksDescription'),
      color: colors.warning,
    },
    {
      icon: PiCalendar,
      title: t('landing.features.calendar'),
      description: t('landing.features.calendarDescription'),
      color: colors.primary,
    },
    {
      icon: PiSmileyStickerBold,
      title: t('landing.features.mood'),
      description: t('landing.features.moodDescription'),
      color: colors.warning,
    },
    {
      icon: PiPaintBrush,
      title: t('landing.features.theme'),
      description: t('landing.features.themeDescription'),
      color: colors.tertiary,
    },
    {
      icon: PiTrophy,
      title: t('landing.features.achievements'),
      description: t('landing.features.achievementsDescription'),
      color: colors.info,
    },
    {
      icon: PiSparkle,
      title: t('landing.features.aiInsights'),
      description: t('landing.features.aiInsightsDescription'),
      color: colors.accent2,
    }
  ];

  useEffect(() => {
    let timer: NodeJS.Timeout;
    
    if (!userInteracted) {
      timer = setInterval(() => {
        setCurrentPreview((prev) => (prev + 1) % themePreviews.length);
      }, 7500);
    }

    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      if (timer) clearInterval(timer);
      window.removeEventListener('scroll', handleScroll)
    };
  }, [userInteracted, themePreviews.length]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await interestService.submitInterest(formData);
      setSubmitted(true);
      addNotification({
        type: 'success',
        title: t('landing.interestForm.successTitle'),
        message: t('landing.interestForm.successMessage'),
        duration: 5000
      });
    } catch (error) {
      const appError = error instanceof AppError 
        ? error 
        : new AppError('Failed to submit interest form', 'interest/unknown', 500);
      
      showError(appError, 'LandingPage');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const isUnlocked = sessionStorage.getItem('app_unlock_state') === 'true';
    
    navigate('/login', { 
      state: { unlocked: isUnlocked }
    });
  };

  const handleSmoothScroll = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
      const headerOffset = 96 // This accounts for the header height plus some padding
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + (window.scrollY || window.pageYOffset) - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }

  const handleHeartClick = async () => {
    setHeartClicks(prev => prev + 1);
    
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }
    
    resetTimer.current = setTimeout(() => {
      setHeartClicks(0);
    }, 3000);

    await heartAnimation.start({
      scale: [1, 1.4, 1],
      transition: { duration: 0.3 }
    });

    if (heartClicks + 1 === 7) {
      handleUnlock();
      setHeartClicks(0);
    }
  };

  const handleUnlock = () => {
    setUnlockState(true);
    setUnlockState(true);

    addNotification({
      type: 'success',
      title: '🎉 Developer Mode Unlocked!',
      message: 'Welcome to the testing playground.',
      duration: 5000
    });

    window.location.reload();
  };

  return (
    <motion.div 
      className="min-h-screen overflow-x-hidden"
      initial="hidden"
      animate="visible"
      style={{ backgroundColor: colors.background }}
    >
      {/* Header - Updated for mobile */}
      <motion.header 
        className="fixed top-0 left-0 right-0 z-50 px-4 sm:px-6 lg:px-8 py-4"
        style={{ 
          backgroundColor: colors.background,
          borderBottom: `1px solid ${colors.border}`
        }}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="flex items-center">
            <Logo className="h-8 w-auto" />
          </div>

          <nav className="w-full sm:w-auto">
            <ul className="flex flex-wrap justify-center sm:justify-end items-center gap-3 sm:gap-6">
              <li>
                <button 
                  onClick={() => handleSmoothScroll('features')}
                  className={`${buttonStyles.nav(colors)} text-sm`}
                  style={{ color: colors.text }}
                >
                  {t('landing.features.features')}
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleSmoothScroll('ai-insights')}
                  className={`${buttonStyles.nav(colors)} text-sm`}
                  style={{ color: colors.text }}
                >
                  {t('landing.features.aiInsights')}
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleSmoothScroll('testimonials')}
                  className={`${buttonStyles.nav(colors)} text-sm`}
                  style={{ color: colors.text }}
                >
                  {t('landing.features.testimonials')}
                </button>
              </li>
              <li>
                <DarkModeToggle />
              </li>
              {shouldAllowAccess(allowAppAccess) ? (
                <li className="w-full flex justify-center sm:w-auto">
                  <motion.button
                    onClick={handleLoginClick}
                    className={`${buttonStyles.base} ${buttonStyles.gradient(colors)}`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ color: colors.text }}
                  >
                    {t('auth.login')} <PiArrowRight className="ml-2 h-5 w-5 inline animate-pulse" style={{ color: colors.text }}/>
                  </motion.button>
                </li>
              ) : (
                <li className="w-full flex justify-center sm:w-auto">
                  <motion.button
                    onClick={() => handleSmoothScroll('interest-signup')}
                    className={buttonStyles.headerButton(colors)}
                    style={{ color: colors.text }}
                  >
                    {t('landing.features.joinWaitlist')} <PiArrowRight className="ml-2 h-3 w-3 inline" style={{ color: colors.text }} />
                  </motion.button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </motion.header>

      {/* Increase top padding for mobile */}
      <div className="pt-40 sm:pt-24">
        {/* Hero Section - Updated padding */}
        <motion.section 
          className="py-16 sm:py-20 px-4 sm:px-6 lg:px-8 text-center relative overflow-hidden"
          style={{ backgroundColor: colors.surfaceAlt }}
          variants={staggerContainer}
        >
          <div 
            className="absolute inset-0 opacity-10 z-0"
            style={{ 
              background: `linear-gradient(to right, ${colors.primary}, ${colors.accent1})`
            }}
          />
          <div className="relative z-10">
          <motion.h1 
            className="text-3xl sm:text-5xl lg:text-6xl font-extrabold mb-6 leading-tight"
            variants={fadeInUp}
            style={{ color: colors.text }}
          >
            {t('common.your')} {" "}
            <span 
              className="text-transparent bg-clip-text"
              style={{ 
                backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.accent1})`
              }}
            >
              {t('common.allInOne')}
            </span>{" "}
            {t('common.planner')},{" "}
            <span 
              className="text-transparent bg-clip-text"
              style={{ 
                backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.accent1})`
              }}
            >
              {t('common.organized')}
            </span>{" "}
            <span 
              className="text-transparent bg-clip-text"
              style={{ color: colors.text }}
            >
              {t('common.and')}
            </span>{" "}
            <span 
              className="text-transparent bg-clip-text"
              style={{ 
                backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.accent1})`
              }}
            >
              {t('common.smarter')}
            </span>
          </motion.h1>
            <motion.p 
              className="text-base sm:text-xl mb-8 max-w-3xl mx-auto px-4"
              variants={fadeInUp}
              style={{ color: colors.textSecondary }}
            >
              {t('landing.hero.description')}
            </motion.p>
            <motion.div 
              className="flex justify-center gap-4"
              variants={fadeInUp}
            >
              {shouldAllowAccess(allowAppAccess) ? (
                <motion.button
                  onClick={handleLoginClick}
                  className={`${buttonStyles.base} ${buttonStyles.gradient(colors)}`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{ color: colors.text }}
                >
                  {t('common.getStarted')} <PiArrowRight className="ml-2 h-5 w-5 inline animate-pulse" style={{ color: colors.text }}/>
                </motion.button>
              ) : (
                <motion.button
                  onClick={() => handleSmoothScroll('interest-signup')}
                  className={`${buttonStyles.base} ${buttonStyles.gradient(colors)}`}
                  style={{ color: colors.text }}
                >
                  {t('landing.features.joinWaitlist')}
                </motion.button>
              )}
            </motion.div>
          </div>

          {/* Update app preview image container for mobile */}
          <motion.div 
            className="mt-16 relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8"
            variants={heroImageVariants}
          >
            <div 
              className="relative rounded-xl overflow-hidden shadow-2xl"
              style={{ 
                border: `4px solid ${colors.surface}`,
                background: colors.surface,
                cursor: 'grab'
              }}
              onTouchStart={() => {}}
              onTouchMove={() => {}}
            >
              <div className="overflow-x-auto">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentPreview}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                    className="relative"
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={0.2}
                    onDragEnd={(_, info) => {
                      setUserInteracted(true);
                      if (Math.abs(info.offset.x) > 100) {
                        if (info.offset.x > 0) {
                          // Swiping right (going backwards)
                          setCurrentPreview((prev) => 
                            prev === 0 ? themePreviews.length - 1 : prev - 1
                          );
                        } else {
                          // Swiping left (going forwards)
                          setCurrentPreview((prev) => 
                            (prev + 1) % themePreviews.length
                          );
                        }
                        // Reset the timer after 10 seconds of user inactivity
                        setTimeout(() => {
                          setUserInteracted(false);
                        }, 10000);
                      }
                    }}
                  >
                    <img 
                      src={themePreviews[currentPreview].image}
                      alt={`Tydy App Preview - Theme ${currentPreview + 1}`}
                      className="w-full aspect-[16/9] object-cover"
                      loading="lazy"
                    />
                    
                    {/* Theme indicator dots */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
                      {themePreviews.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setCurrentPreview(index);
                            setUserInteracted(true);
                            // Reset the timer after 10 seconds of user inactivity
                            setTimeout(() => {
                              setUserInteracted(false);
                            }, 10000);
                          }}
                          className={`w-2 h-2 rounded-full transition-all ${
                            index === currentPreview ? 'w-6' : ''
                          }`}
                          style={{
                            backgroundColor: index === currentPreview 
                              ? themePreviews[currentPreview].colors.primary 
                              : `${colors.primary}40`
                          }}
                        />
                      ))}
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </motion.div>
        </motion.section>

        {/* Features Grid */}
        <motion.section 
          id="features" 
          className="py-20 px-4 sm:px-6 lg:px-8"
          style={{ backgroundColor: colors.background }}
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-4xl font-bold text-center mb-16"
            style={{ color: colors.primary }}
            variants={sectionTitleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            Everything You Need, All in One Place
          </motion.h2>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto"
            variants={staggerContainer}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="p-8 rounded-xl"
                style={{ 
                  backgroundColor: colors.surface,
                  boxShadow: `0 4px 20px ${colors.shadow}20`,
                  border: `1px solid ${colors.border}`,
                }}
                variants={featureCardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                whileHover={{ 
                  y: -8,
                  transition: { type: "spring", stiffness: 300, damping: 20 }
                }}
                whileTap={{ scale: 0.98 }}
              >
                <div 
                  className="p-3 rounded-full inline-block mb-6"
                  style={{ 
                    backgroundColor: `${feature.color}15`,
                    color: feature.color
                  }}
                >
                  <feature.icon className="h-8 w-8" />
                </div>
                <h3 
                  className="text-2xl font-semibold mb-4"
                  style={{ color: colors.text }}
                >
                  {feature.title}
                </h3>
                <p style={{ color: colors.textSecondary }}>
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </motion.section>

        {/* AI Insights Section */}
        <motion.section 
          id="ai-insights" 
          className="py-20 px-4 sm:px-6 lg:px-8"
          style={{ 
            background: `linear-gradient(to right, ${colors.primary}, ${colors.accent1})`,
            color: 'white'
          }}
          variants={staggerContainer}
        >
          <div className="max-w-5xl mx-auto">
            <motion.div 
              className="text-center mb-16"
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
            >
              <motion.div variants={fadeInUp}>
                <PiSparkle className="h-16 w-16 text-yellow-300 mx-auto mb-6" />
              </motion.div>
              <motion.h2 
                className="text-4xl font-bold mb-6"
                variants={fadeInUp}
              >
                AI Insights: Your Personal Life Assistant
              </motion.h2>
              <motion.p 
                className="text-xl opacity-90 mb-8"
                variants={fadeInUp}
              >
                Harness the power of AI to gain deep insights into your life. Ask questions, get personalized advice,
                and make informed decisions based on your own data.
              </motion.p>
            </motion.div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div className="bg-white bg-opacity-10 backdrop-blur-lg p-8 rounded-xl">
                <h3 className="text-2xl font-semibold mb-4">Example Insights</h3>
                <ul className="space-y-4">
                  {[
                    "Based on your spending habits, you could save an extra $300 this month by cutting back on dining out.",
                    "Your productivity peaks on Tuesdays and Wednesdays. Consider scheduling important tasks on these days.",
                    "Your mood seems to improve after completing tasks in your 'Personal Growth' list. Maybe focus more on these activities?"
                  ].map((insight, index) => (
                    <motion.li 
                      key={index}
                      className="flex items-start"
                      variants={scrollReveal}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, margin: "-100px" }}
                    >
                      <PiCheckCircle className="h-6 w-6 text-green-400 mr-3 flex-shrink-0 mt-1" />
                      <p>{insight}</p>
                    </motion.li>
                  ))}
                </ul>
              </div>
              <div className="relative flex items-center">
                <motion.div 
                  className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl overflow-hidden w-full"
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                >
                  <img 
                    src="/app/ai-dashboard.gif" 
                    alt="AI Dashboard Demo"
                    className="w-full h-full object-cover"
                    loading="lazy"
                    onError={(e) => {
                      e.currentTarget.style.display = 'none';
                      e.currentTarget.nextElementSibling?.classList.remove('hidden');
                    }}
                  />
                  <div className="hidden w-full h-full bg-gray-100 flex items-center justify-center">
                    <span className="text-gray-400">AI Dashboard Preview</span>
                  </div>
                </motion.div>
                <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-full shadow-lg">
                  <PiSparkle className="h-10 w-10 text-yellow-400" />
                </div>
              </div>
            </div>
          </div>
        </motion.section>

        {/* Testimonials Section */}
        <motion.section 
          id="testimonials" 
          className="py-20 px-4 sm:px-6 lg:px-8"
          style={{ backgroundColor: colors.surfaceAlt }}
          variants={staggerContainer}
        >
          <motion.h2 
            className="text-4xl font-bold text-center mb-16"
            style={{ color: colors.primary }}
            variants={sectionTitleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            What Our Beta Users Say
          </motion.h2>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-6xl mx-auto"
            variants={staggerContainer}
          >
            {[
              {
                name: "Alex Thompson",
                role: "Entrepreneur",
                quote:
                  "Tydy has transformed how I manage my life. It's like having a personal assistant and life coach rolled into one!",
                avatar: "/avatars/alex.png"
              },
              {
                name: "Samantha Lee",
                role: "Product Manager",
                quote:
                  "The AI insights are game-changing. It's helping me make better decisions and reach my goals faster.",
                avatar: "/avatars/samantha.png"
              },
              {
                name: "Jordan Patel",
                role: "Freelance Designer",
                quote:
                  "I love how everything is connected. My finances, tasks, and mood all in one place. It's brilliant!",
                avatar: "/avatars/jordan.png"
              }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                className="p-8 rounded-xl"
                style={{ 
                  backgroundColor: colors.surface,
                  boxShadow: `0 4px 20px ${colors.shadow}20`,
                  border: `1px solid ${colors.border}`,
                }}
                variants={featureCardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                whileHover={{ 
                  y: -8,
                  transition: { type: "spring", stiffness: 300, damping: 20 }
                }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center mb-6">
                  <img 
                    src={testimonial.avatar}
                    alt={`${testimonial.name}'s avatar`}
                    className="w-12 h-12 rounded-full mr-4 object-cover"
                    onError={(e) => {
                      e.currentTarget.style.display = 'none';
                      e.currentTarget.nextElementSibling?.classList.remove('hidden');
                    }}
                  />
                  <div 
                    className="hidden w-12 h-12 rounded-full mr-4"
                    style={{ backgroundColor: `${colors.primary}15` }}
                  />
                  <div>
                    <p 
                      className="font-semibold"
                      style={{ color: colors.text }}
                    >
                      {testimonial.name}
                    </p>
                    <p style={{ color: colors.textSecondary }}>
                      {testimonial.role}
                    </p>
                  </div>
                </div>
                <p 
                  className="italic"
                  style={{ color: colors.textSecondary }}
                >
                  "{testimonial.quote}"
                </p>
              </motion.div>
            ))}
          </motion.div>
        </motion.section>

        {/* Interest Signup Section */}
        <motion.section
          id="interest-signup"
          className="py-20 px-4 sm:px-6 lg:px-8 text-center"
          style={{ 
            background: `linear-gradient(to left, ${colors.primary}, ${colors.accent1})`,
            color: 'white'
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                duration: 0.6,
                staggerChildren: 0.2
              }
            }
          }}
        >
          <div className="max-w-4xl mx-auto">
            <motion.h2 
              className="text-4xl font-bold mb-6"
              variants={fadeInUp}
            >
              Ready to Tydy Up Your Life?
            </motion.h2>
            <motion.p 
              className="text-xl mb-8 opacity-90"
              variants={fadeInUp}
            >
              Be among the first to experience Tydy's powerful AI-driven productivity. Sign up below
              and we'll let you know when we're ready for you.
            </motion.p>

            {!submitted ? (
              <motion.div 
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                className="max-w-md mx-auto p-8 rounded-xl"
                style={{ 
                  backgroundColor: isDarkMode 
                    ? `${colors.surface}` 
                    : `${colors.surface}`,
                  border: `2px solid rgba(255, 255, 255, 0.1)`,
                  boxShadow: `
                    0 4px 24px -2px ${colors.primary}40,
                    0 12px 40px -4px rgba(0, 0, 0, 0.3),
                    inset 0 0 80px rgba(255, 255, 255, 0.05)
                  `,
                  backdropFilter: 'blur(16px)',
                  WebkitBackdropFilter: 'blur(16px)'
                }}
              >
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border text-base bg-white text-gray-900"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Your name (optional)"
                      value={formData.name || ''}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border text-base bg-white text-gray-900"
                    />
                  </div>
                  <div>
                    <textarea
                      placeholder="How do you plan to use Tydy? (optional)"
                      value={formData.useCase || ''}
                      onChange={(e) => setFormData({ ...formData, useCase: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border text-base min-h-[100px] bg-white text-gray-900"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full ${buttonStyles.base} ${buttonStyles.gradient(colors)}`}
                    style={{
                      opacity: isSubmitting ? 0.7 : 1,
                      backgroundColor: isSubmitting ? colors.primary : undefined,
                      border: `2px solid ${colors.primary}`,
                      boxShadow: `0 4px 12px -2px ${colors.primary}40`,
                      color: isDarkMode ? 'white' : colors.primary
                    }}
                  >
                    <span className="relative z-10" style={{ color: isDarkMode ? 'white' : colors.primary }}>
                      {isSubmitting ? (
                        <div className="flex items-center justify-center">
                          <div 
                            className="w-5 h-5 border-2 border-t-transparent rounded-full animate-spin mr-2"
                            style={{ borderColor: isDarkMode ? 'white' : colors.primary, borderTopColor: 'transparent' }}
                          />
                          Submitting...
                        </div>
                      ) : (
                        'Join Waitlist'
                      )}
                    </span>
                  </button>
                </form>
              </motion.div>
            ) : (
              <motion.div 
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                className="max-w-md mx-auto p-8 rounded-xl"
                style={{ 
                  backgroundColor: isDarkMode 
                    ? `${colors.surface}` 
                    : `${colors.surface}`,
                  border: `2px solid rgba(255, 255, 255, 0.1)`,
                  boxShadow: `
                    0 4px 24px -2px ${colors.primary}40,
                    0 12px 40px -4px rgba(0, 0, 0, 0.3),
                    inset 0 0 80px rgba(255, 255, 255, 0.05)
                  `,
                  backdropFilter: 'blur(16px)',
                  WebkitBackdropFilter: 'blur(16px)'
                }}
              >
                <PiSparkle className="h-12 w-12 text-yellow-300 mx-auto mb-4" />
                <h3 className="text-xl font-semibold mb-3">Thank you for your interest!</h3>
                <p className="opacity-90">
                  We appreciate your excitement. We'll let you know as soon as you can start using Tydy.
                </p>
              </motion.div>
            )}
          </div>
        </motion.section>

        {/* Footer */}
        <footer 
          className="py-12 px-4 sm:px-6 lg:px-8"
          style={{ backgroundColor: colors.surface }}
        >
          <div className="max-w-6xl mx-auto flex flex-col sm:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 sm:mb-0">
              <Logo size={24} />
              <span 
                className="text-xl font-bold"
                style={{ color: colors.primary }}
              >
                Tydy
              </span>
            </div>
            <div className="flex flex-wrap justify-center sm:justify-end gap-6">
              {[
                { label: "About Us", href: "#" },
                { label: "Contact Us", href: "mailto:superman50196@gmail.com?subject=Hello Tydy!" },
                { label: "Privacy", href: "#" },
                { label: "Terms", href: "#" }
              ].map((link) => (
                <a
                  key={link.label}
                  href={link.href}
                  className="text-sm transition-colors"
                  style={{ 
                    color: colors.textSecondary,
                    "&:hover": { color: colors.primary }
                  }}
                >
                  {link.label}
                </a>
              ))}
            </div>
          </div>
          <div className="mt-8 text-center text-sm" style={{ color: colors.textSecondary }}>
            <p>© {new Date().getFullYear()} Tydy. All rights reserved.</p>
            <div className="mt-2 flex items-center justify-center gap-1">
              Made with{' '}
              <motion.span 
                animate={heartAnimation}
                onClick={handleHeartClick}
                className="select-none"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <PiHeart className="h-4 w-4 text-red-500" />
              </motion.span>
              {' '}for organized minds everywhere.
            </div>
          </div>
        </footer>

        {error && (
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        )}

        <AnimatePresence>
          {showScrollTop && (
            <motion.button
              className="fixed bottom-8 right-8 p-3 rounded-full shadow-lg z-50"
              style={{ 
                backgroundColor: colors.primary,
                color: 'white'
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <PiCaretDown className="h-6 w-6 rotate-180" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};