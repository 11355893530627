import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { UserSubscription, User } from '../types/user';
import { SubscriptionTier } from '../types/subscription';
import { AppError } from '../utils/errors';
import { activityService } from './activityService';
import { analyticsService, AnalyticsEventTypes } from './analyticsService';
import { useStore } from '../store/useStore';
import { addMonths } from 'date-fns';
import { aiCreditService } from './aiCreditService';
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';


// Simple in-memory cache for subscription checks
const subscriptionCheckCache = new Map<string, {
  tier: SubscriptionTier;
  timestamp: number;
}>();

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const subscriptionService = {
  async checkAccess(userId: string, requiredTier: SubscriptionTier): Promise<void> {
    // Check cache first
    const cached = subscriptionCheckCache.get(userId);
    const now = Date.now();
    
    if (cached && (now - cached.timestamp < CACHE_DURATION)) {
      const tiers = ['free', 'core', 'pro'];
      const requiredIndex = tiers.indexOf(requiredTier);
      const userIndex = tiers.indexOf(cached.tier);

      if (userIndex < requiredIndex) {
        throw new AppError(
          `This feature requires ${requiredTier} subscription or higher`,
          'SUBSCRIPTION_REQUIRED'
        );
      }
      return;
    }

    // If not in cache or expired, check database
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new AppError('User not found', 'USER_NOT_FOUND');
    }

    const userTier = userDoc.data()?.subscription?.tier || 'free';
    
    // Update cache
    subscriptionCheckCache.set(userId, {
      tier: userTier,
      timestamp: now
    });

    // Updated tier hierarchy
    const tierHierarchy = {
      'free': 0,
      'core': 1,
      'pro': 2
    };

    if (tierHierarchy[userTier] < tierHierarchy[requiredTier]) {
      // Track failed access attempts
      await analyticsService.trackEvent(AnalyticsEventTypes.SUBSCRIPTION_ACCESS_DENIED, {
        requiredTier,
        userTier,
        featureAttempted: requiredTier
      });
      
      throw new AppError(
        `This feature requires ${requiredTier} subscription or higher`,
        'SUBSCRIPTION_REQUIRED'
      );
    }
  },

  async upgradeSubscription(
    userId: string, 
    tier: SubscriptionTier
  ): Promise<{ subscription: UserSubscription; userData: any }> {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new AppError('User not found', 'USER_NOT_FOUND');
      }

      const currentTier = userDoc.data()?.subscription?.tier || 'free';
      
      const subscription: UserSubscription = {
        tier,
        startDate: new Date(),
        status: 'active',
        features: {
          finance: tier !== 'free',
          calendar: tier === 'pro',
        }
      };

      // Update Firestore
      await updateDoc(userRef, {
        'subscription.tier': tier,
        'subscription.startDate': serverTimestamp(),
        'subscription.status': 'active',
        'subscription.features': subscription.features,
        updatedAt: serverTimestamp()
      });

      // Clear subscription cache
      subscriptionCheckCache.clear();

      // Get updated user data
      const updatedUserDoc = await getDoc(userRef);
      const userData = updatedUserDoc.exists() ? {
        ...updatedUserDoc.data(),
        id: updatedUserDoc.id,
        subscription: {
          ...subscription,
          startDate: new Date()
        }
      } : null;

      // Track activity
      await activityService.createActivity(
        'subscription_upgraded',
        `Upgraded to ${tier} subscription`,
        '',
        { fromTier: currentTier, toTier: tier },
        userId,
        'subscription'
      );

      // Track analytics
      await analyticsService.trackEvent(
        AnalyticsEventTypes.SUBSCRIPTION_UPGRADED,
        {
          fromTier: currentTier,
          toTier: tier
        }
      );

      return { subscription, userData };
    } catch (error) {
      console.error('Subscription upgrade error:', error);
      throw new AppError(
        'Failed to update subscription',
        'SUBSCRIPTION_UPDATE_FAILED',
        { cause: error }
      );
    }
  },

  async upgradeTier(userId: string, newTier: SubscriptionTier): Promise<void> {
    try {
      if (!userId) throw new AppError('USER_ID_REQUIRED', 'User ID is required')

      const userRef = doc(db, 'users', userId)
      
      // Update subscription in user document
      await updateDoc(userRef, {
        'subscription.tier': newTier,
        'subscription.startDate': serverTimestamp(),
        'subscription.status': 'active',
        'subscription.currentPeriodEnd': addMonths(new Date(), 1),
        updatedAt: serverTimestamp()
      })

      // Sync AI credits with new subscription tier
      await aiCreditService.syncCreditsWithSubscription(userId, newTier)

    } catch (error) {
      console.error('Failed to upgrade subscription:', error)
      throw new AppError(
        'UPGRADE_FAILED',
        'Failed to upgrade subscription',
        { cause: error }
      )
    }
  },

  async downgradeTier(userId: string): Promise<void> {
    try {
      if (!userId) throw new AppError('USER_ID_REQUIRED', 'User ID is required')

      const userRef = doc(db, 'users', userId)
      
      // Update subscription in user document
      await updateDoc(userRef, {
        'subscription.tier': 'free',
        'subscription.status': 'active',
        'subscription.currentPeriodEnd': addMonths(new Date(), 1),
        updatedAt: serverTimestamp()
      })

      // Sync AI credits with free tier
      await aiCreditService.syncCreditsWithSubscription(userId, 'free')

    } catch (error) {
      console.error('Failed to downgrade subscription:', error)
      throw new AppError(
        'DOWNGRADE_FAILED',
        'Failed to downgrade subscription',
        { cause: error }
      )
    }
  },

  async cancelSubscription(userId: string): Promise<void> {
    try {
      if (!userId) throw new AppError('USER_ID_REQUIRED', 'User ID is required')

      const userRef = doc(db, 'users', userId)
      
      await updateDoc(userRef, {
        'subscription.status': 'cancelled',
        updatedAt: serverTimestamp()
      })

    } catch (error) {
      console.error('Failed to cancel subscription:', error)
      throw new AppError(
        'CANCEL_FAILED',
        'Failed to cancel subscription',
        { cause: error }
      )
    }
  },

  async updateSubscription(userId: string, tier: SubscriptionTier): Promise<void> {
    try {
      // Update subscription in user doc
      await updateDoc(doc(db, 'users', userId), {
        'subscription.tier': tier,
        'subscription.status': 'active',
        'subscription.currentPeriodEnd': addMonths(new Date(), 1)
      })

      // Reset credits for new tier
      await aiCreditService.resetMonthlyCredits(userId, tier)
      
    } catch (error) {
      throw new AppError('Failed to update subscription', 'SUBSCRIPTION_UPDATE_FAILED')
    }
  }
}; 