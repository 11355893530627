import { ThemeCustomizer } from '../components/theme/ThemeCustomizer';
import { useTheme } from '../contexts/ThemeContext';
import { useState } from 'react';
import { 
  PiPaintBrush, 
  PiPlus,
  PiPencilSimple,
  PiTrash,
  PiStar
} from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import { containerVariants, itemVariants } from '../components/animations';

// Add theme-specific variants
const previewVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 30
    }
  }
};

const buttonVariants = {
  initial: { scale: 1 },
  hover: { scale: 1.02 },
  tap: { scale: 0.98 }
};

const ThemePreview = () => {
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState<string | null>(null);
  const [textValue, setValue] = useState('Try different theme colors for this text by selecting a color from the dropdown');
  const [textColorType, setTextColorType] = useState('text');

  const getTextColor = (type: string) => {
    switch(type) {
      case 'primary': return colors.primary;
      case 'secondary': return colors.secondary;
      case 'tertiary': return colors.tertiary;
      case 'quaternary': return colors.quaternary;
      default: return colors.text;
    }
  };

  const colorOptions = [
    { value: 'text', label: 'Default Text' },
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'tertiary', label: 'Tertiary' },
    { value: 'quaternary', label: 'Quaternary' }
  ];

  const interactiveElements = [
    {
      id: 'buttons',
      title: 'Buttons',
      content: (
        <div className="space-y-3">
          <button
            className="w-full px-4 py-2 rounded-lg transition-all duration-200"
            style={{ 
              backgroundColor: colors.primary,
              color: 'white',
              transform: isHovered === 'primary' ? 'scale(1.02)' : 'scale(1)'
            }}
            onMouseEnter={() => setIsHovered('primary')}
            onMouseLeave={() => setIsHovered(null)}
          >
            Primary Button
          </button>
          <button
            className="w-full px-4 py-2 rounded-lg transition-all duration-200"
            style={{ 
              backgroundColor: colors.secondary,
              color: 'white',
              transform: isHovered === 'secondary' ? 'scale(1.02)' : 'scale(1)'
            }}
            onMouseEnter={() => setIsHovered('secondary')}
            onMouseLeave={() => setIsHovered(null)}
          >
            Secondary Button
          </button>
          <button
            className="w-full px-4 py-2 rounded-lg transition-all duration-200"
            style={{ 
              backgroundColor: colors.tertiary,
              color: 'white',
              transform: isHovered === 'tertiary' ? 'scale(1.02)' : 'scale(1)'
            }}
            onMouseEnter={() => setIsHovered('tertiary')}
            onMouseLeave={() => setIsHovered(null)}
          >
            Tertiary Button
          </button>
        </div>
      )
    },
    {
      id: 'inputs',
      title: 'Text Input',
      content: (
        <div className="space-y-2">
          <div className="flex gap-2 items-start">
            <textarea
              value={textValue}
              onChange={(e) => setValue(e.target.value)}
              rows={3}
              className="flex-1 px-3 py-2 rounded-lg border transition-all duration-200 resize-none"
              style={{
                backgroundColor: colors.background,
                borderColor: isHovered === 'textarea' ? colors.primary : colors.border,
                color: getTextColor(textColorType),
                outline: 'none'
              }}
              onMouseEnter={() => setIsHovered('textarea')}
              onMouseLeave={() => setIsHovered(null)}
              onFocus={() => setIsHovered('textarea')}
              onBlur={() => setIsHovered(null)}
            />
            <select
              value={textColorType}
              onChange={(e) => setTextColorType(e.target.value)}
              className="px-2 py-2 rounded-lg border text-sm"
              style={{
                backgroundColor: colors.background,
                borderColor: colors.border,
                color: colors.text
              }}
            >
              {colorOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )
    },
    {
      id: 'toggles',
      title: 'Toggles & Checkboxes',
      content: (
        <div className="space-y-3">
          <label 
            className="flex items-center gap-2 cursor-pointer group"
            onMouseEnter={() => setIsHovered('checkbox')}
            onMouseLeave={() => setIsHovered(null)}
          >
            <input
              type="checkbox"
              className="rounded transition-all duration-200"
              style={{
                accentColor: colors.primary,
                transform: isHovered === 'checkbox' ? 'scale(1.1)' : 'scale(1)'
              }}
            />
            <span 
              className="transition-colors duration-200"
              style={{ 
                color: isHovered === 'checkbox' ? colors.primary : colors.text
              }}
            >
              Interactive Checkbox
            </span>
          </label>
          <label 
            className="flex items-center gap-2 cursor-pointer"
            onMouseEnter={() => setIsHovered('radio')}
            onMouseLeave={() => setIsHovered(null)}
          >
            <input
              type="radio"
              name="radio"
              className="transition-all duration-200"
              style={{
                accentColor: colors.primary,
                transform: isHovered === 'radio' ? 'scale(1.1)' : 'scale(1)'
              }}
            />
            <span 
              className="transition-colors duration-200"
              style={{ 
                color: isHovered === 'radio' ? colors.primary : colors.text
              }}
            >
              Interactive Radio
            </span>
          </label>
        </div>
      )
    }
  ];

  return (
    <motion.div
      variants={previewVariants}
      initial="hidden"
      animate="visible"
      className="space-y-8"
    >
      {/* Interactive Elements */}
      <div className="space-y-6">
        {interactiveElements.map((element, index) => (
          <motion.div 
            key={element.id} 
            variants={itemVariants}
            custom={index}
            className="space-y-2"
          >
            <h4 
              style={{ color: colors.textSecondary }} 
              className="text-sm font-medium"
            >
              {element.title}
            </h4>
            {element.content}
          </motion.div>
        ))}
      </div>

      {/* Content Cards */}
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
        variants={containerVariants}
      >
        {/* Main Content Card */}
        <motion.div 
          className="rounded-lg p-6 accent-border-left"
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
          style={{ 
            backgroundColor: colors.surface,
            borderColor: colors.primary 
          }}
        >
          <h4 style={{ color: colors.quaternary }} className="text-lg font-semibold mb-2">
            Content Card
          </h4>
          <p style={{ color: colors.text }} className="mb-4">
            Main content with <span style={{ color: colors.accent1 }}>accent highlights</span> and
            <span style={{ color: colors.accent2 }}> secondary accents</span>.
          </p>
          <div className="flex gap-2">
            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="px-3 py-1 rounded text-sm"
              style={{
                backgroundColor: `${colors.primary}20`,
                color: colors.primary
              }}
            >
              Action
            </motion.button>
            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="px-3 py-1 rounded text-sm"
              style={{
                backgroundColor: `${colors.secondary}20`,
                color: colors.secondary
              }}
            >
              Another Action
            </motion.button>
          </div>
        </motion.div>

        {/* Status Card */}
        <motion.div 
          className="rounded-lg p-6"
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
          style={{ backgroundColor: colors.surface }}
        >
          <h4 style={{ color: colors.primary }} className="text-lg font-semibold mb-4">
            Status Colors
          </h4>
          <motion.div 
            className="space-y-2"
            variants={containerVariants}
          >
            {['success', 'error', 'warning', 'info'].map((status, index) => (
              <motion.div 
                key={status}
                variants={itemVariants}
                custom={index}
                className="flex items-center gap-2"
              >
                <motion.div 
                  className="w-4 h-4 rounded-full"
                  style={{ backgroundColor: colors[status] }}
                  whileHover={{ scale: 1.2 }}
                />
                <span style={{ color: colors.text }}>
                  {status.charAt(0).toUpperCase() + status.slice(1)} State
                </span>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export const ThemePage = () => {
  return (
    <motion.div 
      className="max-w-4xl mx-auto p-4 space-y-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={itemVariants}>
        <ThemeCustomizer />
      </motion.div>
      <motion.div variants={itemVariants}>
        <ThemePreview />
      </motion.div>
    </motion.div>
  );
}; 