import { 
  PiSmileyWink,
  PiSmiley,
  PiSmileyMeh,
  PiSmileyStickerLight,
  PiSmileySad,
  PiSmileyXEyes
} from 'react-icons/pi'
import type { Icon } from '@phosphor-icons/react'

export const moodTypes = {
  Amazing: {
    label: 'Amazing',
    color: '#22c55e', // Adjust colors as needed
    value: 5,
    description: 'Feeling fantastic!'


  },
  Happy: {
    label: 'Happy',
    color: '#84cc16',
    value: 4,
    description: 'In a good mood'
  },
  Neutral: {
    label: 'Neutral',
    color: '#facc15',
    value: 3,
    description: 'Feeling okay'
  },
  Meh: {
    label: 'Meh',
    color: '#f97316',
    value: 2,
    description: 'Not great, not terrible'
  },
  Sad: {
    label: 'Sad',
    color: '#ef4444',
    value: 1,
    description: 'Feeling down'
  },
  Terrible: {
    label: 'Terrible',
    color: '#7f1d1d',
    value: 0,
    description: 'Having a rough time'
  }
} as const

export type MoodType = keyof typeof moodTypes

export type DayPeriod = 'morning' | 'afternoon' | 'evening' | 'night';

export interface WeatherInfo {
  condition: string
  temperature: number
  location: string
}

export interface MoodEntry {
  id: string
  date: Date
  period: DayPeriod
  mood: MoodType
  note?: string
  activities?: string[]
  weather?: WeatherInfo
  createdAt: Date
  updatedAt?: Date
}

export interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

export interface FirestoreMoodEntry {
  id: string;
  userId: string;
  date: FirestoreTimestamp;
  period: DayPeriod;
  mood: MoodType;
  note?: string;
  activities?: string[];
  weather?: string;
  createdAt: FirestoreTimestamp;
  updatedAt: FirestoreTimestamp;
}

export const periodLabels: Record<DayPeriod, { label: string, time: string }> = {
  morning: { label: 'Morning', time: '5am - 12pm' },
  afternoon: { label: 'Afternoon', time: '12pm - 5pm' },
  evening: { label: 'Evening', time: '5pm - 9pm' },
  night: { label: 'Night', time: '9pm - 5am' }
} as const;

export const moodIcons: Record<MoodType, Icon> = {
  Amazing: PiSmileyWink,
  Happy: PiSmiley,
  Neutral: PiSmileyMeh,
  Meh: PiSmileyStickerLight,
  Sad: PiSmileySad,
  Terrible: PiSmileyXEyes
} as const 