import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { AppError } from '../utils/errors';
import type { InterestFormData } from '../types/interest';

interface InterestSignup {
  email: string;
  name?: string;
  useCase?: string;
  createdAt: Date;
}

export const interestService = {
  submitInterest: async (formData: InterestFormData): Promise<void> => {
    try {
      await addDoc(collection(db, 'interests'), {
        ...formData,
        createdAt: serverTimestamp(),
        status: 'pending'
      });
    } catch (error) {
      console.error('Error submitting interest:', error);
      throw new AppError(
        'Failed to submit interest form',
        'interest/submit-failed',
        500
      );
    }
  }
}; 