import { useTheme } from '../contexts/ThemeContext';
import { useGamificationStore } from '../store/useGamificationStore';
import { ACHIEVEMENTS } from '../constants/achievements';
import { LevelProgress } from '../components/gamification/LevelProgress';
import { AchievementsGrid } from '../components/gamification/AchievementsGrid';
import { StatsOverview } from '../components/gamification/StatsOverview';
import { StreaksOverview } from '../components/gamification/StreaksOverview';
import { 
  PiTrophy, 
  PiChartLineUp, 
  PiStarFour, 
  PiCrown,
} from 'react-icons/pi';
import { motion } from 'framer-motion';

export const AchievementsPage = () => {
  const { colors } = useTheme();
  const { 
    level, 
    xp, 
    achievements, 
  } = useGamificationStore();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="p-4 space-y-8"
    >
      <motion.div variants={itemVariants} className="flex items-center gap-3">
        <PiTrophy 
          className="w-8 h-8" 
          style={{ color: colors.primary }} 
        />
        <div>
          <h1 
            className="text-2xl font-semibold mb-2"
            style={{ color: colors.primary }}
          >
            Achievements & Progress
          </h1>
          <p style={{ color: colors.textSecondary }}>
            Track your journey and unlock achievements
          </p>
        </div>
      </motion.div>

      {/* Stats and Progress Section */}
      <motion.div variants={itemVariants} className="space-y-6">
        {/* Stats Overview */}
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatsOverview
            achievementCount={achievements.length}
            totalXP={xp}
            level={level}
          />
        </div>

        {/* Level Progress */}
        <motion.div variants={itemVariants}>
          <div className="flex items-center gap-2 mb-4">
            <PiCrown 
              className="w-6 h-6" 
              style={{ color: colors.primary }} 
            />
            <h2 
              className="text-lg md:text-xl font-semibold"
              style={{ color: colors.primary }}
            >
              Level Progress
            </h2>
          </div>
          <LevelProgress level={level} xp={xp} />
        </motion.div>

        {/* Active Streaks */}
        <motion.div variants={itemVariants}>
          <div className="flex items-center gap-2 mb-4">
            <PiChartLineUp 
              className="w-6 h-6" 
              style={{ color: colors.primary }} 
            />
            <h2 
              className="text-lg md:text-xl font-semibold"
              style={{ color: colors.primary }}
            >
              Active Streaks
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <StreaksOverview />
          </div>
        </motion.div>
      </motion.div>

      {/* Achievements Collection */}
      <motion.div variants={itemVariants} className="mt-12">
        <div className="flex items-center gap-2 mb-4">
          <PiStarFour 
            className="w-6 h-6" 
            style={{ color: colors.primary }} 
          />
          <h2 
            className="text-lg md:text-xl font-semibold"
            style={{ color: colors.primary }}
          >
            Achievements Collection
          </h2>
        </div>
        <AchievementsGrid
          achievements={ACHIEVEMENTS}
          userAchievements={achievements}
          showSecret={true}
        />
      </motion.div>
    </motion.div>
  );
}; 