import { useTheme } from '../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import { PriorityLevel } from '../../types/lists'
import { PiFlag } from 'react-icons/pi'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface PrioritySelectProps {
  value: PriorityLevel
  onChange: (value: PriorityLevel) => void
  priorityColors: Record<string, string>
  iconSize?: number
}

export function PrioritySelect({ 
  value, 
  onChange, 
  priorityColors,
  iconSize = 5
}: PrioritySelectProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  // Normalize the value to lowercase for consistency with priorityColors
  const normalizedValue = (value || 'none').toLowerCase()

  return (
    <div className="relative">
      <motion.button
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}
        className="p-1 rounded hover:bg-opacity-10 transition-colors flex items-center justify-center"
        style={{ 
          color: normalizedValue === 'none' ? colors.textSecondary : priorityColors[normalizedValue]
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <PiFlag className={`h-${iconSize} w-${iconSize}`} />
      </motion.button>
      
      <AnimatePresence>
        {isOpen && (
          <>
            <div 
              className="fixed inset-0" 
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(false)
              }} 
            />
            <motion.div 
              className="absolute right-0 mt-1 py-1 rounded-md shadow-lg z-50"
              style={{ 
                backgroundColor: colors.surface,
                borderColor: colors.border,
                borderWidth: 1,
                minWidth: '180px'
              }}
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scale: 0.95 }}
              transition={{ duration: 0.15 }}
            >
              {Object.keys(priorityColors).map((priority) => {
                return (
                  <motion.button
                    key={priority}
                    onClick={(e) => {
                      e.stopPropagation()
                      onChange(priority as PriorityLevel)
                      setIsOpen(false)
                    }}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-opacity-10 transition-colors flex items-center gap-3"
                    style={{ 
                      color: priority === 'none' ? colors.textSecondary : priorityColors[priority],
                      backgroundColor: normalizedValue === priority ? `${colors.quaternary}10` : undefined
                    }}
                    whileHover={{ 
                      backgroundColor: `${colors.quaternary}10`,
                      x: 2
                    }}
                  >
                    <PiFlag className={`h-${iconSize} w-${iconSize}`} />
                    {t(`lists.priority.${priority}`)}
                  </motion.button>
                )
              })}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
} 