import i18n from 'i18next';
import { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const options: InitOptions = {
  fallbackLng: 'en',
  debug: false,
  
  interpolation: {
    escapeValue: false,
  },
  
  backend: {
    loadPath: '/locales/{{lng}}/translation.json',
  },
  
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
  },

  supportedLngs: ['en', 'es', 'fr', 'de', 'zh'],
  load: 'languageOnly',
  cleanCode: true,
  lowerCaseLng: true
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export default i18n; 