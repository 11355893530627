import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { Achievement, UserAchievement, AchievementCategory } from '../../types/gamification';
import { AchievementCategories } from './AchievementCategories';
import { AchievementBadge } from './AchievementBadge';
import { cn } from '../../lib/utils';
import { Modal } from '../ui/modal';
import { PiX, PiLockSimple, PiLockOpen } from 'react-icons/pi';
import { achievementStates } from '../../theme/colors';
import { Timestamp } from 'firebase/firestore';


interface AchievementsGridProps {
  achievements: Achievement[];
  userAchievements: UserAchievement[];
  showSecret?: boolean;
}

function formatDate(date: Date | Timestamp | string | null): string {
  if (!date) return '';
  
  try {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    }
    
    if (date instanceof Timestamp) {
      return date.toDate().toLocaleDateString();
    }
    
    // If it's a string, try to parse it
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toLocaleDateString();
    }
    
    return '';
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
}

function getAchievementStyle(achievement: Achievement, isUnlocked: boolean, colors: ThemeColors) {
  if (achievement.secret && !isUnlocked) {
    return {
      border: achievementStates.hidden.border,
      background: `linear-gradient(135deg, ${achievementStates.hidden.background}, rgba(107, 114, 128, 0.25))`,
      glow: 'rgba(107, 114, 128, 0.2)',
      text: achievementStates.hidden.text,
      overlay: 'rgba(0, 0, 0, 0.4)'
    };
  }

  if (!isUnlocked) {
    return {
      border: achievementStates.locked.border,
      background: `linear-gradient(135deg, ${achievementStates.locked.background}, rgba(55, 65, 81, 0.2))`,
      text: achievementStates.locked.text,
      overlay: 'rgba(0, 0, 0, 0.3)'
    };
  }

  return {
    border: achievement.color,
    background: `linear-gradient(135deg, ${achievement.color}20, ${achievement.color}35)`,
    glow: `${achievement.color}40`,
    text: colors.surface,
    overlay: 'none'
  };
}

export function AchievementsGrid({ 
  achievements, 
  userAchievements,
  showSecret = false 
}: AchievementsGridProps) {
  const { colors } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState<AchievementCategory | 'all'>('all');
  const [selectedAchievement, setSelectedAchievement] = useState<Achievement | null>(null);

  // Calculate achievement counts per category
  const achievementCounts = achievements.reduce((acc, achievement) => {
    if (!showSecret && achievement.secret) return acc;
    acc[achievement.category] = (acc[achievement.category] || 0) + 1;
    return acc;
  }, {} as Record<AchievementCategory, number>);

  // Filter achievements based on selected category
  const filteredAchievements = achievements.filter(achievement => {
    if (!showSecret && achievement.secret) return false;
    return selectedCategory === 'all' || achievement.category === selectedCategory;
  });

  return (
    <div className="space-y-8">
      <AchievementCategories
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
        achievementCounts={achievementCounts}
      />

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {filteredAchievements.map((achievement) => {
          const isUnlocked = userAchievements.some(
            ua => ua.achievementId === achievement.id
          );
          const userAchievement = userAchievements.find(
            ua => ua.achievementId === achievement.id
          );
          const styles = getAchievementStyle(achievement, isUnlocked, colors);

          return (
            <motion.div
              key={achievement.id}
              layoutId={achievement.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              whileHover={{ scale: 1.05 }}
              onClick={() => setSelectedAchievement(achievement)}
              className={cn(
                "relative aspect-square p-4 rounded-xl cursor-pointer overflow-hidden",
                "flex flex-col items-center justify-center text-center",
                "transition-all duration-300 group",
                isUnlocked ? "shadow-lg" : "grayscale hover:grayscale-0"
              )}
              style={{
                border: `2px solid ${styles.border}`,
                background: styles.background,
                boxShadow: isUnlocked ? `0 0 20px ${styles.glow}` : 'none'
              }}
            >
              {/* Dark Overlay for Locked Achievements */}
              {!isUnlocked && (
                <div 
                  className="absolute inset-0 transition-opacity duration-300 
                    group-hover:opacity-0 pointer-events-none"
                  style={{ 
                    background: styles.overlay,
                    backdropFilter: 'blur(1px)'
                  }}
                />
              )}

              {/* Status Icon */}
              <div 
                className={cn(
                  "absolute top-3 right-3 rounded-full p-2",
                  "backdrop-blur-sm transition-all duration-300",
                  isUnlocked 
                    ? "bg-emerald-500/10 text-emerald-400 group-hover:scale-110" 
                    : "bg-gray-500/10 text-gray-400 group-hover:bg-gray-500/20"
                )}
              >
                {isUnlocked ? (
                  <PiLockOpen className="w-5 h-5" />
                ) : (
                  <PiLockSimple className="w-5 h-5" />
                )}
              </div>

              {/* Achievement Badge */}
              <AchievementBadge
                achievement={achievement}
                userAchievement={userAchievement}
                size="lg"
              />

              {/* Title */}
              <h3 
                className={cn(
                  "mt-4 font-medium text-sm line-clamp-2",
                  "group-hover:scale-105 transition-transform",
                  !isUnlocked && "opacity-75 group-hover:opacity-100"
                )}
                style={{ color: styles.text }}
              >
                {achievement.secret && !isUnlocked ? "???" : achievement.title}
              </h3>

              {/* XP or Status */}
              <div 
                className={cn(
                  "mt-2 text-xs font-medium",
                  "px-3 py-1.5 rounded-full",
                  "backdrop-blur-sm transition-all duration-300",
                  isUnlocked 
                    ? "bg-surface/10" 
                    : "bg-gray-500/10 group-hover:bg-surface/10"
                )}
                style={{ color: styles.text }}
              >
                {isUnlocked 
                  ? "Unlocked!"
                  : achievement.secret 
                    ? "Hidden Achievement" 
                    : `Locked • ${achievement.xpReward} XP`
                }
              </div>
            </motion.div>
          );
        })}
      </div>

      {/* Achievement Detail Modal */}
      <Modal
        isOpen={!!selectedAchievement}
        onClose={() => setSelectedAchievement(null)}
        className="border-2 transition-colors"
      >
        {selectedAchievement && (
          <div className="relative p-6">
            {/* Close button */}
            <button
              onClick={() => setSelectedAchievement(null)}
              className="absolute right-4 top-4 rounded-full p-1.5
                hover:bg-gray-500/10 transition-colors"
              style={{ color: colors.textSecondary }}
            >
              <PiX className="h-5 w-5" />
            </button>

            <div className="flex flex-col items-center text-center">
              <AchievementBadge
                achievement={selectedAchievement}
                userAchievement={userAchievements.find(
                  ua => ua.achievementId === selectedAchievement.id
                )}
                size="lg"
              />

              {/* Title */}
              <h2 
                className="mt-4 text-xl font-semibold"
                style={{ color: selectedAchievement.color }}
              >
                {selectedAchievement.secret && !userAchievements.some(ua => ua.achievementId === selectedAchievement.id)
                  ? "???"
                  : selectedAchievement.title}
              </h2>

              {/* Description or Hint */}
              <p 
                className="mt-2 text-sm"
                style={{ color: colors.textSecondary }}
              >
                {selectedAchievement.secret && !userAchievements.some(ua => ua.achievementId === selectedAchievement.id)
                  ? (
                    <span className="flex flex-col gap-2">
                      <span className="text-sm font-medium text-gray-400">
                        This achievement is hidden
                      </span>
                      <span className="text-sm italic">
                        Hint: {selectedAchievement.hint}
                      </span>
                    </span>
                  )
                  : selectedAchievement.description}
              </p>

              {/* Achievement Details */}
              <div className="mt-4 flex flex-wrap justify-center gap-2">
                {(!selectedAchievement.secret || userAchievements.some(ua => ua.achievementId === selectedAchievement.id)) && (
                  <div 
                    className="px-3 py-1 rounded-full text-sm"
                    style={{ 
                      backgroundColor: `${selectedAchievement.color}15`,
                      color: selectedAchievement.color
                    }}
                  >
                    +{selectedAchievement.xpReward} XP
                  </div>
                )}
              </div>

              {/* Progress or Unlock Date */}
              {userAchievements.find(ua => ua.achievementId === selectedAchievement.id) ? (
                <div 
                  className="mt-4 text-sm"
                  style={{ color: colors.textSecondary }}
                >
                  Unlocked {formatDate(
                    userAchievements.find(
                      ua => ua.achievementId === selectedAchievement.id
                    )?.unlockedAt
                  )}
                </div>
              ) : (
                selectedAchievement.secret && (
                  <div 
                    className="mt-4 text-sm"
                    style={{ color: colors.textSecondary }}
                  >
                    Keep playing to discover this achievement!
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
} 