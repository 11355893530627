import { useEffect, useState } from 'react'
import { useTheme } from '../contexts/ThemeContext'
import { useErrorFeedback } from '../hooks/useErrorFeedback'
import { ErrorFeedback } from '../components/shared/ErrorFeedback'
import { ListCreator } from '../components/lists/ListCreator'
import { useListsStore } from '../store/useListsStore'
import { listsService } from '../services/listsService'
import { useStore } from '../store/useStore'
import { useTranslation } from 'react-i18next'
import { ListCard } from '../components/lists/ListCard'
import { List } from '../types/lists'
import { 
  PiListBold,
  PiSpinner,
  PiWarning,
} from 'react-icons/pi'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits'


export function ListsPage() {
  const { colors } = useTheme()
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()
  const { user, isInitialized } = useStore()
  const { lists, setLists, isLoading, setIsLoading, setError } = useListsStore()
  const { t } = useTranslation()
  const [listItemCounts, setListItemCounts] = useState<Record<string, { total: number, completed: number }>>({})
  const userTier = user?.subscription?.tier || 'free'
  const listLimit = SUBSCRIPTION_LIMITS.lists[userTier]
  
  const showLimitWarning = lists.length >= Math.floor(listLimit * 0.8)

  useEffect(() => {
    document.title = 'Lists | Tydy'
  }, [])

  useEffect(() => {
    async function fetchLists() {
      if (!isInitialized || !user) return
      
      try {
        setIsLoading(true)
        const fetchedLists = await listsService.getLists(user.id)
        setLists(fetchedLists)

        // Fetch item counts for each list
        const counts: Record<string, { total: number, completed: number }> = {}
        await Promise.all(
          fetchedLists.map(async (list) => {
            const items = await listsService.getListItems(list.id)
            counts[list.id] = {
              total: items.length,
              completed: items.filter(item => item.isCompleted).length
            }
          })
        )
        setListItemCounts(counts)
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'ListsPage.fetchLists')
          setError(err)
        } else {
          const error = new Error('An unknown error occurred')
          showError(error, 'ListsPage.fetchLists')
          setError(error)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchLists()
  }, [user, isInitialized, setLists, setIsLoading, setError, showError])

  const handleUpdateList = async (id: string, updates: Partial<List>) => {
    try {
      await listsService.updateList(id, updates)
      setLists(lists.map(list => 
        list.id === id ? { ...list, ...updates } : list
      ))
    } catch (err) {
      showError(err instanceof Error ? err : new Error('Failed to update list'), 'ListsPage')
    }
  }

  const handleDeleteList = async (id: string) => {
    try {
      await listsService.deleteList(id)
      setLists(lists.filter(list => list.id !== id))
    } catch (err) {
      showError(err instanceof Error ? err : new Error('Failed to delete list'), 'ListsPage')
    }
  }

  if (!isInitialized || isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen gap-2">
        <PiSpinner size={24} className="animate-spin" style={{ color: colors.textSecondary }} />
        <div className="text-lg" style={{ color: colors.textSecondary }}>
          {t('common.loading')}
        </div>
      </div>
    )
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <motion.div 
        className="flex items-center justify-between"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <h1 
          className="text-2xl font-semibold flex items-center gap-2"
          style={{ color: colors.primary }}
        >
          <PiListBold size={28} />
          {t("lists.title")}
        </h1>
        <ListCreator />
      </motion.div>

      {/* Add limit warning */}
      <AnimatePresence>
        {showLimitWarning && lists.length < listLimit && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mb-4 p-4 rounded-lg flex items-center gap-3"
            style={{
              backgroundColor: `${colors.warning}15`,
              border: `1px solid ${colors.warning}`
            }}
          >
            <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
            <div>
              <p className="text-sm" style={{ color: colors.text }}>
                You've used {lists.length} of {listLimit} lists.{' '}
                <Link 
                  to="/upgrade"
                  className="font-medium hover:underline"
                  style={{ color: colors.warning }}
                >
                  Upgrade to create more lists
                </Link>
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Show empty state if no lists */}
      {lists.length === 0 ? (
        <motion.div 
          className="text-center py-12"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3 }}
        >
          <p 
            className="text-lg mb-4"
            style={{ color: colors.textSecondary }}
          >
            {t("lists.empty")}
          </p>
          <p style={{ color: colors.textSecondary }}>
            {t("lists.emptyDescription")}
          </p>
        </motion.div>
      ) : (
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <AnimatePresence mode="popLayout">
            {lists.map((list, index) => (
              <motion.div
                key={list.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ delay: index * 0.1 }}
              >
                <ListCard 
                  list={list} 
                  itemCount={listItemCounts[list.id]?.total || 0}
                  completedCount={listItemCounts[list.id]?.completed || 0}
                  onUpdateList={handleUpdateList}
                  onDelete={handleDeleteList}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}

      {error && (
        <ErrorFeedback
          error={error}
          componentName={componentName}
          isOpen={isErrorVisible}
          onClose={clearError}
        />
      )}
    </motion.div>
  )
} 