import { useState } from 'react'
import { useTheme } from '../../../contexts/ThemeContext'
import { Button } from '../../ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover'
import { Input } from '../../ui/input'
import { useTranslation } from 'react-i18next'
import { PiImageBold, PiLinkBold } from 'react-icons/pi'

// Default avatar options
const DEFAULT_AVATARS = [
  '/avatars/robot-blue.png',
  '/avatars/robot-green.png',
  '/avatars/robot-purple.png',
  '/avatars/robot-red.png',
  '/avatars/robot-yellow.png',
  '/avatars/robot-orange.png',
  '/avatars/robot-young.png',
  '/avatars/robot-silver.png',
  '/avatars/robot-earth.png',
]

interface AvatarPickerProps {
  value: string
  onChange: (value: string) => void
  size?: 'sm' | 'md' | 'lg'
}

export function AvatarPicker({ value, onChange, size = 'md' }: AvatarPickerProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [customUrl, setCustomUrl] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const sizeClasses = {
    sm: 'w-10 h-10',
    md: 'w-16 h-16',
    lg: 'w-20 h-20'
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={`p-0 overflow-hidden rounded-full ${sizeClasses[size]}`}
          style={{ borderColor: colors.border }}
        >
          {value.startsWith('http') || value.startsWith('/') ? (
            <img 
              src={value} 
              alt="Avatar"
              className="w-full h-full object-cover"
            />
          ) : (
            <div 
              className="w-full h-full flex items-center justify-center"
              style={{ backgroundColor: value }}
            >
              <PiImageBold className="w-8 h-8" style={{ color: colors.textSecondary }} />
            </div>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent 
        className="w-[360px] p-4"
        style={{ backgroundColor: colors.surface, borderColor: colors.border }}
      >
        <div className="space-y-4">
          {/* Default Avatars Grid */}
          <div className="grid grid-cols-3 gap-3">
            {DEFAULT_AVATARS.map((avatar) => (
              <Button
                key={avatar}
                variant="outline"
                className={`p-0 rounded-full aspect-square w-24 h-24`}
                style={{ 
                  borderColor: value === avatar ? colors.primary : colors.border,
                  borderWidth: value === avatar ? 2 : 1
                }}
                onClick={() => {
                  onChange(avatar)
                  setIsOpen(false)
                }}
              >
                <img 
                  src={avatar} 
                  alt="Avatar option"
                  className="w-full h-full object-cover rounded-full"
                />
              </Button>
            ))}
          </div>

          {/* Custom URL Input */}
          <div className="space-y-2">
            <div className="flex gap-2">
              <Input
                value={customUrl}
                onChange={(e) => setCustomUrl(e.target.value)}
                placeholder={t('settings.ai.assistant.avatarUrl')}
                className="flex-1"
                style={{ color: colors.text }}
              />
              <Button
                onClick={() => {
                  if (customUrl) {
                    onChange(customUrl)
                    setIsOpen(false)
                  }
                }}
                className="shrink-0"
              >
                <PiLinkBold className="w-4 h-4" />
              </Button>
            </div>
            <p className="text-xs" style={{ color: colors.textSecondary }}>
              {t('settings.ai.assistant.avatarHelp')}
            </p>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
} 