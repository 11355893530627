import { motion, AnimatePresence } from 'framer-motion';
import { useNotificationStore } from '../../store/useNotificationStore';
import { useTheme } from '../../contexts/ThemeContext';
import { PiBell, PiX } from 'react-icons/pi';
import { NotificationData } from '../../types/notification';
import { IconType } from 'react-icons';

export const NotificationCenter = () => {
  const { colors } = useTheme();
  const { notifications, removeNotification } = useNotificationStore();

  const getIconColor = (type: string) => {
    switch (type) {
      case 'streak': return '#EF4444'; // red-500
      case 'achievement': return '#F59E0B'; // amber-500
      case 'levelUp': return '#6366F1'; // indigo-500
      case 'xp': return '#10B981'; // emerald-500
      case 'reminder': return '#8B5CF6'; // violet-500
      default: return colors.primary;
    }
  };

  const renderMessage = (notification: NotificationData) => {
    if (notification.isHTML) {
      return (
        <div 
          className="text-sm"
          style={{ color: colors.textSecondary }}
          dangerouslySetInnerHTML={{ __html: notification.message }}
          onClick={(e) => {
            // Prevent notification from being dismissed when clicking links
            if ((e.target as HTMLElement).tagName === 'A') {
              e.stopPropagation();
            }
          }}
        />
      );
    }

    return (
      <div 
        className="text-sm"
        style={{ color: colors.textSecondary }}
      >
        {notification.message}
      </div>
    );
  };

  return (
    <div className="fixed top-4 right-4 z-[100] space-y-2">
      <AnimatePresence>
        {notifications.map((notification) => {
          const IconComponent = notification.icon as IconType || PiBell;

          return (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="p-4 rounded-lg shadow-lg max-w-sm"
              style={{ 
                backgroundColor: colors.surface,
                border: `1px solid ${notification.color || colors.border}`
              }}
            >
              <div className="flex items-center gap-3">
                <div 
                  className="p-2 rounded-full"
                  style={{ 
                    backgroundColor: `${notification.color || getIconColor(notification.type)}15`
                  }}
                >
                  {IconComponent && <IconComponent 
                    className="w-5 h-5"
                    style={{ 
                      color: notification.color || getIconColor(notification.type)
                    }}
                  />}
                </div>
                <div className="flex-1">
                  <div 
                    className="font-medium"
                    style={{ 
                      color: notification.color || getIconColor(notification.type)
                    }}
                  >
                    {notification.title}
                  </div>
                  {renderMessage(notification)}
                </div>
                <button
                  onClick={() => removeNotification(notification.id)}
                  className="p-1 rounded-full hover:bg-opacity-10"
                  style={{ color: colors.textSecondary }}
                >
                  <PiX className="w-5 h-5" />
                </button>
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}; 