import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { useNotificationStore } from '../../store/useNotificationStore';
import { ReactNode, useEffect, useState } from 'react';
import { useErrorFeedback } from '../../hooks/useErrorFeedback';
import { ErrorFeedback } from '../shared/ErrorFeedback';

interface TrackerGuardProps {
  trackerId: string;
  children: ReactNode;
}

export const TrackerGuard = ({ trackerId, children }: TrackerGuardProps) => {
  const { user } = useStore();
  const location = useLocation();
  const { addNotification } = useNotificationStore();
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    if (user?.preferences?.enabledTrackers) {
      setIsAllowed(user.preferences.enabledTrackers.includes(trackerId));
    }
  }, [user?.preferences?.enabledTrackers, trackerId]);

  useEffect(() => {
    if (isAllowed === false) {
      console.log('Access denied to tracker:', trackerId);
      addNotification({
        type: 'warning',
        title: 'Access Denied',
        message: 'This tracker is currently disabled. You can enable it from your profile settings.',
        duration: 5000,
        color: '#EF4444'
      });
    }
  }, [isAllowed, addNotification, trackerId]);

  if (isAllowed === null) {
    return null; // Or a loading state
  }

  if (!isAllowed) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>
    {children}
    {error && (
      <ErrorFeedback
        error={error}
        componentName={componentName}
        isOpen={isErrorVisible}
        onClose={clearError}
      />
    )}
  </>;
}; 