import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../utils/firebase'
import { migrateUserEncryption } from '../migrations/userMigrations'
import { AppError } from '../errors'
import { adminService } from '../../services/adminService'

export async function runAdminMigrations() {
  console.log('[AdminMigrations] Starting migrations...')
  
  try {
    // Verify admin status first
    const isAdmin = await adminService.isAdmin()
    if (!isAdmin) {
      throw new AppError('UNAUTHORIZED', 'Must be an admin to perform migrations')
    }

    // Get all users
    const usersSnapshot = await getDocs(collection(db, 'users'))
    const totalUsers = usersSnapshot.docs.length
    console.log(`[AdminMigrations] Found ${totalUsers} users to process`)

    let successCount = 0
    let errorCount = 0
    const errors: Record<string, string> = {}

    // Process each user
    for (const userDoc of usersSnapshot.docs) {
      try {
        console.log(`[AdminMigrations] Processing user ${userDoc.id}...`)
        
        const encryptionKey = await migrateUserEncryption(userDoc.id)
        
        if (encryptionKey) {
          console.log(`[AdminMigrations] Successfully migrated user ${userDoc.id}`)
          successCount++
        } else {
          console.log(`[AdminMigrations] User ${userDoc.id} already migrated`)
        }
      } catch (error) {
        console.error(`[AdminMigrations] Error migrating user ${userDoc.id}:`, error)
        errorCount++
        errors[userDoc.id] = error instanceof Error ? error.message : 'Unknown error'
      }
    }

    const summary = {
      totalUsers,
      successCount,
      errorCount,
      errors
    }

    console.log('[AdminMigrations] Migration summary:', summary)

    if (errorCount > 0) {
      throw new AppError(
        'MIGRATION_PARTIAL_FAILURE',
        `Migration completed with ${errorCount} errors. Check logs for details.`
      )
    }

    return summary
  } catch (error) {
    console.error('[AdminMigrations] Migration failed:', error)
    throw error
  }
} 