import { 
  collection, 
  doc, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where,
  serverTimestamp,
  Timestamp,
  getDoc
} from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import { activityService } from './activityService';
import { FirestoreMoodEntry, MoodEntry } from '../types/mood';
import { useStore } from '../store/useStore';
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';
import { useGamificationStore } from '../store/useGamificationStore';
import { gamificationService } from './gamificationService';

// Helper to remove undefined fields from an object
const removeUndefinedFields = <T extends Record<string, unknown>>(obj: T): Partial<T> => {
  const cleanObj = { ...obj };
  Object.keys(cleanObj).forEach(key => {
    if (cleanObj[key] === undefined) {
      delete cleanObj[key];
    }
  });
  return cleanObj;
};

export const moodService = {
  // Get all mood entries for a user
  getUserMoodEntries: async (userId: string, { startDate, endDate }: { startDate: Date; endDate: Date }) => {
    try {
      const userTier = useStore.getState().user?.subscription?.tier || 'free'
      const monthsLimit = SUBSCRIPTION_LIMITS.moodHistory.monthsHistory[userTier]

      // Calculate the earliest allowed date for this user's tier
      const earliestAllowed = new Date()
      earliestAllowed.setMonth(earliestAllowed.getMonth() - monthsLimit)
      earliestAllowed.setHours(0, 0, 0, 0)

      // Adjust startDate if it's before the earliest allowed date
      const effectiveStartDate = startDate < earliestAllowed ? earliestAllowed : startDate

      const q = query(
        collection(db, 'moods'),
        where('userId', '==', userId),
        where('date', '>=', Timestamp.fromDate(effectiveStartDate)),
        where('date', '<=', Timestamp.fromDate(endDate))
      )
      
      const snapshot = await getDocs(q)
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      })) as FirestoreMoodEntry[]
    } catch (error) {
      // Fallback to simple userId query if index doesn't exist
      console.warn('Falling back to simple query. Please create the required index.');
      const q = query(
        collection(db, 'moods'),
        where('userId', '==', userId)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      })) as FirestoreMoodEntry[];
    }
  },

  // Create a new mood entry
  createMoodEntry: async (userId: string, entry: Omit<MoodEntry, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (auth.currentUser?.uid !== userId) {
      throw new Error('Unauthorized');
    }

    // Remove undefined fields and create the entry data
    const entryData = removeUndefinedFields({
      userId,
      date: Timestamp.fromDate(entry.date),
      period: entry.period,
      mood: entry.mood,
      note: entry.note || null, // Convert undefined to null
      activities: entry.activities || null, // Convert undefined to null
      weather: entry.weather || null, // Convert undefined to null
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    const moodRef = await addDoc(collection(db, 'moods'), entryData);

    // Track the activity
    await activityService.createActivity(
      'mood_entry_created',
      `Tracked mood: ${entry.mood}`,
      entry.note || '',
      { moodId: moodRef.id },
      moodRef.id,
      'mood_entry'
    );

    // Update gamification
    const gamificationStore = useGamificationStore.getState();
    await gamificationStore.updateStreakProduction('mood');
    await gamificationService.checkMoodAchievements(userId);

    return moodRef.id;
  },

  // Update a mood entry
  updateMoodEntry: async (entryId: string, updates: Partial<MoodEntry>) => {
    const moodRef = doc(db, 'moods', entryId);
    const moodSnap = await getDoc(moodRef);
    const currentEntry = moodSnap.data() as FirestoreMoodEntry;
    
    // Convert any Date objects to Timestamps and remove undefined fields
    const cleanUpdates = removeUndefinedFields({
      ...updates,
      date: updates.date ? Timestamp.fromDate(updates.date) : undefined,
      note: updates.note || null, // Convert undefined to null
      activities: updates.activities || null, // Convert undefined to null
      weather: updates.weather || null, // Convert undefined to null
      updatedAt: serverTimestamp()
    });

    await updateDoc(moodRef, cleanUpdates);

    // Track the activity
    await activityService.createActivity(
      'mood_entry_updated',
      `Updated mood entry`,
      updates.note || '',
      { moodId: entryId },
      entryId,
      'mood_entry'
    );
  },

  // Delete a mood entry
  deleteMoodEntry: async (entryId: string) => {
    const moodRef = doc(db, 'moods', entryId);
    const moodSnap = await getDoc(moodRef);
    const entry = moodSnap.data() as FirestoreMoodEntry;

    await deleteDoc(moodRef);

    // Track the activity
    await activityService.createActivity(
      'mood_entry_deleted',
      `Deleted mood entry`,
      entry.note || '',
      { moodId: entryId },
      entryId,
      'mood_entry'
    );
  },

  // Helper methods for achievements
  async getMoodEntryCount(userId: string): Promise<number> {
    try {
      const entries = await this.getUserMoodEntries(userId, {
        startDate: new Date(0), // From beginning
        endDate: new Date()     // To now
      });
      return entries.length;
    } catch (error) {
      console.error('Error counting mood entries:', error);
      throw error;
    }
  },

  async getConsecutiveDays(userId: string): Promise<number> {
    try {
      const entries = await this.getUserMoodEntries(userId, {
        startDate: new Date(0),
        endDate: new Date()
      });

      if (entries.length === 0) return 0;

      // Sort entries by date
      const sortedDates = entries
        .map(e => e.date)
        .sort((a, b) => b.toDate().getTime() - a.toDate().getTime());

      // Get unique dates
      const uniqueDates = Array.from(new Set(
        sortedDates.map(date => date.toDate().toDateString())
      )).map(dateStr => new Date(dateStr));

      // Count consecutive days
      let consecutiveDays = 1;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // If most recent entry isn't today, streak is broken
      if (uniqueDates[0].getTime() !== today.getTime()) {
        return 0;
      }

      // Check consecutive days
      for (let i = 1; i < uniqueDates.length; i++) {
        const currentDate = uniqueDates[i];
        const previousDate = uniqueDates[i - 1];
        
        const diffTime = previousDate.getTime() - currentDate.getTime();
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays === 1) {
          consecutiveDays++;
        } else {
          break;
        }
      }

      return consecutiveDays;
    } catch (error) {
      console.error('Error checking consecutive days:', error);
      throw error;
    }
  },

  async getMoodEntriesWithNotes(userId: string): Promise<number> {
    try {
      const entries = await this.getUserMoodEntries(userId, {
        startDate: new Date(0),
        endDate: new Date()
      });
      return entries.filter(entry => entry.note && entry.note.trim().length > 0).length;
    } catch (error) {
      console.error('Error counting mood entries with notes:', error);
      throw error;
    }
  },

  async checkMoodSwing(userId: string): Promise<boolean> {
    try {
      const now = new Date();
      const twelveHoursAgo = new Date(now.getTime() - (12 * 60 * 60 * 1000));

      const entries = await this.getUserMoodEntries(userId, {
        startDate: twelveHoursAgo,
        endDate: now
      });

      // Get min and max moods in the last 12 hours
      const moods = entries.map(e => e.mood);
      const maxMood = Math.max(...moods);
      const minMood = Math.min(...moods);

      // Check if difference is drastic (e.g., from 1 to 5 or vice versa)
      return maxMood - minMood >= 4;
    } catch (error) {
      console.error('Error checking mood swing:', error);
      throw error;
    }
  }
}; 