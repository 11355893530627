import { useTheme } from '../../contexts/ThemeContext';
import { useThemedStyles } from '../../hooks/useThemedStyles';

type SaveStatusType = 'saved' | 'saving' | 'unsaved';

interface SaveStatusProps {
  status: SaveStatusType;
}

export const SaveStatus = ({ status }: SaveStatusProps) => {
  const { colors } = useTheme();
  const styles = useThemedStyles();

  const renderDots = () => {
    if (status !== 'saving') return null;
    
    return (
      <span className="inline-flex ml-1">
        <span className="animate-dot1">.</span>
        <span className="animate-dot2">.</span>
        <span className="animate-dot3">.</span>
      </span>
    );
  };

  const getStatusColor = () => {
    switch (status) {
      case 'saved': return colors.accent2;
      case 'saving': return colors.primary;
      case 'unsaved': return colors.warning;
    }
  };

  return (
    <div className="flex items-center text-sm">
      <span style={styles.text.muted}>
        {status === 'saved' && 'All changes '}
        <span style={{ color: getStatusColor() }} className="font-medium">
          {status === 'saved' && 'saved'}
          {status === 'saving' && 'Saving'}
          {status === 'unsaved' && 'Unsaved changes'}
          {renderDots()}
        </span>
      </span>
    </div>
  );
}; 