import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  FirestoreError,
  orderBy,
  getDoc,
  Timestamp
} from 'firebase/firestore'
import { db } from '../utils/firebase'
import { activityService } from './activityService'
import type { List, ListItem } from '../types/lists'
import { AppError } from '../utils/errors'
import { useGamificationStore } from '../store/useGamificationStore'
import { useStore } from '../store/useStore'
import { gamificationService } from './gamificationService'

export class ListsServiceError extends Error {
  constructor(message: string, public originalError?: Error) {
    super(message)
    this.name = 'ListsServiceError'
  }
}

export const listsService = {
  async getLists(userId: string): Promise<List[]> {
    try {
      const listsRef = collection(db, 'lists')
      const q = query(listsRef, where('userId', '==', userId))
      
      const querySnapshot = await getDocs(q)
      
      const lists = querySnapshot.docs.map(doc => {
        const data = doc.data()
        return {
          ...data,
          id: doc.id,
          createdAt: data.createdAt?.toDate(),
          updatedAt: data.updatedAt?.toDate()
        }
      }) as List[]

      return lists
    } catch (error) {
      console.error('Error getting lists:', error)
      if (error instanceof FirestoreError) {
        console.error('Firestore error code:', error.code)
        if (error.code === 'permission-denied') {
          throw new ListsServiceError(
            'You do not have permission to access these lists. Please check your account settings.',
            error
          )
        }
      }
      throw new ListsServiceError(
        'Failed to fetch lists. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  async createList(userId: string, list: Omit<List, 'id' | 'userId' | 'createdAt' | 'updatedAt'>): Promise<string> {
    try {
      const listsRef = collection(db, 'lists')
      
      // Sanitize the input data
      const sanitizedList = {
        title: list.title,
        description: list.description || '',
        icon: list.icon || 'PiListBold',
        color: list.color || '#3B82F6',
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }
      
      const docRef = await addDoc(listsRef, sanitizedList)

      // Update gamification
      const gamificationStore = useGamificationStore.getState()
      await gamificationStore.updateStreakProduction('lists')
      await gamificationService.checkListAchievements(userId)

      // Track the activity
      await activityService.createActivity(
        'list_created',
        `Created list "${list.title}"`,
        sanitizedList.description,
        { listId: docRef.id },
        docRef.id,
        'list'
      )

      return docRef.id
    } catch (error) {
      console.error('Error creating list:', error)
      throw new ListsServiceError(
        'Failed to create list. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  async updateList(listId: string, updates: Partial<List>): Promise<void> {
    try {
      const listRef = doc(db, 'lists', listId)
      const listSnap = await getDoc(listRef)
      const currentList = listSnap.data() as List
      
      await updateDoc(listRef, {
        ...updates,
        updatedAt: serverTimestamp()
      })

      // Track the activity
      await activityService.createActivity(
        'list_updated',
        `Updated list "${currentList.title}"`,
        '',
        { listId },
        listId,
        'list'
      )
    } catch (error) {
      console.error('Error updating list:', error)
      throw new ListsServiceError(
        'Failed to update list. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  async deleteList(listId: string): Promise<void> {
    try {
      const listRef = doc(db, 'lists', listId)
      const listSnap = await getDoc(listRef)
      const list = listSnap.data() as List
      
      await deleteDoc(listRef)

      // Track the activity
      await activityService.createActivity(
        'list_deleted',
        `Deleted list "${list.title}"`,
        '',
        { listId },
        listId,
        'list'
      )
    } catch (error) {
      console.error('Error deleting list:', error)
      throw new ListsServiceError(
        'Failed to delete list. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  async getListItems(listId: string): Promise<ListItem[]> {
    try {
      const itemsRef = collection(db, 'lists', listId, 'items')
      const snapshot = await getDocs(query(itemsRef, orderBy('order')))
      
      const items = snapshot.docs.map(doc => {
        const data = doc.data()
        const item = {
          ...data,
          id: doc.id,
          dueDate: data.dueDate ? new Date(data.dueDate.toDate()) : null,
          createdAt: data.createdAt?.toDate() || new Date(),
          updatedAt: data.updatedAt?.toDate() || new Date()
        } as ListItem
        return item
      })
      return items
    } catch (error) {
      console.error('Error getting list items:', error)
      throw error
    }
  },

  async createListItem(
    listId: string, 
    item: Omit<ListItem, 'id' | 'listId' | 'createdAt' | 'updatedAt'>
  ): Promise<ListItem> {
    try {
      const itemsRef = collection(db, `lists/${listId}/items`)
      
      // Sanitize the input data
      const sanitizedItem = {
        content: item.content,
        description: item.description || '',
        isCompleted: Boolean(item.isCompleted),
        order: typeof item.order === 'number' ? item.order : 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }
      
      const docRef = await addDoc(itemsRef, sanitizedItem)
      
      const newItem = {
        ...sanitizedItem,
        id: docRef.id,
        listId,
        createdAt: new Date(),
        updatedAt: new Date()
      }

      // Try to create activity record, but don't fail if it errors
      try {
        await activityService.createActivity(
          'list_item_created',
          `Added item "${item.content}"`,
          sanitizedItem.description,
          { listId, itemId: docRef.id },
          docRef.id,
          'list_item'
        )
      } catch (activityError) {
        // Log but don't throw - activity creation is non-critical
        console.warn('Failed to create activity record:', activityError)
      }

      return newItem
    } catch (error) {
      console.error('Error creating list item:', error)
      throw new ListsServiceError(
        'Failed to create list item. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  async updateListItem(
    listId: string,
    itemId: string,
    updates: Partial<ListItem>
  ): Promise<void> {
    try {
      const itemRef = doc(db, 'lists', listId, 'items', itemId)
      
      // Convert Date objects to Firestore Timestamps
      const processedUpdates = {
        ...updates,
        dueDate: updates.dueDate ? Timestamp.fromDate(new Date(updates.dueDate)) : null,
        updatedAt: serverTimestamp()
      }

      await updateDoc(itemRef, processedUpdates)
    } catch (error) {
      console.error('Error updating list item:', error)
      throw error
    }
  },

  async deleteListItem(listId: string, itemId: string): Promise<void> {
    try {
      const itemRef = doc(db, 'lists', listId, 'items', itemId)
      const itemSnap = await getDoc(itemRef)
      const item = itemSnap.data() as ListItem
      
      await deleteDoc(itemRef)

      // Track the activity
      await activityService.createActivity(
        'list_item_deleted',
        `Deleted item "${item.title}"`,
        '',
        { listId, itemId },
        itemId,
        'list_item'
      )
    } catch (error) {
      console.error('Error deleting list item:', error)
      throw new ListsServiceError(
        'Failed to delete list item. Please try again later.',
        error instanceof Error ? error : undefined
      )
    }
  },

  // Helper method to check list completion
  async checkListCompletion(listId: string): Promise<boolean> {
    const items = await this.getListItems(listId)
    if (items.length === 0) return false
    return items.every(item => item.isCompleted)
  },

  // Helper method to get lists with item counts
  async getListsWithItemCounts(userId: string): Promise<{ listId: string, itemCount: number }[]> {
    const lists = await this.getLists(userId)
    const results = []
    
    for (const list of lists) {
      const items = await this.getListItems(list.id)
      results.push({
        listId: list.id,
        itemCount: items.length
      })
    }
    
    return results
  },

  // Helper to count total completed items
  async getCompletedItemCount(userId: string): Promise<number> {
    try {
      const lists = await this.getLists(userId)
      let completedCount = 0
      
      for (const list of lists) {
        const items = await this.getListItems(list.id)
        completedCount += items.filter(item => item.isCompleted).length
      }
      
      return completedCount
    } catch (error) {
      console.error('Error counting completed items:', error)
      throw new ListsServiceError(
        'Failed to count completed items',
        error instanceof Error ? error : undefined
      )
    }
  },

  // Helper to count completed lists (all items completed)
  async getCompletedListCount(userId: string): Promise<number> {
    try {
      const lists = await this.getLists(userId)
      let completedLists = 0
      
      for (const list of lists) {
        const isCompleted = await this.checkListCompletion(list.id)
        if (isCompleted) completedLists++
      }
      
      return completedLists
    } catch (error) {
      console.error('Error counting completed lists:', error)
      throw new ListsServiceError(
        'Failed to count completed lists',
        error instanceof Error ? error : undefined
      )
    }
  },

  async checkListLightning(userId: string): Promise<boolean> {
    try {
      const now = new Date();
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
      
      // Get recently created lists
      const recentLists = await this.getLists(userId, {
        startDate: fiveMinutesAgo,
        endDate: now
      });

      // Check each list that was created in the last 5 minutes
      for (const list of recentLists) {
        // Only consider lists with 5 or more items
        const items = await this.getListItems(list.id);
        if (items.length >= 5) {
          // Check if all items were completed within 5 minutes of list creation
          const allCompleted = items.every(item => item.isCompleted);
          if (allCompleted) {
            const lastCompletedItem = items
              .map(item => item.updatedAt?.toDate() || new Date(0))
              .sort((a, b) => b.getTime() - a.getTime())[0];

            // If the last item was completed within 5 minutes of list creation
            const listCreationTime = list.createdAt.toDate();
            if (lastCompletedItem.getTime() - listCreationTime.getTime() <= 5 * 60 * 1000) {
              return true;
            }
          }
        }
      }
      
      return false;
    } catch (error) {
      console.error('Error checking list lightning:', error);
      throw error;
    }
  },

  async hasActivityOnDate(userId: string, date: Date): Promise<boolean> {
    try {
      const lists = await this.getLists(userId);
      const items = await Promise.all(lists.map(list => this.getListItems(list.id)));
      
      // Check for any list activity on the given date
      const hasListActivity = lists.some(list => {
        // Handle both Timestamp and Date objects
        const listDate = list.updatedAt instanceof Date 
          ? list.updatedAt 
          : list.updatedAt?.toDate?.() || new Date(0);

        return listDate.getFullYear() === date.getFullYear() &&
               listDate.getMonth() === date.getMonth() &&
               listDate.getDate() === date.getDate();
      });

      // Check for any item activity on the given date
      const hasItemActivity = items.flat().some(item => {
        // Handle both Timestamp and Date objects
        const itemDate = item.updatedAt instanceof Date 
          ? item.updatedAt 
          : item.updatedAt?.toDate?.() || new Date(0);

        return itemDate.getFullYear() === date.getFullYear() &&
               itemDate.getMonth() === date.getMonth() &&
               itemDate.getDate() === date.getDate();
      });

      return hasListActivity || hasItemActivity;
    } catch (error) {
      console.error('Error checking list activity:', error);
      return false;
    }
  }
} 