import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotebookStore } from '../store/useNotebookStore';
import { useThemedStyles } from '../hooks/useThemedStyles';
import { useTheme } from '../contexts/ThemeContext';
import { useDebounce } from '../hooks/useDebounce';
import { SaveStatus } from '../components/shared/SaveStatus';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PiArrowLeft,
  PiTrash,
  PiPencilSimple,
  PiX,
  PiCheck,
  PiSpinner
} from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import EditorErrorBoundary from '../components/editor/EditorErrorBoundary';
import { RichTextEditor } from '../components/editor/RichTextEditor';

const formatDate = (timestamp: Date | { seconds: number } | null) => {
  if (!timestamp) return 'Never';
  
  if ('seconds' in timestamp) {
    return new Date(timestamp.seconds * 1000).toLocaleString();
  }
  
  const date = new Date(timestamp);
  return !isNaN(date.getTime()) ? date.toLocaleString() : 'Invalid Date';
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 }
};

export function NotePage() {
  const { notebookId, noteId } = useParams();
  const navigate = useNavigate();
  const { 
    notebooks,
    currentNotes,
    fetchNotes,
    updateNoteContent,
    updateNoteTitle,
    removeNote,
    fetchNotebooks,
  } = useNotebookStore();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'unsaved'>('saved');
  const styles = useThemedStyles();
  const { colors } = useTheme();
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const notebook = notebooks.find(n => n.id === notebookId);
  const note = currentNotes.find(n => n.id === noteId);
  const { t } = useTranslation(); 
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(note?.content || '');

  const MIN_SAVING_DURATION = 500;

  const handleContentChange = useDebounce((newContent: string) => {
    setContent(newContent);
    setSaveStatus('unsaved');
    if (noteId) {
      setSaveStatus('saving');
      updateNoteContent(noteId, newContent)
        .then(() => {
          setTimeout(() => {
            setSaveStatus('saved');
          }, MIN_SAVING_DURATION);
        })
        .catch(() => setSaveStatus('unsaved'));
    }
  }, 1500);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4, 5, 6]
        }
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'editor-image',
        },
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true,
      }),
      Placeholder.configure({
        placeholder: 'Start writing...',
      }),
    ],
    content: note?.content || '',
    onUpdate: ({ editor }) => {
      handleContentChange(editor.getHTML());
    },
  });

  // Load data effect
  useEffect(() => {
    async function loadData() {
      if (!notebookId || !noteId) return;
      try {
        if (notebooks.length === 0) {
          await fetchNotebooks();
        }
        if (!currentNotes.length || currentNotes[0]?.notebookId !== notebookId) {
          await fetchNotes(notebookId);
        }
        const currentNote = currentNotes.find(n => n.id === noteId);
        if (currentNote && editor) {
          setTitle(currentNote.title);
          setContent(currentNote.content);
          editor.commands.setContent(currentNote.content);
        }
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'NotePage.loadData');
        }
      }
    }

    loadData();
  }, [notebookId, noteId, notebooks.length, currentNotes, fetchNotebooks, fetchNotes, showError, editor]);

  // Update content when note changes
  useEffect(() => {
    if (note && editor) {
      setTitle(note.title);
      setContent(note.content);
      editor.commands.setContent(note.content);
    }
  }, [note, editor]);

  const handleTitleChange = async (newTitle: string) => {
    setTitle(newTitle);
    if (noteId) {
      try {
        setSaveStatus('saving');
        // Add artificial delay for better UX
        await new Promise(resolve => setTimeout(resolve, MIN_SAVING_DURATION));
        await updateNoteTitle(noteId, newTitle);
        setSaveStatus('saved');
      } catch (error) {
        if (error instanceof Error) {
          showError(error, 'NotePage.handleTitleChange');
        } else {
          showError('An unknown error occurred', 'NotePage.handleTitleChange');
        }
        setSaveStatus('unsaved');
      }
    }
  };

  const handleTitleBlur = () => {
    if (title !== note?.title) {
      handleTitleChange(title);
    }
  };

  if (!notebook || !note) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <PiSpinner className="h-8 w-8" style={{ color: colors.primary }} />
        </motion.div>
      </div>
    );
  }

  const handleDelete = async () => {
    if (!noteId) return;
    try {
      await removeNote(noteId);
      navigate(`/notebooks/${notebookId}`);
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'NotePage.handleDelete');
      } else {
        showError('An unknown error occurred', 'NotePage.handleDelete');
      }
    }
  };

  return (
    <motion.div 
      className="h-full flex flex-col"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="sticky top-4 z-30 flex items-center justify-between p-4 border-b bg-opacity-90 backdrop-blur-sm hover:bg-opacity-20 transition-colors"
        style={{
          backgroundColor: colors.background,
          borderColor: colors.tertiary,
        }}
        variants={itemVariants}
      >
        <div className="flex items-center space-x-4">
          <motion.button
            onClick={() => navigate(`/notebooks/${notebookId}`)}
            className="p-2 rounded-full transition-colors hover:bg-opacity-20"
            style={{ 
              color: colors.quaternary,
              backgroundColor: `${colors.quaternary}10`,
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <PiArrowLeft className="h-5 w-5" />
          </motion.button>
          <SaveStatus status={saveStatus} />
        </div>

        <div className="flex items-center space-x-4">
          <div className="text-sm">
            <span style={styles.text.muted}>Updated</span>
            <span style={{ color: colors.accent2 }} className="ml-1">
              {formatDate(note.updatedAt)}
            </span>
          </div>
          <motion.button
            onClick={() => setShowDeleteConfirm(true)}
            className="p-2 rounded-full transition-colors hover:bg-opacity-20"
            style={{ 
              color: colors.error,
              backgroundColor: `${colors.error}10`,
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <PiTrash className="h-5 w-5" />
          </motion.button>
        </div>
      </motion.div>

      <motion.div 
        className="flex-1 overflow-auto relative z-20"
        variants={itemVariants}
      >
        <div className="max-w-5xl mx-auto px-4 pt-8 pb-20 md:pb-4 note-content">
          <motion.div 
            className="pb-4 border-b transition-colors relative accent-border-left"
            style={{ 
              borderColor: `${colors.quaternary}20`,
              backgroundColor: colors.background,
              borderLeftColor: colors.accent1
            }}
            variants={itemVariants}
          >
            <div 
              className="text-xs font-medium mb-3 mt-0"
              style={{ color: colors.tertiary }}
            >
              {t('notebooks.notes.title')}
            </div>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={handleTitleBlur}
              className="w-full text-3xl font-bold bg-transparent border-none focus:outline-none placeholder-gray-400 mb-2"
              style={styles.text.body}
              placeholder={t('notes.title.placeholder')}
              autoFocus
            />
            <div 
              className="text-xs"
              style={{ color: colors.textSecondary }}
            >
              {t('notebooks.notes.lastEdited')} {formatDate(note.updatedAt)}
            </div>
          </motion.div>
          
          <div className="mt-4 relative">
            <EditorErrorBoundary>
              <RichTextEditor
                content={content}
                onChange={handleContentChange}
                placeholder="Start writing... (Cmd/Ctrl + Enter to focus here)"
              />
            </EditorErrorBoundary>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {showDeleteConfirm && (
          <motion.div 
            className="fixed inset-0 bg-black/50 flex items-center justify-center p-4"
            style={{ zIndex: 100 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="rounded-lg p-6 max-w-sm w-full"
              style={{
                backgroundColor: colors.surface,
                borderLeft: `4px solid ${colors.error}`
              }}
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
            >
              <h3 style={styles.text.body} className="text-lg font-semibold mb-2">
                {t('notebooks.notes.delete')}
              </h3>
              <p style={styles.text.muted} className="mb-4">
                {t('notebooks.notes.deleteConfirm')}
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 rounded transition-colors hover:bg-opacity-20"
                  style={{ 
                    color: colors.textSecondary,
                    backgroundColor: `${colors.quaternary}10`
                  }}
                >
                  {t('notebooks.notes.deleteCancel')}
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 rounded transition-colors hover:opacity-90"
                  style={{ 
                    backgroundColor: colors.error,
                    color: 'white'
                  }}
                >
                  {t('notebooks.notes.deleteConfirm')}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="fixed bottom-4 right-4 text-xs text-gray-500 dark:text-gray-400">
        <kbd className="px-2 py-1 bg-gray-100 dark:bg-gray-800 rounded">⌘/Ctrl + Enter</kbd> to focus on content
      </div>

      <AnimatePresence>
        {error && (
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

