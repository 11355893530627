import type React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "../contexts/ThemeContext"
import { useStore } from "../store/useStore"
import { Toggle } from "../components/shared/Toggle"
import { useAuthStore } from "../store/useAuthStore"
import { useNavigate } from "react-router-dom"
import { auth } from "../utils/firebase"
import { deleteUserData } from "../utils/firebase"
import { useNotebookStore } from "../store/useNotebookStore"
import { useMoodStore } from "../store/useMoodStore"
import { useFinanceStore } from "../store/useFinanceStore"
import { useNotificationStore } from "../store/useNotificationStore"
import { useGamificationStore } from "../store/useGamificationStore"
import { useAIStore } from "../store/useAIStore"
import {
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  EmailAuthProvider,
  GoogleAuthProvider,
} from "../utils/firebase"
import type { PrivacyPreferences, UserPreferences } from "../types/user"
import { NotificationType } from "../types/notification"
import i18next from "i18next"
import { useErrorFeedback } from "../hooks/useErrorFeedback"
import { ErrorFeedback } from "../components/shared/ErrorFeedback"
import { AIPersonalizationSection } from '../components/settings/AIPersonalizationSection'
import { Input } from '../components/ui/input'
import { AICreditManagement } from '../components/settings/AICreditManagement'
import { 
  PiPalette,
  PiGear,
  PiUserCircle,
  PiBell,
  PiTranslate,
  PiRobot,
  PiShieldCheck,
  PiKey,
  PiCreditCard,
  PiCloud,
  PiTrash,
  PiWarning,
  PiSun,
  PiMoon,
  PiGlobe,
  PiChartBar,
} from 'react-icons/pi';
import { ThemeCustomizer } from '../components/theme/ThemeCustomizer';
import { motion, AnimatePresence } from 'framer-motion';
import { containerVariants, itemVariants } from '../components/animations';

interface PrivacySetting {
  id: keyof PrivacyPreferences
  label: string
  description: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const buttonVariants = {
  initial: { scale: 1 },
  hover: { scale: 1.02 },
  tap: { scale: 0.98 },
  disabled: { opacity: 0.5 }
};

const toggleVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 20 }
};

const SettingSection: React.FC<{
  title: string
  children: React.ReactNode
  id: string
}> = ({ title, children, id }) => {
  const { colors } = useTheme()
  
  return (
    <motion.div 
      variants={itemVariants}
      layout
      className="bg-surface rounded-lg p-6 shadow-md mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      id={id}
    >
      <motion.h2 
        className="text-xl font-semibold mb-4" 
        style={{ color: colors.primary }}
        layout="position"
      >
        {title}
      </motion.h2>
      <motion.div layout>
        {children}
      </motion.div>
    </motion.div>
  )
}

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { 
  variant?: "primary" | "danger" | "secondary"
  icon?: React.ReactNode
  fullWidth?: boolean
}> = ({
  children,
  variant = "primary",
  icon,
  fullWidth = false,
  disabled,
  ...props
}) => {
  const { colors } = useTheme()
  
  return (
    <motion.button 
      className={`px-4 py-2 rounded-lg font-medium flex items-center justify-center
        ${fullWidth ? "w-full" : ""}`}
      variants={buttonVariants}
      initial="initial"
      whileHover={disabled ? "disabled" : "hover"}
      whileTap={disabled ? "disabled" : "tap"}
      style={{
        backgroundColor: variant === "primary" ? colors.primary :
          variant === "secondary" ? `${colors.primary}15` :
          colors.error + "15",
        color: variant === "primary" ? "#ffffff" :
          variant === "secondary" ? colors.primary :
          colors.error,
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer"
      }}
      {...props}
    >
      {icon && (
        <motion.span 
          className="mr-2"
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.1 }}
        >
          {icon}
        </motion.span>
      )}
      {children}
    </motion.button>
  )
}

export const SettingsPage: React.FC = () => {
  const { colors, isDarkMode, setMode } = useTheme()
  const { t, i18n } = useTranslation()
  const { logout } = useAuthStore()
  const navigate = useNavigate()
  const notebookStore = useNotebookStore()
  const moodStore = useMoodStore()
  const financeStore = useFinanceStore()
  const { addNotification } = useNotificationStore.getState()
  const aiStore = useAIStore()
  const { user, updatePreferences } = useStore()
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()
  const [isExporting, setIsExporting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const languages = [
    { code: "en", name: "English" },
    { code: "es", name: "Español" },
  ]

  const privacySettings: PrivacySetting[] = [
    {
      id: "analytics",
      label: t("settings.privacy.analytics"),
      description: t("settings.privacy.analyticsDescription"),
      icon: PiChartBar,
    },
  ]

  const sections = [
    {
      id: 'appearance',
      title: 'Appearance',
      icon: PiPalette,
      component: ThemeCustomizer
    },
    {
      id: 'ai',
      title: 'AI Personalization',
      icon: PiRobot,
      component: AIPersonalizationSection
    },
  ];

  const handleDataExport = async () => {
    setIsExporting(true)
    try {
      const userData = {
        profile: {
          ...user,
          createdAt: user?.createdAt.toISOString(),
          updatedAt: user?.updatedAt.toISOString(),
        },
        preferences: user?.preferences,
        notebooks: await (notebookStore as any).exportData(),
        moods: await (moodStore as any).exportData(),
        finance: await (financeStore as any).exportData(),
        gamification: useGamificationStore.getState(),
      }

      const dateStr = new Date().toISOString().split("T")[0]
      const filename = `${user?.name}-data-export-${dateStr}.json`

      const dataStr = JSON.stringify(userData, null, 2)
      const blob = new Blob([dataStr], { type: "application/json" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      addNotification({
        type: "success",
        title: t("settings.data.exportSuccess"),
        message: t("settings.data.exportSuccessMessage"),
        duration: 3000,
      })
    } catch (error) {
      if (error instanceof Error) {
        showError(error, "SettingsPage.handleDataExport")
      } else {
        showError("An unknown error occurred", "SettingsPage.handleDataExport")
      }
    } finally {
      setIsExporting(false)
    }
  }

  const handleAccountDeletion = async () => {
    const confirmed = window.confirm(t("settings.data.deleteAccountConfirmation"))
    if (!confirmed) return

    setIsDeleting(true)
    try {
      const user = auth.currentUser
      if (!user) throw new Error("No user logged in")

      if (user.providerData[0]?.providerId === "password") {
        const email = prompt(t("settings.data.reauth.emailPrompt")) || ""
        const password = prompt(t("settings.data.reauth.passwordPrompt")) || ""

        const credential = EmailAuthProvider.credential(email, password)
        await reauthenticateWithCredential(user, credential)
      } else if (user.providerData[0]?.providerId === "google.com") {
        const provider = new GoogleAuthProvider()
        await reauthenticateWithPopup(user, provider)
      }

      notebookStore.clearAllData()
      moodStore.clearAllData()
      financeStore.clearAllData()
      useGamificationStore.getState().clearAllData()
      useAIStore.getState().clearAllData()
      useNotificationStore.getState().clearAllData()

      await Promise.all([deleteUserData(user.uid), user.delete()])

      localStorage.clear()

      await logout()
      navigate("/login")

      addNotification({
        type: "success",
        title: t("settings.data.deleteSuccess"),
        message: t("settings.data.deleteSuccessMessage"),
        duration: 3000,
      })
    } catch (error) {
      if (error instanceof Error) {
        showError(error, "SettingsPage.handleAccountDeletion")
      } else {
        showError("An unknown error occurred", "SettingsPage.handleAccountDeletion")
      }
    } finally {
      setIsDeleting(false)
    }
  }

  const handleApiKeySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const apiKey = formData.get("apiKey") as string

    if (apiKey) {
      aiStore.setApiKey(apiKey)
      addNotification({
        type: "success",
        title: t("settings.ai.keySaved"),
        message: apiKey.slice(0, 8) + "...",
        duration: 3000,
      })
    }
  }

  const handleLanguageChange = async (language: string) => {
    try {
      await i18next.changeLanguage(language)

      setTimeout(() => {
        const languageName = t(`settings.language.${language}`)
        addNotification({
          type: "success",
          title: t("settings.language.changed"),
          message: languageName,
          duration: 3000,
        })
      }, 100)
    } catch (error) {
      if (error instanceof Error) {
        showError(error, "SettingsPage.handleLanguageChange")
      } else {
        showError("An unknown error occurred", "SettingsPage.handleLanguageChange")
      }
    }
  }

  const handlePrivacyToggle = (settingId: keyof PrivacyPreferences) => {
    if (!user?.preferences) return

    const currentPrivacy: PrivacyPreferences = {
      isPublicProfile: user.preferences.privacy?.isPublicProfile ?? false,
      shareActivity: user.preferences.privacy?.shareActivity ?? false,
      analytics: user.preferences.privacy?.analytics ?? false,
      cookies: user.preferences.privacy?.cookies ?? false,
      dataCollection: user.preferences.privacy?.dataCollection ?? false,
    }

    const newPreferences: UserPreferences = {
      ...user.preferences,
      privacy: {
        ...currentPrivacy,
        [settingId]: !currentPrivacy[settingId],
      },
    }

    updatePreferences(newPreferences)
  }

  const handleDarkModeToggle = () => {
    setMode(isDarkMode ? 'light' : 'dark')
  }

  return (
    <motion.div 
      className="max-w-2xl mx-auto p-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.h1 
        variants={itemVariants}
        custom={0}
        className="text-3xl font-bold mb-6" 
        style={{ color: colors.primary }}
      >
        {t("settings.title")}
      </motion.h1>

      <SettingSection title={t("settings.appearance.title")} id="appearance">
        <motion.div
          variants={toggleVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Toggle
            isEnabled={isDarkMode}
            onChange={handleDarkModeToggle}
            label={t("settings.appearance.darkMode")}
            icon={isDarkMode ? <PiMoon className="h-6 w-6" /> : <PiSun className="h-6 w-6" />}
          />
        </motion.div>
      </SettingSection>

      <SettingSection title={t("settings.language.title")} id="language">
        <div className="space-y-4">
          <div className="flex items-center gap-2 mb-2">
            <PiGlobe className="h-6 w-6" style={{ color: colors.textSecondary }} />
            <span style={{ color: colors.text }}>{t("settings.language.select")}</span>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {languages.map((lang) => (
              <button
                key={`lang-${lang.code}`}
                onClick={() => handleLanguageChange(lang.code)}
                className="p-3 rounded-lg transition-colors"
                style={{
                  backgroundColor: i18n.language === lang.code ? `${colors.primary}15` : colors.surface,
                  color: i18n.language === lang.code ? colors.primary : colors.text,
                  border: `1px solid ${i18n.language === lang.code ? colors.primary : colors.border}`,
                }}
              >
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      </SettingSection>

      <SettingSection title={t("settings.privacy.title")} id="privacy">
        <div className="space-y-4">
          {privacySettings.map((setting) => (
            <div key={`privacy-${setting.id}`} className="space-y-1">
              <Toggle
                isEnabled={user?.preferences?.privacy?.[setting.id] ?? false}
                onChange={() => handlePrivacyToggle(setting.id)}
                label={setting.label}
                icon={<setting.icon className="h-6 w-6" />}
              />
              <p className="text-sm ml-14" style={{ color: colors.textSecondary }}>
                {setting.description}
              </p>
            </div>
          ))}
        </div>
      </SettingSection>

      <SettingSection title={t("settings.data.title")} id="data">
        <div className="space-y-4">
          <Button 
            onClick={handleDataExport} 
            disabled={isExporting}
            variant="secondary"
            icon={<PiCloud className="h-5 w-5" />}
            fullWidth
          >
            {isExporting ? t("settings.data.exporting") : t("settings.data.export")}
          </Button>
          <Button 
            onClick={handleAccountDeletion} 
            variant="danger" 
            disabled={isDeleting}
            icon={<PiTrash className="h-5 w-5" />}
            fullWidth
          >
            {isDeleting ? t("settings.data.deleting") : t("settings.data.deleteAccount")}
          </Button>
        </div>
      </SettingSection>

      <SettingSection title={t("settings.ai.title")} id="ai">
        <div className="space-y-4 mb-8">
          <div className="flex items-center gap-2 mb-2">
            <PiKey className="h-6 w-6" style={{ color: colors.textSecondary }} />
            <span style={{ color: colors.text }}>{t("settings.ai.apiKey")}</span>
          </div>

          <p className="text-sm mb-4" style={{ color: colors.textSecondary }}>
            {t("settings.ai.apiKeyDescription")}
          </p>

          {aiStore.apiKey ? (
            <div className="space-y-2">
              <div className="p-2 rounded bg-opacity-10" style={{ backgroundColor: `${colors.primary}15` }}>
                <code>{aiStore.apiKey.slice(0, 8)}...</code>
              </div>
              <Button
                onClick={() => {
                  aiStore.clearApiKey()
                  addNotification({
                    type: "success",
                    title: t("settings.ai.keyRemoved"),
                    message: t("settings.ai.switchedToDefault"),
                    duration: 3000,
                  })
                }}
                variant="danger"
              >
                {t("settings.ai.removeKey")}
              </Button>
            </div>
          ) : (
            <div className="space-y-2">
              <p className="text-sm" style={{ color: colors.success }}>
                {t("settings.ai.usingDefaultKey")}
              </p>
              <form onSubmit={handleApiKeySubmit} className="space-y-2">
                <Input
                  type="password"
                  name="apiKey"
                  placeholder={t("settings.ai.customKeyPlaceholder")}
                  className="w-full"
                />
                <Button type="submit">{t("settings.ai.enterCustomKey")}</Button>
              </form>
            </div>
          )}
        </div>

        <div className="border-t pt-8 mb-8" style={{ borderColor: colors.border }}>
          <AICreditManagement />
        </div>

        <div className="border-t pt-8" style={{ borderColor: colors.border }}>
          <h3 className="text-lg font-medium mb-4" style={{ color: colors.primary }}>
            {t("settings.ai.assistant.title")}
          </h3>
          <AIPersonalizationSection />
        </div>
      </SettingSection>

      <AnimatePresence>
        {error && (
          <motion.div
            key={`error-${error.message || 'unknown'}`}
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -20, scale: 0.95 }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
          >
            <ErrorFeedback 
              error={error} 
              componentName={componentName} 
              isOpen={isErrorVisible} 
              onClose={clearError} 
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}