import type { Variants } from "framer-motion"

export const containerVariants: Variants = {
  hidden: { 
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
}

export const itemVariants: Variants = {
  hidden: { 
    opacity: 0,
    y: 20
  },
  visible: (custom = 0) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.1,
      duration: 0.5,
      ease: "easeOut"
    }
  })
}

export const sidebarVariants: Variants = {
  hidden: { 
    x: "-100%",
    transition: {
      type: "tween",
      duration: 0.2,
      ease: "easeOut"
    }
  },
  visible: { 
    x: 0,
    transition: {
      type: "tween",
      duration: 0.2,
      ease: "easeOut"
    }
  }
}

export const overlayVariants: Variants = {
  hidden: { 
    opacity: 0,
    transition: { duration: 0.15 }
  },
  visible: { 
    opacity: 1,
    transition: { duration: 0.15 }
  }
}

export const dropdownVariants: Variants = {
  hidden: { 
    height: 0,
    opacity: 0,
    transition: {
      height: { duration: 0.2 },
      opacity: { duration: 0.1 }
    }
  },
  visible: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: { duration: 0.2 },
      opacity: { duration: 0.1, delay: 0.1 }
    }
  }
}