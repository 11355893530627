import { auth } from '../utils/firebase';
import { create } from 'zustand';
import { User } from 'firebase/auth';
import { analyticsService, AnalyticsEventTypes } from '../services/analyticsService';
import { userService } from '../services/userService';
import { useAIStore } from './useAIStore';
import { AppError } from '../utils/errors';

// Define a simpler user type for session storage
interface SessionUser {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  isAnonymous: boolean;
}

interface AuthState {
  user: User | SessionUser | null;
  loading: boolean;
  error: Error | null;
  initialized: boolean;
  initializeAuthListener: () => () => void;
  logout: () => Promise<void>;
}

const AUTH_SESSION_KEY = 'auth_session';

const handleAuthStateChange = async (firebaseUser: User | null, set: any) => {
  try {
    if (firebaseUser) {
      try {
        const userData = await userService.getUser(firebaseUser.uid);
        
        // Initialize AI settings from user data
        if (userData?.aiSettings) {
          useAIStore.getState().initializeFromUser(userData.aiSettings);
        }

        set({ 
          user: firebaseUser,
          loading: false,
          initialized: true,
          error: null 
        });

        try {
          await analyticsService.trackEvent(AnalyticsEventTypes.USER_LOGIN);
        } catch (error) {
          console.warn('[AuthStore] Failed to track login event:', error);
        }
      } catch (error) {
        // Handle specific error cases
        if (error instanceof AppError && 
            (error.code === 'USER_NOT_FOUND' || error.code === 'UNAUTHORIZED')) {
          // Set the Firebase user even if profile fetch fails
          set({ 
            user: firebaseUser,
            loading: false,
            initialized: true,
            error: null 
          });
          return;
        }
        console.error('[AuthStore] Error getting user data:', error);
        // Still set the user but with error state
        set({
          user: firebaseUser,
          error: error as Error,
          loading: false,
          initialized: true
        });
      }
    } else {
      set({ 
        user: null,
        loading: false,
        initialized: true,
        error: null 
      });
    }
  } catch (error) {
    console.error('[AuthStore] Error in auth state change:', error);
    set({
      error: error as Error,
      loading: false,
      initialized: true
    });
  }
};

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  loading: true,
  error: null,
  initialized: false,

  initializeAuthListener: () => {
    if (get().initialized) {
      return () => {}
    }

    try {
      const unsubscribe = auth.onAuthStateChanged(
        async (firebaseUser) => {
          await handleAuthStateChange(firebaseUser, set)
        },
        (error) => {
          console.error('[AuthStore] Auth state error:', error)
          set({
            user: null,
            error: error as Error,
            loading: false,
            initialized: true
          })
        }
      )

      return unsubscribe
    } catch (error) {
      console.error('[AuthStore] Error initializing auth:', error)
      set({
        user: null,
        error: error as Error,
        loading: false,
        initialized: true
      })
      return () => {}
    }
  },

  logout: async () => {
    try {
      await auth.signOut();
      sessionStorage.removeItem(AUTH_SESSION_KEY);
      set({ 
        user: null,
        initialized: true,
        loading: false,
        error: null
      });
      try {
        await analyticsService.trackEvent(AnalyticsEventTypes.USER_LOGOUT);
      } catch (error) {
        console.warn('Failed to track logout event:', error);
      }
    } catch (error) {
      set({ error: error as Error });
    }
  }
})); 