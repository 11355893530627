import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { 
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  FirestoreSettings
} from 'firebase/firestore';
import { 
  getAuth, 
  GoogleAuthProvider,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInAnonymously as firebaseSignInAnonymously,
  updateProfile,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  getRedirectResult,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
  signInWithRedirect,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import { writeBatch, collection, query, where, getDocs, doc, setDoc, serverTimestamp, getDoc, updateDoc } from 'firebase/firestore';
import { defaultUserPreferences } from '../types/user';
import { ENV } from './environment'
import { getAnalytics, setUserProperties, Analytics } from 'firebase/analytics';
import type { User as FirebaseUser } from 'firebase/auth';
import type { UserProfile } from '../types/user';

// Helper to determine the current environment
const getEnvironmentConfig = () => {
  return {
    isProduction: ENV.isProduction,
    authDomain: 'plnnr-11ee8.firebaseapp.com'
  }
}

const envConfig = getEnvironmentConfig()

// Get the correct auth domain based on environment
const getAuthDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'plnnr-11ee8.firebaseapp.com';
  }
  return 'plnnr-11ee8.firebaseapp.com'; // Use Firebase project domain even in production
};

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: getAuthDomain(),
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting auth persistence:', error);
});

// Update Firestore initialization to prevent multiple initializations
let db;
try {
  db = getFirestore(app);
} catch {
  // If getFirestore fails, initialize with settings
  const firestoreSettings: FirestoreSettings = {
    experimentalForceLongPolling: true,
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager()
    })
  };
  db = initializeFirestore(app, firestoreSettings);
}

export { db };

// Update connection state monitoring
const monitorFirestoreConnection = () => {
  let isOnline = true;

  window.addEventListener('online', () => {
    if (!isOnline) {
      isOnline = true;
    }
  });

  window.addEventListener('offline', () => {
    isOnline = false;
  });
};

// Initialize everything
const initializeFirebase = () => {
  monitorFirestoreConnection();
};

// Call initialization
initializeFirebase();

// Update the anonymous sign-in function
export const signInAnonymously = async () => {
  try {
    const result = await firebaseSignInAnonymously(auth);
    if (result.user) {
      // Set default display name and photo URL for anonymous users
      await updateProfile(result.user, {
        displayName: 'Guest User',
        photoURL: '/icons/default-avatar.png'
      });

      // Create user profile with proper null handling
      const userProfile = {
        id: result.user.uid,
        name: 'Guest User',
        email: null,
        photoURL: '/icons/default-avatar.png',
        isAnonymous: true,
        createdAt: serverTimestamp(),
        preferences: {
          ...defaultUserPreferences,
          enabledTrackers: ['notebooks', 'mood', 'finance', 'calendar', 'habits', 'lists']
        }
      };

      console.log('Creating anonymous user profile:', userProfile);

      // Create the user document
      await setDoc(doc(db, 'users', result.user.uid), userProfile);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Anonymous sign-in error:', error);
    throw error;
  }
};

// Add email link authentication settings
const actionCodeSettings = {
  url: window.location.origin + '/login/email-link',
  handleCodeInApp: true,
};

// Add new function for passwordless email sign-in
export const sendLoginLink = async (email: string) => {
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    // Save the email for later use
    window.localStorage.setItem('emailForSignIn', email);
    return true;
  } catch (error) {
    console.error('Error sending sign-in link:', error);
    throw error;
  }
};

// Add function to complete sign-in with email link
export const completeEmailSignIn = async (email: string, link: string) => {
  try {
    const result = await signInWithEmailLink(auth, email, link);
    window.localStorage.removeItem('emailForSignIn');
    
    // Create user profile if new user
    if (result.additionalUserInfo?.isNewUser) {
      await createUserProfile(result.user);
    }
    
    return result;
  } catch (error) {
    console.error('Error completing email link sign-in:', error);
    throw error;
  }
};

// Add function to check if current URL is a sign-in link
export const isEmailSignInLink = (link: string) => {
  return isSignInWithEmailLink(auth, link);
};

// Configure Google provider
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');
googleProvider.setCustomParameters({
  prompt: 'select_account',
  redirect_uri: `${getAuthDomain()}/__/auth/handler`
});

// Google sign-in function
export const signInWithGoogle = async () => {
  try {
    auth.useDeviceLanguage();
    const result = await signInWithPopup(auth, googleProvider);
    return result;
  } catch (error: any) {
    console.error('[Firebase] Google sign in error:', error);
    throw error;
  }
};

// Export all auth-related functions and classes
export { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  GoogleAuthProvider,
  getRedirectResult,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  auth,
  storage
};

export const deleteUserData = async (userId: string) => {
  const batch = writeBatch(db);
  
  // Delete notebooks and notes
  const notebooksRef = collection(db, 'notebooks');
  const notebooksQuery = query(notebooksRef, where('userId', '==', userId));
  const notebooksSnapshot = await getDocs(notebooksQuery);
  notebooksSnapshot.forEach(doc => batch.delete(doc.ref));

  // Delete mood entries
  const moodsRef = collection(db, 'moods');
  const moodsQuery = query(moodsRef, where('userId', '==', userId));
  const moodsSnapshot = await getDocs(moodsQuery);
  moodsSnapshot.forEach(doc => batch.delete(doc.ref));

  // Delete finance entries
  const financeRef = collection(db, 'finance');
  const financeQuery = query(financeRef, where('userId', '==', userId));
  const financeSnapshot = await getDocs(financeQuery);
  financeSnapshot.forEach(doc => batch.delete(doc.ref));

  // Delete calendar events
  const calendarRef = collection(db, 'calendar');
  const calendarQuery = query(calendarRef, where('userId', '==', userId));
  const calendarSnapshot = await getDocs(calendarQuery);
  calendarSnapshot.forEach(doc => batch.delete(doc.ref));

  // Delete user preferences
  const userRef = doc(db, 'users', userId);
  batch.delete(userRef);

  // Commit the batch
  await batch.commit();
};

// Update the createUserProfile function
export const createUserProfile = async (user: any, displayName?: string) => {
  try {
    const userProfile = {
      id: user.uid,
      name: displayName || user.displayName || user.email?.split('@')[0] || 'User',
      email: user.email,
      photoURL: user.photoURL || '/icons/default-avatar.png',
      isAnonymous: false,
      emailVerified: user.emailVerified,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      // Add new fields with default values
      lastLogin: serverTimestamp(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language.split('-')[0], // Get browser language
      accountType: 'User',
      accountStatus: 'Active',
      bio: '',
      location: '',
      website: '',
      preferences: defaultUserPreferences
    };

    await setDoc(doc(db, 'users', user.uid), userProfile);
    
    if (!user.displayName) {
      await updateProfile(user, {
        displayName: userProfile.name
      });
    }

    return userProfile;
  } catch (error) {
    console.error('Error creating user profile:', error);
    throw error;
  }
};

// Add a migration function to update existing users
export const migrateUserProfile = async (userId: string) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return;
    
    const userData = userDoc.data();
    const updates: Record<string, any> = {};
    
    // Check and add missing fields
    if (!userData.lastLogin) updates.lastLogin = serverTimestamp();
    if (!userData.timezone) updates.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!userData.language) updates.language = navigator.language.split('-')[0];
    if (!userData.accountType) updates.accountType = 'User';
    if (!userData.accountStatus) updates.accountStatus = 'Active';
    if (!userData.bio) updates.bio = '';
    if (!userData.location) updates.location = '';
    if (!userData.website) updates.website = '';
    
    // Only update if there are missing fields
    if (Object.keys(updates).length > 0) {
      await updateDoc(userRef, updates);
    }
  } catch (error) {
    console.error('Error migrating user profile:', error);
    throw error;
  }
};

// Initialize Firebase Analytics with debug mode for development
let analytics: Analytics | null = null;
try {
  analytics = getAnalytics(app);
  
  if (!ENV.isProduction) {
    // Enable debug mode and localhost analytics
    // @ts-expect-error - Debug mode is not in types but works
    window.self.FIREBASE_ANALYTICS_DEBUG_MODE = true;
    // @ts-expect-error - Internal config property
    window.self.GA_DEBUG = true;
    // @ts-expect-error - Internal config property
    window.self.GA_DEBUG_ENABLED = true;

    // Log configuration attempt
    console.debug('Attempting to initialize Firebase Analytics with:', {
      host: window.location.hostname,
      measurementId: firebaseConfig.measurementId,
      isDebugMode: true
    });

    // Set preview-specific user property
    if (analytics) {
      setUserProperties(analytics, {
        environment: 'preview',
        preview_host: window.location.hostname,
        debug_mode: true
      });
    }
  }
} catch (error) {
  console.error('Analytics initialization failed:', error, {
    config: {
      ...firebaseConfig,
      apiKey: '[REDACTED]' // Don't log the actual API key
    },
    environment: ENV.isProduction ? 'production' : 'development',
    host: window.location.hostname
  });
}

// Add this debug function
const debugAnalytics = () => {
  if (!ENV.isProduction) {
    console.debug('Current Analytics State:', {
      isInitialized: !!analytics,
      measurementId: firebaseConfig.measurementId,
      debugMode: window.self.FIREBASE_ANALYTICS_DEBUG_MODE,
      gaDebug: window.self.GA_DEBUG,
      host: window.location.hostname
    });
  }
};

// Call debug function after initialization
debugAnalytics();

// Set user properties with proper types
export const setAnalyticsUser = (user: FirebaseUser, profile: UserProfile) => {
  if (!analytics) return;
  
  try {
    setUserProperties(analytics, {
      userId: user.uid,
      userEmail: user.email || undefined,
      isAnonymous: user.isAnonymous,
      accountType: profile.accountType,
      enabledTrackers: profile.preferences.enabledTrackers.join(','),
    });
    
    if (!ENV.isProduction) {
      console.debug('Analytics user properties set:', {
        userId: user.uid,
        email: user.email,
        isAnonymous: user.isAnonymous,
        accountType: profile.accountType
      });
    }
  } catch (error) {
    console.warn('Failed to set analytics user properties:', error);
  }
};

export { analytics };

// Export the app instance as well
export { app };