import { ACHIEVEMENTS } from '../constants/achievements';
import { useNotificationStore } from '../store/useNotificationStore';
import { NotificationData } from '../types/notification';
import { useStore } from '../store/useStore';
import { NotificationPreferences } from '../types/user';
import i18next from 'i18next';
import * as Icons from 'react-icons/pi';

const REMINDER_KEY = 'last_reminder_timestamp';
let reminderInterval: NodeJS.Timeout | null = null;
let initializationAttempts = 0;
const MAX_ATTEMPTS = 5;

// Helper to get icon component
const getIconComponent = (iconName: string) => {
  if (!iconName) return null;
  // Convert string like 'PiListPlus' to actual component
  return Icons[iconName as keyof typeof Icons] || null;
};

export const notificationService = {
  // Check if we should show a daily reminder
  shouldShowDailyReminder: (): boolean => {
    const lastReminder = localStorage.getItem(REMINDER_KEY);
    if (!lastReminder) return true;

    const lastReminderDate = new Date(lastReminder);
    const now = new Date();

    // Check if the last reminder was on a different day
    return (
      lastReminderDate.getDate() !== now.getDate() ||
      lastReminderDate.getMonth() !== now.getMonth() ||
      lastReminderDate.getFullYear() !== now.getFullYear()
    );
  },

  // Update the last reminder timestamp
  updateLastReminderTimestamp: () => {
    localStorage.setItem(REMINDER_KEY, new Date().toISOString());
  },

  // Show daily reminder notification
  showDailyReminder: () => {
    if (!notificationService.shouldShowNotification('reminders')) {
      console.log('Daily reminders are disabled');
      return;
    }

    const { addNotification } = useNotificationStore.getState();

    const notification: Omit<NotificationData, 'id' | 'timestamp'> = {
      type: 'reminder',
      title: i18next.t('notifications.dailyReminder.title'),
      message: i18next.t('notifications.dailyReminder.message'),
      icon: 'HeartIcon',
      duration: 10000,
      isHTML: true,
    };

    addNotification(notification);
    notificationService.updateLastReminderTimestamp();
  },

  // Initialize daily reminder check
  initializeDailyReminder: () => {
    const initializeReminders = () => {
      try {
        // Increment attempt counter
        initializationAttempts++;

        // Get store state safely
        const store = useStore.getState();
        if (!store) {
          throw new Error('Store not initialized');
        }

        // Clear existing interval if any
        if (reminderInterval) {
          clearInterval(reminderInterval);
          reminderInterval = null;
        }

        // Check if we've exceeded max attempts
        if (initializationAttempts > MAX_ATTEMPTS) {
          console.warn('Max initialization attempts reached, stopping reminders setup');
          return;
        }

        // Only proceed if we have a valid user with preferences
        if (!store.user?.preferences?.notifications) {
          console.log(`Attempt ${initializationAttempts}: Waiting for user preferences...`);
          // Try again in a few seconds
          setTimeout(initializeReminders, 2000);
          return;
        }

        // Setup successful, reset attempts
        initializationAttempts = 0;

        // Set up the interval
        reminderInterval = setInterval(() => {
          if (notificationService.shouldShowDailyReminder()) {
            notificationService.showDailyReminder();
          }
        }, 3600000);

      } catch (error) {
        console.error('Error initializing reminders:', error);
        if (initializationAttempts <= MAX_ATTEMPTS) {
          setTimeout(initializeReminders, 2000);
        }
      }
    };

    // Start initialization with a delay to allow for store hydration
    setTimeout(initializeReminders, 1000);
  },

  shouldShowNotification: (type: keyof NotificationPreferences): boolean => {
    try {
      const store = useStore.getState();
      if (!store?.user?.preferences?.notifications) {
        return false;
      }
      return store.user.preferences.notifications[type] ?? false;
    } catch (error) {
      console.error('Error checking notification preferences:', error);
      return false;
    }
  },

  showNotification: (notification: Omit<NotificationData, 'id' | 'timestamp'>) => {
    // Map notification types to preference keys
    const preferenceMap: { [key: string]: keyof NotificationPreferences } = {
      'achievement': 'achievements',
      'streak': 'streaks',
      'levelUp': 'levelUp',
      'xp': 'xp',
      'reminder': 'reminders',
      'info': 'general',
      'warning': 'general',
      'error': 'general'
    };

    // Get the corresponding preference key
    const preferenceKey = preferenceMap[notification.type];

    // Check if this type of notification is enabled
    if (preferenceKey && !notificationService.shouldShowNotification(preferenceKey)) {
      console.log(`Notification of type ${notification.type} is disabled`);
      return;
    }

    const { addNotification } = useNotificationStore.getState();
    addNotification(notification);
  },

  // Add a new method for temporary notifications
  showTemporaryNotification: (notification: Omit<NotificationData, 'id' | 'timestamp'>) => {
    const { addNotification, removeNotification } = useNotificationStore.getState()
    
    const id = addNotification({
      ...notification,
      duration: notification.duration || 5000 // Default 5 seconds
    })

    // Auto-remove after duration
    setTimeout(() => {
      removeNotification(id)
    }, notification.duration || 5000)
  },

  showAchievementNotification: (achievementId: string) => {
    const achievement = ACHIEVEMENTS.find(a => a.id === achievementId);
    if (!achievement) {
      console.warn(`Achievement not found: ${achievementId}`);
      return;
    }

    // Get the icon component
    const IconComponent = getIconComponent(achievement.icon);

    notificationService.showNotification({
      type: 'achievement',
      title: `Achievement Unlocked: ${achievement.title}`,
      message: achievement.description,
      icon: IconComponent,
      color: achievement.color,
      duration: 5000
    });
  }
}; 