import React, { useState, useRef, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "../contexts/ThemeContext"
import { useErrorFeedback } from "../hooks/useErrorFeedback"
import { ErrorFeedback } from "../components/shared/ErrorFeedback"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { auth } from "../utils/firebase"
import { createUserProfile } from "../utils/firebase"
import { useAuthStore } from "../store/useAuthStore"

const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement> & { label: string }>(
  ({ label, ...props }, ref) => {
    const { colors, isDarkMode } = useTheme()
    return (
      <div className="space-y-1">
        <label htmlFor={props.id} className="block text-sm font-medium" style={{ color: colors.textSecondary }}>
          {label}
        </label>
        <input
          ref={ref}
          className="w-full px-4 py-2 rounded-lg transition-colors duration-200 border focus:border-primary focus:outline-none"
          style={{
            backgroundColor: isDarkMode ? colors.surfaceAlt : colors.background,
            color: colors.text,
            borderColor: colors.border,
          }}
          {...props}
        />
      </div>
    )
  },
)

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  const { colors } = useTheme()
  return (
    <button
      className="w-full py-3 px-4 rounded-lg font-medium transition-all duration-200 flex items-center justify-center min-h-[48px] hover:opacity-90"
      style={{
        backgroundColor: colors.primary,
        color: "#ffffff",
        opacity: props.disabled ? 0.7 : 1,
      }}
      {...props}
    >
      {children}
    </button>
  )
}

export function SignUpPage() {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const confirmPasswordRef = useRef<HTMLInputElement>(null)
  const { user } = useAuthStore()

  useEffect(() => {
    if (user) {
      console.log("SignUpPage: User detected, navigating to home")
      navigate("/home", { replace: true })
    }
  }, [user, navigate])

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!emailRef.current?.value || !passwordRef.current?.value) {
      showError(new Error("Please fill in all fields"), "SignUpPage.handleSignUp")
      return
    }

    if (passwordRef.current.value !== confirmPasswordRef.current?.value) {
      showError(new Error("Passwords do not match"), "SignUpPage.handleSignUp")
      return
    }

    try {
      setIsLoading(true)
      console.log("SignUpPage: Creating user account...")

      const { user } = await createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)

      console.log("SignUpPage: User account created, creating profile...")

      // Create user profile
      await createUserProfile(user)

      console.log("SignUpPage: User profile created")

      // Force loading state to false since navigation might not happen immediately
      setIsLoading(false)
    } catch (error: any) {
      console.error("SignUpPage: Error during signup:", error)
      setIsLoading(false)
      // Show user-friendly error messages
      let errorMessage = "Failed to sign up"
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "An account with this email already exists"
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address"
      } else if (error.code === "auth/operation-not-allowed") {
        errorMessage = "Email/password accounts are not enabled. Please contact support."
      } else if (error.code === "auth/weak-password") {
        errorMessage = "Password should be at least 6 characters"
      }
      showError(new Error(errorMessage), "SignUpPage.handleSignUp")
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center p-4" style={{ backgroundColor: colors.background }}>
      <div className="max-w-md w-full space-y-6 p-8 rounded-lg shadow-lg" style={{ backgroundColor: colors.surface }}>
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-2" style={{ color: colors.primary }}>
            Create Account
          </h2>
          <p className="text-sm" style={{ color: colors.textSecondary }}>
            Sign up to get started
          </p>
        </div>

        <form onSubmit={handleSignUp} className="space-y-4">
          <Input ref={emailRef} label="Email" type="email" id="email" disabled={isLoading} />
          <Input ref={passwordRef} label="Password" type="password" id="password" disabled={isLoading} />
          <Input
            ref={confirmPasswordRef}
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            disabled={isLoading}
          />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Creating Account..." : "Sign Up"}
          </Button>
        </form>

        <div className="text-center mt-6">
          <p style={{ color: colors.textSecondary }}>
            Already have an account?{" "}
            <Link
              to="/login"
              className="font-medium hover:text-secondary transition-colors duration-200"
              style={{ color: colors.primary }}
            >
              Sign in
            </Link>
          </p>
        </div>

        {error && (
          <ErrorFeedback error={error} componentName={componentName} isOpen={isErrorVisible} onClose={clearError} />
        )}
      </div>
    </div>
  )
}

export default SignUpPage