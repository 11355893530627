import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class EditorErrorBoundary extends Component<Props, State> {
  state: State = { hasError: false };

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Editor Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 rounded-lg bg-red-50 border border-red-200">
          <h3 className="text-red-600 font-medium">
            Editor Error: {this.state.error?.message}
          </h3>
          <button
            className="mt-2 text-red-600 hover:underline"
            onClick={() => this.setState({ hasError: false })}
          >
            Try reloading the editor
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default EditorErrorBoundary; 