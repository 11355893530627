import { forwardRef, useState } from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { 
  PiPencilSimple,
  PiTrash,
  PiDotsThreeVertical,
  PiCaretDown,
  PiCaretUp,
  PiNotepad,
  PiCheck,
  PiX
} from 'react-icons/pi'
import type { ListItem as ListItemType, TaskStatus } from '../../types/lists'
import { useTheme } from '../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '../shared/pickers/DatePicker'
import { PrioritySelect } from './PrioritySelect'
import { StatusSelect } from './StatusSelect'
import { motion, AnimatePresence } from 'framer-motion'

// Define priority colors here instead of using from theme
const priorityColors = {
  none: 'transparent',  // This won't be used directly, we'll use textSecondary instead
  low: '#60A5FA',      // blue-400
  medium: '#FBBF24',   // yellow-400
  high: '#EF4444'      // red-500
}

interface ListItemProps {
  item: ListItemType
  onToggle: (id: string, isCompleted: boolean) => void
  onEdit: (item: ListItemType) => void
  onDelete: (id: string) => void
  isEditing: boolean
  editingText: string
  onEditingTextChange: (text: string) => void
  onSaveEdit: () => void
  onCancelEdit: () => void
  onUpdateItem: (id: string, updates: Partial<ListItemType>) => void
}

export const ListItemComponent = forwardRef<HTMLDivElement, ListItemProps>(({
  item,
  onToggle,
  onEdit,
  onDelete,
  isEditing,
  editingText,
  onEditingTextChange,
  onSaveEdit,
  onCancelEdit,
  onUpdateItem
}, ref) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id })

  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false)
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [editingDescription, setEditingDescription] = useState(item.description || '')

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: !isDragging ? transition : undefined,
    backgroundColor: colors.surface,
    border: `1px solid ${colors.border}`,
    opacity: isDragging ? 0 : 1,
    cursor: isDragging ? 'grabbing' : 'grab',
    touchAction: 'none'
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isCompleted = e.target.checked
    onUpdateItem(item.id, { 
      isCompleted,
      status: isCompleted ? 'COMPLETED' : (item.status === 'IN_PROGRESS' ? 'IN_PROGRESS' : 'NOT_STARTED')
    })
  }

  const handleStatusChange = (status: TaskStatus) => {
    onUpdateItem(item.id, { 
      status,
      isCompleted: status === 'COMPLETED'
    })
  }

  const handleDescriptionClick = () => {
    setIsDescriptionOpen(true)
    setIsEditingDescription(true)
  }

  const handleDescriptionSubmit = async () => {
    if (!editingDescription.trim() && !item.description) {
      setIsEditingDescription(false)
      return
    }

    await onUpdateItem(item.id, { description: editingDescription.trim() })
    setIsEditingDescription(false)
  }

  const handleDateChange = async (date: Date | undefined) => {
    console.log('Setting date:', date) // Debug log
    await onUpdateItem(item.id, { 
      dueDate: date // Make sure we're passing the full date object
    })
  }

  return (
    <motion.div
      ref={setNodeRef}
      style={style}
      className="flex flex-col gap-2 p-3 rounded-md"
      initial={{ opacity: 0, y: 10 }}
      animate={{ 
        opacity: 1, 
        y: 0,
      }}
      exit={{ opacity: 0, scale: 0.95 }}
      {...attributes}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-start gap-3">
          <div className="flex items-center gap-2">
            <motion.button
              className={`touch-none p-1 ${
                isDragging ? 'opacity-100' : 'opacity-50 hover:opacity-100'
              }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              {...listeners}
            >
              <PiDotsThreeVertical 
                className="h-4 w-4" 
                style={{ 
                  color: isDragging 
                    ? colors.primary 
                    : colors.textSecondary 
                }} 
              />
            </motion.button>

            <motion.input
              type="checkbox"
              checked={item.isCompleted}
              onChange={handleCheckboxChange}
              className="h-5 w-5 rounded border-2"
              whileHover={{ scale: 1.1 }}
              style={{ 
                borderColor: colors.border,
                accentColor: colors.primary
              }}
            />
          </div>

          <div className="flex-1 min-w-0">
            {isEditing ? (
              <motion.form 
                onSubmit={(e) => {
                  e.preventDefault()
                  onSaveEdit()
                }}
                className="flex gap-2"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <input
                  type="text"
                  value={editingText}
                  onChange={(e) => onEditingTextChange(e.target.value)}
                  className="flex-1 px-2 py-1 rounded border"
                  style={{
                    backgroundColor: colors.surface,
                    borderColor: colors.border,
                    color: colors.text
                  }}
                  autoFocus
                />
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-3 py-1 rounded text-sm whitespace-nowrap flex items-center gap-1"
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.text
                  }}
                >
                  <PiCheck className="w-4 h-4" />
                  {t('common.save')}
                </motion.button>
                <motion.button
                  type="button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onCancelEdit}
                  className="px-3 py-1 rounded text-sm whitespace-nowrap flex items-center gap-1"
                  style={{
                    backgroundColor: `${colors.quaternary}10`,
                    color: colors.quaternary
                  }}
                >
                  <PiX className="w-4 h-4" />
                  {t('common.cancel')}
                </motion.button>
              </motion.form>
            ) : (
              <div className="flex flex-col gap-1">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <span
                    className="flex-1 break-words"
                    style={{ 
                      color: colors.text,
                      textDecoration: item.isCompleted ? 'line-through' : 'none',
                      opacity: item.isCompleted ? 0.7 : 1
                    }}
                  >
                    {item.content}
                  </span>
                  
                  <AnimatePresence>
                    {!isEditing && (
                      <motion.div 
                        className="hidden sm:flex items-center gap-6"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 20 }}
                      >
                        <StatusSelect
                          value={item.status}
                          onChange={handleStatusChange}
                          iconSize={7}
                        />
                        <DatePicker
                          date={item.dueDate}
                          onSelect={handleDateChange}
                          iconSize={7}
                        />
                        <PrioritySelect
                          value={item.priority || 'NONE'}
                          onChange={(priority) => onUpdateItem(item.id, { priority })}
                          priorityColors={priorityColors}
                          iconSize={7}
                        />
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => onEdit(item)}
                          className="p-1 rounded hover:bg-opacity-10 transition-colors"
                          style={{ color: colors.textSecondary }}
                        >
                          <PiPencilSimple className="h-7 w-7" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => onDelete(item.id)}
                          className="p-1 rounded hover:bg-opacity-10 transition-colors"
                          style={{ color: colors.error }}
                        >
                          <PiTrash className="h-7 w-7" />
                        </motion.button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <AnimatePresence>
                  <motion.button
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                    className="flex items-center gap-1 text-sm sm:ml-0 mt-2"
                    style={{ color: colors.textSecondary }}
                  >
                    {isDescriptionOpen ? (
                      <PiCaretUp className="h-4 w-4" />
                    ) : (
                      <PiCaretDown className="h-4 w-4" />
                    )}
                    <span>Add description...</span>
                  </motion.button>
                </AnimatePresence>

                <AnimatePresence>
                  {isDescriptionOpen && (
                    <motion.div 
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="ml-2 pl-2 border-l mt-2"
                      style={{ borderColor: colors.border }}
                    >
                      {isEditingDescription ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="py-1"
                        >
                          <textarea
                            value={editingDescription}
                            onChange={(e) => setEditingDescription(e.target.value)}
                            className="w-full px-3 py-2 text-sm rounded border"
                            style={{
                              backgroundColor: colors.surface,
                              borderColor: colors.border,
                              color: colors.text
                            }}
                            rows={3}
                            placeholder="Add a description..."
                            autoFocus
                            onBlur={handleDescriptionSubmit}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                                handleDescriptionSubmit()
                              }
                              if (e.key === 'Escape') {
                                setIsEditingDescription(false)
                                setEditingDescription(item.description || '')
                              }
                            }}
                          />
                        </motion.div>
                      ) : (
                        <motion.div 
                          className="group flex items-start gap-2 py-1 cursor-pointer"
                          onClick={handleDescriptionClick}
                          whileHover={{ backgroundColor: `${colors.textSecondary}05` }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                        >
                          <p 
                            className="text-sm flex-1"
                            style={{ 
                              color: colors.textSecondary,
                              opacity: item.isCompleted ? 0.7 : 1
                            }}
                          >
                            {item.description || 'Click to add a description...'}
                          </p>
                          <motion.button
                            className="p-1 rounded hover:bg-opacity-10 transition-colors opacity-0 group-hover:opacity-100"
                            style={{ color: colors.textSecondary }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <PiPencilSimple className="h-4 w-4" />
                          </motion.button>
                        </motion.div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>

                {!isEditing && (
                  <motion.div 
                    className="flex sm:hidden flex-wrap items-center gap-6 mt-2"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                  >
                    <StatusSelect
                      value={item.status}
                      onChange={handleStatusChange}
                      iconSize={6}
                    />
                    <DatePicker
                      date={item.dueDate}
                      onSelect={handleDateChange}
                      iconSize={6}
                    />
                    <PrioritySelect
                      value={item.priority || 'NONE'}
                      onChange={(priority) => onUpdateItem(item.id, { priority })}
                      priorityColors={priorityColors}
                      iconSize={6}
                    />
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => onEdit(item)}
                      className="p-1 rounded hover:bg-opacity-10 transition-colors"
                      style={{ color: colors.textSecondary }}
                    >
                      <PiPencilSimple className="h-6 w-6" />
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => onDelete(item.id)}
                      className="p-1 rounded hover:bg-opacity-10 transition-colors"
                      style={{ color: colors.error }}
                    >
                      <PiTrash className="h-6 w-6" />
                    </motion.button>
                  </motion.div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )
})

ListItemComponent.displayName = 'ListItem' 