import { useAICreditsStore } from '../../store/useAICreditsStore'
import { useStore } from '../../store/useStore'
import { PiCoinVerticalBold } from 'react-icons/pi'
import { useTheme } from '../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'

interface AICreditDisplayProps {
  compact?: boolean
}

export function AICreditDisplay({ compact = false }: AICreditDisplayProps) {
  const { credits } = useAICreditsStore()
  const { user } = useStore()
  const { colors } = useTheme()
  const { t } = useTranslation()

  // Use user's credits if store hasn't loaded yet
  const currentCredits = credits || user?.credits

  if (!currentCredits) return null

  if (compact) {
    return (
      <div 
        className="flex items-center gap-1 px-2 py-1 rounded-full bg-primary/10"
        title={t('ai.credits.tooltip', {
          remaining: currentCredits.remaining,
          used: currentCredits.used,
          total: currentCredits.remaining + currentCredits.used
        })}
      >
        <PiCoinVerticalBold className="w-4 h-4" style={{ color: colors.primary }} />
        <span className="text-sm font-medium" style={{ color: colors.text }}>
          {currentCredits.remaining}
        </span>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <PiCoinVerticalBold className="w-5 h-5" style={{ color: colors.primary }} />
        <span className="text-lg font-medium" style={{ color: colors.text }}>
          {currentCredits.remaining}
        </span>
      </div>
      <div className="text-xs" style={{ color: colors.textSecondary }}>
        {t('ai.assistantChat.credits.used')}: {currentCredits.used}
      </div>
    </div>

  )
} 