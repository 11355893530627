import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs,
  serverTimestamp,
  Timestamp,
  DocumentData,
  orderBy,
  limit,
  doc,
  setDoc,
  increment,
  runTransaction
} from 'firebase/firestore';
import { db, auth, analytics } from '../utils/firebase';
import { logEvent } from 'firebase/analytics';
import { ENV } from '../utils/environment';

export interface AnalyticsEvent {
  id?: string;
  userId: string;
  eventType: string;
  eventData?: Record<string, any>;
  timestamp: Date;
  sessionId: string;
  platform: string;
  deviceType: string;
  path: string;
}

interface EventAggregation {
  totalEvents: number;
  uniqueUsers: number;
  eventsByType: Record<string, number>;
  eventsByPath: Record<string, number>;
  deviceTypes: Record<string, number>;
  platforms: Record<string, number>;
}

// Event types
export enum AnalyticsEventTypes {
  // Core events
  PAGE_VIEW = 'page_view',
  USER_SIGNUP = 'sign_up',
  USER_LOGIN = 'login',
  USER_LOGOUT = 'logout',
  
  // Feature usage
  FEATURE_USED = 'feature_used',
  
  // Business events
  SUBSCRIPTION_STARTED = 'subscription_start',
  SUBSCRIPTION_CANCELLED = 'subscription_cancel',
  
  // Custom events
  NOTE_CREATED = 'note_created',
  MOOD_LOGGED = 'mood_logged',
  TRANSACTION_CREATED = 'transaction_created',
  
  // Finance events
  FINANCE_TAB_VIEW = 'finance_tab_view',
  FINANCE_TRANSACTION_CREATED = 'finance_transaction_created',
  FINANCE_TRANSACTION_UPDATED = 'finance_transaction_updated',
  FINANCE_TRANSACTION_DELETED = 'finance_transaction_deleted',
  FINANCE_BUDGET_CREATED = 'finance_budget_created',
  FINANCE_BUDGET_UPDATED = 'finance_budget_updated',
  FINANCE_TODO_CREATED = 'finance_todo_created',
  FINANCE_TODO_COMPLETED = 'finance_todo_completed',
  FINANCE_FILTER_USED = 'finance_filter_used',
  FINANCE_SORT_USED = 'finance_sort_used',
  FINANCE_EXPORT_USED = 'finance_export_used',
  
  // Subscription events
  SUBSCRIPTION_UPGRADED = 'subscription_upgraded',
  SUBSCRIPTION_DOWNGRADED = 'subscription_downgraded',
  SUBSCRIPTION_ACCESS_DENIED = 'subscription_access_denied',
  SUBSCRIPTION_UPGRADE_FAILED = 'subscription_upgrade_failed',
  
  // App Actions
  APP_ACTION = 'app_action', // Generic action tracking
  
  // Finance Module Actions
  FINANCE_ACTION_TAB_VIEW = 'finance_tab_view',
  FINANCE_ACTION_TRANSACTION_CREATE = 'finance_transaction_create',
  FINANCE_ACTION_TRANSACTION_UPDATE = 'finance_transaction_update',
  FINANCE_ACTION_TRANSACTION_DELETE = 'finance_transaction_delete',
  FINANCE_ACTION_BUDGET_CREATE = 'finance_budget_create',
  FINANCE_ACTION_BUDGET_UPDATE = 'finance_budget_update',
  FINANCE_ACTION_FILTER_USE = 'finance_filter_use',
  FINANCE_ACTION_EXPORT_USE = 'finance_export_use'
}

export type AnalyticsEventType = typeof AnalyticsEventTypes[keyof typeof AnalyticsEventTypes];

// Helper function to determine device type
function getDeviceType(): string {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
}

function getCurrentSessionId(): string {
  // Check if we already have a session ID in sessionStorage
  let sessionId = sessionStorage.getItem('analytics_session_id');
  
  // If no session ID exists, create a new one
  if (!sessionId) {
    sessionId = `${Date.now()}-${Math.random().toString(36).substring(2)}`;
    sessionStorage.setItem('analytics_session_id', sessionId);
  }
  
  return sessionId;
}

export const analyticsService = {
  // High-frequency events go to Firebase Analytics
  trackPageView: (path: string) => {
    if (!analytics) {
      console.debug('Analytics not initialized');
      return;
    }

    try {
      if (!ENV.isProduction) {
        console.debug('Analytics page view:', { path });
      }

      logEvent(analytics, AnalyticsEventTypes.PAGE_VIEW, {
        page_path: path,
        page_title: document.title,
        environment: ENV.isProduction ? 'production' : 'development',
        device_type: getDeviceType(),
        platform: navigator.platform
      });
    } catch (error) {
      console.warn('Page view tracking failed:', error);
    }
  },

  // Important business events go to Firestore
  trackBusinessEvent: async (event: BusinessEvent) => {
    await addDoc(collection(db, 'business_events'), {
      ...event,
      timestamp: serverTimestamp(),
    });
  },

  // Daily aggregates for dashboard
  updateDailyStats: async () => {
    const statsRef = doc(db, 'statistics', 'daily');
    await runTransaction(db, async (transaction) => {
      // Update daily stats...
    });
  },

  // Use Firebase Analytics for real-time events
  trackEvent: (
    eventType: AnalyticsEventType,
    eventData?: Record<string, any>
  ) => {
    if (!analytics) return;

    try {
      if (!ENV.isProduction) {
        console.debug('📊 Analytics Event:', {
          type: eventType,
          data: eventData,
          timestamp: new Date().toISOString()
        });
      }
      
      logEvent(analytics, eventType, {
        ...eventData,
        timestamp: Date.now(),
        session_id: getCurrentSessionId(),
        view: window.location.pathname
      });
    } catch (error) {
      console.warn('Analytics tracking failed:', error);
    }
  },

  // Store daily aggregates in Firestore
  storeDailyAggregate: async () => {
    const today = new Date().toISOString().split('T')[0];
    const analyticsRef = doc(db, 'analytics_daily', today);
    
    // Update once per day per metric
    await setDoc(analyticsRef, {
      totalUsers: increment(1),
      pageViews: increment(1),
      // other metrics...
    }, { merge: true });
  },

  // Get aggregated analytics for a time period
  getAnalytics: async (startDate: Date, endDate: Date): Promise<EventAggregation> => {
    const analyticsRef = collection(db, 'analytics');
    const q = query(
      analyticsRef,
      where('timestamp', '>=', Timestamp.fromDate(startDate)),
      where('timestamp', '<=', Timestamp.fromDate(endDate)),
      orderBy('timestamp', 'desc')
    );

    const snapshot = await getDocs(q);
    const events = snapshot.docs.map(doc => ({
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate()
    })) as AnalyticsEvent[];

    return aggregateEvents(events);
  },

  // Get most recent events
  getRecentEvents: async (limit: number = 100): Promise<AnalyticsEvent[]> => {
    const analyticsRef = collection(db, 'analytics');
    const q = query(
      analyticsRef,
      orderBy('timestamp', 'desc'),
      limit(limit)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate()
    })) as AnalyticsEvent[];
  },

  // Get user activity
  getUserActivity: async (userId: string): Promise<AnalyticsEvent[]> => {
    const analyticsRef = collection(db, 'analytics');
    const q = query(
      analyticsRef,
      where('userId', '==', userId),
      orderBy('timestamp', 'desc')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate()
    })) as AnalyticsEvent[];
  }
};

// Helper function to aggregate events
const aggregateEvents = (events: AnalyticsEvent[]): EventAggregation => {
  const uniqueUsers = new Set(events.map(e => e.userId)).size;
  const eventsByType: Record<string, number> = {};
  const eventsByPath: Record<string, number> = {};
  const deviceTypes: Record<string, number> = {};
  const platforms: Record<string, number> = {};

  events.forEach(event => {
    // Count events by type
    eventsByType[event.eventType] = (eventsByType[event.eventType] || 0) + 1;
    
    // Count events by path
    eventsByPath[event.path] = (eventsByPath[event.path] || 0) + 1;
    
    // Count device types
    deviceTypes[event.deviceType] = (deviceTypes[event.deviceType] || 0) + 1;
    
    // Count platforms
    platforms[event.platform] = (platforms[event.platform] || 0) + 1;
  });

  return {
    totalEvents: events.length,
    uniqueUsers,
    eventsByType,
    eventsByPath,
    deviceTypes,
    platforms
  };
};

// Helper to track app actions with consistent parameters
export const trackAppAction = (
  category: string,
  action: string,
  details?: Record<string, any>
) => {
  analyticsService.trackEvent('app_action', {
    category,
    action,
    ...details,
    timestamp: Date.now(),
    session_id: getCurrentSessionId()
  });
}; 