import { type ReactNode, type CSSProperties } from 'react'
import { useTheme } from '../../contexts/ThemeContext'

interface MenuButtonProps {
  isActive?: boolean
  onClick: () => void
  children: ReactNode
  title?: string
  style?: CSSProperties
  className?: string
}

export function MenuButton({ 
  isActive, 
  onClick, 
  children, 
  title, 
  style,
  className
}: MenuButtonProps) {
  const { colors } = useTheme()
  
  return (
    <button
      onClick={onClick}
      title={title}
      className={`p-1.5 rounded-lg transition-colors duration-200 hover:bg-opacity-10 ${className || ''}`}
      style={{
        backgroundColor: isActive ? `${colors.primary}15` : 'transparent',
        color: isActive ? colors.primary : colors.text,
        ...style
      }}
    >
      {children}
    </button>
  )
} 