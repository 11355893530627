import { useTheme } from '../../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PiX,
  PiNotebook,
  PiPlus,
  PiXCircle
} from 'react-icons/pi';

interface CreateNotebookModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  title: string;
  onTitleChange: (value: string) => void;
}

const modalVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 }
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
};

export function CreateNotebookModal({ 
  isOpen, 
  onClose, 
  onSubmit,
  title,
  onTitleChange
}: CreateNotebookModalProps) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/50"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <motion.div 
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div 
              className="bg-surface rounded-lg shadow-lg max-w-md w-full"
              style={{ backgroundColor: colors.surface }}
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <PiNotebook 
                      className="h-6 w-6" 
                      style={{ color: colors.primary }} 
                    />
                    <h3 
                      className="text-xl font-semibold"
                      style={{ color: colors.primary }}
                    >
                      {t('notebooks.newNotebook')}
                    </h3>
                  </div>
                  <motion.button
                    onClick={onClose}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    style={{ color: colors.textSecondary }}
                  >
                    <PiX className="h-6 w-6" />
                  </motion.button>
                </div>

                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => onTitleChange(e.target.value)}
                    placeholder={t('notebooks.enterTitle')}
                    className="w-full px-3 py-2 rounded-lg border mb-4"
                    style={{
                      backgroundColor: colors.surface,
                      borderColor: colors.border,
                      color: colors.text
                    }}
                    autoFocus
                  />

                  <div className="flex justify-end gap-2">
                    <motion.button
                      type="button"
                      onClick={onClose}
                      className="px-4 py-2 rounded-lg flex items-center gap-2"
                      style={{
                        backgroundColor: `${colors.error}20`,
                        color: colors.error
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <PiXCircle className="h-5 w-5" />
                      {t('common.cancel')}
                    </motion.button>
                    <motion.button
                      type="submit"
                      className="px-4 py-2 rounded-lg flex items-center gap-2"
                      style={{
                        backgroundColor: colors.primary,
                        color: 'white'
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      disabled={!title.trim()}
                    >
                      <PiPlus className="h-5 w-5" />
                      {t('notebooks.create')}
                    </motion.button>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
} 