import { AppError } from '../utils/errors'

const WEATHER_API_KEY = import.meta.env.VITE_WEATHER_API_KEY
const BASE_URL = 'https://api.weatherapi.com/v1'

interface WeatherData {
  temp_f: number
  condition: {
    text: string
    code: number
  }
  is_day: number
}

interface WeatherResponse {
  location: {
    name: string
    region: string
  }
  current: {
    temp_f: number
    condition: {
      text: string
      code: number
    }
    is_day: number
  }
}

interface CachedWeatherData {
  data: WeatherResponse
  timestamp: number
}

// Cache weather data for 30 minutes
const CACHE_DURATION = 30 * 60 * 1000 // 30 minutes in milliseconds
let weatherCache: CachedWeatherData | null = null

export async function getCurrentWeather(latitude: number, longitude: number): Promise<WeatherResponse> {
  // Check if we have valid cached data
  if (weatherCache) {
    const now = Date.now()
    const isCacheValid = now - weatherCache.timestamp < CACHE_DURATION
    
    if (isCacheValid) {
      return weatherCache.data
    }
  }

  if (!WEATHER_API_KEY) {
    throw new AppError('Weather API key not configured', 'WEATHER_API_KEY_MISSING', 500)
  }

  try {
    const url = `${BASE_URL}/current.json?key=${WEATHER_API_KEY}&q=${latitude},${longitude}&aqi=no`
    const response = await fetch(url)
    const data = await response.json()

    if (!response.ok) {
      throw new AppError(
        'Failed to fetch weather data',
        'WEATHER_API_ERROR',
        response.status
      )
    }

    // Cache the new weather data
    weatherCache = {
      data,
      timestamp: Date.now()
    }

    return data
  } catch (error) {
    if (error instanceof AppError) {
      throw error
    }
    throw new AppError('Failed to fetch weather data', 'WEATHER_FETCH_ERROR', 500)
  }
}

// Add function to clear cache if needed
export function clearWeatherCache() {
  weatherCache = null
} 