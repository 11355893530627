import { Timestamp } from 'firebase/firestore';
import { ThemePreset } from './theme';
import { SubscriptionTier } from './subscription';
import { GamificationState } from './gamification';

export interface ThemeColors {
  logo: {
    primary: string;
    secondary: string;
    tertiary: string;
    accent: string;
    highlight: string;
  };
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
  accent1: string;
  accent2: string;
  background: string;
  surface: string;
  surfaceAlt: string;
  text: string;
  textSecondary: string;
  border: string;
  success: string;
  error: string;
  warning: string;
  info: string;
  shadow: string;
}

export interface NotificationPreferences {
  achievements: boolean;
  streaks: boolean;
  reminders: boolean;
  levelUp: boolean;
  xp: boolean;
  general: boolean;
}

export interface PrivacyPreferences {
  analytics: boolean;
  cookies: boolean;
  dataCollection: boolean;
  isPublicProfile: boolean;
  shareActivity: boolean;
}

export interface SecurityPreferences {
  twoFactorEnabled: boolean;
  lastPasswordChange: Timestamp | null;
  encryptionKey: string | null;
  encryptedFields: string[];
}

export interface UserPreferences {
  theme: 'light' | 'dark' | 'system';
  notifications: NotificationPreferences;
  privacy: PrivacyPreferences;
  security: SecurityPreferences;
  themeColors: ThemeColors;
  enabledTrackers: Array<'notebooks' | 'mood' | 'finance' | 'calendar' | 'lists'>;
  customThemes?: ThemePreset[];
  moodViewPreference: 'circle' | 'timeline';
}

export interface User {
  id: string;
  email: string;
  emailVerified: boolean;
  name: string;
  displayName?: string;
  photoURL?: string;
  bio?: string;
  location?: string;
  website?: string;
  language: string;
  timezone: string;
  role: 'user' | 'admin' | 'superadmin';
  accountStatus: 'active' | 'suspended' | 'deleted';
  createdAt: Timestamp;
  updatedAt: Timestamp;
  lastLogin?: Timestamp;

  // AI Settings
  aiSettings: {
    name: string;
    avatarColor: string;
    personalityPreset: string;
    apiKey?: string | null;
  };

  // Credits
  credits: {
    remaining: number;
    used: number;
    lastReset: Timestamp;
    nextReset: Timestamp;
  };

  // Subscription
  subscription: {
    tier: SubscriptionTier;
    startDate: Timestamp;
    currentPeriodEnd: Timestamp;
    status: 'active' | 'cancelled' | 'expired';
    features: {
      finance: boolean;
      calendar: boolean;
      lists: boolean;
      mood: boolean;
      notebooks: boolean;
    };
  };

  preferences: UserPreferences;

  // Add gamification data
  gamification: GamificationState;
}

export const defaultUserPreferences: UserPreferences = {
  theme: 'system',
  notifications: {
    achievements: true,
    streaks: true,
    reminders: true,
    levelUp: true,
    xp: true,
    general: true
  },
  themeColors: {
    primary: '#007AFF',      // Modern iOS blue
    secondary: '#8E8E93',    // Modern grey
    tertiary: '#5856D6',     // Modern purple
    quaternary: '#34C759',   // Modern green
    accent1: '#6D28D9',      // Modern violet
    accent2: '#2bb44d',      // Modern light green
    background: '#ffffff',
    surface: '#F8F8F8',
    surfaceAlt: '#F2F2F2',
    text: '#111827',
    textSecondary: '#4B5563',
    border: '#E5E7EB',
    success: '#059669',
    error: '#DC2626',
    warning: '#D97706',
    info: '#2563EB',
    shadow: '#000000',
    logo: {
      primary: '#54a066',
      secondary: '#007aff',
      tertiary: '#616e81',
      accent: '#2f784c',
      highlight: '#c3cfb5'
    }
  },
  enabledTrackers: ['notebooks', 'mood', 'finance', 'calendar', 'lists'],
  privacy: {
    analytics: false,
    cookies: false,
    dataCollection: false,
    isPublicProfile: false,
    shareActivity: false
  },
  security: {
    twoFactorEnabled: false,
    lastPasswordChange: null,
    encryptionKey: null,
    encryptedFields: []
  },
  moodViewPreference: 'circle'
};

export const defaultGamificationState: GamificationState = {
  achievements: [],
  streaks: {
    login: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    mood: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    notebook: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    finance: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    lists: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    }
  },
  level: 1,
  xp: 0,
  lastActivity: null
};