import { useState } from 'react'
import { PiCheckCircle } from 'react-icons/pi'
import { useTheme } from '../../contexts/ThemeContext'
import { motion, AnimatePresence } from 'framer-motion'
import { TaskStatus } from '../../types/lists'

interface StatusSelectProps {
  value?: TaskStatus
  onChange: (status: TaskStatus) => void
  iconSize?: number
}

export function StatusSelect({ value = 'NOT_STARTED', onChange, iconSize = 5 }: StatusSelectProps) {
  const [open, setOpen] = useState(false)
  const { colors } = useTheme()

  const statusConfig = {
    NOT_STARTED: {
      label: 'Not Started',
      color: colors.textSecondary,
      showBg: false
    },
    IN_PROGRESS: {
      label: 'In Progress',
      color: colors.warning,
      showBg: true
    },
    COMPLETED: {
      label: 'Completed',
      color: colors.success,
      showBg: true
    }
  } as const

  // Ensure we have a valid status value
  const safeValue = Object.keys(statusConfig).includes(value) ? value : 'NOT_STARTED'
  const currentStatus = statusConfig[safeValue as keyof typeof statusConfig]

  return (
    <div className="relative">
      <motion.button
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
        className="p-1 rounded hover:bg-opacity-10 transition-colors"
        style={{ 
          color: currentStatus.color,
          backgroundColor: currentStatus.showBg ? `${currentStatus.color}10` : undefined
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <PiCheckCircle className={`h-${iconSize} w-${iconSize}`} />
      </motion.button>

      <AnimatePresence>
        {open && (
          <>
            <div 
              className="fixed inset-0" 
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
            />
            <motion.div 
              className="absolute right-0 mt-1 py-1 rounded-md shadow-lg z-50"
              style={{ 
                backgroundColor: colors.surface,
                borderColor: colors.border,
                borderWidth: 1,
                width: '150px'
              }}
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scale: 0.95 }}
              transition={{ duration: 0.15 }}
            >
              <div className="space-y-1">
                {Object.entries(statusConfig).map(([key, config]) => (
                  <motion.button
                    key={key}
                    className="w-full text-left px-3 py-2 rounded text-sm transition-colors"
                    style={{
                      color: config.color,
                      backgroundColor: safeValue === key && config.showBg ? `${config.color}10` : undefined
                    }}
                    onClick={() => {
                      onChange(key as TaskStatus)
                      setOpen(false)
                    }}
                    whileHover={{ 
                      backgroundColor: `${config.color}10`,
                      x: 2
                    }}
                  >
                    {config.label}
                  </motion.button>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
} 