import { useTheme } from '../../contexts/ThemeContext'
import { motion } from 'framer-motion'
import { PiSun, PiMoon } from 'react-icons/pi'

export function DarkModeToggle() {
  const { isDarkMode, setMode, colors } = useTheme()

  const handleToggle = () => {
    try {
      // Directly toggle between light and dark, ignoring system
      const newMode = isDarkMode ? 'light' : 'dark'
      setMode(newMode)
      
      // Save preference to localStorage
      localStorage.setItem('theme-preference', newMode)
    } catch (error) {
      console.error('Failed to toggle theme:', error)
    }
  }

  return (
    <motion.button
      onClick={handleToggle}
      className="p-2 rounded-lg transition-colors relative"
      style={{
        backgroundColor: isDarkMode ? colors.surface : 'rgba(0,0,0,0.1)',
        color: colors.text
      }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      aria-label={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
    >
      <motion.div
        initial={false}
        animate={{ rotate: isDarkMode ? 180 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {isDarkMode ? (
          <PiMoon className="h-5 w-5" />
        ) : (
          <PiSun className="h-5 w-5" />
        )}
      </motion.div>
    </motion.button>
  )
}
