import type React from "react"
import { useEffect, useState } from "react"
import { useTheme } from "../contexts/ThemeContext"
import { Link } from "react-router-dom"
import { SwatchIcon, BellIcon, PencilIcon, CheckIcon, XMarkIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import { Toggle } from "../components/shared/Toggle"
import { useNotificationStore } from "../store/useNotificationStore"
import type { UserPreferences } from "../types/user"
import { useTranslation } from "react-i18next"
import { useErrorFeedback } from "../hooks/useErrorFeedback"
import { useAuthStore } from "../store/useAuthStore"
import { useStore } from "../store/useStore"
import { Navigate } from "react-router-dom"
import { Loader } from "../components/shared/Loader"
import { AppError } from "../utils/errors"
import { motion } from "framer-motion"
import { containerVariants, itemVariants } from "../components/animations"
import { SubscriptionSection } from '../components/profile/SubscriptionSection'
import { SubscriptionBadge } from '../components/shared/SubscriptionBadge'
import { Timestamp } from "firebase/firestore"
import { Card } from "../components/ui/card"
import { formatLanguage, formatTimezone } from "../utils/formatters"
import { authService } from "../services/authService"
import { useTimezoneCheck } from '../hooks/useTimezoneCheck'

function formatDate(date: Date | Timestamp | null | undefined): string {
  if (!date) return 'N/A'
  
  try {
    const dateObj = date instanceof Date ? date : date.toDate()
    if (isNaN(dateObj.getTime())) return 'N/A'
    return new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(dateObj)
  } catch (error) {
    console.error('Error formatting date:', error)
    return 'N/A'
  }
}

const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement> & { label: string }> = ({ label, ...props }) => {
  const { colors } = useTheme()
  return (
    <div className="space-y-1">
      <label htmlFor={props.id} className="block text-sm font-medium" style={{ color: colors.textSecondary }}>
        {label}
      </label>
      <input
        className="w-full px-4 py-2 rounded-lg transition-colors duration-200 border focus:border-primary focus:outline-none"
        style={{
          backgroundColor: colors.surface,
          color: colors.text,
          borderColor: colors.border,
        }}
        {...props}
      />
    </div>
  )
}

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: "primary" | "secondary" }> = ({
  children,
  variant = "primary",
  ...props
}) => {
  const { colors } = useTheme()
  const baseStyle = "px-4 py-2 rounded-lg font-medium transition-all duration-200 flex items-center justify-center"
  const variantStyles = {
    primary: {
      backgroundColor: colors.primary,
      color: "#ffffff",
    },
    secondary: {
      backgroundColor: `${colors.primary}15`,
      color: colors.primary,
    },
  }

  return (
    <button className={`${baseStyle} ${props.className || ""}`} style={variantStyles[variant]} {...props}>
      {children}
    </button>
  )
}

const InfoCard = ({ 
  label, 
  children, 
  custom 
}: { 
  label: string
  children: React.ReactNode
  custom: number 
}) => {
  const { colors } = useTheme()
  
  return (
    <motion.div
      variants={itemVariants}
      custom={custom}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
    >
      <Card 
        className="p-6 border backdrop-blur-sm"
        style={{
          backgroundColor: `${colors.surface}95`, // Add slight transparency
          borderColor: `${colors.border}50`,
          boxShadow: `0 4px 6px -1px ${colors.shadow}10, 0 2px 4px -1px ${colors.shadow}05`
        }}
      >
        <label className="block text-sm font-medium mb-2" style={{ color: colors.textSecondary }}>
          {label}
        </label>
        <div className="relative">
          {children}
        </div>
      </Card>
    </motion.div>
  )
}

export const ProfilePage: React.FC = () => {
  const { user: authUser } = useAuthStore()
  const { user, updateUser, updatePreferences, fetchProfile } = useStore()
  const { colors } = useTheme()
  const { addNotification } = useNotificationStore()
  const [isEditingName, setIsEditingName] = useState(false)
  const [editedName, setEditedName] = useState(user?.name || "")
  const [isEditingPhoto, setIsEditingPhoto] = useState(false)
  const [photoUrl, setPhotoUrl] = useState("")
  const [photoUrlError, setPhotoUrlError] = useState("")
  const [isEditingBio, setIsEditingBio] = useState(false)
  const [editedBio, setEditedBio] = useState(user?.bio || "")
  const { t } = useTranslation()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { showError } = useErrorFeedback()

  useTimezoneCheck()

  useEffect(() => {
    const loadProfile = async () => {
      if (!authUser) return
      
      setIsLoading(true)
      try {
        const profile = await fetchProfile()
        if (profile) {
          setEditedName(profile.name || '')
        }
      } catch (error) {
        showError(
          error instanceof AppError 
            ? error 
            : new AppError('Failed to load profile', 'PROFILE_FETCH_FAILED'),
          'ProfilePage'
        )
        addNotification({
          type: 'error',
          title: t('profile.errors.loadFailed'),
          message: t('profile.errors.tryAgain'),
          duration: 5000
        })
      } finally {
        setIsLoading(false)
      }
    }

    loadProfile()
  }, [authUser, fetchProfile, showError, addNotification, t])

  if (!authUser) {
    return <Navigate to="/login" replace />
  }

  if (isLoading || !user || !authUser) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader />
      </div>
    )
  }

  const handleNameSave = async () => {
    if (editedName.trim()) {
      setIsUpdating(true)
      try {
        await updateUser({ name: editedName.trim() })
        addNotification({
          type: "success",
          title: t("profile.nameUpdated"),
          message: t("profile.nameUpdatedMessage"),
          duration: 3000,
        })
        setIsEditingName(false)
      } catch (error) {
        showError(error instanceof AppError ? error : new AppError(
          'Failed to update name',
          'profile/update-failed',
          500
        ), 'ProfilePage')
      } finally {
        setIsUpdating(false)
      }
    }
  }

  const handleNameCancel = () => {
    setEditedName(user.name)
    setIsEditingName(false)
  }

  const toggleNotifications = async () => {
    if (!user?.preferences) return

    try {
      setIsUpdating(true)
      const newPreferences: UserPreferences = {
        ...user.preferences,
        notifications: {
          ...user.preferences.notifications,
          general: !user.preferences.notifications.general,
        },
      }

      await updatePreferences(newPreferences)
      addNotification({
        type: newPreferences.notifications.general ? "success" : "info",
        title: t(newPreferences.notifications.general ? "profile.notifications.enabled" : "profile.notifications.disabled"),
        message: t(newPreferences.notifications.general ? "profile.notifications.enableMessage" : "profile.notifications.disableMessage"),
        duration: 3000,
      })
    } catch (error) {
      showError(error instanceof AppError ? error : new AppError(
        'Failed to update notifications',
        'profile/notifications-failed',
        500
      ), 'ProfilePage')
    } finally {
      setIsUpdating(false)
    }
  }

  const handlePhotoUrlSubmit = async () => {
    if (!photoUrl.trim()) {
      setPhotoUrlError(t("profile.photo.error"))
      return
    }

    try {
      setIsUpdating(true)
      await updateUser({ photoURL: photoUrl.trim() })
      setIsEditingPhoto(false)
      setPhotoUrl("")
      setPhotoUrlError("")
      addNotification({
        type: "success",
        title: t("profile.photo.updated"),
        message: t("profile.photo.updatedMessage"),
        duration: 3000,
      })
    } catch (error) {
      if (error instanceof Error) {
        showError(error, "ProfilePage.handlePhotoUrlSubmit")
      } else {
        showError("An unknown error occurred", "ProfilePage.handlePhotoUrlSubmit")
      }
    } finally {
      setIsUpdating(false)
    }
  }

  const handleBioSave = async () => {
    setIsUpdating(true)
    try {
      await updateUser({ bio: editedBio.trim() })
      addNotification({
        type: "success",
        title: t("profile.bioUpdated"),
        message: t("profile.bioUpdatedMessage"),
        duration: 3000,
      })
      setIsEditingBio(false)
    } catch (error) {
      showError(error instanceof AppError ? error : new AppError(
        'Failed to update bio',
        'profile/update-failed',
        500
      ), 'ProfilePage')
    } finally {
      setIsUpdating(false)
    }
  }

  const handleTrackerToggle = async (tracker: string) => {
    if (!user?.preferences) return;
    
    try {
      setIsUpdating(true);
      const isEnabled = user.preferences.enabledTrackers.includes(tracker);
      
      // Create new preferences with updated trackers
      const updatedTrackers = isEnabled
        ? user.preferences.enabledTrackers.filter(t => t !== tracker)
        : [...user.preferences.enabledTrackers, tracker];
      
      const newPreferences = {
        ...user.preferences,
        enabledTrackers: updatedTrackers
      };

      await updatePreferences(newPreferences);
      
      // Show success notification
      addNotification({
        type: isEnabled ? "info" : "success",
        title: t(`profile.trackers.${isEnabled ? 'disabled' : 'enabled'}`, { tracker }),
        message: t(`profile.trackers.${isEnabled ? 'disableMessage' : 'enableMessage'}`, { tracker }),
        duration: 3000,
      });
    } catch (error) {
      showError(
        error instanceof AppError 
          ? error 
          : new AppError(t('profile.trackers.error'), 'TRACKER_UPDATE_FAILED'),
        'ProfilePage'
      );
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <motion.div
      className="max-w-6xl mx-auto p-4 space-y-6"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Profile Header with Photo and Name */}
      <motion.div 
        variants={itemVariants} 
        custom={0} 
        className="bg-surface rounded-lg p-4 sm:p-6 shadow-sm"
        whileHover={{ scale: 1.01 }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        <div className="flex flex-col items-center gap-6">
          {/* Photo section */}
          <div className="relative">
            <div className="group">
              {user.photoURL ? (
                <motion.img
                  src={user.photoURL}
                  alt={user.name}
                  className="w-24 h-24 rounded-full object-cover ring-2 ring-offset-2"
                  style={{ 
                    ringColor: colors.primary,
                    ringOffsetColor: colors.background 
                  }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                />
              ) : (
                <motion.div
                  className="w-24 h-24 rounded-full flex items-center justify-center"
                  style={{ backgroundColor: `${colors.primary}15` }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <UserCircleIcon 
                    className="w-16 h-16" 
                    style={{ color: colors.primary }} 
                  />
                </motion.div>
              )}
              
              <motion.button
                onClick={() => setIsEditingPhoto(true)}
                className="absolute -bottom-2 px-3 py-1.5 rounded-full text-sm flex items-center gap-1.5 shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 sm:left-1/2 sm:ml-[-48px] left-[calc(50%-24px)]"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  backgroundColor: colors.surface,
                  color: colors.primary,
                  border: `1px solid ${colors.border}`,
                  transformOrigin: 'center',
                }}
              >
                <PencilIcon className="w-3.5 h-3.5" />
                <span className="hidden sm:inline">{t("profile.editPhoto")}</span>
              </motion.button>
            </div>
          </div>
          
          {/* Name and Info section */}
          <div className="flex-1 w-full max-w-2xl">
            {isEditingName ? (
              <div className="flex flex-col sm:flex-row items-center gap-2">
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  className="w-full flex-1 px-4 py-2 rounded-lg transition-colors duration-200 text-center sm:text-left"
                  style={{
                    backgroundColor: colors.surface,
                    color: colors.text,
                    border: `1px solid ${colors.border}`,
                  }}
                  placeholder={t("profile.userInfo.name")}
                />
                <div className="flex gap-2 mt-2 sm:mt-0">
                  <motion.button
                    onClick={handleNameSave}
                    disabled={isUpdating}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-2 rounded-full"
                    style={{ backgroundColor: `${colors.success}20` }}
                  >
                    <CheckIcon className="w-5 h-5" style={{ color: colors.success }} />
                  </motion.button>
                  <motion.button
                    onClick={handleNameCancel}
                    disabled={isUpdating}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-2 rounded-full"
                    style={{ backgroundColor: `${colors.error}20` }}
                  >
                    <XMarkIcon className="w-5 h-5" style={{ color: colors.error }} />
                  </motion.button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center sm:items-start gap-2 w-full">
                <div className="flex items-center justify-center gap-2">
                  <motion.h1 
                    className="text-2xl font-semibold text-center sm:text-left"
                    style={{ color: colors.text }}
                    layout
                  >
                    {user.name}
                  </motion.h1>
                  <motion.button
                    onClick={() => setIsEditingName(true)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-1.5 rounded-full hover:bg-black/5 dark:hover:bg-white/5 transition-colors"
                  >
                    <PencilIcon className="w-4 h-4" style={{ color: colors.primary }} />
                  </motion.button>
                </div>
                
                {user.email && (
                  <p className="text-sm text-center sm:text-left" style={{ color: colors.textSecondary }}>
                    {user.email}
                  </p>
                )}

                {/* Bio section */}
                <div className="w-full mt-4">
                  {isEditingBio ? (
                    <div className="space-y-3">
                      <textarea
                        value={editedBio}
                        onChange={(e) => setEditedBio(e.target.value)}
                        className="w-full px-4 py-2 rounded-lg transition-colors duration-200 text-center sm:text-left"
                        style={{
                          backgroundColor: colors.surface,
                          color: colors.text,
                          border: `1px solid ${colors.border}`,
                        }}
                        rows={2}
                        maxLength={160}
                        placeholder={t("profile.userInfo.bioPlaceholder")}
                      />
                      <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
                        <span className="text-sm order-2 sm:order-1" style={{ color: colors.textSecondary }}>
                          {editedBio.length}/160
                        </span>
                        <div className="flex gap-2 order-1 sm:order-2">
                          <Button onClick={handleBioSave} disabled={isUpdating}>
                            {t("common.save")}
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setEditedBio(user?.bio || '')
                              setIsEditingBio(false)
                            }}
                            disabled={isUpdating}
                          >
                            {t("common.cancel")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center sm:text-left">
                      <p className="text-sm" style={{ color: colors.textSecondary }}>
                        {user.bio || t("profile.userInfo.noBio")}
                      </p>
                      <motion.button
                        onClick={() => setIsEditingBio(true)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="mt-2 text-sm flex items-center gap-1 mx-auto sm:mx-0"
                        style={{ color: colors.primary }}
                      >
                        <PencilIcon className="w-4 h-4" />
                        {t("profile.editBio")}
                      </motion.button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Email Status */}
        <InfoCard label={t("profile.userInfo.email")} custom={1}>
          <div className="flex items-center gap-2">
            <span className="text-lg" style={{ color: colors.text }}>
              {user.email}
            </span>
            <span 
              className="text-sm px-2 py-1 rounded"
              style={{ 
                backgroundColor: user.emailVerified ? colors.success + '20' : colors.warning + '20',
                color: user.emailVerified ? colors.success : colors.warning 
              }}
            >
              {t(user.emailVerified ? "profile.userInfo.verified" : "profile.userInfo.notVerified")}
            </span>
          </div>
        </InfoCard>

        {/* Account Type */}
        <InfoCard label={t("profile.userInfo.accountType")} custom={2}>
          <div className="flex items-center gap-2">
            <span className="text-lg" style={{ color: colors.text }}>
              {t(`profile.userInfo.accountTypes.${user.role || 'user'}`)}
            </span>
            {user.subscription?.tier && (
              <SubscriptionBadge tier={user.subscription.tier} size="sm" />
            )}
          </div>
        </InfoCard>

        {/* Member Since */}
        <InfoCard label={t("profile.userInfo.memberSince")} custom={3}>
          <span className="text-lg" style={{ color: colors.text }}>
            {user.createdAt ? formatDate(user.createdAt) : 'N/A'}
          </span>
        </InfoCard>

        {/* Last Login */}
        <InfoCard label={t("profile.userInfo.lastLogin")} custom={4}>
          <span className="text-lg" style={{ color: colors.text }}>
            {formatDate(user.lastLogin)}
          </span>
        </InfoCard>

        {/* Account Status */}
        {user.accountStatus && (
          <InfoCard label={t("profile.userInfo.accountStatus")} custom={5}>
            <span 
              className="text-sm px-2 py-1 rounded"
              style={{ 
                backgroundColor: user.accountStatus === 'active' ? colors.success + '20' : colors.warning + '20',
                color: user.accountStatus === 'active' ? colors.success : colors.warning 
              }}
            >
              {t(`profile.userInfo.status.${user.accountStatus}`)}
            </span>
          </InfoCard>
        )}

        {/* Optional sections in grid */}
        {user.location && (
          <InfoCard label={t("profile.userInfo.location")} custom={6}>
            <span className="text-lg" style={{ color: colors.text }}>
              {user.location}
            </span>
          </InfoCard>
        )}

        {user.website && (
          <InfoCard label={t("profile.userInfo.website")} custom={7}>
            <a
              href={user.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg hover:underline"
              style={{ color: colors.primary }}
            >
              {user.website}
            </a>
          </InfoCard>
        )}

        {user.timezone && (
          <InfoCard label={t("profile.userInfo.timezone")} custom={8}>
            <span className="text-lg" style={{ color: colors.text }}>
              {formatTimezone(user.timezone)}
            </span>
          </InfoCard>
        )}

        {user.language && (
          <InfoCard label={t("profile.userInfo.language")} custom={9}>
            <span className="text-lg" style={{ color: colors.text }}>
              {formatLanguage(user.language)}
            </span>
          </InfoCard>
        )}
      </div>

      {/* Active Trackers */}
      <motion.div 
        variants={itemVariants} 
        custom={10} 
        className="border backdrop-blur-sm rounded-lg p-6"
        style={{
          backgroundColor: `${colors.surface}95`,
          borderColor: `${colors.border}50`,
          boxShadow: `0 4px 6px -1px ${colors.shadow}10, 0 2px 4px -1px ${colors.shadow}05`
        }}
      >
        <label className="block text-sm font-medium mb-1" style={{ color: colors.textSecondary }}>
          {t("profile.userInfo.activeTrackers")}
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          {['notebooks', 'mood', 'finance', 'calendar', 'lists'].map((tracker) => {
            const isEnabled = user.preferences?.enabledTrackers?.includes(tracker) ?? false;
            
            return (
              <motion.button
                key={tracker}
                onClick={() => handleTrackerToggle(tracker)}
                disabled={isUpdating}
                className="flex items-center gap-2 p-2 rounded cursor-pointer transition-colors duration-200"
                style={{ 
                  backgroundColor: isEnabled ? colors.success + '10' : colors.surface,
                  border: `1px solid ${isEnabled ? colors.success + '30' : colors.border}`,
                  color: isEnabled ? colors.success : colors.textSecondary,
                  opacity: isUpdating ? 0.7 : 1,
                  cursor: isUpdating ? 'wait' : 'pointer'
                }}
                whileHover={{
                  backgroundColor: isEnabled ? colors.success + '20' : colors.primary + '10',
                  scale: isUpdating ? 1 : 1.02
                }}
                whileTap={{ scale: isUpdating ? 1 : 0.98 }}
              >
                <motion.div
                  initial={false}
                  animate={{ 
                    backgroundColor: isEnabled ? colors.success : 'transparent',
                    borderColor: isEnabled ? colors.success : colors.border
                  }}
                  className="w-4 h-4 rounded-sm border flex items-center justify-center"
                >
                  {isEnabled && <CheckIcon className="w-3 h-3 text-white" />}
                </motion.div>
                <span className="capitalize">{t(`profile.sections.trackers.types.${tracker}`)}</span>
              </motion.button>
            );
          })}
        </div>
      </motion.div>

      {/* Subscription Section */}
      <motion.div variants={itemVariants} custom={11}>
        <SubscriptionSection />
      </motion.div>

      {/* Notifications Section */}
      <motion.div 
        className="bg-surface rounded-lg p-6 shadow-md"
        variants={itemVariants}
        custom={4}
      >
        <h2 className="text-2xl font-semibold mb-4" style={{ color: colors.primary }}>
          {t("profile.sections.notifications.title")}
        </h2>
        <Toggle
          isEnabled={user?.preferences?.notifications?.general ?? false}
          onChange={toggleNotifications}
          label={t("profile.sections.notifications.toggle")}
          icon={<BellIcon className="h-6 w-6" />}
          disabled={isLoading || !user || isUpdating}
        />
      </motion.div>

      {/* Theme Customization */}
      <motion.div 
        className="bg-surface rounded-lg p-6 shadow-md"
        variants={itemVariants}
        custom={5}
      >
        <h2 className="text-2xl font-semibold mb-4" style={{ color: colors.primary }}>
          {t("profile.appearance.title")}
        </h2>

        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Link
            to="/theme"
            className="flex items-center justify-between p-4 rounded-lg transition-colors"
            style={{
              backgroundColor: `${colors.primary}15`,
              color: colors.primary,
            }}
          >
            <div className="flex items-center gap-3">
              <SwatchIcon className="h-6 w-6" />
              <span className="font-medium">{t("profile.appearance.customize")}</span>
            </div>
          </Link>
        </motion.div>
      </motion.div>

      {/* Profile Picture Edit Modal */}
      {isEditingPhoto && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-surface w-full max-w-md p-6 rounded-lg shadow-lg"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            <h3 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
              {t("profile.editPhoto")}
            </h3>
            <div className="space-y-4">
              <Input
                label={t("profile.photo.imageUrl")}
                type="url"
                value={photoUrl}
                onChange={(e) => setPhotoUrl(e.target.value)}
                placeholder={t("profile.photo.placeholder")}
              />
              {photoUrlError && (
                <p className="mt-1 text-sm" style={{ color: colors.error }}>
                  {photoUrlError}
                </p>
              )}
              <div className="flex justify-end gap-2">
                <Button onClick={handlePhotoUrlSubmit} disabled={isUpdating}>
                  {isUpdating ? t("profile.photo.updating") : t("profile.photo.update")}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditingPhoto(false)
                    setPhotoUrl("")
                    setPhotoUrlError("")
                  }}
                  disabled={isUpdating}
                >
                  {t("profile.photo.cancel")}
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  )
}