import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Modal } from './Modal';
import { 
  ExclamationTriangleIcon, 
  EnvelopeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CommandLineIcon,
  CheckIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline';

interface ErrorFeedbackProps {
  error: Error | string;
  componentName?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ErrorFeedback = ({ error, componentName, isOpen, onClose }: ErrorFeedbackProps) => {
  const { colors } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const errorMessage = error instanceof Error ? error.message : error;
  const errorStack = error instanceof Error ? error.stack : undefined;

  const getErrorReport = () => {
    const fullReport = `
      Error Details:
      
      Message: ${errorMessage}
      Component: ${componentName || 'Unknown'}
      Stack Trace: ${errorStack || 'Not available'}
      
      Additional Information:
      URL: ${window.location.href}
      User Agent: ${navigator.userAgent}
      Timestamp: ${new Date().toISOString()}
    `;
  
    // Temporarily truncate to see if that fixes the mailto not opening
    return fullReport.slice(0, 1000); 
  };

  const handleCopyReport = async () => {
    try {
      await navigator.clipboard.writeText(getErrorReport());
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleSendEmail = () => {
    const subject = encodeURIComponent(`Error Report: ${componentName || 'Unknown Component'}`);
    const body = encodeURIComponent(getErrorReport());
    window.location.href = `mailto:superman50196@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6 space-y-6">
        {/* Header */}
        <div className="flex items-start gap-4">
          <div 
            className="p-3 rounded-full flex-shrink-0"
            style={{ backgroundColor: `${colors.error}15` }}
          >
            <ExclamationTriangleIcon 
              className="w-6 h-6" 
              style={{ color: colors.error }} 
            />
          </div>
          <div className="space-y-1 flex-1">
            <h3 
              className="text-xl font-semibold"
              style={{ color: colors.error }}
            >
              Something went wrong
            </h3>
            <p 
              className="text-base"
              style={{ color: colors.text }}
            >
              {errorMessage}
            </p>
            {componentName && (
              <p 
                className="text-sm"
                style={{ color: colors.textSecondary }}
              >
                Location: {componentName}
              </p>
            )}
          </div>
        </div>

        {/* Error Details (Expandable) */}
        {errorStack && (
          <div 
            className="rounded-lg overflow-hidden"
            style={{ backgroundColor: `${colors.error}08` }}
          >
            <button
              className="w-full px-4 py-3 flex items-center justify-between text-sm"
              style={{ color: colors.textSecondary }}
              onClick={() => setShowDetails(!showDetails)}
            >
              <div className="flex items-center gap-2">
                <CommandLineIcon className="w-4 h-4" />
                <span>Technical Details</span>
              </div>
              {showDetails ? (
                <ChevronUpIcon className="w-4 h-4" />
              ) : (
                <ChevronDownIcon className="w-4 h-4" />
              )}
            </button>
            {showDetails && (
              <div 
                className="px-4 py-3"
                style={{ 
                  borderTop: `1px solid ${colors.border}50`
                }}
              >
                <pre 
                  className="text-xs overflow-x-auto whitespace-pre-wrap"
                  style={{ 
                    color: colors.text,
                    maxHeight: '200px'
                  }}
                >
                  {errorStack}
                </pre>
              </div>
            )}
          </div>
        )}

        {/* Report Section */}
        <div 
          className="rounded-lg p-4 space-y-3"
          style={{ backgroundColor: `${colors.surface}` }}
        >
          <div className="flex items-center justify-between">
            <h4 
              className="text-sm font-medium"
              style={{ color: colors.text }}
            >
              Error Report
            </h4>
            <div className="flex items-center gap-2">
              <button
                onClick={handleCopyReport}
                className="px-3 py-1.5 rounded-lg text-sm flex items-center gap-1 transition-colors"
                style={{
                  backgroundColor: copySuccess ? `${colors.success}20` : `${colors.primary}10`,
                  color: copySuccess ? colors.success : colors.primary,
                }}
              >
                {copySuccess ? (
                  <>
                    <CheckIcon className="w-4 h-4" />
                    Copied!
                  </>
                ) : (
                  <>
                    <ClipboardIcon className="w-4 h-4" />
                    Copy
                  </>
                )}
              </button>
              <button
                onClick={handleSendEmail}
                className="px-3 py-1.5 rounded-lg text-sm flex items-center gap-1"
                style={{
                  backgroundColor: `${colors.primary}10`,
                  color: colors.primary,
                }}
              >
                <EnvelopeIcon className="w-4 h-4" />
                Email
              </button>
            </div>
          </div>
          
          <pre 
            className="text-xs p-3 rounded whitespace-pre-wrap"
            style={{ 
              backgroundColor: `${colors.background}`,
              color: colors.text,
              border: `1px solid ${colors.border}`,
              maxHeight: '200px',
              overflow: 'auto'
            }}
          >
            {getErrorReport()}
          </pre>
          
          <p 
            className="text-xs"
            style={{ color: colors.textSecondary }}
          >
            Send error report to: <span style={{ color: colors.primary }}>superman50196@gmail.com</span>
          </p>
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-3 pt-2">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded-lg transition-colors"
            style={{
              backgroundColor: colors.surface,
              color: colors.text,
              border: `1px solid ${colors.border}`
            }}
          >
            Dismiss
          </button>
        </div>
      </div>
    </Modal>
  );
}; 