import { useTheme } from '../../contexts/ThemeContext';
import { useMoodStore } from '../../store/useMoodStore';
import { MoodType, DayPeriod, moodTypes } from '../../types/mood';
import { 
  PiChartLine, 
  PiSun, 
  PiMoon, 
  PiClock,
  PiCloud, 
  PiCloudRain,
  PiCloudLightning,
  PiThermometer,
  PiFireSimple,
  PiSmileySad,
  PiSmileyWink,
  PiSparkle,
  PiThermometerSimple
} from 'react-icons/pi'
import { motion } from 'framer-motion';
import { useStore } from '../../store/useStore'
import { SUBSCRIPTION_LIMITS } from '../../constants/subscriptionLimits'
import { UpgradePrompt } from '../shared/UpgradePrompt'

interface InsightMessage {
  message: string;
  emoji: string;
  priority: number;
  icon?: JSX.Element;
  type: 'weather' | 'temperature' | 'time' | 'streak' | 'pattern';
  subMessage?: string;
}

export const MoodInsights = () => {
  const { colors } = useTheme();
  const { entries } = useMoodStore();
  const { user } = useStore();
  const userTier = user?.subscription?.tier || 'free';
  
  // Update check to include both Core and Pro tiers
  const canAccessInsights = ['core', 'pro'].includes(userTier);

  if (!canAccessInsights) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="rounded-lg p-4"
        style={{ backgroundColor: colors.surface }}
      >
        <UpgradePrompt
          title="Unlock Mood Insights"
          description="Upgrade to Core or higher to access detailed mood insights, patterns, and personalized recommendations based on your mood tracking data."
          feature="moodInsights"
          currentTier={userTier}
          requiredTier="core"
          icon={<PiSparkle className="w-6 h-6" />}
        />
      </motion.div>
    );
  }

  const getRecentEntries = (days: number) => {
    const cutoff = new Date();
    cutoff.setDate(cutoff.getDate() - days);
    return entries.filter(entry => new Date(entry.date) >= cutoff);
  };

  // Weather icons mapping
  const weatherIcons: Record<string, JSX.Element> = {
    sunny: <PiSun size={20} />,
    cloudy: <PiCloud size={20} />,
    rainy: <PiCloudRain size={20} />,
    stormy: <PiCloudLightning size={20} />,
    hot: <PiThermometer size={20} />,
    cold: <PiThermometer size={20} className="rotate-180" />
  };

  // Period icons mapping
  const periodIcons: Record<DayPeriod, JSX.Element> = {
    morning: <PiSun size={20} />,
    afternoon: <PiSun size={20} className="rotate-90" />,
    evening: <PiMoon size={20} className="-rotate-45" />,
    night: <PiMoon size={20} />
  };

  // Mood category icons
  const moodCategoryIcons = {
    positive: <PiSmileyWink size={20} />,
    negative: <PiSmileySad size={20} />,
    streak: <PiFireSimple size={20} />
  };

  // Add getWeatherIcon function
  const getWeatherIcon = (weather: string) => {
    const condition = weather.toLowerCase()
    if (condition.includes('sun') || condition.includes('clear')) {
      return <PiSun size={20} />
    }
    if (condition.includes('cloud') || condition.includes('overcast')) {
      return <PiCloud size={20} />
    }
    if (condition.includes('rain') || condition.includes('drizzle')) {
      return <PiCloudRain size={20} />
    }
    if (condition.includes('storm') || condition.includes('thunder')) {
      return <PiCloudLightning size={20} />
    }
    if (condition.includes('hot')) {
      return <PiThermometer size={20} />
    }
    if (condition.includes('cold') || condition.includes('cool')) {
      return <PiThermometer size={20} className="rotate-180" />
    }
    return <PiCloud size={20} />
  }

  const generateInsights = (): InsightMessage[] => {
    const insights: InsightMessage[] = []
    const recentEntries = getRecentEntries(30)
    const lastWeekEntries = getRecentEntries(7)

    if (recentEntries.length === 0) {
      return []
    }

    // Streak Analysis
    const moodStreak = lastWeekEntries.reduce((acc, entry) => {
      const mood = entry.mood
      if (['Amazing', 'Happy'].includes(mood)) {
        acc.positive++
      } else if (['Sad', 'Terrible'].includes(mood)) {
        acc.negative++
      }
      return acc
    }, { positive: 0, negative: 0 })

    if (moodStreak.positive >= 4) {
      insights.push({
        message: "You're on a positive streak! 🌟",
        subMessage: `You've had ${moodStreak.positive} positive moods in the last week. Whatever you're doing, keep it up!`,
        emoji: '✨',
        priority: 100,
        icon: moodCategoryIcons.positive,
        type: 'streak'
      })
    }

    // Time of Day Analysis with more context
    const timePatterns = new Map<DayPeriod, Map<MoodType, number>>()
    recentEntries.forEach(entry => {
      if (!timePatterns.has(entry.period)) {
        timePatterns.set(entry.period, new Map())
      }
      const moodCount = timePatterns.get(entry.period)!
      moodCount.set(entry.mood, (moodCount.get(entry.mood) || 0) + 1)
    })

    timePatterns.forEach((moodCounts, period) => {
      const [dominantMood] = [...moodCounts.entries()]
        .sort((a, b) => b[1] - a[1])
      
      if (dominantMood && dominantMood[1] >= 2 && moodTypes[dominantMood[0]]) {
        const mood = moodTypes[dominantMood[0]]
        const isPositive = ['Amazing', 'Happy'].includes(dominantMood[0])
        const isNegative = ['Sad', 'Terrible'].includes(dominantMood[0])
        
        let message = ''
        let subMessage = ''

        if (isPositive) {
          message = `The ${period} brings out your best mood!`
          subMessage = `You're typically ${mood.label.toLowerCase()} during ${period} time. This could be your optimal time for important activities.`
        } else if (isNegative) {
          message = `${period}s can be challenging`
          subMessage = `You tend to feel ${mood.label.toLowerCase()} during this time. Consider planning uplifting activities for ${period} time.`
        } else {
          message = `${period} mood pattern spotted`
          subMessage = `You're usually feeling ${mood.label.toLowerCase()} during ${period} time.`
        }

        insights.push({
          message,
          subMessage,
          emoji: mood.emoji,
          priority: dominantMood[1] * (isPositive ? 2 : 1.5),
          icon: periodIcons[period],
          type: 'time'
        })
      }
    })

    // Weather Impact Analysis
    if (recentEntries.some(entry => entry.weather)) {
      const weatherMoods = new Map<string, Map<MoodType, number>>()
      const tempRangeMoods = new Map<string, Map<MoodType, number>>()
      let entriesWithWeather = 0

      recentEntries.forEach(entry => {
        if (!entry.weather) return
        entriesWithWeather++

        const { condition, temperature } = entry.weather
        
        // Weather condition analysis
        if (!weatherMoods.has(condition)) {
          weatherMoods.set(condition, new Map())
        }
        const moodCount = weatherMoods.get(condition)!
        moodCount.set(entry.mood, (moodCount.get(entry.mood) || 0) + 1)

        // Temperature analysis
        const tempRange = temperature < 40 ? 'cold' : temperature < 70 ? 'mild' : 'warm'
        if (!tempRangeMoods.has(tempRange)) {
          tempRangeMoods.set(tempRange, new Map())
        }
        const tempMoodCount = tempRangeMoods.get(tempRange)!
        tempMoodCount.set(entry.mood, (tempMoodCount.get(entry.mood) || 0) + 1)
      })

      if (entriesWithWeather >= 3) {
        weatherMoods.forEach((moodCounts, weather) => {
          const [dominantMood] = [...moodCounts.entries()]
            .sort((a, b) => b[1] - a[1])
          
          if (dominantMood && dominantMood[1] >= 2 && moodTypes[dominantMood[0]]) {
            const mood = moodTypes[dominantMood[0]]
            const isPositive = ['Amazing', 'Happy'].includes(dominantMood[0])
            
            insights.push({
              message: isPositive 
                ? `${weather} weather lifts your spirits!` 
                : `Weather impact detected`,
              subMessage: `${weather} conditions tend to put you in a ${mood.label.toLowerCase()} mood.${
                isPositive ? ' Perfect time for outdoor activities!' : ''
              }`,
              emoji: mood.emoji,
              priority: dominantMood[1] * 1.8,
              icon: getWeatherIcon(weather),
              type: 'weather'
            })
          }
        })

        tempRangeMoods.forEach((moodCounts, tempRange) => {
          const [dominantMood] = [...moodCounts.entries()]
            .sort((a, b) => b[1] - a[1])
          
          if (dominantMood && dominantMood[1] >= 2 && moodTypes[dominantMood[0]]) {
            const mood = moodTypes[dominantMood[0]]
            insights.push({
              message: `Temperature preference found`,
              subMessage: `Your mood tends to be ${mood.label.toLowerCase()} in ${tempRange} temperatures.`,
              emoji: mood.emoji,
              priority: dominantMood[1] * 1.5,
              icon: <PiThermometerSimple size={20} />,
              type: 'temperature'
            })
          }
        })
      }
    }

    return insights
      .sort((a, b) => b.priority - a.priority)
      .slice(0, 3)
  }

  const insights = generateInsights()

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  const item = {
    hidden: { opacity: 0, x: -20 },
    show: { opacity: 1, x: 0 }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="rounded-lg p-4"
      style={{ backgroundColor: colors.surface }}
    >
      <motion.h3 
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2 }}
        className="text-lg font-medium mb-4 flex items-center gap-2"
        style={{ color: colors.primary }}
      >
        <motion.span
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 180, 360]
          }}
          transition={{ 
            duration: 1,
            delay: 0.5,
            ease: "easeInOut"
          }}
        >
          <PiChartLine size={24} />
        </motion.span>
        Mood Insights
      </motion.h3>

      <motion.div 
        variants={container}
        initial="hidden"
        animate="show"
        className="space-y-4"
      >
        {insights.length > 0 ? (
          insights.map((insight, index) => (
            <motion.div 
              key={index}
              variants={item}
              whileHover={{ scale: 1.02 }}
              className="flex items-start gap-3 p-4 rounded-lg"
              style={{ backgroundColor: `${colors.primary}10` }}
            >
              <motion.div 
                className="flex flex-col items-center gap-1"
                whileHover={{ scale: 1.1 }}
              >
                <span className="text-xl">{insight.emoji}</span>
                {insight.icon && (
                  <motion.span 
                    style={{ color: colors.text }}
                    whileHover={{ rotate: 20 }}
                  >
                    {insight.icon}
                  </motion.span>
                )}
              </motion.div>
              <div className="flex-1">
                <p 
                  className="font-medium"
                  style={{ color: colors.text }}
                >
                  {insight.message}
                </p>
                {insight.subMessage && (
                  <p 
                    className="text-sm mt-1"
                    style={{ color: colors.textSecondary }}
                  >
                    {insight.subMessage}
                  </p>
                )}
              </div>
            </motion.div>
          ))
        ) : (
          <motion.p 
            variants={item}
            className="flex items-center gap-2" 
            style={{ color: colors.textSecondary }}
          >
            <motion.span
              animate={{ 
                rotate: [0, 360],
                scale: [1, 1.2, 1]
              }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                repeatDelay: 5
              }}
            >
              <PiClock size={20} />
            </motion.span>
            Log a few more moods and I'll share some interesting patterns I notice!
          </motion.p>
        )}
      </motion.div>
    </motion.div>
  );
}; 