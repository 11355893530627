import { motion } from 'framer-motion';
import { ACHIEVEMENT_CATEGORIES } from '../../constants/achievements';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  PiTrophy,
  PiFire,
  PiNotebook,
  PiListChecks,
  PiPiggyBank,
  PiSmiley,
  PiStar,
  PiQuestion
} from 'react-icons/pi';
import type { AchievementCategory } from '../../types/gamification';

// Map category icons
const CATEGORY_ICONS = {
  streak: PiFire,
  notes: PiNotebook,
  lists: PiListChecks,
  finance: PiPiggyBank,
  mood: PiSmiley,
  general: PiStar,
  hidden: PiQuestion,
  calendar: PiStar // You might want to use a different calendar icon
} as const;

interface AchievementCategoriesProps {
  selectedCategory: AchievementCategory | 'all';
  onSelectCategory: (category: AchievementCategory | 'all') => void;
  achievementCounts: Record<AchievementCategory, number>;
}

export function AchievementCategories({
  selectedCategory,
  onSelectCategory,
  achievementCounts
}: AchievementCategoriesProps) {
  const { colors } = useTheme();

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4 mb-8">
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => onSelectCategory('all')}
        className={`p-4 rounded-lg transition-colors ${
          selectedCategory === 'all'
            ? 'bg-primary/10 border-2 border-primary'
            : 'bg-surface hover:bg-surface/80 border-2 border-transparent'
        }`}
      >
        <div className="flex flex-col items-center gap-2">
          <div 
            className="w-10 h-10 rounded-full flex items-center justify-center"
            style={{ backgroundColor: `${colors.primary}15` }}
          >
            <PiTrophy 
              className="w-5 h-5"
              style={{ color: colors.primary }}
            />
          </div>
          <div className="text-sm font-medium" style={{ color: colors.text }}>
            All
          </div>
          <div className="text-xs" style={{ color: colors.textSecondary }}>
            {Object.values(achievementCounts).reduce((a, b) => a + b, 0)}
          </div>
        </div>
      </motion.button>

      {(Object.entries(ACHIEVEMENT_CATEGORIES) as [AchievementCategory, typeof ACHIEVEMENT_CATEGORIES[keyof typeof ACHIEVEMENT_CATEGORIES]][])
        .map(([category, data]) => {
          const Icon = CATEGORY_ICONS[category];
          return (
            <motion.button
              key={category}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => onSelectCategory(category)}
              className={`p-4 rounded-lg transition-colors ${
                selectedCategory === category
                  ? 'bg-primary/10 border-2 border-primary'
                  : 'bg-surface hover:bg-surface/80 border-2 border-transparent'
              }`}
            >
              <div className="flex flex-col items-center gap-2">
                <div 
                  className="w-10 h-10 rounded-full flex items-center justify-center"
                  style={{ backgroundColor: `${data.color}15` }}
                >
                  <Icon 
                    className="w-5 h-5"
                    style={{ color: data.color }}
                  />
                </div>
                <div className="text-sm font-medium" style={{ color: colors.text }}>
                  {data.label}
                </div>
                <div className="text-xs" style={{ color: colors.textSecondary }}>
                  {achievementCounts[category]}
                </div>
              </div>
            </motion.button>
          );
        })}
    </div>
  );
} 