import { 
  collection, 
  doc, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where,
  serverTimestamp,
  writeBatch,
  getDoc,
  orderBy,
  limit
} from 'firebase/firestore';
import { db } from '../utils/firebase';
import { FirestoreNotebook, FirestoreNote } from '../types/notebook';
import { auth } from '../utils/firebase';
import { activityService } from './activityService'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';
import { AppError } from '../utils/errors';

export const notebookService = {
  // Get all notebooks for a user
  getUserNotebooks: async (userId: string) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new AppError('User not found', 'USER_NOT_FOUND');
    }

    const userTier = userDoc.data()?.subscription?.tier || 'free';
    const notebookLimit = SUBSCRIPTION_LIMITS.notebooks[userTier];

    const notebooksRef = collection(db, 'notebooks');
    const q = query(
      notebooksRef, 
      where('userId', '==', userId),
      orderBy('updatedAt', 'desc'),
      limit(notebookLimit) // Limit results based on subscription
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => {
      const data = doc.data();
      // Clean the title data
      const rawTitle = data.title;
      let cleanTitle = '';
      
      try {
        if (typeof rawTitle === 'string') {
          // Check if it's a JSON string
          const parsed = JSON.parse(rawTitle);
          // If it contains a color property, it's likely corrupted data
          if (parsed && typeof parsed === 'object' && 'color' in parsed) {
            cleanTitle = 'Untitled';
          } else {
            cleanTitle = String(rawTitle);
          }
        } else if (typeof rawTitle === 'object') {
          cleanTitle = 'Untitled';
        } else {
          cleanTitle = String(rawTitle || 'Untitled');
        }
      } catch {
        // If JSON.parse fails, use the raw title
        cleanTitle = String(rawTitle || 'Untitled');
      }

      return {
        id: doc.id,
        title: cleanTitle,
        userId: data.userId,
        color: data.color || null,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt
      };
    });
  },

  // Create a new notebook
  createNotebook: async (userId: string, title: string) => {
    if (!userId || !title.trim()) {
      throw new AppError('Invalid notebook data', 'INVALID_PARAMETERS')
    }

    const notebookRef = await addDoc(collection(db, 'notebooks'), {
      title: title.trim(),
      userId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    })

    // Try to create activity but don't let it block notebook creation
    try {
      await activityService.createActivity(
        'notebook_created',
        `Created notebook "${title}"`,
        '',
        { notebookId: notebookRef.id },
        notebookRef.id,
        'notebook'
      )
    } catch (error) {
      // Log the error but don't throw it
      console.error('Failed to create activity:', error)
    }

    return notebookRef.id
  },

  // Update notebook title
  updateNotebook: async (id: string, updates: Partial<Notebook>) => {
    const docRef = doc(db, 'notebooks', id);
    
    // Clean the updates
    const cleanUpdates = { ...updates };
    if ('title' in cleanUpdates) {
      let cleanTitle = '';
      const rawTitle = cleanUpdates.title;
      
      try {
        if (typeof rawTitle === 'string') {
          // Check if it's a JSON string
          const parsed = JSON.parse(rawTitle);
          if (parsed && typeof parsed === 'object' && 'color' in parsed) {
            cleanTitle = 'Untitled';
          } else {
            cleanTitle = String(rawTitle);
          }
        } else if (typeof rawTitle === 'object') {
          cleanTitle = 'Untitled';
        } else {
          cleanTitle = String(rawTitle || 'Untitled');
        }
      } catch {
        cleanTitle = String(rawTitle || 'Untitled');
      }
      
      cleanUpdates.title = cleanTitle;
    }

    await updateDoc(docRef, {
      ...cleanUpdates,
      updatedAt: serverTimestamp()
    });
  },

  // Delete notebook and all its notes
  deleteNotebook: async (notebookId: string) => {
    const batch = writeBatch(db);
    
    // Get notebook data for activity tracking
    const notebookRef = doc(db, 'notebooks', notebookId);
    const notebookSnap = await getDoc(notebookRef);
    const notebookTitle = notebookSnap.data()?.title;
    
    // Delete all notes in the notebook
    const notesQuery = query(
      collection(db, 'notes'), 
      where('notebookId', '==', notebookId)
    );
    const notesSnapshot = await getDocs(notesQuery);
    notesSnapshot.docs.forEach(noteDoc => {
      batch.delete(noteDoc.ref);
    });

    // Delete the notebook
    batch.delete(notebookRef);
    await batch.commit();

    // Track the activity
    await activityService.createActivity(
      'notebook_deleted',
      `Deleted notebook "${notebookTitle}"`,
      '',
      { notebookId },
      notebookId,
      'notebook'
    );
  },

  // Get all notes for a notebook
  getNotebookNotes: async (notebookId: string, userId: string) => {
    if (!notebookId || !userId) {
      throw new AppError(
        'Invalid notebook or user ID',
        'INVALID_PARAMETERS'
      );
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    const userTier = userDoc.data()?.subscription?.tier || 'free';
    const noteLimit = SUBSCRIPTION_LIMITS.notesPerNotebook[userTier];

    const q = query(
      collection(db, 'notes'), 
      where('notebookId', '==', notebookId),
      orderBy('updatedAt', 'desc'),
      limit(noteLimit)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as FirestoreNote[];
  },

  // Create a new note
  createNote: async (notebookId: string, userId: string, title: string, content: string) => {
    if (!notebookId || !userId || !title.trim()) {
      throw new AppError('Invalid note data', 'INVALID_PARAMETERS')
    }

    const noteRef = await addDoc(collection(db, 'notes'), {
      notebookId,
      userId,
      title: title.trim(),
      content,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    })

    const noteDoc = await getDoc(noteRef)
    const noteData = noteDoc.data()

    if (!noteDoc.exists() || !noteData) {
      throw new AppError('Failed to create note', 'NOTE_CREATION_FAILED')
    }

    // Only track activity, remove achievement checks
    try {
      await activityService.createActivity(
        'note_created',
        `Created note "${title}"`,
        '',
        { notebookId, noteId: noteRef.id },
        noteRef.id,
        'note'
      )
    } catch (error) {
      console.error('Failed to create activity for note:', error)
    }

    return {
      id: noteRef.id,
      notebookId,
      userId,
      title,
      content,
      createdAt: noteData.createdAt,
      updatedAt: noteData.updatedAt
    }
  },

  // Update note content with debounce
  updateNoteContent: async (noteId: string, content: string): Promise<void> => {
    try {
      const noteRef = doc(db, 'notes', noteId)
      
      await updateDoc(noteRef, {
        content,
        updatedAt: serverTimestamp()
      })

      // Try to log activity, but don't block if it fails
      try {
        await activityService.createActivity(
          'note_updated',
          'Updated note content',
          '',
          { noteId },
          noteId,
          'note'
        )
      } catch (error) {
        // Silently fail activity logging - don't block the main functionality
        console.warn('Failed to log note update activity:', error)
      }

    } catch (error) {
      console.error('Error updating note content:', error)
      throw new AppError('Failed to update note content', 'NOTE_UPDATE_FAILED')
    }
  },

  // Update note title
  updateNoteTitle: async (noteId: string, title: string) => {
    const noteRef = doc(db, 'notes', noteId);
    const noteSnap = await getDoc(noteRef);
    const oldTitle = noteSnap.data()?.title;

    await updateDoc(noteRef, {
      title,
      updatedAt: serverTimestamp()
    });

    // Track the activity
    await activityService.createActivity(
      'note_updated',
      `Renamed note "${oldTitle}" to "${title}"`,
      '',
      { noteId, notebookId: noteSnap.data()?.notebookId },
      noteId,
      'note'
    );
  },

  // Delete a note
  deleteNote: async (noteId: string) => {
    const noteRef = doc(db, 'notes', noteId);
    const noteSnap = await getDoc(noteRef);
    const noteData = noteSnap.data();

    await deleteDoc(noteRef);

    // Track the activity
    await activityService.createActivity(
      'note_deleted',
      `Deleted note "${noteData?.title}"`,
      '',
      { noteId, notebookId: noteData?.notebookId },
      noteId,
      'note'
    );
  }
}; 