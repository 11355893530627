import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminService } from '../services/adminService';
import { useTheme } from '../contexts/ThemeContext';
import type { AnalyticsDashboardData } from '../types/admin';
import {
  PiUsers,
  PiChartLine,
  PiDevices,
  PiGear,
  PiSpinner,
  PiCheckCircle,
  PiWarning
} from 'react-icons/pi';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { StatCard } from '../components/admin/StatCard';
import { PieChart } from '../components/admin/PieChart';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import { runAdminMigrations } from '../utils/admin/runMigrations';
import { migrateEncryption, migrateAllUsers } from '../utils/migrations/encryptionMigration';
import { getDocs, collection } from 'firebase/firestore';
import { financeService } from '../services/financeService';
import { db } from '../utils/firebase';
import { notificationService } from '../services/notificationService';
import { AppError } from '../utils/errors';

export function AdminDashboard() {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<AnalyticsDashboardData | null>(null);
  const [isMigrating, setIsMigrating] = useState(false);
  const [migrationError, setMigrationError] = useState<string | null>(null);
  const [migrationSuccess, setMigrationSuccess] = useState(false);
  const [migrationStats, setMigrationStats] = useState<{
    totalUsers: number;
    successCount: number;
    errorCount: number;
    errors: Record<string, string>;
  } | null>(null);
  const [results, setResults] = useState<any>(null);
  const [encryptionStats, setEncryptionStats] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const dashboardData = await adminService.getDashboardData();
        setData(dashboardData);
        setError(null);
      } catch (err) {
        console.error('Dashboard error:', err);
        setError(err instanceof Error ? err.message : 'Failed to load dashboard');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRunMigrations = async () => {
    setIsMigrating(true);
    setMigrationError(null);
    setMigrationSuccess(false);
    setMigrationStats(null);

    try {
      const summary = await runAdminMigrations();
      setMigrationStats(summary);
      setMigrationSuccess(true);
    } catch (error) {
      console.error('Migration error:', error);
      setMigrationError(error instanceof Error ? error.message : 'Failed to run migrations');
    } finally {
      setIsMigrating(false);
    }
  };

  const handleMigrateEncryption = async () => {
    setIsMigrating(true);
    try {
      const results = await migrateAllUsers();
      setResults(results);
    } catch (e) {
      console.error('Migration failed:', e);
    }
    setIsMigrating(false);
  };

  const handleCheckEncryption = async () => {
    setIsMigrating(true);
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const stats = {};
      
      for (const userDoc of usersSnapshot.docs) {
        stats[userDoc.id] = await financeService.checkTransactionEncryption(userDoc.id);
      }
      
      setEncryptionStats(stats);
    } catch (e) {
      console.error('Check failed:', e);
    }
    setIsMigrating(false);
  };

  const handleCleanupUser = async (userId: string) => {
    try {
      setIsMigrating(true)
      
      // Delete all transactions
      const deleted = await financeService.deleteAllUserTransactions(userId)
      
      // Run encryption migration to ensure proper key setup
      await migrateEncryption(userId)
      
      notificationService.showTemporaryNotification({
        type: 'success',
        title: 'Cleanup Complete',
        message: `Cleaned up ${deleted} transactions. User can now add new transactions.`,
        icon: PiCheckCircle,
        duration: 5000
      });
    } catch (error) {
      console.error('Cleanup failed:', error)
      notificationService.showTemporaryNotification({
        type: 'error',
        title: 'Cleanup Failed',
        message: error instanceof AppError ? error.message : 'Failed to clean up user data',
        icon: PiWarning,
        duration: 5000
      });
    } finally {
      setIsMigrating(false)
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg" style={{ color: colors.text }}>
          Loading dashboard...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div 
          className="p-4 rounded-lg bg-red-100 text-red-700"
          style={{ backgroundColor: `${colors.error}20`, color: colors.error }}
        >
          Error: {error}
        </div>
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-2xl font-bold mb-6" style={{ color: colors.text }}>
        Admin Dashboard
      </h1>

      {/* User Migrations Section */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          User Data Migrations
        </h2>
        <div className="space-y-4">
          <p className="text-sm" style={{ color: colors.textSecondary }}>
            Run migrations to update user data structures and ensure consistency across all users.
          </p>
          
          <div className="flex items-center gap-4">
            <Button 
              onClick={handleRunMigrations}
              disabled={isMigrating}
              className="relative"
            >
              {isMigrating ? (
                <>
                  <PiSpinner className="w-4 h-4 animate-spin mr-2" />
                  Running Migrations...
                </>
              ) : (
                'Run Migrations'
              )}
            </Button>

            {migrationSuccess && (
              <span className="text-sm" style={{ color: colors.success }}>
                Migrations completed successfully!
              </span>
            )}

            {migrationError && (
              <span className="text-sm" style={{ color: colors.error }}>
                Error: {migrationError}
              </span>
            )}
          </div>
        </div>
      </Card>

      {/* Encryption Migration */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          Encryption Migration
        </h2>
        <div className="space-y-4">
          <p className="text-sm" style={{ color: colors.textSecondary }}>
            Migrate encryption for all users.
          </p>
          
          <div className="flex items-center gap-4">
            <Button 
              onClick={handleMigrateEncryption}
              disabled={isMigrating}
              className="relative"
            >
              {isMigrating ? (
                <>
                  <PiSpinner className="w-4 h-4 animate-spin mr-2" />
                  Migrating...
                </>
              ) : (
                'Migrate Encryption'
              )}
            </Button>

            {results && (
              <div>
                <p>Migration complete:</p>
                <ul>
                  <li>Successfully migrated: {results.success}</li>
                  <li>Failed to migrate: {results.failed}</li>
                  <li>Skipped (already migrated): {results.skipped}</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </Card>

      {/* Encryption Status */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          Encryption Status
        </h2>
        <div className="space-y-4">
          <Button onClick={handleCheckEncryption}>
            Check Encryption Status
          </Button>
          
          {encryptionStats && (
            <div className="mt-4">
              <h3 className="font-medium mb-2">Status:</h3>
              {Object.entries(encryptionStats).map(([userId, stats]: [string, any]) => (
                <div key={userId} className="mb-4 p-4 border rounded">
                  <div className="flex justify-between items-start">
                    <div>
                      <p>User: {userId}</p>
                      <p>Total Transactions: {stats.total}</p>
                      <p>Encrypted (New): {stats.encrypted}</p>
                      <p>Encrypted (Old): {stats.oldFormat}</p>
                      <p>Unencrypted: {stats.unencrypted}</p>
                    </div>
                    <Button 
                      variant="destructive" 
                      size="sm"
                      onClick={() => handleCleanupUser(userId)}
                      disabled={isMigrating}
                    >
                      {isMigrating ? 'Cleaning...' : 'Clean Up User'}
                    </Button>
                  </div>
                  <div className="mt-2">
                    <p className="font-medium">Fields:</p>
                    {Object.entries(stats.fields).map(([field, fieldStats]: [string, any]) => (
                      <div key={field} className="ml-4">
                        <p>{field}:</p>
                        <p className="ml-2">New: {fieldStats.encrypted}</p>
                        <p className="ml-2">Old: {fieldStats.oldFormat}</p>
                        <p className="ml-2">Unencrypted: {fieldStats.unencrypted}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>

      {/* Analytics Section */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          Analytics Overview
        </h2>
        {data ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="p-4 rounded-lg" style={{ backgroundColor: colors.surface }}>
              <h3 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
                Total Users
              </h3>
              <p className="text-2xl font-bold" style={{ color: colors.text }}>
                {data.overview.totalUsers}
              </p>
            </div>
            <div className="p-4 rounded-lg" style={{ backgroundColor: colors.surface }}>
              <h3 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
                Active Users
              </h3>
              <p className="text-2xl font-bold" style={{ color: colors.text }}>
                {data.overview.activeUsers}
              </p>
            </div>
            <div className="p-4 rounded-lg" style={{ backgroundColor: colors.surface }}>
              <h3 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
                Total Events
              </h3>
              <p className="text-2xl font-bold" style={{ color: colors.text }}>
                {data.overview.totalEvents}
              </p>
            </div>
            <div className="p-4 rounded-lg" style={{ backgroundColor: colors.surface }}>
              <h3 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
                New Users Today
              </h3>
              <p className="text-2xl font-bold" style={{ color: colors.text }}>
                {data.overview.newUsersToday}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-sm" style={{ color: colors.textSecondary }}>
            Loading analytics data...
          </p>
        )}
      </Card>

      {/* Feature Usage */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          Feature Usage
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {Object.entries(data.featureUsage).map(([feature, count]) => (
            <div 
              key={feature}
              className="p-4 rounded-lg"
              style={{ backgroundColor: `${colors.primary}10` }}
            >
              <div className="text-sm" style={{ color: colors.textSecondary }}>
                {feature}
              </div>
              <div className="text-xl font-semibold" style={{ color: colors.primary }}>
                {count}
              </div>
            </div>
          ))}
        </div>
      </Card>

      {/* Device Stats */}
      <Card className="p-6">
        <h2 className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
          Device Statistics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PieChart data={data.deviceStats.devices} title="Devices" />
          <PieChart data={data.deviceStats.platforms} title="Platforms" />
        </div>
      </Card>

      {/* Migration Status */}
      {migrationStats && (
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4" style={{ color: colors.text }}>
            Migration Results
          </h3>
          <div className="space-y-2">
            <p>Total Users: {migrationStats.totalUsers}</p>
            <p>Successfully Migrated: {migrationStats.successCount}</p>
            <p>Failed: {migrationStats.errorCount}</p>
            
            {migrationStats.errorCount > 0 && (
              <div className="mt-4">
                <h4 className="font-medium mb-2">Errors:</h4>
                <pre className="text-sm bg-red-100 p-4 rounded">
                  {JSON.stringify(migrationStats.errors, null, 2)}
                </pre>
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
}

// Add to your routes in App.tsx 