import { create } from 'zustand';
import { financeService } from '../services/financeService';
import { notificationService } from '../services/notificationService';
import { PiCheckCircle, PiWarning } from 'react-icons/pi';
import { FinanceStore } from '../types/stores';
import { defaultCategories } from '../constants/financeCategories';
import { useStore } from './useStore';
import { Transaction, FirestoreTransaction, Budget, FirestoreBudget, FinancialTodo, Asset } from '../types/finance';
import { safeDate, formatDateForInput } from '../utils/dateUtils';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { validateAsset } from '../utils/validators'
import { AppError } from '../utils/errors'

// Helper function to convert Firestore timestamp to Date
const convertFirestoreTransaction = (transaction: FirestoreTransaction): Transaction => {
  try {
    let transactionDate: Date;
    
    // Handle both Timestamp and Date objects
    if (transaction.date instanceof Date) {
      // Get the date string in YYYY-MM-DD format
      const dateStr = transaction.date.toISOString().split('T')[0];
      // Create a new date from this string which will be at midnight local time
      transactionDate = new Date(`${dateStr}T00:00:00`);
    } else if ('seconds' in transaction.date) {
      const date = new Date(transaction.date.seconds * 1000);
      const dateStr = date.toISOString().split('T')[0];
      transactionDate = new Date(`${dateStr}T00:00:00`);
    } else {
      throw new Error('Invalid date format');
    }
    
    // Validate the date
    if (isNaN(transactionDate.getTime())) {
      console.error('Invalid timestamp in transaction:', transaction);
      // Fallback to current date at start of local day
      const now = new Date();
      const fallbackDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        12, // noon UTC
        0,
        0
      );
      return {
        ...transaction,
        id: transaction.id,
        date: fallbackDate,
        recurringConfig: undefined,
        subscriptionDetails: undefined
      };
    }

    return {
      ...transaction,
      id: transaction.id,
      date: transactionDate,
      recurringConfig: transaction.recurringConfig ? {
        ...transaction.recurringConfig,
        startDate: transaction.recurringConfig.startDate instanceof Date 
          ? new Date(transaction.recurringConfig.startDate)
          : new Date(transaction.recurringConfig.startDate.seconds * 1000),
        endDate: transaction.recurringConfig.endDate
          ? (transaction.recurringConfig.endDate instanceof Date 
              ? new Date(transaction.recurringConfig.endDate)
              : new Date(transaction.recurringConfig.endDate.seconds * 1000))
          : undefined
      } : undefined,
      subscriptionDetails: transaction.subscriptionDetails ? {
        ...transaction.subscriptionDetails,
        renewalDate: transaction.subscriptionDetails.renewalDate
          ? (transaction.subscriptionDetails.renewalDate instanceof Date
              ? new Date(transaction.subscriptionDetails.renewalDate)
              : new Date(transaction.subscriptionDetails.renewalDate.seconds * 1000))
          : undefined
      } : undefined
    };
  } catch (error) {
    console.error('Error converting transaction:', transaction, error);
    const now = new Date();
    const dateStr = now.toISOString().split('T')[0];
    return {
      ...transaction,
      id: transaction.id,
      date: new Date(`${dateStr}T00:00:00`),
      recurringConfig: undefined,
      subscriptionDetails: undefined
    };
  }
};

const convertFirestoreBudget = (budget: FirestoreBudget): Budget => ({
  id: budget.id,
  categoryId: budget.categoryId,
  amount: budget.amount,
  timeframe: budget.timeframe,
  startDate: new Date(budget.startDate.seconds * 1000),
  endDate: budget.endDate ? new Date(budget.endDate.seconds * 1000) : undefined
});

// Helper function to calculate monthly overviews
const calculateMonthlyOverviews = (transactions: Transaction[]) => {
  const overviews: Record<string, {
    month: string;
    openingBalance: number;
    totalIncome: number;
    totalExpenses: number;
    closingBalance: number;
    categoryTotals: Record<string, number>;
  }> = {};

  // Sort transactions by date
  const sortedTransactions = [...transactions].sort((a, b) => {
    try {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      
      // Check if dates are valid
      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        return 0;
      }
      
      return dateA.getTime() - dateB.getTime();
    } catch (error) {
      console.error('Error sorting dates:', error);
      return 0;
    }
  });

  sortedTransactions.forEach(transaction => {
    try {
      const date = new Date(transaction.date);
      
      // Skip invalid dates
      if (isNaN(date.getTime())) {
        console.warn('Invalid date found in transaction:', transaction);
        return;
      }
      
      // Format as YYYY-MM
      const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!overviews[month]) {
        overviews[month] = {
          month,
          openingBalance: 0,
          totalIncome: 0,
          totalExpenses: 0,
          closingBalance: 0,
          categoryTotals: {}
        };
      }

      const amount = transaction.amount;
      if (transaction.type === 'income') {
        overviews[month].totalIncome += amount;
        overviews[month].closingBalance += amount;
      } else {
        overviews[month].totalExpenses += amount;
        overviews[month].closingBalance -= amount;
      }

      // Update category totals
      if (!overviews[month].categoryTotals[transaction.categoryId]) {
        overviews[month].categoryTotals[transaction.categoryId] = 0;
      }
      overviews[month].categoryTotals[transaction.categoryId] += amount;
    } catch (error) {
      console.error('Error processing transaction:', transaction, error);
    }
  });

  // Calculate opening balances based on previous months
  let runningBalance = 0;
  Object.keys(overviews).sort().forEach(month => {
    overviews[month].openingBalance = runningBalance;
    runningBalance = overviews[month].closingBalance;
  });

  return Object.values(overviews);
};

export const useFinanceStore = create<FinanceStore>((set, get) => ({
  transactions: [],
  budgets: [],
  categories: defaultCategories,
  financialTodos: [],
  monthlyOverviews: [],
  loading: false,
  error: null,
  assets: [],

  exportData: () => {
    const { transactions, categories, budgets, monthlyOverviews, financialTodos } = get();
    return {
      transactions: transactions.map(t => ({
        ...t,
        date: new Date(t.date).toISOString()
      })),
      categories,
      budgets,
      monthlyOverviews,
      financialTodos
    };
  },

  setLoading: (loading: boolean) => set({ loading }),

  fetchTransactions: async (userId: string) => {
    try {
      set({ loading: true });
      const firestoreTransactions = await financeService.getUserTransactions(userId);
      const transactions = firestoreTransactions.map(convertFirestoreTransaction);
      const monthlyOverviews = calculateMonthlyOverviews(transactions);
      set({ 
        transactions, 
        monthlyOverviews,
        loading: false,
        error: null 
      });
    } catch (error) {
      console.error('Error fetching transactions:', error);
      set({ 
        error: error as Error,
        loading: false 
      });
      throw error;
    }
  },

  fetchOverviews: async (userId: string) => {
    try {
      // Monthly overviews are calculated from transactions
      const firestoreTransactions = await financeService.getUserTransactions(userId);
      const transactions = firestoreTransactions.map(convertFirestoreTransaction);
      const monthlyOverviews = calculateMonthlyOverviews(transactions);
      set({ monthlyOverviews });
    } catch (error) {
      console.error('Error fetching overviews:', error);
      throw error;
    }
  },

  fetchBudgets: async (userId: string) => {
    try {
      const firestoreBudgets = await financeService.getUserBudgets(userId);
      const budgets = firestoreBudgets.map(convertFirestoreBudget);
      set({ budgets });
    } catch (error) {
      console.error('Error fetching budgets:', error);
      throw error;
    }
  },

  fetchFinancialTodos: async (userId: string) => {
    try {
      const firestoreTodos = await financeService.getUserTodos(userId);
      const todos = firestoreTodos.map(todo => ({
        id: todo.id,
        description: todo.description,
        dueDate: todo.dueDate ? new Date(todo.dueDate.seconds * 1000) : undefined,
        isCompleted: todo.isCompleted,
        priority: todo.priority
      }));
      set({ financialTodos: todos });
    } catch (error) {
      console.error('Error fetching financial todos:', error);
      throw error;
    }
  },

  addTransaction: async (transaction: Omit<Transaction, 'id'>) => {
    try {
      set({ loading: true, error: null });
      const userId = useStore.getState().user?.id;
      if (!userId) throw new Error('User not authenticated');

      // If it's an asset transfer, update the asset amount
      if (transaction.type === 'asset-transfer' && transaction.assetId) {
        const { assets } = get();
        const asset = assets.find(a => a.id === transaction.assetId);
        if (!asset) throw new Error('Asset not found');

        const newAmount = transaction.assetTransferType === 'deposit'
          ? asset.amount + transaction.amount
          : asset.amount - transaction.amount;

        if (newAmount < 0) throw new Error('Insufficient asset balance');

        // Update the asset first
        await financeService.updateAsset(asset.id, {
          amount: newAmount,
          lastUpdated: new Date()
        });
      }

      // Add the transaction
      const id = await financeService.createTransaction(userId, transaction);
      
      // Fetch fresh data to update the state
      const [firestoreTransactions, firestoreAssets] = await Promise.all([
        financeService.getUserTransactions(userId),
        financeService.getUserAssets(userId)
      ]);

      const transactions = firestoreTransactions.map(convertFirestoreTransaction);
      const assets = firestoreAssets.map(a => ({
        ...a,
        lastUpdated: new Date(a.lastUpdated.seconds * 1000)
      }));
      const monthlyOverviews = calculateMonthlyOverviews(transactions);
      
      set({ 
        transactions, 
        assets,
        monthlyOverviews,
        loading: false,
        error: null 
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
      throw error;
    }
  },

  updateTransaction: async (id: string, updates: Partial<Transaction>) => {
    try {
      set({ loading: true, error: null });
      const { transactions, assets } = get();
      const oldTransaction = transactions.find(t => t.id === id);
      if (!oldTransaction) throw new Error('Transaction not found');

      // If changing to/from asset transfer or changing asset transfer details
      if (
        (oldTransaction.type === 'asset-transfer' && updates.type !== 'asset-transfer') ||
        (oldTransaction.type !== 'asset-transfer' && updates.type === 'asset-transfer') ||
        (oldTransaction.type === 'asset-transfer' && updates.type === 'asset-transfer' && (
          updates.amount !== oldTransaction.amount ||
          updates.assetId !== oldTransaction.assetId ||
          updates.assetTransferType !== oldTransaction.assetTransferType
        ))
      ) {
        // Revert old asset transfer if it exists
        if (oldTransaction.type === 'asset-transfer' && oldTransaction.assetId) {
          const oldAsset = assets.find(a => a.id === oldTransaction.assetId);
          if (oldAsset) {
            const revertedAmount = oldTransaction.assetTransferType === 'deposit'
              ? oldAsset.amount - oldTransaction.amount
              : oldAsset.amount + oldTransaction.amount;

            await financeService.updateAsset(oldAsset.id, {
              amount: revertedAmount,
              lastUpdated: new Date()
            });

            // Update local state
            set({
              assets: assets.map(a => 
                a.id === oldAsset.id 
                  ? { ...a, amount: revertedAmount, lastUpdated: new Date() }
                  : a
              )
            });
          }
        }

        // Apply new asset transfer if needed
        if (updates.type === 'asset-transfer' && updates.assetId) {
          const newAsset = assets.find(a => a.id === updates.assetId);
          if (!newAsset) throw new Error('Asset not found');

          const newAmount = updates.assetTransferType === 'deposit'
            ? newAsset.amount + (updates.amount || 0)
            : newAsset.amount - (updates.amount || 0);

          if (newAmount < 0) throw new Error('Insufficient asset balance');

          await financeService.updateAsset(newAsset.id, {
            amount: newAmount,
            lastUpdated: new Date()
          });

          // Update local state
          set({
            assets: assets.map(a => 
              a.id === newAsset.id 
                ? { ...a, amount: newAmount, lastUpdated: new Date() }
                : a
            )
          });
        }
      }

      // Update the transaction
      await financeService.updateTransaction(id, updates);
      
      set({
        transactions: transactions.map(t => 
          t.id === id ? { ...t, ...updates } : t
        ),
        loading: false
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
      throw error;
    }
  },

  deleteTransaction: async (id: string) => {
    try {
      set({ loading: true, error: null });
      const { transactions, assets } = get();
      const transaction = transactions.find(t => t.id === id);
      if (!transaction) throw new Error('Transaction not found');

      // If it's an asset transfer, revert the asset amount
      if (transaction.type === 'asset-transfer' && transaction.assetId) {
        const asset = assets.find(a => a.id === transaction.assetId);
        if (asset) {
          const revertedAmount = transaction.assetTransferType === 'deposit'
            ? asset.amount - transaction.amount
            : asset.amount + transaction.amount;

          await financeService.updateAsset(asset.id, {
            amount: revertedAmount,
            lastUpdated: new Date()
          });

          // Update local state
          set({
            assets: assets.map(a => 
              a.id === asset.id 
                ? { ...a, amount: revertedAmount, lastUpdated: new Date() }
                : a
            )
          });
        }
      }

      await financeService.deleteTransaction(id);
      
      set({
        transactions: transactions.filter(t => t.id !== id),
        loading: false
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
      throw error;
    }
  },

  addBudget: async (budget) => {
    try {
      set({ loading: true, error: null });
      const userId = useStore.getState().user?.id;
      if (!userId) throw new Error('User not authenticated');

      await financeService.createBudget(userId, budget);
      const firestoreBudgets = await financeService.getUserBudgets(userId);
      const budgets = firestoreBudgets.map(convertFirestoreBudget);
      set({ budgets, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  updateBudget: async (budgetId, updates) => {
    try {
      set({ loading: true, error: null });
      await financeService.updateBudget(budgetId, updates);
      const { budgets } = get();
      const updatedBudgets = budgets.map(budget =>
        budget.id === budgetId ? { ...budget, ...updates } : budget
      );
      set({ budgets: updatedBudgets, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  deleteBudget: async (budgetId) => {
    try {
      set({ loading: true, error: null });
      await financeService.deleteBudget(budgetId);
      const { budgets } = get();
      set({ 
        budgets: budgets.filter(budget => budget.id !== budgetId),
        loading: false 
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  clearAllData: () => {
    set({ 
      transactions: [],
      budgets: [],
      monthlyOverviews: [],
      loading: false,
      error: null 
    });
  },

  fetchTodos: async (userId: string) => {
    try {
      const firestoreTodos = await financeService.getUserTodos(userId);
      const todos = firestoreTodos.map(todo => ({
        id: todo.id,
        description: todo.description,
        dueDate: todo.dueDate ? new Date(todo.dueDate.seconds * 1000) : undefined,
        isCompleted: todo.isCompleted,
        priority: todo.priority
      }));
      set({ financialTodos: todos });
    } catch (error) {
      set({ error: error as Error });
      throw error;
    }
  },

  addTodo: async (todo) => {
    try {
      set({ loading: true, error: null });
      const userId = useStore.getState().user?.id;
      if (!userId) throw new Error('User not authenticated');

      await financeService.createTodo(userId, todo);
      const firestoreTodos = await financeService.getUserTodos(userId);
      const todos = firestoreTodos.map(todo => ({
        id: todo.id,
        description: todo.description,
        dueDate: todo.dueDate ? new Date(todo.dueDate.seconds * 1000) : undefined,
        isCompleted: todo.isCompleted,
        priority: todo.priority
      }));
      set({ financialTodos: todos, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  updateTodo: async (todoId, updates) => {
    try {
      set({ loading: true, error: null });
      await financeService.updateTodo(todoId, updates);
      const { financialTodos } = get();
      const updatedTodos = financialTodos.map((todo: FinancialTodo) =>
        todo.id === todoId ? { ...todo, ...updates } : todo
      );
      set({ financialTodos: updatedTodos, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  deleteTodo: async (todoId) => {
    try {
      set({ loading: true, error: null });
      await financeService.deleteTodo(todoId);
      const { financialTodos } = get();
      set({ 
        financialTodos: financialTodos.filter((todo: FinancialTodo) => todo.id !== todoId),
        loading: false 
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  checkRecurringTransactions: async () => {
    try {
      const userId = useStore.getState().user?.id
      if (!userId) return

      await financeService.processRecurringTransactions(userId)
      
      // Refresh transactions after processing
      const transactions = await financeService.getUserTransactions(userId)
      set({ transactions })
    } catch (error) {
      console.error('Failed to process recurring transactions:', error)
    }
  },

  addAsset: async (asset: Omit<Asset, 'id'>) => {
    try {
      set({ loading: true, error: null })
      const userId = useStore.getState().user?.id
      if (!userId) throw new AppError('User not authenticated', 'AUTH_ERROR')

      // Validate and clean the asset data
      const validatedAsset = validateAsset(asset)

      await financeService.createAsset(userId, validatedAsset)
      const firestoreAssets = await financeService.getUserAssets(userId)
      const assets = firestoreAssets.map(a => ({
        ...a,
        lastUpdated: new Date(a.lastUpdated.seconds * 1000)
      }))
      set({ assets, loading: false })
    } catch (error) {
      set({ error: error as Error, loading: false })
      // Let the error bubble up to be handled by the form
      throw error
    }
  },

  updateAsset: async (id: string, data: Partial<Asset>) => {
    try {
      // Clean the data by removing undefined values
      const cleanData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Partial<Asset>);

      // First update Firestore
      await financeService.updateAsset(id, cleanData);
      
      // Then update local state
      set(state => ({
        assets: state.assets.map(asset => 
          asset.id === id 
            ? { ...asset, ...cleanData, lastUpdated: new Date() }
            : asset
        )
      }));
    } catch (error) {
      console.error('Failed to update asset:', error);
      throw error;
    }
  },

  deleteAsset: async (id: string) => {
    try {
      set({ loading: true, error: null });
      await financeService.deleteAsset(id);
      const { assets } = get();
      set({ 
        assets: assets.filter(asset => asset.id !== id),
        loading: false 
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
      throw error;
    }
  },

  fetchAssets: async (userId: string) => {
    try {
      set({ loading: true });
      const firestoreAssets = await financeService.getUserAssets(userId);
      const assets = firestoreAssets.map(asset => ({
        ...asset,
        lastUpdated: new Date(asset.lastUpdated.seconds * 1000)
      }));
      set({ 
        assets,
        loading: false,
        error: null 
      });
    } catch (error) {
      console.error('Error fetching assets:', error);
      set({ 
        error: error as Error,
        loading: false 
      });
      throw error;
    }
  }
})); 