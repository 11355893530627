import { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { MoodCircle } from '../components/mood/MoodCircle';
import { MoodKey } from '../components/mood/MoodKey';
import { MoodLogger } from '../components/mood/MoodLogger';
import { MoodInsights } from '../components/mood/MoodInsights';
import { useMoodStore } from '../store/useMoodStore';
import { MoodEditModal } from '../components/mood/MoodEditModal';
import { CurrentMoodLogger } from '../components/mood/CurrentMoodLogger';
import { DayPeriod, MoodEntry } from '../types/mood';
import { useStore } from '../store/useStore';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { useTranslation } from 'react-i18next';
import { 
  PiCaretLeft, 
  PiCaretRight, 
  PiSpinner,
  PiPlus,
  PiWarning,
  PiSmiley,
  PiSmileySad,
  PiSmileyWink,
  PiSmileyXEyes,
  PiSmileyNervous,
  PiSmileyMeh,
  PiSmileyAngry,
  PiSmileyStickerLight
} from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom';
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';
import { MoodTimeline } from '../components/mood/MoodTimeline'
import { MoodViewToggle } from '../components/mood/MoodViewToggle'

// MoodPage component for the mood tracker page
export const MoodPage = () => {
  const { colors } = useTheme();
  const { user, updateUser } = useStore();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState<DayPeriod | null>(null);
  const [showMoodLogger, setShowMoodLogger] = useState(false); // Modal for logging mood at a specific time in the mood circle
  const [showCurrentMoodLogger, setShowCurrentMoodLogger] = useState(false); // Modal for logging current mood
  const [editEntry, setEditEntry] = useState<MoodEntry | null>(null);
  const { entries, loading, fetchEntries } = useMoodStore();
  const { error, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const { t } = useTranslation();
  const [currentIcon, setCurrentIcon] = useState<number>(0);
  const userTier = user?.subscription?.tier || 'free';
  const monthsLimit = SUBSCRIPTION_LIMITS.moodHistory.monthsHistory[userTier];
  const [showHistoryWarning, setShowHistoryWarning] = useState(false);
  const [view, setView] = useState<'circle' | 'timeline'>(() => {
    const userPreference = user?.preferences?.moodViewPreference
    const localPreference = localStorage.getItem('moodViewPreference')
    return (userPreference || localPreference || 'circle') as 'circle' | 'timeline'
  });

  useEffect(() => {
    localStorage.setItem('moodViewPreference', view)
  }, [view])

  const moodIcons = [
    <PiSmiley size={28} />,
    <PiSmileyWink size={28} />,
    <PiSmileyStickerLight size={28} />,
    <PiSmileyMeh size={28} />,
    <PiSmileyNervous size={28} />,
    <PiSmileySad size={28} />,
    <PiSmileyAngry size={28} />,
    <PiSmileyXEyes size={28} />
  ];

  const handleIconClick = () => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * moodIcons.length);
    } while (newIndex === currentIcon); // Ensure we get a different icon
    setCurrentIcon(newIndex);
  };

  // Fetch entries for the selected month
  useEffect(() => {
    if (user?.id) {
      const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
      const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0, 23, 59, 59);
      fetchEntries(user.id, { startDate, endDate });
    }
  }, [fetchEntries, user?.id, selectedDate]);

  const handleMonthChange = (offset: number) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + offset);
    
    // Check if we're exceeding the history limit
    const monthsDiff = Math.floor(
      (new Date().getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
    );
    
    if (monthsDiff >= monthsLimit) {
      setShowHistoryWarning(true);
      return;
    }
    
    const originalDay = selectedDate.getDate();
    const lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(originalDay, lastDayOfMonth));
    
    setSelectedDate(newDate);
  };

  const getCurrentPeriod = (): DayPeriod => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return 'morning';
    if (hour >= 12 && hour < 17) return 'afternoon';
    if (hour >= 17 && hour < 21) return 'evening';
    return 'night';
  };

  const handleSegmentSelect = (day: number, period: DayPeriod, e: React.MouseEvent) => {
    setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day));
    setSelectedPeriod(period);
    setShowMoodLogger(true);
  };

  // Show warning when within 1 month of the limit
  const isApproachingLimit = Math.floor(
    (new Date().getTime() - selectedDate.getTime()) / (1000 * 60 * 60 * 24 * 30)
  ) >= (monthsLimit - 1);

  const handleViewChange = (newView: 'circle' | 'timeline') => {
    setView(newView)
    localStorage.setItem('moodViewPreference', newView)
    
    if (user?.id) {
      updateUser({
        preferences: {
          ...user.preferences,
          moodViewPreference: newView
        }
      })
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen gap-2">
        <PiSpinner size={24} className="animate-spin" style={{ color: colors.textSecondary }} />
        <div className="text-lg" style={{ color: colors.textSecondary }}>
          {t('common.loading')}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center gap-2 text-lg" style={{ color: colors.error }}>
          <PiWarning size={24} />
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto p-4 space-y-8"
    >
      {/* Header Section */}
      <motion.div 
        className="flex flex-col gap-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        {/* Title and Month Navigation - Centered on mobile */}
        <div className="flex flex-col items-center sm:items-start gap-6">
          <h1 
            className="text-2xl sm:text-3xl font-bold flex items-center gap-2 cursor-pointer select-none"
            style={{ color: colors.primary }}
            onClick={handleIconClick}
          >
            <motion.button
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick();
              }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              className="cursor-pointer"
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIcon}
                  initial={{ rotate: -180, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: 180, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {moodIcons[currentIcon]}
                </motion.div>
              </AnimatePresence>
            </motion.button>
            <motion.span
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('mood.title')}
            </motion.span>
          </h1>
          
          {/* Month Navigation */}
          <div 
            className="flex items-center gap-2 rounded-lg p-2 self-center sm:self-start shadow-sm"
            style={{ 
              backgroundColor: colors.surface,
              borderColor: colors.border,
              borderWidth: '1px'
            }}
          >
            <button
              onClick={() => handleMonthChange(-1)}
              className="p-2.5 rounded-lg hover:opacity-80 transition-opacity"
              style={{ 
                backgroundColor: `${colors.primary}10`,
                color: colors.primary 
              }}
              aria-label="Previous month"
            >
              <PiCaretLeft size={20} />
            </button>
            <span 
              className="text-lg font-medium min-w-[180px] text-center px-3"
              style={{ color: colors.text }}
            >
              {selectedDate.toLocaleString('default', { 
                month: 'long',
                year: 'numeric' 
              })}
            </span>
            <button
              onClick={() => handleMonthChange(1)}
              className="p-2.5 rounded-lg hover:opacity-80 transition-opacity"
              style={{ 
                backgroundColor: `${colors.primary}10`,
                color: colors.primary 
              }}
              aria-label="Next month"
            >
              <PiCaretRight size={20} />
            </button>
          </div>
        </div>

        {/* Add view toggle and log mood button in same row */}
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <MoodViewToggle 
            view={view} 
            onChange={handleViewChange}
          />
          <button
            onClick={() => setShowCurrentMoodLogger(true)}
            className="w-full sm:w-auto px-6 py-2.5 rounded-lg hover:opacity-80 transition-opacity flex items-center justify-center gap-2"
            style={{ 
              backgroundColor: colors.primary,
              color: 'white'
            }}
          >
            <PiPlus size={20} />
            {t('mood.logCurrentMood')}
          </button>
        </div>
      </motion.div>

      {/* Add proactive history limit warning */}
      <AnimatePresence>
        {(showHistoryWarning || isApproachingLimit) && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mb-4 p-4 rounded-lg flex items-center gap-3"
            style={{
              backgroundColor: `${colors.warning}15`,
              border: `1px solid ${colors.warning}`
            }}
          >
            <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
            <div>
              <p className="text-sm" style={{ color: colors.text }}>
                {showHistoryWarning ? (
                  <>
                    Free users can view up to {monthsLimit} months of mood history.{' '}
                    <Link 
                      to="/upgrade"
                      className="font-medium hover:underline"
                      style={{ color: colors.warning }}
                    >
                      Upgrade to Core to access your full history
                    </Link>
                  </>
                ) : (
                  <>
                    You're approaching the {monthsLimit}-month history limit.{' '}
                    <Link 
                      to="/upgrade"
                      className="font-medium hover:underline"
                      style={{ color: colors.warning }}
                    >
                      Upgrade to Core to keep all your mood history
                    </Link>
                  </>
                )}
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main Content */}
      <motion.div 
        className="grid grid-cols-1 lg:grid-cols-3 gap-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        {/* Left Column - Mood Circle/Timeline */}
        <div className="lg:col-span-2 space-y-6">
          <div 
            className="p-4 sm:p-6 rounded-lg"
            style={{ backgroundColor: colors.surface }}
          >
            <AnimatePresence mode="wait">
              {view === 'circle' ? (
                <MoodCircle
                  key="circle"
                  selectedDate={selectedDate}
                  moodEntries={entries}
                  onEditMood={setEditEntry}
                  onSegmentSelect={handleSegmentSelect}
                  selectedPeriod={selectedPeriod}
                />
              ) : (
                <MoodTimeline
                  key="timeline"
                  entries={entries}
                  selectedDate={selectedDate}
                  onEditMood={setEditEntry}
                  onAddMood={handleSegmentSelect}
                />
              )}
            </AnimatePresence>
          </div>
          <MoodKey />
        </div>

        {/* Right Column - Insights */}
        <div className="order-first lg:order-none">
          <MoodInsights />
        </div>
      </motion.div>

      {/* Mood Loggers */}
      <AnimatePresence mode="wait">
        {showMoodLogger && (
          <MoodLogger
            key="mood-logger"
            onClose={() => {
              setShowMoodLogger(false);
              setSelectedPeriod(null);
            }}
            selectedDate={selectedDate}
            initialPeriod={selectedPeriod}
          />
        )}

        {showCurrentMoodLogger && (
          <CurrentMoodLogger
            key="current-mood-logger"
            onClose={() => setShowCurrentMoodLogger(false)}
            initialPeriod={getCurrentPeriod()}
          />
        )}

        {editEntry && (
          <MoodEditModal
            key="mood-edit-modal"
            entry={editEntry}
            onClose={() => setEditEntry(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}; 