import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { AIAssistantSettings } from '../types/ai';
import { userService } from '../services/userService';
import { useStore } from './useStore';

interface AIState {
  apiKey: string | null;
  assistantSettings: AIAssistantSettings;
  setApiKey: (key: string | null) => void;
  setAssistantSettings: (settings: AIAssistantSettings) => void;
}

export const useAIStore = create<AIState>()(
  persist(
    (set, get) => ({
      apiKey: null,
      assistantSettings: {
        temperature: 0.7,
        maxTokens: 1000,
      },
      setApiKey: (key) => set({ apiKey: key }),
      setAssistantSettings: (settings) => set({ assistantSettings: settings }),

      updateAssistantSettings: async (newSettings) => {
        const { user, updateUser } = useStore.getState();
        if (!user?.id) return;

        const updatedSettings = {
          ...get().assistantSettings,
          ...newSettings
        };

        // Update local state
        set({ assistantSettings: updatedSettings });

        // Persist to user profile
        try {
          await updateUser({
            aiSettings: updatedSettings
          });
        } catch (error) {
          console.error('Failed to update AI settings:', error);
          // Revert on error
          set({ assistantSettings: get().assistantSettings });
        }
      },

      initializeFromUser: () => {
        const { user } = useStore.getState();
        if (user?.aiSettings) {
          set({ assistantSettings: user.aiSettings });
        }
      }
    }),
    {
      name: 'ai-storage'
    }
  )
); 