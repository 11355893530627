import { useState } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { motion, AnimatePresence } from 'framer-motion'
import {
  PiInfo,
  PiFile,
  PiFileDoc,
  PiFilePdf,
  PiFileText,
  PiFileHtml,
  PiX
} from 'react-icons/pi'
import { useTranslation } from 'react-i18next'

const tooltipVariants = {
  hidden: { opacity: 0, scale: 0.95, y: 10 },
  visible: { 
    opacity: 1, 
    scale: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95, 
    y: 10,
    transition: { duration: 0.2 }
  }
}

export function FileFormatInfo() {
  const [isOpen, setIsOpen] = useState(false)
  const { colors } = useTheme()
  const { t } = useTranslation()

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <div 
      className="relative inline-block"
      onClick={(e) => e.stopPropagation()}
    >
      <motion.button
        onClick={handleClick}
        className="p-1 rounded-full"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        style={{ color: colors.textSecondary }}
      >
        <PiInfo className="w-4 h-4" />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute z-50 w-64 p-4 rounded-lg shadow-lg right-0 mt-2"
            style={{ 
              backgroundColor: colors.surface,
              border: `1px solid ${colors.border}`
            }}
            variants={tooltipVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-start mb-3">
              <h3 
                className="text-sm font-medium"
                style={{ color: colors.text }}
              >
                {t("notebooks.fileFormatInfo.title")}
              </h3>
              <motion.button

                onClick={handleClose}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ color: colors.textSecondary }}
              >
                <PiX className="w-4 h-4" />
              </motion.button>
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <PiFileText className="w-5 h-5" style={{ color: colors.primary }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.txt")}</span>
              </div>
              <div className="flex items-center gap-2">
                <PiFileDoc className="w-5 h-5" style={{ color: colors.accent1 }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.docx")}</span>
              </div>
              <div className="flex items-center gap-2">
                <PiFilePdf className="w-5 h-5" style={{ color: colors.error }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.pdf")}</span>
              </div>
              <div className="flex items-center gap-2">
                <PiFile className="w-5 h-5" style={{ color: colors.accent2 }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.rtf")}</span>
              </div>
              <div className="flex items-center gap-2">
                <PiFileText className="w-5 h-5" style={{ color: colors.quaternary }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.md")}</span>
              </div>
              <div className="flex items-center gap-2">

                <PiFileHtml className="w-5 h-5" style={{ color: colors.warning }} />
                <span className="text-sm" style={{ color: colors.text }}>{t("notebooks.fileFormatInfo.formats.html")}</span>
              </div>
            </div>
          </motion.div>

        )}
      </AnimatePresence>
    </div>
  )
} 