import { useNavigate } from 'react-router-dom';
import { useNotebookStore } from '../../store/useNotebookStore';
import { useTheme } from '../../contexts/ThemeContext';
import type { Notebook } from '../../types/notebook';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PiPencilSimple, 
  PiTrash,
  PiPalette,
  PiWarning
} from 'react-icons/pi';
import { ColorPicker } from '../shared/pickers/ColorPicker';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { SUBSCRIPTION_LIMITS } from '../../constants/subscriptionLimits';

interface NotebookListProps {
  notebooks: Notebook[];
}

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 20 }
}

// Add a helper to determine warning threshold based on limit
const getWarningThreshold = (limit: number) => {
  if (limit <= 2) return 0.5;  // Show warning at 50% for very small limits
  if (limit <= 5) return 0.6;  // Show warning at 60% for small limits
  if (limit <= 10) return 0.7; // Show warning at 70% for medium limits
  return 0.8;                  // Show warning at 80% for larger limits
};

export function NotebookList({ notebooks }: NotebookListProps) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteNotebook, updateNotebook } = useNotebookStore();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingTitle, setEditingTitle] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useStore();
  const userTier = user?.subscription?.tier || 'free';
  const notebookLimit = SUBSCRIPTION_LIMITS.notebooks[userTier];

  const warningThreshold = getWarningThreshold(notebookLimit);

  useEffect(() => {
    if (editingId && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editingId]);

  const handleEdit = (notebook: Notebook) => {
    setEditingId(notebook.id);
    setEditingTitle(notebook.title);
  };

  const handleSubmitEdit = async (id: string) => {
    if (editingTitle.trim()) {
      try {
        await updateNotebook(id, { title: editingTitle.trim() });
        setEditingId(null);
        setEditingTitle('');
      } catch (error) {
        console.error('Error updating notebook:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, notebookId: string) => {
    if (e.key === 'Enter') {
      handleSubmitEdit(notebookId);
    } else if (e.key === 'Escape') {
      setEditingId(null);
      setEditingTitle('');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteNotebook(id);
    } catch (error) {
      console.error('Error deleting notebook:', error);
    }
  };

  const handleColorChange = async (notebookId: string, color: string) => {
    try {
      await updateNotebook(notebookId, { color });
    } catch (error) {
      console.error('Error updating notebook color:', error);
    }
  };

  const renderLimitWarning = () => {
    if (notebooks.length >= notebookLimit * warningThreshold) {
      const remainingCount = notebookLimit - notebooks.length;
      return (
        <motion.div
          className="mb-4 p-4 rounded-lg flex items-center gap-3"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          style={{
            backgroundColor: `${colors.warning}15`,
            border: `1px solid ${colors.warning}`
          }}
        >
          <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
          <div>
            <p className="text-sm" style={{ color: colors.text }}>
              {notebooks.length === notebookLimit ? (
                <span>You've reached your notebook limit of {notebookLimit}. </span>
              ) : remainingCount === 1 ? (
                <span>You can create 1 more notebook. </span>
              ) : (
                <span>You can create {remainingCount} more notebooks. </span>
              )}
              <Link 
                to="/upgrade"
                className="font-medium hover:underline"
                style={{ color: colors.warning }}
              >
                Upgrade to create more notebooks
              </Link>
            </p>
          </div>
        </motion.div>
      );
    }
    return null;
  };

  if (notebooks.length === 0) {
    return (
      <motion.div 
        className="text-center py-8 rounded-lg"
        style={{ backgroundColor: colors.surface }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <p style={{ color: colors.textSecondary }}>
          {t('notebooks.empty')}
        </p>
      </motion.div>
    );
  }

  return (
    <div>
      {renderLimitWarning()}
      <motion.div 
        className="space-y-2"
        variants={listVariants}
        initial="hidden"
        animate="visible"
      >
        <AnimatePresence mode="popLayout">
          {notebooks.map((notebook) => {
            return (
              <motion.div
                key={notebook.id}
                variants={itemVariants}
                layout
                onClick={() => navigate(`/notebooks/${notebook.id}`)}
                className="group relative bg-surface rounded-lg p-4 hover:shadow-sm transition-all"
                style={{ 
                  backgroundColor: colors.surface,
                  borderLeft: `4px solid ${notebook.color || colors.accent1}`
                }}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    {editingId === notebook.id ? (
                      <motion.input
                        ref={inputRef}
                        type="text"
                        value={editingTitle}
                        onChange={(e) => setEditingTitle(e.target.value)}
                        onBlur={() => handleSubmitEdit(notebook.id)}
                        onKeyDown={(e) => handleKeyDown(e, notebook.id)}
                        className="w-full bg-transparent border-none focus:outline-none"
                        style={{ color: colors.text }}
                        onClick={(e) => e.stopPropagation()}
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                      />
                    ) : (
                      <div className="flex items-center space-x-2">
                        <h3 
                          className="text-lg font-medium" 
                          style={{ color: colors.text }}
                        >
                          {(() => {
                            const title = notebook.title;
                            if (typeof title === 'object') {
                              return JSON.stringify(title);
                            }
                            return String(title || '');
                          })()}
                        </h3>
                        <motion.button
                          onClick={(e) => {
                            e.stopPropagation()
                            handleEdit(notebook)
                          }}
                          className="p-1 rounded hover:bg-quaternary/10 transition-colors opacity-0 group-hover:opacity-100"
                          style={{ color: colors.quaternary }}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <PiPencilSimple className="h-5 w-5" />
                        </motion.button>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center space-x-1 opacity-0 group-hover:opacity-100">
                    <ColorPicker
                      value={notebook.color || undefined}
                      onChange={(color) => handleColorChange(notebook.id, color)}
                      iconSize={6}
                      icon={<PiPalette className="h-6 w-6" />}
                    />
                    <motion.button
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDelete(notebook.id)
                      }}
                      className="p-1 rounded hover:bg-error/10 transition-colors"
                      style={{ color: colors.error }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <PiTrash className="h-6 w-6" />
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
    </div>
  );
} 