import { createContext, useContext, useEffect, useState } from 'react';
import { useStore } from '../store/useStore';
import { PartialThemeColors, ThemeMode, ThemePreset } from '../types/theme';
import { 
  ThemeColors, 
  UserPreferences, 
  defaultUserPreferences
} from '../types/user';
import { generateThemeVariants } from '../utils/themeUtils';
import { useUserStore } from '../store/useUserStore';
import { UserProfile } from '../types/user';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { useAuthStore } from '../store/useAuthStore';
import { User as FirebaseUser } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';

export const defaultThemeColors: ThemeColors = {
  primary: '#007AFF',      // Modern iOS blue
  secondary: '#8E8E93',    // Modern grey
  tertiary: '#5856D6',     // Modern purple
  quaternary: '#34C759',   // Modern green
  accent1: '#6D28D9',      // Modern violet
  accent2: '#2bb44d',      // Modern light green
  background: '#ffffff',
  surface: '#F8F8F8',
  surfaceAlt: '#F2F2F2',
  text: '#111827',
  textSecondary: '#4B5563',
  border: '#E5E7EB',
  success: '#059669',
  error: '#DC2626',
  warning: '#D97706',
  info: '#2563EB',
  shadow: '#000000',
  logo: {
    primary: '#54a066',
    secondary: '#007aff',
    tertiary: '#616e81',
    accent: '#2f784c',
    highlight: '#c3cfb5'
  }
};

interface ThemeContextType {
  colors: ThemeColors;
  mode: ThemeMode;
  isDarkMode: boolean;
  customThemes: ThemePreset[];
  setMode: (mode: ThemeMode) => void;
  updateColors: (colors: PartialThemeColors) => void;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

// Load saved theme
const getSavedTheme = (onError?: (error: Error | string, component: string) => void) => {
  try {
    const savedTheme = localStorage.getItem('app-theme');
    if (savedTheme) {
      return JSON.parse(savedTheme);
    }
  } catch (error) {
    if (error instanceof Error) {
      onError?.(error, 'ThemeContext.getSavedTheme');
    } else {
      onError?.(new Error('Failed to load saved theme'), 'ThemeContext.getSavedTheme');
    }
  }
  return null;
}; 

const getInitialThemeState = (profile: UserProfile | null, onError?: (error: Error | string, component: string) => void) => {
  const savedTheme = getSavedTheme(onError);
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  // Priority: profile preferences > saved theme > system preference
  const themeMode = profile?.preferences?.theme || savedTheme?.mode || 'system';
  
  // Only use system preference if theme is set to 'system'
  const isDark = themeMode === 'dark' || (themeMode === 'system' && prefersDark);
  
  return {
    themeMode,
    isDark,
    colors: profile?.preferences?.themeColors || savedTheme?.colors || defaultThemeColors
  };
};

interface ThemeContextProps {
  children: React.ReactNode
}

const ThemeTransition = ({ children }: { children: React.ReactNode }) => {
  const { isDarkMode } = useTheme();

  return (
    <motion.div
      key={isDarkMode ? 'dark' : 'light'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      style={{ width: '100%', height: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export function ThemeProvider({ children }: ThemeContextProps) {
  const { user, updatePreferences } = useStore()
  const { showError } = useErrorFeedback()
  
  // Initialize theme state
  const [state, setState] = useState(() => {
    // Get system preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Priority: 
    // 1. User preferences from store
    // 2. localStorage preference
    // 3. System preference
    
    const savedTheme = localStorage.getItem('theme-preference');
    const userTheme = user?.preferences?.theme;
    const themeMode = userTheme || savedTheme as ThemeMode || 'system';
    
    // Determine if dark mode should be enabled
    const isDark = themeMode === 'dark' || 
      (themeMode === 'system' && prefersDark);

    // Get theme colors
    const savedColors = JSON.parse(localStorage.getItem('app-theme') || '{}')?.colors;
    const themeColors = user?.preferences?.themeColors || savedColors || defaultThemeColors;

    return {
      isDarkMode: isDark,
      colors: generateThemeVariants(themeColors, isDark),
      mode: themeMode
    };
  });

  // Update effect for system preference changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e: MediaQueryListEvent) => {
      if (state.mode === 'system') {
        const newColors = generateThemeVariants(
          user?.preferences?.themeColors || defaultThemeColors,
          e.matches
        );
        setState(prev => ({
          ...prev,
          isDarkMode: e.matches,
          colors: newColors
        }));
        updateCssVariables(newColors);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [state.mode, user?.preferences?.themeColors]);

  // Sync with user preferences when they change
  useEffect(() => {
    if (user?.preferences) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const isDark = user.preferences.theme === 'dark' ||
        (user.preferences.theme === 'system' && prefersDark);
      
      const newColors = generateThemeVariants(
        user.preferences.themeColors || defaultThemeColors,
        isDark
      );

      setState(prev => ({
        ...prev,
        isDarkMode: isDark,
        colors: newColors,
        mode: user.preferences.theme || prev.mode
      }));
      
      updateCssVariables(newColors);
    }
  }, [user?.preferences]);

  // Update CSS variables whenever colors change
  useEffect(() => {
    updateCssVariables(state.colors);
  }, [state.colors]);

  // Update data-theme attribute
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', state.isDarkMode ? 'dark' : 'light');
  }, [state.isDarkMode]);

  const value = {
    colors: state.colors,
    mode: state.mode,
    isDarkMode: state.isDarkMode,
    customThemes: user?.preferences?.customThemes || [],
    setMode: async (mode: ThemeMode) => {
      try {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const isDark = mode === 'dark' || (mode === 'system' && prefersDark);
        
        // First, update the CSS variables with transition
        document.documentElement.style.setProperty('--theme-transition', '0.3s');
        
        // Update local state first
        setState(prev => ({
          ...prev,
          isDarkMode: isDark,
          colors: generateThemeVariants(
            user?.preferences?.themeColors || defaultThemeColors,
            isDark
          ),
          mode
        }));

        // Store in localStorage
        localStorage.setItem('theme-preference', mode);

        // Then update user preferences if logged in
        if (user?.preferences) {
          await updatePreferences({
            ...user.preferences,
            theme: mode
          });
        }

        // Remove transition after animation completes
        setTimeout(() => {
          document.documentElement.style.removeProperty('--theme-transition');
        }, 300);
      } catch (error) {
        showError(error instanceof Error ? error : new Error('Failed to update theme'), 'ThemeContext');
      }
    },
    updateColors: async (newColors: PartialThemeColors) => {
      try {
        // Update local state immediately
        const updatedColors = {
          ...state.colors,
          ...newColors,
          logo: state.colors.logo
        };
        
        console.log('Updating colors with:', updatedColors);
        
        // Generate variants and update state first
        const themeVariants = generateThemeVariants(updatedColors, state.isDarkMode);
        setState(prev => ({
          ...prev,
          colors: themeVariants
        }));

        // Store in localStorage as fallback
        try {
          localStorage.setItem('app-theme', JSON.stringify({
            mode: state.mode,
            colors: updatedColors
          }));
        } catch (e) {
          console.warn('Failed to save theme to localStorage:', e);
        }

        // If user is logged in, update their preferences
        if (user?.preferences) {
          console.log('Current user preferences:', user.preferences);
          
          const updatedPreferences = {
            ...defaultUserPreferences,
            ...user.preferences,
            themeColors: updatedColors,
            theme: state.mode    
          };

          console.log('Updating preferences with:', updatedPreferences);

          try {
            await updatePreferences(updatedPreferences);
            console.log('Preferences updated successfully');
          } catch (error) {
            console.error('Failed to update preferences:', error);
            throw error; // Re-throw to be caught by outer catch block
          }
        } else {
          console.log('No user preferences found, skipping update');
        }
      } catch (error) {
        console.error('Theme update error:', error);
        if (error instanceof Error) {
          console.error('Error details:', {
            message: error.message,
            stack: error.stack,
            name: error.name
          });
        }
        showError(error instanceof Error ? error : new Error('Failed to update colors'), 'ThemeContext');
        
        // Revert state on error
        setState(prev => ({
          ...prev,
          colors: generateThemeVariants(
            user?.preferences?.themeColors || defaultThemeColors,
            prev.isDarkMode
          )
        }));
      }
    }
  };

  return (
    <ThemeContext.Provider value={value}>
      <AnimatePresence mode="wait">
        <ThemeTransition>
          {children}
        </ThemeTransition>
      </AnimatePresence>
    </ThemeContext.Provider>
  );
}

// Helper function to update CSS variables
function updateCssVariables(colors: ThemeColors) {
  const root = document.documentElement;
  document.body.style.backgroundColor = colors.background;
  
  // Process colors recursively
  function processColors(obj: Record<string, any>, prefix = '') {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'string') {
        root.style.setProperty(`--color-${prefix}${key}`, value);
        if (value.startsWith('#')) {
          const rgb = hexToRgb(value);
          if (rgb) {
            root.style.setProperty(
              `--color-${prefix}${key}-rgb`,
              `${rgb.r}, ${rgb.g}, ${rgb.b}`
            );
          }
        }
      } else if (typeof value === 'object' && value !== null) {
        processColors(value, `${key}-`);
      }
    });
  }

  processColors(colors);
}

// Helper function to convert hex to rgb
function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};