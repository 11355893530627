import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import { GamificationState, UserAchievement, StreakData, StreakNotification } from '../types/gamification';
import { ACHIEVEMENTS } from '../constants/achievements';
import { getStartOfDay, isSameDay } from '../utils/dateUtils';
import { notificationService } from '../services/notificationService';
import { useStore } from './useStore';
import { defaultGamificationState } from '../types/user';
import { AppError } from '../utils/errors';
import type { GamificationStore } from '../types/stores';

interface GamificationStore extends GamificationState {
  updateStreak: (tracker: keyof GamificationState['streaks']) => Promise<void>;
  checkAchievements: () => void;
  addXP: (amount: number) => Promise<void>;
  resetStreaks: () => Promise<void>;
  getStreakNotification: (tracker: keyof GamificationState['streaks']) => StreakNotification | null;
  updateStreakProduction: (tracker: keyof GamificationState['streaks']) => Promise<void>;
  resetAchievements: () => Promise<void>;
  clearAllData: () => Promise<void>;
  initializeFromUser: (userData: GamificationState) => void;
  resetState: () => void;
  addAchievement: (achievementId: string) => void;
  syncGamificationState: (state: GamificationState) => Promise<void>;
}

interface Achievement {
  id: string;
  trackerType: 'login' | 'mood' | 'notebook' | 'finance' | 'any';
  requirement: number;
  description: string;
}

const calculateLevel = (xp: number): number => {
  return Math.floor(Math.sqrt(xp / 100)) + 1;
};

const initialState: GamificationStateData = {
  achievements: [],
  streaks: {
    login: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    mood: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    notebook: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    finance: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    },
    lists: {
      currentStreak: 0,
      longestStreak: 0,
      lastUpdateDate: null,
      streakStartDate: null
    }
  },
  level: 1,
  xp: 0,
  lastActivity: null
};

// Helper to sync gamification state with Firebase
async function syncGamificationState(state: GamificationState) {
  if (!auth.currentUser?.uid) {
    console.warn('Cannot sync gamification state: No user logged in');
    return;
  }

  try {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    
    // Create a data-only version of the state for storage
    const stateForStorage = {
      achievements: state.achievements,
      streaks: state.streaks,
      level: state.level,
      xp: state.xp,
      lastActivity: state.lastActivity,
      updatedAt: serverTimestamp()
    };

    await updateDoc(userRef, {
      gamification: stateForStorage
    });
  } catch (error) {
    console.error('Failed to sync gamification state:', error);
  }
}

const useGamificationStore = create<GamificationStore>()(
  persist(
    (set) => ({
      ...defaultGamificationState,

      // Disable all achievement and notification methods
      addAchievement: () => {},
      updateStreak: async () => {},
      checkAchievements: () => {},
      addXP: async () => {},
      resetStreaks: async () => {},
      getStreakNotification: () => null,
      updateStreakProduction: async () => {},
      resetAchievements: async () => {},
      clearAllData: async () => {
        set(defaultGamificationState);
      },
      initializeFromUser: (userData: GamificationState) => {
        set({
          achievements: userData.achievements || [],
          streaks: userData.streaks || defaultGamificationState.streaks,
          level: userData.level || 1,
          xp: userData.xp || 0,
          lastActivity: userData.lastActivity || null
        });
      },
      resetState: () => {
        set(defaultGamificationState);
      },
      syncGamificationState: async (state: GamificationState) => {
        if (!auth.currentUser?.uid) {
          console.warn('Cannot sync gamification state: No user logged in');
          return;
        }

        try {
          const userRef = doc(db, 'users', auth.currentUser.uid);
          
          // Create a data-only version of the state for storage
          const stateForStorage = {
            achievements: state.achievements,
            streaks: state.streaks,
            level: state.level,
            xp: state.xp,
            lastActivity: state.lastActivity,
            updatedAt: serverTimestamp()
          };

          await updateDoc(userRef, {
            gamification: stateForStorage
          });
        } catch (error) {
          console.error('Failed to sync gamification state:', error);
        }
      }
    }),
    {
      name: 'gamification-storage',
      partialize: (state) => ({
        achievements: [],
        streaks: state.streaks,
        level: 1,
        xp: 0,
        lastActivity: null
      })
    }
  )
);

// Export a wrapped version that ensures the store is initialized
export function useGamification() {
  const store = useGamificationStore();
  // Instead of throwing an error, return the store with default state
  return store ?? {
    ...defaultGamificationState,
    updateStreak: async () => {},
    checkAchievements: () => {},
    addXP: async () => {},
    resetStreaks: async () => {},
    getStreakNotification: () => null,
    updateStreakProduction: async () => {},
    resetAchievements: async () => {},
    clearAllData: async () => {},
    initializeFromUser: () => {},
    resetState: () => {},
    addAchievement: () => {},
    syncGamificationState: async () => {}
  };
}

// Export the store
export { useGamificationStore }; 