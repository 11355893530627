import { create } from 'zustand'
import type { List, ListItem } from '../types/lists'

interface ListsState {
  lists: List[]
  selectedList: List | null
  isLoading: boolean
  error: Error | null
  setLists: (lists: List[]) => void
  setSelectedList: (list: List | null) => void
  setIsLoading: (isLoading: boolean) => void
  setError: (error: Error | null) => void
}

export const useListsStore = create<ListsState>((set) => ({
  lists: [],
  selectedList: null,
  isLoading: false,
  error: null,
  setLists: (lists) => set({ lists }),
  setSelectedList: (list) => set({ selectedList: list }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setError: (error) => set({ error })
})) 