import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { useTheme } from '../../contexts/ThemeContext'
import { CalendarEvent, EventRecurrence } from '../../types/calendar'
import { useTranslation } from 'react-i18next'
import { format, addYears } from 'date-fns'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { TodoEventDetails } from './TodoEventDetails'
import { useNavigate } from 'react-router-dom'

interface EventModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (event: Partial<CalendarEvent>) => Promise<void>
  event?: Partial<CalendarEvent>
  selectedDate?: Date
}

export function EventModal({ isOpen, onClose, onSave, event, selectedDate }: EventModalProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [isAllDay, setIsAllDay] = useState(false)
  const [location, setLocation] = useState('')
  const [recurrence, setRecurrence] = useState<EventRecurrence>('none')
  const [isLoading, setIsLoading] = useState(false)
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date | null>(null)

  useEffect(() => {
    if (event) {
      setTitle(event.title || '')
      setDescription(event.description || '')
      setStartDate(event.date || new Date())
      setEndDate(event.endDate || event.date || new Date())
      setIsAllDay(event.isAllDay || false)
      setLocation(event.location || '')
      setRecurrence(event.recurrence || 'none')
      setRecurrenceEndDate(event.recurrenceEndDate || null)
    } else if (selectedDate) {
      const date = new Date(selectedDate)
      // Set default times for new events
      date.setHours(9, 0, 0, 0) // Default to 9 AM
      const endTime = new Date(date)
      endTime.setHours(10, 0, 0, 0) // Default to 10 AM
      setStartDate(date)
      setEndDate(endTime)
      setRecurrence('none')
      setRecurrenceEndDate(null)
    }
  }, [event, selectedDate])

  // Reset form when modal closes
  useEffect(() => {
    if (!isOpen) {
      setTitle('')
      setDescription('')
      setStartDate(new Date())
      setEndDate(new Date())
      setIsAllDay(false)
      setLocation('')
      setRecurrence('none')
      setRecurrenceEndDate(null)
    }
  }, [isOpen])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await onSave({
        ...event,
        title,
        description,
        date: startDate,
        endDate, // This is for the event duration
        recurrenceEndDate: recurrence !== 'none' ? recurrenceEndDate || addYears(startDate, 1) : null,
        isAllDay,
        location,
        recurrence,
        type: 'event',
        source: 'internal'
      })
      onClose()
    } catch (error) {
      console.error('Error saving event:', error)
    } finally {
      setIsLoading(false)
    }
  }

  function getEventTypeLabel(event: CalendarEvent) {
    return t(`calendar.types.${event.type}`)
  }

  function getEventSourceLabel(event: CalendarEvent) {
    return t(`calendar.source.${event.source}`)
  }

  function getRecurrenceLabel(event: CalendarEvent) {
    return event.recurrence ? t(`calendar.recurrence.${event.recurrence}`) : t('calendar.recurrence.none')
  }

  // Don't allow editing subscription or recurring transaction events
  if (event?.type === 'subscription' || event?.type === 'recurring-transaction') {
    return (
      <Dialog 
        open={isOpen} 
        onClose={onClose}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel 
            className="mx-auto max-w-lg w-full rounded-lg p-6"
            style={{ backgroundColor: colors.surface }}
          >
            <div className="flex items-center justify-between mb-4">
              <Dialog.Title 
                className="text-lg font-medium"
                style={{ color: colors.primary }}
              >
                {getEventTypeLabel(event as CalendarEvent)}
              </Dialog.Title>
              <button
                onClick={onClose}
                className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
                style={{ color: colors.textSecondary }}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium" style={{ color: colors.text }}>
                {event.title}
              </h3>
              {event.description && (
                <p style={{ color: colors.textSecondary }}>
                  {event.description}
                </p>
              )}
              <div className="flex items-center gap-2" style={{ color: colors.textSecondary }}>
                <CalendarIcon className="h-5 w-5" />
                {format(event.date, 'PPP')}
              </div>
              {event.type === 'subscription' && (
                <div className="text-sm" style={{ color: colors.textSecondary }}>
                  {t('finance.subscriptions.manageInFinance')}
                </div>
              )}
              {event.type === 'recurring-transaction' && (
                <div className="text-sm" style={{ color: colors.textSecondary }}>
                  {t('finance.transactions.manageInFinance')}
                </div>
              )}
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={onClose}
                className="px-4 py-2 rounded"
                style={{
                  backgroundColor: `${colors.quaternary}10`,
                  color: colors.quaternary
                }}
              >
                {t('common.close')}
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel 
          className="w-full max-w-md rounded-lg"
          style={{ backgroundColor: colors.surface }}
        >
          {event?.type === 'todo' ? (
            <div 
              className="flex flex-col gap-2 p-4 rounded-lg cursor-pointer"
              style={{ 
                background: `linear-gradient(45deg, ${colors.primary}10, ${colors.secondary}10)`,
                borderLeft: `3px solid ${colors.primary}`
              }}
              onClick={() => {
                onClose()
                navigate(`/lists?todoId=${event.sourceId}`)
              }}
            >
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium" style={{ color: colors.text }}>
                  {event.title}
                </span>
                {event.completed && (
                  <span 
                    className="text-sm px-2 py-1 rounded" 
                    style={{ backgroundColor: `${colors.success}20`, color: colors.success }}
                  >
                    Completed
                  </span>
                )}
              </div>

              {event.description && (
                <p className="text-sm" style={{ color: colors.textSecondary }}>
                  {event.description}
                </p>
              )}

              <div className="flex justify-between items-center mt-2">
                {event.priority && (
                  <span 
                    className="text-sm px-3 py-1 rounded"
                    style={{ 
                      backgroundColor: 
                        event.priority === 'high' ? `${colors.error}20` :
                        event.priority === 'medium' ? `${colors.warning}20` :
                        `${colors.info}20`,
                      color:
                        event.priority === 'high' ? colors.error :
                        event.priority === 'medium' ? colors.warning :
                        colors.info
                    }}
                  >
                    {event.priority.charAt(0).toUpperCase() + event.priority.slice(1)} Priority
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="p-4">
              <div className="flex items-center justify-between mb-4">
                <Dialog.Title 
                  className="text-lg font-medium"
                  style={{ color: colors.primary }}
                >
                  {event ? t('calendar.editEvent') : t('calendar.newEvent')}
                </Dialog.Title>
                <button
                  onClick={onClose}
                  className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
                  style={{ color: colors.textSecondary }}
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t('calendar.eventTitle')}
                    className="w-full px-3 py-2 rounded border"
                    style={{
                      backgroundColor: colors.surface,
                      borderColor: colors.border,
                      color: colors.text
                    }}
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={isAllDay}
                      onChange={(e) => setIsAllDay(e.target.checked)}
                      className="rounded border"
                      style={{ 
                        borderColor: colors.border,
                        accentColor: colors.primary
                      }}
                    />
                    <span style={{ color: colors.text }}>
                      {t('calendar.allDay')}
                    </span>
                  </label>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm mb-1" style={{ color: colors.textSecondary }}>
                      {t('calendar.startDate')}
                    </label>
                    <input
                      type={isAllDay ? "date" : "datetime-local"}
                      value={format(startDate, isAllDay ? "yyyy-MM-dd" : "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => {
                        const newDate = new Date(e.target.value)
                        setStartDate(newDate)
                        // If end date is before new start date, update it
                        if (endDate < newDate) {
                          setEndDate(newDate)
                        }
                      }}
                      className="w-full px-3 py-2 rounded border"
                      style={{
                        backgroundColor: colors.surface,
                        borderColor: colors.border,
                        color: colors.text
                      }}
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm mb-1" style={{ color: colors.textSecondary }}>
                      {recurrence === 'none' 
                        ? t('calendar.endDate')
                        : t('calendar.recurrenceEndDate')}
                    </label>
                    <input
                      type={isAllDay ? "date" : "datetime-local"}
                      value={format(endDate, isAllDay ? "yyyy-MM-dd" : "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => setEndDate(new Date(e.target.value))}
                      min={format(startDate, isAllDay ? "yyyy-MM-dd" : "yyyy-MM-dd'T'HH:mm")}
                      className="w-full px-3 py-2 rounded border"
                      style={{
                        backgroundColor: colors.surface,
                        borderColor: colors.border,
                        color: colors.text
                      }}
                      required
                    />
                    {recurrence !== 'none' && (
                      <p className="text-xs mt-1" style={{ color: colors.textSecondary }}>
                        {t('calendar.recurrenceEndDateHint')}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={t('calendar.description')}
                    className="w-full px-3 py-2 rounded border"
                    style={{
                      backgroundColor: colors.surface,
                      borderColor: colors.border,
                      color: colors.text
                    }}
                    rows={3}
                  />
                </div>

                <div>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder={t('calendar.location')}
                    className="w-full px-3 py-2 rounded border"
                    style={{
                      backgroundColor: colors.surface,
                      borderColor: colors.border,
                      color: colors.text
                    }}
                  />
                </div>

                <div>
                  <select
                    value={recurrence}
                    onChange={(e) => setRecurrence(e.target.value as EventRecurrence)}
                    className="w-full px-3 py-2 rounded border"
                    style={{
                      backgroundColor: colors.surface,
                      borderColor: colors.border,
                      color: colors.text
                    }}
                  >
                    <option value="none">{t('calendar.recurrence.none')}</option>
                    <option value="daily">{t('calendar.recurrence.daily')}</option>
                    <option value="weekly">{t('calendar.recurrence.weekly')}</option>
                    <option value="monthly">{t('calendar.recurrence.monthly')}</option>
                    <option value="yearly">{t('calendar.recurrence.yearly')}</option>
                  </select>
                </div>

                {recurrence !== 'none' && (
                  <div>
                    <label className="block text-sm mb-1" style={{ color: colors.textSecondary }}>
                      {t('calendar.recurrenceEndDate')}
                    </label>
                    <input
                      type="date"
                      value={recurrenceEndDate ? format(recurrenceEndDate, "yyyy-MM-dd") : ''}
                      onChange={(e) => setRecurrenceEndDate(e.target.value ? new Date(e.target.value) : null)}
                      className="w-full px-3 py-2 rounded border"
                      style={{
                        backgroundColor: colors.surface,
                        borderColor: colors.border,
                        color: colors.text
                      }}
                      min={format(startDate, "yyyy-MM-dd")}
                    />
                    <p className="text-xs mt-1" style={{ color: colors.textSecondary }}>
                      {t('calendar.recurrenceEndDateHint')}
                    </p>
                  </div>
                )}

                <div className="flex justify-end gap-3 pt-4">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 rounded"
                    style={{
                      backgroundColor: `${colors.quaternary}10`,
                      color: colors.quaternary
                    }}
                  >
                    {t('common.cancel')}
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="px-4 py-2 rounded"
                    style={{
                      backgroundColor: colors.primary,
                      color: '#fff',
                      opacity: isLoading ? 0.7 : 1
                    }}
                  >
                    {isLoading ? t('common.saving') : t('common.save')}
                  </button>
                </div>
              </form>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
} 