import { NodeViewWrapper } from '@tiptap/react'
import { PiTrash } from 'react-icons/pi'
import { imageService } from '../../services/imageService'
import { useErrorFeedback } from '../../hooks/useErrorFeedback'

interface NodeViewProps {
  node: {
    attrs: {
      src: string
      alt?: string
    }
  }
  deleteNode: () => void
}

export default function ImageNodeComponent({ node, deleteNode }: NodeViewProps) {
  const { showError } = useErrorFeedback()

  const handleDelete = async () => {
    try {
      // Remove from editor first for immediate feedback
      deleteNode()
      
      // Then try to delete from storage
      await imageService.deleteImage(node.attrs.src).catch((error) => {
        console.warn('Failed to delete image from storage:', error)
        // Don't show error to user since the image was removed from the editor
      })
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'ImageNodeComponent.handleDelete')
      }
    }
  }

  return (
    <NodeViewWrapper className="image-wrapper">
      <img 
        src={node.attrs.src} 
        alt={node.attrs.alt || ''} 
        className="editor-image"
      />
      <button
        onClick={handleDelete}
        className="delete-image-button"
        title="Delete image"
      >
        <PiTrash className="w-3.5 h-3.5" />
      </button>
    </NodeViewWrapper>
  )
} 