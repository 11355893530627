import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { SubscriptionFeature, SubscriptionTier } from '../../types/subscription';
import { PiStarFour, PiCalendar, PiSparkle, PiMoney, PiWallet, PiPencilLine, PiChartBar } from 'react-icons/pi';
import { useState, ReactNode } from 'react';
import { UpgradeModal } from '../subscription/UpgradeModal';
import { useLocation } from 'react-router-dom';

interface UpgradePromptProps {
  title: string;
  description: string;
  feature: 'calendar' | 'moodInsights' | 'budgets' | 'assets' | 'aiEditor' | 'monthlyOverview';
  currentTier: SubscriptionTier;
  requiredTier: SubscriptionTier;
  icon?: ReactNode;
}

export function UpgradePrompt({ 
  title, 
  description, 
  feature, 
  currentTier, 
  requiredTier, 
  icon
}: UpgradePromptProps) {
  const location = useLocation();
  const { colors } = useTheme();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const getFeatureIcon = () => {
    switch (feature) {
      case 'calendar':
        return icon || <PiCalendar className="w-8 h-8" style={{ color: colors.primary }}/>
      case 'moodInsights':
        return icon || <PiSparkle className="w-8 h-8" style={{ color: colors.primary }}/>
      case 'budgets':
        return icon || <PiMoney className="w-8 h-8" style={{ color: colors.primary }}/>
      case 'assets':
        return icon || <PiWallet className="w-8 h-8" style={{ color: colors.primary }}/>
      case 'aiEditor':
        return icon || <PiPencilLine className="w-8 h-8" style={{ color: colors.primary }}/>
      case 'monthlyOverview':
        return icon || <PiChartBar className="w-8 h-8" style={{ color: colors.primary }}/>
      default:
        return icon || <PiStarFour className="w-8 h-8" style={{ color: colors.primary }}/>
    }
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-6 rounded-lg text-center relative z-30"
        style={{
          backgroundColor: colors.surface,
          borderLeft: `4px solid ${colors.primary}`
        }}
      >
        {getFeatureIcon()}
        <h3 className="text-lg font-semibold mb-2" style={{ color: colors.text }}>
          {title}
        </h3>
        <p className="mb-4" style={{ color: colors.textSecondary }}>
          {description}
        </p>
        <button
          onClick={() => setShowUpgradeModal(true)}
          className="px-4 py-2 rounded-lg font-medium"
          style={{
            backgroundColor: `${colors.primary}20`,
            color: colors.primary
          }}
        >
          Upgrade to {requiredTier.charAt(0).toUpperCase() + requiredTier.slice(1)}
        </button>
      </motion.div>

      <div className="relative z-50">
        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
          requiredTier={requiredTier}
          returnTo={location.pathname}
        />
      </div>
    </>
  );
} 