import { useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../ui/popover'
import { useTheme } from '../../../contexts/ThemeContext'
import {
  PiListBold,
  PiCheckCircleBold,
  PiStarBold,
  PiHeartBold,
  PiFlagBold,
  PiBookmarkBold,
  PiCalendarBold,
  PiHouseBold,
  PiShoppingCartBold,
  PiBriefcaseBold,
  PiGraduationCapBold,
  PiSparkleBold,
  PiCookingPotBold,
  PiBookBold,
  PiMusicNotesBold,
  PiGameControllerBold,
  PiNoteBold,
  PiPaintBrushBold,
  PiPlantBold,
  PiDogBold,
  PiCatBold,
  PiFirstAidBold,
  PiGiftBold,
  PiRocketBold,
  PiSunBold,
  PiMoonBold,
  PiTargetBold,
  PiTrophyBold,
  PiCrownBold,
  PiHeartStraightBold,
  PiCoffeeBold
} from 'react-icons/pi'

interface IconPickerProps {
  value?: string
  onChange: (icon: string) => void
}

type IconOption = {
  value: string
  label: string
  Icon: React.ComponentType<{ className?: string }>
}

export function IconPicker({ value, onChange }: IconPickerProps) {
  const [open, setOpen] = useState(false)
  const { colors } = useTheme()

  const iconOptions: IconOption[] = [
    { value: 'PiListBold', label: 'List', Icon: PiListBold },
    { value: 'PiCheckCircleBold', label: 'Check Circle', Icon: PiCheckCircleBold },
    { value: 'PiStarBold', label: 'Star', Icon: PiStarBold },
    { value: 'PiHeartBold', label: 'Heart', Icon: PiHeartBold },
    { value: 'PiFlagBold', label: 'Flag', Icon: PiFlagBold },
    { value: 'PiBookmarkBold', label: 'Bookmark', Icon: PiBookmarkBold },
    { value: 'PiCalendarBold', label: 'Calendar', Icon: PiCalendarBold },
    { value: 'PiHouseBold', label: 'House', Icon: PiHouseBold },
    { value: 'PiShoppingCartBold', label: 'Shopping Cart', Icon: PiShoppingCartBold },
    { value: 'PiBriefcaseBold', label: 'Briefcase', Icon: PiBriefcaseBold },
    { value: 'PiGraduationCapBold', label: 'Graduation Cap', Icon: PiGraduationCapBold },
    { value: 'PiSparkleBold', label: 'Sparkles', Icon: PiSparkleBold },
    { value: 'PiCookingPotBold', label: 'Cooking', Icon: PiCookingPotBold },
    { value: 'PiBookBold', label: 'Book', Icon: PiBookBold },
    { value: 'PiMusicNotesBold', label: 'Music', Icon: PiMusicNotesBold },
    { value: 'PiGameControllerBold', label: 'Gaming', Icon: PiGameControllerBold },
    { value: 'PiNoteBold', label: 'Note', Icon: PiNoteBold },
    { value: 'PiPaintBrushBold', label: 'Art', Icon: PiPaintBrushBold },
    { value: 'PiPlantBold', label: 'Plant', Icon: PiPlantBold },
    { value: 'PiDogBold', label: 'Dog', Icon: PiDogBold },
    { value: 'PiCatBold', label: 'Cat', Icon: PiCatBold },
    { value: 'PiFirstAidBold', label: 'Health', Icon: PiFirstAidBold },
    { value: 'PiGiftBold', label: 'Gift', Icon: PiGiftBold },
    { value: 'PiRocketBold', label: 'Rocket', Icon: PiRocketBold },
    { value: 'PiSunBold', label: 'Sun', Icon: PiSunBold },
    { value: 'PiMoonBold', label: 'Moon', Icon: PiMoonBold },
    { value: 'PiTargetBold', label: 'Target', Icon: PiTargetBold },
    { value: 'PiTrophyBold', label: 'Trophy', Icon: PiTrophyBold },
    { value: 'PiCrownBold', label: 'Crown', Icon: PiCrownBold },
    { value: 'PiHeartStraightBold', label: 'Love', Icon: PiHeartStraightBold },
    { value: 'PiCoffeeBold', label: 'Coffee', Icon: PiCoffeeBold }
  ]

  const CurrentIcon = iconOptions.find(opt => opt.value === value)?.Icon || PiListBold

  return (
    <Popover 
      open={open} 
      onOpenChange={setOpen}
    >
      <PopoverTrigger asChild>
        <button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpen(true)
          }}
          className="p-1 rounded hover:bg-opacity-10 transition-colors"
          style={{ color: colors.textSecondary }}
        >
          <CurrentIcon className="h-5 w-5" />
        </button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-auto p-2" 
        align="end"
        style={{ 
          backgroundColor: colors.surface,
          borderColor: colors.border,
          borderWidth: 1,
          zIndex: 50
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div 
          className="grid grid-cols-4 gap-2"
          onClick={(e) => e.stopPropagation()}
        >
          {iconOptions.map(({ value: iconValue, label, Icon }) => (
            <button
              key={iconValue}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onChange(iconValue)
                setOpen(false)
              }}
              className="p-2 rounded transition-colors hover:bg-opacity-10"
              style={{ 
                color: value === iconValue ? colors.primary : colors.text,
                backgroundColor: value === iconValue ? `${colors.primary}10` : undefined
              }}
              title={label}
            >
              <Icon className="h-6 w-6" />
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
} 