import { db } from '../../utils/firebase'
import { collection, getDocs, doc, updateDoc, type DocumentData, DocumentSnapshot, getDoc, serverTimestamp } from 'firebase/firestore'
import type { User } from '../../types/user'
import { adminService } from '../../services/adminService'
import { AppError } from '../../utils/errors'
import { defaultGamificationState } from '../../types/user'
import { encryptionService } from '../../services/encryptionService'
import { auth } from '../firebase'
import { migrateTransactions } from './transactionMigrations'

const DEFAULT_AI_SETTINGS = {
  name: 'Tydy',
  avatarColor: '#3B82F6',
  personalityPreset: 'friendly-coach',
  apiKey: null
}

export async function migrateUserStructure(
  userDoc: DocumentSnapshot,
  basicUserData: User
): Promise<User | null> {
  try {
    const userData = userDoc.data();
    if (!userData) {
      return null;
    }

    // Check if migration is needed
    if (userData.version >= 1) {
      return null; // No migration needed
    }

    // Perform migration logic here
    // ...

    return {
      ...basicUserData,
      // Add any migrated fields
      version: 1
    };
  } catch (error) {
    console.error('[UserMigration] Migration failed:', error);
    return null;
  }
}

// Function to migrate all users
export async function migrateAllUsers(): Promise<void> {
  try {
    // Check admin permissions first
    const isAdmin = await adminService.isAdmin()
    if (!isAdmin) {
      throw new AppError('UNAUTHORIZED', 'Must be an admin to perform migrations')
    }

    const usersRef = collection(db, 'users')
    const snapshot = await getDocs(usersRef)
    
    const migrations = snapshot.docs.map(async (doc) => {
      try {
        await migrateUserStructure(doc, doc.data() as User)
        console.log(`Successfully migrated user: ${doc.id}`)
      } catch (error) {
        console.error(`Failed to migrate user ${doc.id}:`, error)
      }
    })

    await Promise.all(migrations)
  } catch (error) {
    console.error('Failed to run migrations:', error)
    throw error
  }
}

export async function migrateUserEncryption(userId: string) {
  const userRef = doc(db, 'users', userId)
  const userDoc = await getDoc(userRef)
  
  if (!userDoc.exists()) {
    throw new AppError('USER_NOT_FOUND', 'User document not found')
  }

  const userData = userDoc.data()
  
  // Only migrate if encryption key doesn't exist
  if (!userData.security?.encryptionKey) {
    console.log(`[UserMigration] Generating encryption key for user ${userId}`)
    const encryptionKey = encryptionService.generateUserKey()
    
    // For admin migrations, we'll use a temporary key for encryption
    // In production, you might want to use a more secure method
    const tempKey = 'admin-migration-key'
    
    const encryptedKey = encryptionService.encrypt(
      encryptionKey,
      tempKey
    )

    console.log(`[UserMigration] Updating user document with encryption key`)
    await updateDoc(userRef, {
      security: {
        ...userData.security,
        encryptionKey: encryptedKey,
        encryptedFields: ['transactions.amount', 'transactions.description']
      },
      updatedAt: serverTimestamp()
    })

    // Migrate existing transactions
    console.log(`[UserMigration] Starting transaction migration for user ${userId}`)
    await migrateTransactions(userId, encryptionKey)

    return encryptionKey
  }

  return null
} 