import { useEffect } from 'react';
import { useNotifications } from '../../hooks/useNotifications';

export const NotificationInitializer = () => {
  const { initializeDailyReminder } = useNotifications();

  useEffect(() => {
    const cleanup = initializeDailyReminder();
    return () => cleanup();
  }, [initializeDailyReminder]);

  return null;
}; 