import { useTheme } from '../../contexts/ThemeContext';
import { motion } from 'framer-motion';

interface LevelProgressProps {
  level: number;
  xp: number;
}

export const LevelProgress = ({ level, xp }: LevelProgressProps) => {
  const { colors } = useTheme();
  
  // Calculate XP needed for next level
  const baseXP = 100;
  const currentLevelXP = baseXP * (level - 1) * (level - 1);
  const nextLevelXP = baseXP * level * level;
  const xpForCurrentLevel = xp - currentLevelXP;
  const xpNeededForNextLevel = nextLevelXP - currentLevelXP;
  const progress = (xpForCurrentLevel / xpNeededForNextLevel) * 100;

  return (
    <div className="space-y-2">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-between items-center"
      >
        <div className="flex items-center gap-2">
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
            className="w-12 h-12 rounded-full flex items-center justify-center text-lg font-bold"
            style={{ 
              backgroundColor: `${colors.primary}15`,
              color: colors.primary
            }}
          >
            {level}
          </motion.div>
          <div>
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="font-medium"
              style={{ color: colors.text }}
            >
              Level {level}
            </motion.div>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-sm"
              style={{ color: colors.textSecondary }}
            >
              {xpForCurrentLevel} / {xpNeededForNextLevel} XP
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Progress bar */}
      <motion.div 
        initial={{ opacity: 0, scaleX: 0 }}
        animate={{ opacity: 1, scaleX: 1 }}
        transition={{ delay: 0.3 }}
        className="h-2 rounded-full overflow-hidden"
        style={{ backgroundColor: `${colors.primary}15` }}
      >
        <motion.div 
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ 
            delay: 0.5,
            duration: 1,
            ease: "easeOut"
          }}
          className="h-full"
          style={{ backgroundColor: colors.primary }}
        />
      </motion.div>
    </div>
  );
}; 