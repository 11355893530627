import OpenAI from 'openai'
import { ChatCompletionTool } from 'openai/resources/chat/completions'
import { AppError } from '../utils/errors'

const DEFAULT_API_KEY = import.meta.env.VITE_OPENAI_API_KEY

interface ConversationMessage {
  role: 'user' | 'assistant' | 'system' | 'tool'
  content: string
  tool_calls?: any[]
}

interface AIRequestConfig {
  model: string
  systemPrompt: string
  userPrompt?: string
  messages?: ConversationMessage[]
  tools?: ChatCompletionTool[]
  apiKey?: string
  temperature?: number
  isAnalysis?: boolean
}

export async function getAIResponse({
  model,
  systemPrompt,
  userPrompt,
  messages,
  tools,
  apiKey,
  temperature = 0.7,
  isAnalysis = false
}: AIRequestConfig): Promise<string> {
  try {
    const effectiveApiKey = apiKey || DEFAULT_API_KEY

    if (!effectiveApiKey) {
      console.error('[OpenAI] No API key available. Environment key:', DEFAULT_API_KEY)
      throw new AppError('API_KEY_ERROR', 'OpenAI API key not configured')
    }

    const openai = new OpenAI({ 
      apiKey: effectiveApiKey, 
      dangerouslyAllowBrowser: true 
    })

    const apiMessages = isAnalysis ? [
      { role: 'system', content: systemPrompt },
      { role: 'user', content: userPrompt || '' }
    ] : [
      { role: 'system', content: systemPrompt },
      ...(messages || [])
    ]

    try {
      const completion = await openai.chat.completions.create({
        model,
        messages: apiMessages,
        temperature: isAnalysis ? 0.2 : temperature,
        ...(tools && !isAnalysis ? {
          tools,
          tool_choice: 'auto'
        } : {})
      })

      const response = completion.choices[0]

      if (!isAnalysis && response.finish_reason === 'tool_calls' && response.message.tool_calls?.length) {
        return JSON.stringify({
          tool_calls: response.message.tool_calls
        })
      }

      return response.message.content || ''
      
    } catch (openaiError) {
      console.error('[OpenAI] API error:', openaiError)
      throw new AppError(
        'OPENAI_API_ERROR',
        openaiError instanceof Error ? openaiError.message : 'Failed to get AI response'
      )
    }
  } catch (error) {
    console.error('[OpenAI] Service error:', error)
    if (error instanceof AppError) {
      throw error
    }
    throw new AppError('CHAT_ERROR', 'Failed to get AI response')
  }
} 