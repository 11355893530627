import { 
  collection, 
  doc, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where,
  serverTimestamp,
  Timestamp,
  getDoc,
  orderBy,
  writeBatch
} from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import { activityService } from './activityService';
import { 
  FirestoreTransaction, 
  FirestoreBudget,
  Transaction,
  Budget,
  FirestoreFinancialTodo,
  FinancialTodo,
  Asset,
  FirestoreAsset
} from '../types/finance';
import { SubscriptionTier } from '../types/subscription';
import { 
  addMonths, 
  addWeeks, 
  addDays, 
  isBefore, 
  addYears,
  subMonths,
  isSameDay
} from 'date-fns';
import { safeDate, parseFirestoreDate } from '../utils/dateUtils'
import { analyticsService, AnalyticsEventTypes } from './analyticsService';
import { validateAsset } from '../utils/validators'
import { AppError } from '../utils/errors'
import { useStore } from '../store/useStore';
import { FirebaseError } from 'firebase/app';
import { useGamificationStore } from '../store/useGamificationStore'
import { gamificationService } from './gamificationService'
import { encryptionService } from '../services/encryptionService'
import { withEncryption } from '../utils/encryption'
import { migrateUserEncryption } from '../utils/migrations/userMigrations'
import { notificationService } from '../services/notificationService'
import { useTranslation } from 'react-i18next';
import { PiCheckCircle, PiWarning } from 'react-icons/pi'

// Cache for subscription checks
interface SubscriptionCacheEntry {
  tier: SubscriptionTier;
  timestamp: number;
}

const subscriptionCheckCache = new Map<string, SubscriptionCacheEntry>();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Helper to remove undefined fields
const removeUndefinedFields = (obj: Record<string, any>) => {
  const cleanObj = { ...obj };
  Object.keys(cleanObj).forEach(key => {
    if (cleanObj[key] === undefined) {
      delete cleanObj[key];
    }
  });
  return cleanObj;
};

const convertFirestoreTransaction = (transaction: FirestoreTransaction): Transaction => {
  // Create date at start of day in local timezone
  const transactionDate = new Date(transaction.date.seconds * 1000);
  transactionDate.setHours(0, 0, 0, 0);

  return {
    id: transaction.id,
    date: transactionDate,
    amount: transaction.amount,
    description: transaction.description,
    categoryId: transaction.categoryId,
    type: transaction.type,
    notes: transaction.notes,
    isRecurring: transaction.isRecurring,
    recurringConfig: transaction.recurringConfig ? {
      frequency: transaction.recurringConfig.frequency,
      startDate: new Date(transaction.recurringConfig.startDate.seconds * 1000),
      endDate: transaction.recurringConfig.endDate 
        ? new Date(transaction.recurringConfig.endDate.seconds * 1000)
        : undefined
    } : undefined,
    subscriptionDetails: transaction.subscriptionDetails ? {
      ...transaction.subscriptionDetails,
      renewalDate: transaction.subscriptionDetails.renewalDate 
        ? new Date(transaction.subscriptionDetails.renewalDate.seconds * 1000)
        : undefined
    } : undefined
  };
};

// Add this function to calculate next date
function calculateNextDate(transaction: Transaction): Date | null {
  if (!transaction.recurringConfig?.frequency) return null

  const lastDate = transaction.date
  const { frequency } = transaction.recurringConfig

  switch (frequency) {
    case 'daily':
      return addDays(lastDate, 1)
    case 'weekly':
      return addWeeks(lastDate, 1)
    case 'bi-weekly':
      return addWeeks(lastDate, 2)
    case 'monthly':
      return addMonths(lastDate, 1)
    case 'yearly':
      return addYears(lastDate, 1)
    default:
      return null
  }
}

const financeService = {
  // Make checkAccess a standalone function in the object
  checkAccess: async (userId: string, requiredTier: SubscriptionTier): Promise<void> => {
    // Always check database after an upgrade - remove cache check temporarily
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new AppError('User not found', 'USER_NOT_FOUND');
    }

    const userTier = userDoc.data()?.subscription?.tier || 'free';
    
    // Update cache
    subscriptionCheckCache.set(userId, {
      tier: userTier,
      timestamp: Date.now()
    });

    const tiers = ['free', 'core', 'pro'];
    const requiredIndex = tiers.indexOf(requiredTier);
    const userIndex = tiers.indexOf(userTier);

    if (userIndex < requiredIndex) {
      throw new AppError(
        `This feature requires ${requiredTier} subscription or higher`,
        'SUBSCRIPTION_REQUIRED'
      );
    }
  },

  // Transactions
  getUserTransactions: async (userId: string): Promise<Transaction[]> => {
    try {
      // Get user's encryption key
      const userRef = doc(db, 'users', userId)
      const userData = (await getDoc(userRef)).data()

      // Get transactions first
      const q = query(
        collection(db, 'transactions'),
        where('userId', '==', userId),
        orderBy('date', 'desc')
      )
      
      const snapshot = await getDocs(q)
      const transactions = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as FirestoreTransaction[]

      // If user has encryption key and we can get the refresh token
      if (userData?.encryptionKey && auth.currentUser?.refreshToken) {
        try {
          const encryptionKey = userData.encryptionKey

          // Decrypt transactions
          return transactions.map(transaction => {
            try {
              // Handle encrypted data
              if (transaction.amount && typeof transaction.amount === 'string') {
                const decrypted = withEncryption(
                  transaction,
                  ['amount', 'description', 'notes', 'categoryId'],
                  encryptionKey,
                  true // Set to true for decryption
                )

                // Ensure proper type conversion
                const amount = Number(decrypted.amount)
                
                // Convert subscription renewal date from Timestamp to Date
                const subscriptionDetails = transaction.subscriptionDetails ? {
                  ...transaction.subscriptionDetails,
                  renewalDate: transaction.subscriptionDetails.renewalDate?.toDate?.() || 
                             transaction.subscriptionDetails.renewalDate
                } : undefined

                return {
                  ...decrypted,
                  amount: isNaN(amount) ? 0 : amount,
                  description: decrypted.description || '',
                  notes: decrypted.notes || null,
                  categoryId: decrypted.categoryId || null,
                  subscriptionDetails
                }
              }
              
              // Handle non-encrypted data
              const amount = Number(transaction.amount)
              
              // Convert subscription renewal date from Timestamp to Date
              const subscriptionDetails = transaction.subscriptionDetails ? {
                ...transaction.subscriptionDetails,
                renewalDate: transaction.subscriptionDetails.renewalDate?.toDate?.() || 
                           transaction.subscriptionDetails.renewalDate
              } : undefined

              return {
                ...transaction,
                amount: isNaN(amount) ? 0 : amount,
                description: transaction.description || '',
                notes: transaction.notes || null,
                categoryId: transaction.categoryId || null,
                subscriptionDetails
              }
            } catch (error) {
              console.error('Error processing transaction:', error)
              return {
                ...transaction,
                amount: 0,
                description: 'Error: Could not decrypt',
                notes: null,
                categoryId: null
              }
            }
          })
        } catch (error) {
          console.error('Error with encryption key:', error)
          throw new AppError('ENCRYPTION_ERROR', 'Failed to decrypt data')
        }
      }

      // Handle non-encrypted data with date conversion
      return transactions.map(tx => {
        const amount = Number(tx.amount)
        
        // Convert subscription renewal date from Timestamp to Date
        const subscriptionDetails = tx.subscriptionDetails ? {
          ...tx.subscriptionDetails,
          renewalDate: tx.subscriptionDetails.renewalDate?.toDate?.() || 
                     tx.subscriptionDetails.renewalDate
        } : undefined

        return {
          ...tx,
          amount: isNaN(amount) ? 0 : amount,
          description: tx.description || '',
          notes: tx.notes || null,
          categoryId: tx.categoryId || null,
          subscriptionDetails
        }
      })

    } catch (error) {
      console.error('Error fetching transactions:', error)
      throw new AppError('TRANSACTION_FETCH_ERROR', 'Failed to fetch transactions')
    }
  },

  createTransaction: async (userId: string, transaction: Omit<Transaction, 'id'>) => {
    try {
      // Get user's encryption key
      const userRef = doc(db, 'users', userId)
      const userData = (await getDoc(userRef)).data()
      
      // Clean up transaction data
      const cleanTransaction = {
        ...transaction,
        notes: transaction.notes || null,
        amount: Number(transaction.amount),
        description: transaction.description || '',
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        // Handle recurring config
        recurringConfig: transaction.isRecurring ? {
          frequency: transaction.recurringConfig?.frequency || 'monthly',
          startDate: transaction.recurringConfig?.startDate ? 
            Timestamp.fromDate(new Date(transaction.recurringConfig.startDate)) : 
            null,
          endDate: transaction.recurringConfig?.endDate ? 
            Timestamp.fromDate(new Date(transaction.recurringConfig.endDate)) : 
            null,
          lastProcessedDate: null
        } : null,
        // Ensure other fields have valid defaults
        isRecurring: transaction.isRecurring || false,
        assetId: transaction.assetId || null,
        assetTransferType: transaction.assetTransferType || null,
        categoryId: transaction.categoryId || null,
        type: transaction.type || 'expense',
        date: transaction.date ? Timestamp.fromDate(new Date(transaction.date)) : Timestamp.now()
      }

      let transactionData = { ...cleanTransaction }

      // If user has encryption key, encrypt sensitive data
      if (userData?.encryptionKey) {
        try {
          const encryptionKey = userData.encryptionKey

          transactionData = withEncryption(
            cleanTransaction,
            ['amount', 'description', 'notes', 'categoryId'],
            encryptionKey,
            false
          )
        } catch (error) {
          console.error('Error encrypting transaction:', error)
          throw new AppError('ENCRYPTION_ERROR', 'Failed to encrypt transaction data')
        }
      }

      const transactionRef = await addDoc(collection(db, 'transactions'), transactionData)

      // Show success notification
      notificationService.showTemporaryNotification({
        type: 'success',
        title: 'Transaction Added',
        message: 'Transaction has been successfully added',
        icon: PiCheckCircle,
        duration: 3000
      })

      return transactionRef.id

    } catch (error) {
      console.error('Error creating transaction:', error)
      notificationService.showTemporaryNotification({
        type: 'error',
        title: 'Add Failed',
        message: error instanceof AppError ? error.message : 'Failed to add transaction',
        icon: PiWarning,
        duration: 5000
      })
      if (error instanceof AppError) {
        throw error
      }
      throw new AppError('TRANSACTION_CREATE_ERROR', 'Failed to create transaction')
    }
  },

  updateTransaction: async (transactionId: string, updates: Partial<Transaction>) => {
    const transactionRef = doc(db, 'transactions', transactionId);
    
    try {
      // If date is being updated, convert it to Firestore Timestamp
      if (updates.date) {
        const dateStr = updates.date.toISOString().split('T')[0];
        const localDate = new Date(`${dateStr}T00:00:00`);
        updates = {
          ...updates,
          date: Timestamp.fromDate(localDate)
        };
      }

      // Handle recurring config updates
      if (updates.recurringConfig) {
        const recurringConfig = {
          ...updates.recurringConfig,
          lastProcessedDate: updates.recurringConfig.lastProcessedDate 
            ? Timestamp.fromDate(new Date(updates.recurringConfig.lastProcessedDate))
            : null,
          startDate: updates.recurringConfig.startDate 
            ? Timestamp.fromDate(new Date(updates.recurringConfig.startDate))
            : null,
          endDate: updates.recurringConfig.endDate
            ? Timestamp.fromDate(new Date(updates.recurringConfig.endDate))
            : null
        };
        
        updates = {
          ...updates,
          recurringConfig
        };
      }
      
      const cleanUpdates = removeUndefinedFields({
        ...updates,
        updatedAt: serverTimestamp()
      });

      await updateDoc(transactionRef, cleanUpdates);

      // Track the activity
      try {
        await activityService.createActivity(
          'finance_transaction_updated',
          `Updated transaction ${updates.description || 'Untitled'}`,
          '',
          { transactionId },
          transactionId,
          'finance_transaction'
        );
      } catch (error) {
        console.warn('Failed to create activity:', error);
      }

      // Track analytics
      try {
        await analyticsService.trackEvent(AnalyticsEventTypes.FINANCE_TRANSACTION_UPDATED, {
          type: updates.type,
          category: updates.category,
          has_recurring: !!updates.recurringConfig,
          is_recurring_update: !!updates.recurringConfig
        });
      } catch (error) {
        console.warn('Failed to track analytics:', error);
      }
    } catch (error) {
      console.error('Failed to update transaction:', error);
      throw error;
    }
  },

  deleteTransaction: async (transactionId: string) => {
    try {
      const transactionRef = doc(db, 'transactions', transactionId)
      await deleteDoc(transactionRef)

      // Show success notification
      notificationService.showTemporaryNotification({
        type: 'success',
        title: 'Transaction Deleted',
        message: 'Transaction has been successfully deleted',
        icon: PiCheckCircle,
        duration: 3000
      })
    } catch (error) {
      console.error('Error deleting transaction:', error)
      notificationService.showTemporaryNotification({
        type: 'error',
        title: 'Delete Failed',
        message: error instanceof AppError ? error.message : 'Failed to delete transaction',
        icon: PiWarning,
        duration: 5000
      })
      throw new AppError('TRANSACTION_DELETE_ERROR', 'Failed to delete transaction')
    }
  },

  // Budgets
  getUserBudgets: async (userId: string) => {
    await financeService.checkAccess(userId, 'core');
    const q = query(
      collection(db, 'budgets'), 
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as FirestoreBudget[];
  },

  createBudget: async (userId: string, budget: Omit<Budget, 'id'>) => {
    if (auth.currentUser?.uid !== userId) {
      throw new Error('Unauthorized');
    }

    const budgetData = removeUndefinedFields({
      userId,
      categoryId: budget.categoryId,
      amount: budget.amount,
      timeframe: budget.timeframe,
      startDate: Timestamp.fromDate(budget.startDate),
      endDate: budget.endDate ? Timestamp.fromDate(budget.endDate) : null,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    const budgetRef = await addDoc(collection(db, 'budgets'), budgetData);

    // Track the activity
    await activityService.createActivity(
      'finance_budget_created',
      `Created budget for ${budget.amount}`,
      '',
      { budgetId: budgetRef.id },
      budgetRef.id,
      'finance_budget'
    );

    return budgetRef.id;
  },

  updateBudget: async (budgetId: string, updates: Partial<Budget>) => {
    const budgetRef = doc(db, 'budgets', budgetId);
    const budgetSnap = await getDoc(budgetRef);
    const currentBudget = budgetSnap.data() as FirestoreBudget;
    
    const cleanUpdates = removeUndefinedFields({
      ...updates,
      startDate: updates.startDate ? Timestamp.fromDate(updates.startDate) : undefined,
      endDate: updates.endDate ? Timestamp.fromDate(updates.endDate) : null,
      updatedAt: serverTimestamp()
    });

    await updateDoc(budgetRef, cleanUpdates);

    // Track the activity
    await activityService.createActivity(
      'finance_budget_updated',
      `Updated budget ${currentBudget.amount}`,
      '',
      { budgetId },
      budgetId,
      'finance_budget'
    );
  },

  deleteBudget: async (budgetId: string) => {
    const budgetRef = doc(db, 'budgets', budgetId);
    const budgetSnap = await getDoc(budgetRef);
    const budget = budgetSnap.data() as FirestoreBudget;

    await deleteDoc(budgetRef);

    // Track the activity
    await activityService.createActivity(
      'finance_budget_deleted',
      `Deleted budget ${budget.amount}`,
      '',
      { budgetId },
      budgetId,
      'finance_budget'
    );
  },

  // Todos
  getUserTodos: async (userId: string) => {
    await financeService.checkAccess(userId, 'core');
    const q = query(
      collection(db, 'financialTodos'), 
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as FirestoreFinancialTodo[];
  },

  createTodo: async (userId: string, todo: Omit<FinancialTodo, 'id' | 'isCompleted'>) => {
    if (auth.currentUser?.uid !== userId) {
      throw new Error('Unauthorized');
    }

    const todoData = removeUndefinedFields({
      userId,
      description: todo.description,
      dueDate: todo.dueDate ? Timestamp.fromDate(todo.dueDate) : null,
      priority: todo.priority,
      isCompleted: false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    const todoRef = await addDoc(collection(db, 'financialTodos'), todoData);

    // Track the activity
    await activityService.createActivity(
      'finance_todo_created',
      `Created financial todo: ${todo.description}`,
      '',
      { todoId: todoRef.id },
      todoRef.id,
      'finance_todo'
    );

    return todoRef.id;
  },

  updateTodo: async (todoId: string, updates: Partial<FinancialTodo>) => {
    const todoRef = doc(db, 'financialTodos', todoId);
    const todoSnap = await getDoc(todoRef);
    const currentTodo = todoSnap.data() as FirestoreFinancialTodo;
    
    const cleanUpdates = removeUndefinedFields({
      ...updates,
      dueDate: updates.dueDate ? Timestamp.fromDate(updates.dueDate) : null,
      updatedAt: serverTimestamp()
    });

    await updateDoc(todoRef, cleanUpdates);

    // Track completion separately from other updates
    if (updates.isCompleted && !currentTodo.isCompleted) {
      await activityService.createActivity(
        'finance_todo_completed',
        `Completed financial todo: ${currentTodo.description}`,
        '',
        { todoId },
        todoId,
        'finance_todo'
      );
    } else {
      await activityService.createActivity(
        'finance_todo_updated',
        `Updated financial todo: ${currentTodo.description}`,
        '',
        { todoId },
        todoId,
        'finance_todo'
      );
    }
  },

  deleteTodo: async (todoId: string) => {
    const todoRef = doc(db, 'financialTodos', todoId);
    const todoSnap = await getDoc(todoRef);
    const todo = todoSnap.data() as FirestoreFinancialTodo;

    await deleteDoc(todoRef);

    // Track the activity
    await activityService.createActivity(
      'finance_todo_deleted',
      `Deleted financial todo: ${todo.description}`,
      '',
      { todoId },
      todoId,
      'finance_todo'
    );
  },

  // Asset Methods
  createAsset: async (userId: string, asset: Omit<Asset, 'id'>) => {
    try {
      await financeService.checkAccess(userId, 'core');
      const validatedAsset = validateAsset(asset)
      
      const assetRef = await addDoc(collection(db, 'assets'), {
        ...validatedAsset,
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      })
      
      return assetRef.id
    } catch (error) {
      if (error instanceof AppError) throw error
      throw new AppError(
        'Failed to create asset',
        'ASSET_CREATE_ERROR',
        error instanceof FirebaseError ? error.code : undefined
      )
    }
  },

  updateAsset: async (id: string, updates: Partial<Asset>) => {
    try {
      const assetRef = doc(db, 'assets', id);
      
      // Clean the updates object by removing undefined values
      const cleanUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
        // Only include defined values
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      // Add timestamps
      cleanUpdates.lastUpdated = serverTimestamp();
      cleanUpdates.updatedAt = serverTimestamp();

      await updateDoc(assetRef, cleanUpdates);
      
      // Return the updated data for store sync
      return {
        id,
        ...cleanUpdates,
        lastUpdated: new Date(),
        updatedAt: new Date()
      };
    } catch (error) {
      console.error('Error updating asset:', error);
      throw new AppError('ASSET_UPDATE_ERROR', 'Failed to update asset');
    }
  },

  deleteAsset: async (id: string) => {
    const assetRef = doc(db, 'assets', id)
    await deleteDoc(assetRef)
  },

  getUserAssets: async (userId: string) => {
    await financeService.checkAccess(userId, 'core');
    const q = query(
      collection(db, 'assets'),
      where('userId', '==', userId)
    )
    const snapshot = await getDocs(q)
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as FirestoreAsset[]
  },

  async getMonthlyOverview(userId: string) {
    await financeService.checkAccess(userId, 'pro');
    // ... existing logic
  },

  // Add subscription checks to all write operations as well
  async addBudget(userId: string, budget: Budget) {
    await financeService.checkAccess(userId, 'core');
    // ... existing logic
  },

  // ... similar checks for other methods

  // Helper to count total transactions
  async getTransactionCount(userId: string): Promise<number> {
    try {
      const transactions = await this.getUserTransactions(userId)
      return transactions.length
    } catch (error) {
      console.error('Error counting transactions:', error)
      throw new AppError(
        'Failed to count transactions',
        'TRANSACTION_COUNT_ERROR',
        error instanceof Error ? error : undefined
      )
    }
  },

  // Helper to check consecutive days of transactions
  async getConsecutiveDays(userId: string): Promise<number> {
    try {
      const transactions = await this.getUserTransactions(userId)
      if (transactions.length === 0) return 0

      // Sort transactions by date
      const sortedDates = transactions
        .map(t => t.date)
        .sort((a, b) => b.getTime() - a.getTime())

      // Get unique dates (one transaction per day is enough)
      const uniqueDates = Array.from(new Set(
        sortedDates.map(date => date.toDateString())
      )).map(dateStr => new Date(dateStr))

      // Count consecutive days
      let consecutiveDays = 1
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      // If most recent transaction isn't today, streak is broken
      if (uniqueDates[0].getTime() !== today.getTime()) {
        return 0
      }

      // Check consecutive days
      for (let i = 1; i < uniqueDates.length; i++) {
        const currentDate = uniqueDates[i]
        const previousDate = uniqueDates[i - 1]
        
        const diffTime = previousDate.getTime() - currentDate.getTime()
        const diffDays = diffTime / (1000 * 60 * 60 * 24)

        if (diffDays === 1) {
          consecutiveDays++
        } else {
          break
        }
      }

      return consecutiveDays
    } catch (error) {
      console.error('Error checking consecutive days:', error)
      throw new AppError(
        'Failed to check consecutive days',
        'CONSECUTIVE_DAYS_ERROR',
        error instanceof Error ? error : undefined
      )
    }
  },

  async hasActivityOnDate(userId: string, date: Date): Promise<boolean> {
    try {
      const transactions = await this.getUserTransactions(userId)
      return transactions.some(tx => {
        const txDate = new Date(tx.date)
        return txDate.getFullYear() === date.getFullYear() &&
               txDate.getMonth() === date.getMonth() &&
               txDate.getDate() === date.getDate()
      })
    } catch (error) {
      console.error('Error checking activity:', error)
      throw new AppError(
        'Failed to check activity',
        'ACTIVITY_CHECK_ERROR',
        error instanceof Error ? error : undefined
      )
    }
  },

  async checkFinanceFrenzy(userId: string): Promise<boolean> {
    try {
      const transactions = await this.getUserTransactions(userId);
      
      // Skip check if no transactions
      if (!transactions?.length) {
        return false;
      }

      // Sort transactions safely
      const sortedTransactions = [...transactions].sort((a, b) => {
        const dateA = a.date?.toDate?.() || new Date(0);
        const dateB = b.date?.toDate?.() || new Date(0);
        return dateB.getTime() - dateA.getTime();
      });

      // Check if any sequence of 20 transactions was created within 30 minutes
      for (let i = 0; i <= sortedTransactions.length - 20; i++) {
        const startTime = sortedTransactions[i].createdAt.toDate();
        const endTime = sortedTransactions[i + 19].createdAt.toDate();
        
        if (endTime.getTime() - startTime.getTime() <= 30 * 60 * 1000) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.warn('[FinanceService] Error checking finance frenzy:', error);
      // Don't throw - allow other achievement checks to continue
      return false;
    }
  },

  // Add method to check if user has finance data
  async hasUserData(userId: string): Promise<boolean> {
    try {
      const financeRef = collection(db, 'users', userId, 'finances');
      const snapshot = await getDocs(financeRef);
      return !snapshot.empty;
    } catch (error) {
      console.warn('[FinanceService] Error checking user data:', error);
      return false;
    }
  },

  reencryptWithUserKey: async (userId: string, tempKey: string) => {
    const userRef = doc(db, 'users', userId)
    const userData = (await getDoc(userRef)).data()
    
    if (!userData?.security?.encryptionKey) return

    // Decrypt the key using the temp key
    const encryptionKey = encryptionService.decrypt(
      userData.security.encryptionKey,
      tempKey
    )

    // Re-encrypt with user's actual refresh token
    const reencryptedKey = encryptionService.encrypt(
      encryptionKey,
      auth.currentUser!.refreshToken
    )

    await updateDoc(userRef, {
      'security.encryptionKey': reencryptedKey
    })
  },

  checkTransactionEncryption: async (userId: string) => {
    // Get transactions from root collection
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId)
    )
    const snapshot = await getDocs(q)
    
    const stats = {
      total: snapshot.size,
      encrypted: 0,
      oldFormat: 0,
      unencrypted: 0,
      fields: {
        amount: { encrypted: 0, oldFormat: 0, unencrypted: 0 },
        description: { encrypted: 0, oldFormat: 0, unencrypted: 0 },
        category: { encrypted: 0, oldFormat: 0, unencrypted: 0 },
        note: { encrypted: 0, oldFormat: 0, unencrypted: 0 }
      }
    }

    for (const doc of snapshot.docs) {
      const data = doc.data()
      const fields = ['amount', 'description', 'category', 'note']
      
      fields.forEach(field => {
        if (data[field]) {
          if (encryptionService.isEncrypted(data[field])) {
            if (data[field].startsWith('ENC_V1_')) {
              stats.oldFormat++
              stats.fields[field].oldFormat++
            } else {
              stats.encrypted++
              stats.fields[field].encrypted++
            }
          } else {
            stats.unencrypted++
            stats.fields[field].unencrypted++
          }
        }
      })
    }

    return stats
  },

  // Add this method to financeService
  deleteAllUserTransactions: async (userId: string) => {
    try {
      // Get all user's transactions
      const q = query(
        collection(db, 'transactions'),
        where('userId', '==', userId)
      )
      const snapshot = await getDocs(q)
      
      // Delete in batches of 500 (Firestore limit)
      const batch = writeBatch(db)
      let count = 0
      
      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref)
        count++
      })
      
      await batch.commit()
      
      return count
    } catch (error) {
      console.error('Error deleting transactions:', error)
      throw new AppError('DELETE_ERROR', 'Failed to delete transactions')
    }
  },

  // Add this method to financeService
  getUserSubscriptions: async (userId: string) => {
    try {
      const transactions = await financeService.getUserTransactions(userId)

      const subscriptions = transactions.filter(tx => {
        // Only include subscriptions with valid renewal dates
        const renewalDate = tx.subscriptionDetails?.renewalDate
        const isValid = tx.type === 'expense' && 
          tx.subscriptionDetails && 
          renewalDate && 
          renewalDate instanceof Date && 
          !isNaN(renewalDate.getTime())

        return isValid
      })

      return subscriptions
    } catch (error) {
      console.error('Error fetching subscriptions:', error)
      throw error
    }
  },
};

// Export the service
export { financeService };

// Export the helper function for amount ranges
export function getAmountRange(amount: number): string {
  if (amount <= 10) return '0-10';
  if (amount <= 50) return '11-50';
  if (amount <= 100) return '51-100';
  if (amount <= 500) return '101-500';
  if (amount <= 1000) return '501-1000';
  return '1000+';
} 