import { ListItem } from './lists'
import { Transaction } from './finance'
import { formatCurrency } from '../utils/formatters'
import { colors } from '../styles/colors'
import { addDays, addWeeks, addMonths, addYears } from 'date-fns'

export type EventType = 
  | 'todo' 
  | 'event' 
  | 'reminder' 
  | 'subscription'    // Add this
  | 'subscription-payment'    // Historical subscription payment
  | 'subscription-renewal'    // Upcoming subscription renewal
  | 'transaction-historical'  // Historical recurring transaction
  | 'transaction-upcoming'    // Upcoming recurring transaction
export type EventSource = 
  | 'internal' 
  | 'external' 
  | 'todo' 
  | 'ics' 
  | 'subscription' 
  | 'finance'  // Add this
export type EventRecurrence = 'none' | 'daily' | 'weekly' | 'monthly' | 'yearly'

export interface CalendarEvent {
  id: string
  title: string
  description?: string
  date: Date
  endDate?: Date
  recurrenceEndDate?: Date
  isAllDay?: boolean
  location?: string
  recurrence: EventRecurrence
  type: EventType
  source: EventSource
  color?: string
  isRecurring?: boolean
  originalEventId?: string
  userId: string
  createdAt?: Date
  updatedAt?: Date
  sourceId?: string        // Original ID from external source or todo
  listId?: string         // Reference to parent list if it's a todo
  completed?: boolean     // For todos
  status?: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED'
  priority?: 'low' | 'medium' | 'high'
  url?: string
  recurrenceEnd?: Date
  reminders?: {
    type: 'email' | 'notification'
    minutes: number
  }[]
  metadata?: Record<string, any> // For external calendar data
}

export interface ICalendarEvent {
  uid: string
  summary: string
  description?: string
  location?: string
  start: Date
  end: Date
  url?: string
  recurrence?: string
  // ... other iCal specific fields
}

export function todoToCalendarEvent(todo: ListItem): CalendarEvent {
  return {
    id: todo.id,
    title: todo.content,
    description: todo.description,
    date: todo.dueDate || new Date(),
    type: 'todo',
    source: 'todo',
    sourceId: todo.id,
    listId: todo.listId,
    completed: todo.isCompleted,
    priority: todo.priority,
    isAllDay: true,
    recurrence: 'none'
  }
}

export function isUpcoming(date: Date, daysAhead: number = 30): boolean {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  
  const futureDate = new Date(now)
  futureDate.setDate(futureDate.getDate() + daysAhead)
  futureDate.setHours(23, 59, 59, 999)
  
  const checkDate = new Date(date)
  checkDate.setHours(0, 0, 0, 0)
  
  return checkDate >= now && checkDate <= futureDate
}

export function icsToCalendarEvent(icsEvent: ICalendarEvent, userId: string): CalendarEvent {
  return {
    id: icsEvent.uid,
    title: icsEvent.summary,
    date: icsEvent.start,
    endDate: icsEvent.end,
    type: 'event',
    source: 'ics',
    sourceId: icsEvent.uid,
    description: icsEvent.description,
    location: icsEvent.location,
    url: icsEvent.url,
    userId,
    createdAt: new Date(),
    updatedAt: new Date(),
    metadata: { originalIcs: icsEvent }
  }
}

// Helper function to calculate next renewal date
export function calculateNextRenewalDate(
  startDate: Date,
  frequency: string,
  interval: number = 1
): Date {
  const now = new Date();
  let date = new Date(startDate);
  
  // Keep adding intervals until we find a future date
  while (date <= now) {
    switch (frequency.toLowerCase()) {
      case 'daily':
        date = addDays(date, interval);
        break;
      case 'weekly':
        date = addWeeks(date, interval);
        break;
      case 'monthly':
        date = addMonths(date, interval);
        break;
      case 'yearly':
        date = addYears(date, interval);
        break;
      default:
        return date;
    }
  }
  
  return date;
}

export function subscriptionToCalendarEvent(subscription: Transaction): CalendarEvent {
  return {
    id: `subscription-${subscription.id}`,
    title: subscription.description,
    description: `Subscription renewal - ${formatCurrency(subscription.amount)}`,
    date: subscription.subscriptionDetails?.renewalDate || new Date(),
    type: 'subscription',
    source: 'subscription',
    color: '#ef4444',
    isAllDay: true,
    sourceId: subscription.id,
    userId: subscription.userId,
    recurrence: 'none',
    createdAt: new Date(),
    updatedAt: new Date()
  }
}

export function recurringTransactionToCalendarEvent(transaction: Transaction): CalendarEvent {
  const { recurringConfig } = transaction;
  
  if (!recurringConfig?.frequency) {
    throw new Error('Transaction must have recurring configuration');
  }

  // Use the last processed date or original date as base
  const baseDate = recurringConfig.lastProcessedDate 
    ? new Date(recurringConfig.lastProcessedDate)
    : new Date(transaction.date);
  baseDate.setHours(0, 0, 0, 0); // Normalize time to start of day

  // Calculate next occurrence
  const nextDate = calculateNextRenewalDate(
    baseDate,
    recurringConfig.frequency,
    recurringConfig.interval || 1
  );
  nextDate.setHours(0, 0, 0, 0); // Normalize time to start of day

  // Create a unique ID using the transaction ID and normalized date
  const dateString = nextDate.toISOString().split('T')[0];
  const uniqueId = `recurring-${transaction.id}-${dateString}`;

  return {
    id: uniqueId,
    title: transaction.description || 'Recurring Transaction',
    description: `${transaction.amount.toFixed(2)} ${transaction.currency || 'USD'} - ${transaction.category || 'Uncategorized'}`,
    date: nextDate,
    endDate: null,
    type: 'transaction-upcoming' as EventType,
    userId: transaction.userId,
    recurrence: recurringConfig.frequency,
    recurrenceEndDate: recurringConfig.endDate || null,
    color: '#4CAF50',
    isAllDay: true,
    location: '',
    source: 'finance',
    sourceId: transaction.id,
    createdAt: new Date(),
    updatedAt: new Date()
  };
} 