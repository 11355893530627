import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation, Link, Navigate } from "react-router-dom"
import { useTheme } from "../contexts/ThemeContext"
import { signInAnonymously, signInWithEmailAndPassword, sendLoginLink, signInWithGoogle } from "../utils/firebase"
import { auth } from "../utils/firebase"
import { useErrorFeedback } from "../hooks/useErrorFeedback"
import { ErrorFeedback } from "../components/shared/ErrorFeedback"
import { useAuthStore } from "../store/useAuthStore"
import { ENV, shouldAllowAccess } from '../utils/environment'
import { useTranslation } from "react-i18next"
import { authService } from '../services/authService'
import { useStore } from "../store/useStore"

const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement> & { label: string }>(
  ({ label, ...props }, ref) => {
    const { colors, isDarkMode } = useTheme()
    return (
      <div className="space-y-1">
        <label htmlFor={props.id} className="block text-sm font-medium" style={{ color: colors.textSecondary }}>
          {label}
        </label>
        <input
          ref={ref}
          className="w-full px-4 py-2 rounded-lg transition-colors duration-200 border focus:border-primary focus:outline-none"
          style={{
            backgroundColor: isDarkMode ? colors.surfaceAlt : colors.background,
            color: colors.text,
            borderColor: colors.border,
          }}
          {...props}
        />
      </div>
    )
  },
)

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: "primary" | "secondary" }> = ({
  children,
  variant = "secondary",
  ...props
}) => {
  const { colors } = useTheme()
  const baseStyle =
    "w-full py-3 px-4 rounded-lg font-medium transition-all duration-200 flex items-center justify-center min-h-[48px] hover:opacity-90"
  const variantStyles = {
    primary: {
      backgroundColor: colors.primary,
      color: "#ffffff",
    },
    secondary: {
      backgroundColor: `${colors.primary}10`,
      color: colors.primary,
      border: `1px solid ${colors.primary}30`,
    },
  }

  return (
    <button className={baseStyle} style={variantStyles[variant]} {...props}>
      {children}
    </button>
  )
}

const Divider: React.FC<{ text: string }> = ({ text }) => {
  const { colors } = useTheme()
  return (
    <div className="relative my-4">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t" style={{ borderColor: colors.border }}></div>
      </div>
      <div className="relative flex justify-center text-sm">
        <span
          className="px-2"
          style={{
            backgroundColor: colors.surface,
            color: colors.textSecondary,
          }}
        >
          {text}
        </span>
      </div>
    </div>
  )
}

export const LoginPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading, initialized } = useAuthStore();
  const { colors, isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isPasswordless, setIsPasswordless] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  const unlocked = sessionStorage.getItem('app_unlock_state') === 'true';
  const { error: storeError } = useStore();

  // Handle authenticated user
  useEffect(() => {
    console.log('[LoginPage] Auth state:', {
      initialized,
      loading,
      user: user?.uid,
      unlocked,
      locationState: location.state
    });

    if (initialized && !loading && user) {
      // Get redirect path once
      const redirectPath = sessionStorage.getItem('auth_redirect') || 
        (location.state as { from?: string })?.from || 
        '/home';
      
      // Clear redirect immediately
      sessionStorage.removeItem('auth_redirect');
      
      // Only navigate if we're not already on the target path
      if (window.location.pathname !== redirectPath) {
        console.log('[LoginPage] Navigating to:', redirectPath);
        // Use replace to prevent history buildup
        navigate(redirectPath, { replace: true });
      }
    }
  }, [user, loading, initialized, navigate]);

  // Handle access control
  useEffect(() => {
    console.log('[LoginPage] Checking access:', {
      unlocked,
      allowAccess: shouldAllowAccess(false)
    });

    if (!unlocked && !shouldAllowAccess(false)) {
      console.log('[LoginPage] Access denied, redirecting to landing');
      navigate('/', { replace: true });
    }
  }, [navigate, unlocked]);

  // Handle auth errors
  useEffect(() => {
    if (storeError) {
      showError(storeError, 'LoginPage');
    }
  }, [storeError, showError]);

  // Show loading state while auth initializes
  if (!initialized || loading) {
    console.log('[Login] Waiting for auth');
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  // Show error state
  if (error || storeError) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <ErrorFeedback
          error={error || storeError}
          componentName={componentName || 'LoginPage'}
          isOpen={true}
          onClose={() => {
            clearError();
            // Optional: reload on error dismiss
            // window.location.reload()
          }}
        />
      </div>
    );
  }

  const handleEmailSignIn = async (e?: React.FormEvent) => {
    e?.preventDefault();
    console.log('[Login] Attempting email sign in');

    if (!emailRef.current?.value || !passwordRef.current?.value) {
      showError(new Error("Please fill in all fields"), "LoginPage.handleEmailSignIn");
      return;
    }

    try {
      setIsLoading(true);
      const result = await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value);
      console.log('[Login] Sign in successful:', { uid: result.user.uid });
    } catch (error: any) {
      console.error('[Login] Sign in failed:', error);
      setIsLoading(false);
      let errorMessage = t('auth.failedToSignIn');
      if (error.code === "auth/user-not-found") errorMessage = t('auth.noAccountFoundWithThisEmail');
      else if (error.code === "auth/wrong-password") errorMessage = t('auth.incorrectPassword');
      else if (error.code === "auth/invalid-email") errorMessage = t('auth.invalidEmailAddress');
      else if (error.code === "auth/too-many-requests")
        errorMessage = t('auth.tooManyFailedAttempts');
      showError(new Error(errorMessage), "LoginPage.handleEmailSignIn");
    }
  };

  const handleAnonymousSignIn = async () => {
    try {
      setIsLoading(true)
      await authService.signInAnonymously()
      // Auth state listener will handle redirect
    } catch (error) {
      setIsLoading(false)
      showError(
        error instanceof Error ? error : new Error("Failed to sign in as guest"), 
        "LoginPage.handleAnonymousSignIn"
      )
    }
  }

  const handlePasswordlessSignIn = async (e: React.FormEvent) => {
    e.preventDefault()
    setSuccessMessage(null) // Clear any existing message

    if (!emailRef.current?.value) {
      showError(new Error("Please enter your email"), "LoginPage.handlePasswordlessSignIn")
      return
    }

    try {
      setIsLoading(true)
      await sendLoginLink(emailRef.current.value)
      setIsLoading(false)
      setSuccessMessage(`Magic link sent! Check your email (${emailRef.current.value}) to complete sign-in.`)
    } catch (error: any) {
      setIsLoading(false)
      showError(error, "LoginPage.handlePasswordlessSignIn")
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      
      // Store redirect path before sign in
      const redirectPath = (location.state as { from?: string })?.from || '/home';
      sessionStorage.setItem('auth_redirect', redirectPath);
      
      await signInWithGoogle();
      // Don't navigate here - let the auth state change handler do it
      
    } catch (error: any) {
      console.error('[LoginPage] Google sign in error:', error);
      let errorMessage = error.message || 'Failed to sign in with Google';
      if (error.code === 'auth/popup-blocked') {
        errorMessage = 'Please enable popups for this site to sign in with Google';
      }
      showError(new Error(errorMessage), 'LoginPage.handleGoogleSignIn');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4" style={{ backgroundColor: colors.background }}>
      <div className="max-w-md w-full space-y-6 p-8 rounded-lg shadow-lg" style={{ backgroundColor: colors.surface }}>
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-2" style={{ color: colors.primary }}>
            {t('auth.welcomeBack')}
          </h2>
          <p className="text-sm" style={{ color: colors.textSecondary }}>
            {t('auth.signInToContinue')}
          </p>
        </div>

        <div className="space-y-4">
          {isPasswordless ? (
            <form onSubmit={handlePasswordlessSignIn} className="space-y-4">
              <Input ref={emailRef} label="Email" type="email" id="email" disabled={isLoading} />
              <Button type="submit" disabled={isLoading} variant="primary">
                {isLoading ? t('auth.processing') : t('auth.sendMagicLink')}
              </Button>
              <div className="flex flex-col space-y-2">
                <button
                  type="button"
                  onClick={() => setIsPasswordless(false)}
                  className="text-sm text-center hover:text-primary transition-colors duration-200"
                  style={{ color: colors.textSecondary }}
                >
                  {t('auth.backToSignInOptions')}
                </button>
              </div>
            </form>
          ) : (
            <>
              <form onSubmit={handleEmailSignIn} className="space-y-4">
                <Input ref={emailRef} label="Email" type="email" id="email" disabled={isLoading} />
                <Input ref={passwordRef} label="Password" type="password" id="password" disabled={isLoading} />
                <Button type="submit" disabled={isLoading} variant="primary">
                  {isLoading ? t('auth.processing') : t('auth.signIn')}
                </Button>
              </form>
              <Divider text={t('auth.otherOptions')} />
              <Button
                onClick={() => {
                  setIsPasswordless(true)
                }}
              >
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                {t('auth.signInWithMagicLink')}
              </Button>
              <Button
                onClick={handleGoogleSignIn}
                disabled={isLoading}
              >
                <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                  />
                </svg>
                {t('auth.signInWithGoogle')}
              </Button>
              <Divider text={t('auth.or')} />
              <Button onClick={handleAnonymousSignIn} disabled={isLoading}>
                <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                {isLoading ? t('auth.signingIn') : t('auth.continueAsGuest')}
              </Button>
            </>
          )}

          <div className="text-center mt-6">
            <p style={{ color: colors.textSecondary }}>
              {t('auth.dontHaveAnAccount')} {" "}
              <Link
                to="/signup"
                className="font-medium hover:text-secondary transition-colors duration-200"
                style={{ color: colors.primary }}
              >
                {t('auth.signUp')}
              </Link>
            </p>
          </div>
        </div>

        {successMessage && (
          <div
            className="p-4 rounded-lg text-center"
            style={{
              backgroundColor: `${colors.primary}10`,
              color: colors.primary,
              border: `1px solid ${colors.primary}30`,
            }}
          >
            {successMessage}
          </div>
        )}
      </div>
    </div>
  )
}