import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useTheme } from '../../contexts/ThemeContext'
import { useAICreditsStore } from '../../store/useAICreditsStore'
import { useStore } from '../../store/useStore'
import { useNotificationStore } from '../../store/useNotificationStore'
import { motion } from 'framer-motion'
import { PiCreditCard, PiSpinner } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui/button'

interface PurchaseOption {
  amount: number
  price: number
}

interface AICreditPurchaseModalProps {
  isOpen: boolean
  onClose: () => void
  selectedOption: PurchaseOption
}

export function AICreditPurchaseModal({ isOpen, onClose, selectedOption }: AICreditPurchaseModalProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { addCredits } = useAICreditsStore()
  const { user } = useStore()
  const { addNotification } = useNotificationStore()
  const [isLoading, setIsLoading] = useState(false)

  const handlePurchase = async () => {
    if (!user?.id) return

    setIsLoading(true)
    try {
      // TODO: Implement actual payment processing
      // For now, we'll just simulate a successful purchase
      await new Promise(resolve => setTimeout(resolve, 1500))
      
      await addCredits(user.id, selectedOption.amount, 'purchase')
      
      addNotification({
        type: 'success',
        title: t('settings.ai.credits.purchaseSuccess'),
        message: t('settings.ai.credits.purchaseSuccessDetail', { 
          amount: selectedOption.amount,
          price: selectedOption.price 
        }),
        duration: 5000
      })
      
      onClose()
    } catch (error) {
      addNotification({
        type: 'error',
        title: t('settings.ai.credits.purchaseError'),
        message: error instanceof Error ? error.message : 'Unknown error occurred',
        duration: 5000
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-white rounded-lg shadow-xl max-w-md w-full p-6"
            style={{ backgroundColor: colors.surface }}
          >
            <Dialog.Title className="text-xl font-semibold mb-4" style={{ color: colors.text }}>
              {t('settings.ai.credits.confirmPurchase')}
            </Dialog.Title>

            <div className="mb-6">
              <div 
                className="p-4 rounded-lg mb-4"
                style={{ backgroundColor: `${colors.primary}10` }}
              >
                <div className="flex items-center gap-3 mb-2">
                  <div 
                    className="p-2 rounded-full"
                    style={{ backgroundColor: colors.primary }}
                  >
                    <PiCreditCard className="w-6 h-6 text-white" />
                  </div>
                  <div>
                    <div className="font-medium" style={{ color: colors.text }}>
                      {selectedOption.amount} {t('settings.ai.credits.credits')}
                    </div>
                    <div className="text-sm" style={{ color: colors.textSecondary }}>
                      ${selectedOption.price}
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-sm" style={{ color: colors.textSecondary }}>
                {t('settings.ai.credits.purchaseDisclaimer')}
              </p>
            </div>

            <div className="flex gap-3">
              <Button
                onClick={onClose}
                variant="outline"
                className="flex-1"
                disabled={isLoading}
                style={{ color: colors.text }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                onClick={handlePurchase}
                className="flex-1"
                disabled={isLoading}
                style={{ color: colors.text }}
              >
                {isLoading ? (
                  <PiSpinner className="w-5 h-5 animate-spin" />
                ) : (
                  t('settings.ai.credits.confirmButton', { price: selectedOption.price })
                )}

              </Button>
            </div>
          </motion.div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
} 