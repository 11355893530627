import { useState, useCallback } from 'react';

export const useErrorFeedback = () => {
  const [error, setError] = useState<Error | string | null>(null);
  const [componentName, setComponentName] = useState<string | undefined>();

  const showError = useCallback((error: Error | string, componentName?: string) => {
    console.error(`Error in ${componentName || 'unknown component'}:`, error);
    setError(error);
    setComponentName(componentName);
  }, []);

  const clearError = useCallback(() => {
    setError(null);
    setComponentName(undefined);
  }, []);

  return {
    error,
    componentName,
    showError,
    clearError,
    isErrorVisible: !!error
  };
}; 