import { useGamificationStore } from '../../store/useGamificationStore';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  PiFlame, 
  PiNotePencil, 
  PiHeart, 
  PiCurrencyCircleDollar,
  PiSmiley,
  PiNotebook,
  PiPiggyBank,
  PiListChecks
} from 'react-icons/pi';
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';

export const StreaksOverview = () => {
  const { colors, isDarkMode } = useTheme();
  const { streaks } = useGamificationStore();

  const streakConfigs = [
    {
      type: 'login' as const,
      label: 'Login',
      icon: PiFlame,
      color: colors.primary
    },
    {
      type: 'notebook' as const,
      label: 'Notes',
      icon: PiNotePencil,
      color: colors.secondary
    },
    {
      type: 'mood' as const,
      label: 'Mood',
      icon: PiHeart,
      color: colors.tertiary
    },
    {
      type: 'finance' as const,
      label: 'Finance',
      icon: PiCurrencyCircleDollar,
      color: colors.quaternary
    },
    {
      type: 'lists' as const,
      label: 'Lists',
      icon: PiListChecks,
      color: colors.accent1
    }
  ];

  if (!streaks) return null;

  return (
    <>
      {streakConfigs.map(({ type, label, icon: Icon, color }, index) => {
        const streak = streaks[type];
        
        return (
          <motion.div 
            key={type}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 0.3,
              delay: index * 0.1,
              ease: "easeOut"
            }}
            whileHover={{ scale: 1.02 }}
            className="p-4 rounded-xl flex items-center gap-3"
            style={{ 
              backgroundColor: isDarkMode ? colors.surfaceAlt : colors.surface,
              border: `1px solid ${color}25`
            }}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 rounded-lg"
              style={{ backgroundColor: `${color}10` }}
            >
              <Icon 
                className="w-5 h-5 md:w-6 md:h-6"
                style={{ color }}
              />
            </motion.div>
            <div>
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 + 0.2 }}
                className="text-xs md:text-sm font-medium"
                style={{ color: colors.textSecondary }}
              >
                {label} Streak
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 + 0.3 }}
                className="text-base md:text-lg font-semibold"
                style={{ color }}
              >
                {streak?.currentStreak || 0}
              </motion.div>
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.1 + 0.4 }}
                className="text-xs"
                style={{ color: colors.textSecondary }}
              >
                Best: {streak?.longestStreak || 0}
              </motion.div>
            </div>
          </motion.div>
        );
      })}
    </>
  );
}; 