import type { AIAssistantPreset } from '../types/ai'

// Export the presets as a constant array
export const personalityPresets: AIAssistantPreset[] = [
  {
    id: 'professional-assistant',
    name: 'Professional Assistant',
    description: 'A formal and efficient assistant focused on productivity',
    style: { tone: 'professional', formality: 'formal', verbosity: 'concise' }
  },
  {
    id: 'friendly-coach',
    name: 'Friendly Coach',
    description: 'A supportive and encouraging guide',
    style: { tone: 'friendly', formality: 'informal', verbosity: 'balanced' }
  },
  {
    id: 'detailed-expert',
    name: 'Detailed Expert',
    description: 'A thorough and knowledgeable advisor',
    style: { tone: 'professional', formality: 'semiformal', verbosity: 'detailed' }
  },
  {
    id: 'casual-buddy',
    name: 'Casual Buddy',
    description: 'A laid-back and approachable companion',
    style: { tone: 'casual', formality: 'informal', verbosity: 'concise' }
  }
]

// Helper function to get preset by ID
export function getPresetById(id: string): AIAssistantPreset | undefined {
  return personalityPresets.find(preset => preset.id === id)
} 