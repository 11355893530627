import { useTheme } from '../../contexts/ThemeContext';

interface ToggleProps {
  isEnabled: boolean;
  onChange: () => void;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const Toggle = ({ 
  isEnabled, 
  onChange, 
  label, 
  icon, 
  disabled = false 
}: ToggleProps) => {
  const { colors } = useTheme();

  // Simplified color handling
  const switchColor = () => {
    if (disabled) return colors.textSecondary;
    return isEnabled ? colors.primary : colors.textSecondary;
  };

  return (
    <button
      onClick={() => !disabled && onChange()}
      className={`
        flex items-center justify-between w-full p-4 rounded-lg transition-all duration-200
        ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-opacity-90'}
      `}
      style={{
        backgroundColor: `${colors.textSecondary}15`,
        color: switchColor(),
        opacity: disabled ? 0.6 : 1
      }}
      disabled={disabled}
    >
      <div className="flex items-center gap-3">
        {icon && (
          <div style={{ 
            color: 'inherit',
            opacity: disabled ? 0.5 : 1
          }}>
            {icon}
          </div>
        )}
        <span className="font-medium">{label}</span>
      </div>
      <div 
        className="w-11 h-6 rounded-full relative transition-all duration-200"
        style={{
          backgroundColor: switchColor(),
          opacity: disabled ? 0.3 : (isEnabled ? 1 : 0.3)
        }}
      >
        <div 
          className="absolute w-5 h-5 rounded-full top-0.5 left-0.5 transition-all duration-200 shadow-sm"
          style={{
            backgroundColor: colors.surface,
            transform: isEnabled ? 'translateX(20px)' : 'translateX(0)',
          }}
        />
      </div>
    </button>
  );
}; 