export const achievementColors = {
  // Category Colors
  streak: '#FF6B6B',     // Bright Red
  notes: '#4ECDC4',      // Teal
  lists: '#9B89B3',      // Soft Purple
  finance: '#45B7D1',    // Sky Blue
  mood: '#FFB84C',       // Warm Orange
  calendar: '#95D5B2',   // Sage Green
  general: '#FF8FB1',    // Soft Pink
  hidden: '#B8B8B8',     // Neutral Gray
} as const;

// Achievement states
export const achievementStates = {
  locked: {
    border: '#374151',   // Dark gray
    background: 'rgba(55, 65, 81, 0.1)',
    text: '#6B7280'
  },
  hidden: {
    border: '#6B7280',   // Medium gray
    background: 'rgba(107, 114, 128, 0.15)',
    text: '#9CA3AF',
    glow: 'rgba(107, 114, 128, 0.2)'
  }
} as const; 