import { create } from 'zustand';
import { moodService } from '../services/moodService';
import { MoodStore } from '../types/stores';
import { MoodEntry, FirestoreMoodEntry } from '../types/mood';
import { useStore } from './useStore';

// Helper function to convert Firestore timestamp to Date
const convertFirestoreEntry = (entry: FirestoreMoodEntry): MoodEntry => ({
  id: entry.id,
  date: new Date(entry.date.seconds * 1000),
  period: entry.period,
  mood: entry.mood,
  note: entry.note,
  activities: entry.activities,
  weather: entry.weather,
  createdAt: new Date(entry.createdAt.seconds * 1000),
  updatedAt: entry.updatedAt ? new Date(entry.updatedAt.seconds * 1000) : undefined
});

export const useMoodStore = create<MoodStore>((set, get) => ({
  entries: [],
  loading: false,
  error: null,

  exportData: () => {
    const { entries } = get();
    return entries.map(entry => ({
      ...entry,
      date: entry.date.toISOString(),
      createdAt: entry.createdAt.toISOString(),
      updatedAt: entry.updatedAt?.toISOString()
    }));
  },

  fetchEntries: async (userId: string, dateRange: { startDate: Date; endDate: Date }) => {
    try {
      set({ loading: true, error: null });
      const firestoreEntries = await moodService.getUserMoodEntries(userId, dateRange);
      const entries = firestoreEntries.map(convertFirestoreEntry);
      set({ entries, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  addEntry: async (entry: Omit<MoodEntry, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      set({ loading: true, error: null });
      const userId = useStore.getState().user?.id;
      if (!userId) throw new Error('User not authenticated');

      // Add the new entry to Firestore
      const entryId = await moodService.createMoodEntry(userId, entry);
      
      // Fetch updated entries to ensure consistency
      const startDate = new Date(entry.date.getFullYear(), entry.date.getMonth(), 1);
      const endDate = new Date(entry.date.getFullYear(), entry.date.getMonth() + 1, 0, 23, 59, 59);
      const firestoreEntries = await moodService.getUserMoodEntries(userId, { startDate, endDate });
      const entries = firestoreEntries.map(convertFirestoreEntry);
      
      set({ entries, loading: false });
      return entryId;
    } catch (error) {
      set({ error: error as Error, loading: false });
      throw error;
    }
  },

  updateEntry: async (entryId: string, updates: Partial<MoodEntry>) => {
    try {
      set({ loading: true, error: null });
      await moodService.updateMoodEntry(entryId, updates);
      const { entries } = get();
      const updatedEntries = entries.map(entry =>
        entry.id === entryId
          ? { ...entry, ...updates, updatedAt: new Date() }
          : entry
      );
      set({ entries: updatedEntries, loading: false });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  deleteEntry: async (entryId: string) => {
    try {
      set({ loading: true, error: null });
      await moodService.deleteMoodEntry(entryId);
      const { entries } = get();
      set({ 
        entries: entries.filter(entry => entry.id !== entryId),
        loading: false 
      });
    } catch (error) {
      set({ error: error as Error, loading: false });
    }
  },

  clearAllData: () => {
    set({ entries: [], loading: false, error: null });
  },

  fetchMoodEntries: async (userId: string) => {
    try {
      const firestoreEntries = await moodService.getUserMoodEntries(userId);
      const entries = firestoreEntries.map(convertFirestoreEntry);
      set({ entries });
    } catch (error) {
      console.error('Error fetching mood entries:', error);
      throw error;
    }
  },
})); 