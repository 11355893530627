import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTheme } from '../contexts/ThemeContext'
import { useListsStore } from '../store/useListsStore'
import { listsService } from '../services/listsService'
import { useErrorFeedback } from '../hooks/useErrorFeedback'
import { ErrorFeedback } from '../components/shared/ErrorFeedback'
import { 
  PiArrowLeft,
  PiTrash,
  PiPencilSimple,
  PiPlus,
  PiSpinner,
  PiX,
  PiCheck,
  PiList,
  PiWarning,
  PiListPlus
} from 'react-icons/pi'
import { motion, AnimatePresence } from 'framer-motion'
import type { ListItem } from '../types/lists'
import { useTranslation } from 'react-i18next'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { ListItemComponent } from '../components/lists/ListItem'
import { ListSort } from '../components/lists/ListSort'
import { useStore } from '../store/useStore'
import { Link } from 'react-router-dom'
import { UpgradePrompt } from '../components/shared/UpgradePrompt'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits'

const sortWithCompletedAtBottom = (items: ListItem[]) => {
  const completedItems = items.filter(item => item.isCompleted).sort((a, b) => a.order - b.order)
  const nonCompletedItems = items.filter(item => !item.isCompleted).sort((a, b) => a.order - b.order)
  return [...nonCompletedItems, ...completedItems]
}

export function ListDetailPage() {
  const { listId } = useParams()
  const navigate = useNavigate()
  const { colors } = useTheme()
  const { lists, setLists, isLoading: isListsLoading } = useListsStore()
  const { user } = useStore()
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()
  const [items, setItems] = useState<ListItem[]>([])
  const [isItemsLoading, setIsItemsLoading] = useState(true)
  const [newItemText, setNewItemText] = useState('')
  const [editingItemId, setEditingItemId] = useState<string | null>(null)
  const [editingText, setEditingText] = useState('')
  const { t } = useTranslation()
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [editedTitle, setEditedTitle] = useState('')
  const [editedDescription, setEditedDescription] = useState('')
  const [activeId, setActiveId] = useState<string | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const userTier = user?.subscription?.tier || 'free'
  const itemLimit = SUBSCRIPTION_LIMITS.itemsPerList[userTier]
  const showItemLimitWarning = items.length >= (itemLimit * 0.8) // Show warning at 80% of limit
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false)

  const list = lists.find(l => l.id === listId)

  useEffect(() => {
    async function fetchItems() {
      if (!listId) return
      
      try {
        setIsItemsLoading(true)
        const fetchedItems = await listsService.getListItems(listId)
        setItems(sortWithCompletedAtBottom(fetchedItems))
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'ListDetailPage.fetchItems')
        }
      } finally {
        setIsItemsLoading(false)
      }
    }

    fetchItems()
  }, [listId, showError])

  useEffect(() => {
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    async function fetchLists() {
      if (!user?.id) return
      
      try {
        const fetchedLists = await listsService.getLists(user.id)
        setLists(fetchedLists)
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'ListDetailPage.fetchLists')
        }
      }
    }

    fetchLists()
  }, [user?.id, setLists, showError])

  const handleAddItem = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!listId || !newItemText.trim()) return

    if (items.length >= itemLimit) {
      setShowUpgradePrompt(true)
      return
    }

    try {
      const newItem = await listsService.createListItem(listId, {
        content: newItemText.trim(),
        isCompleted: false,
        order: items.length
      })
      setItems(sortWithCompletedAtBottom([...items, newItem]))
      setNewItemText('')
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleAddItem')
      }
    }
  }

  const handleToggleItem = async (itemId: string, isCompleted: boolean) => {
    try {
      const currentItem = items.find(item => item.id === itemId)
      const updates = {
        isCompleted,
        status: isCompleted ? 'COMPLETED' : (currentItem?.status === 'IN_PROGRESS' ? 'IN_PROGRESS' : 'NOT_STARTED')
      }
      
      await listsService.updateListItem(listId!, itemId, updates)
      const updatedItems = items.map(item => 
        item.id === itemId ? { ...item, ...updates } : item
      )
      setItems(sortWithCompletedAtBottom(updatedItems))
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleToggleItem')
      }
    }
  }

  const handleDeleteItem = async (itemId: string) => {
    if (!listId) return
    
    try {
      await listsService.deleteListItem(listId, itemId)
      setItems(items.filter(item => item.id !== itemId))
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleDeleteItem')
      }
    }
  }

  const handleEditItem = (item: ListItem) => {
    setEditingItemId(item.id)
    setEditingText(item.content)
  }

  const handleSaveEdit = async () => {
    if (!listId || !editingItemId || !editingText.trim()) return

    try {
      await listsService.updateListItem(listId, editingItemId, { 
        content: editingText.trim() 
      })
      setItems(items.map(item => 
        item.id === editingItemId 
          ? { ...item, content: editingText.trim() }
          : item
      ))
      setEditingItemId(null)
      setEditingText('')
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleSaveEdit')
      }
    }
  }

  const handleUpdateItem = async (itemId: string, updates: Partial<ListItem>) => {
    if (!listId) return

    try {
      console.log('ListDetailPage updating item:', { itemId, updates })
      
      // Ensure status and isCompleted stay in sync
      const currentItem = items.find(item => item.id === itemId)
      let finalUpdates = { ...updates }

      // Ensure dueDate is a proper Date object
      if (updates.dueDate) {
        finalUpdates.dueDate = new Date(updates.dueDate)
      }

      if ('status' in updates) {
        finalUpdates.isCompleted = updates.status === 'COMPLETED'
      } else if ('isCompleted' in updates) {
        finalUpdates.status = updates.isCompleted 
          ? 'COMPLETED' 
          : (currentItem?.status === 'IN_PROGRESS' ? 'IN_PROGRESS' : 'NOT_STARTED')
      }

      await listsService.updateListItem(listId, itemId, finalUpdates)

      // Update local state
      const updatedItems = items.map(item => 
        item.id === itemId ? { ...item, ...finalUpdates } : item
      )

      // If completion status changed, apply the sort
      if ('isCompleted' in finalUpdates || 'status' in finalUpdates) {
        setItems(sortWithCompletedAtBottom(updatedItems))
      } else {
        setItems(updatedItems)
      }

    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleUpdateItem')
      }
    }
  }  

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    if (!over || active.id === over.id) return

    // Get the dragged item
    const draggedItem = items.find(item => item.id === active.id)
    const targetItem = items.find(item => item.id === over.id)

    // Don't allow dragging completed items up or incomplete items down past the boundary
    if (draggedItem && targetItem) {
      if (draggedItem.isCompleted && !targetItem.isCompleted) return
      if (!draggedItem.isCompleted && targetItem.isCompleted) return
    }

    const oldIndex = items.findIndex(item => item.id === active.id)
    const newIndex = items.findIndex(item => item.id === over.id)

    const newItems = arrayMove(items, oldIndex, newIndex)
    setItems(newItems) // Don't sort here, just update the order

    // Update orders in database
    try {
      await Promise.all(
        newItems.map((item, index) => 
          listsService.updateListItem(listId!, item.id, { order: index })
        )
      )
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleDragEnd')
      }
      // Revert to previous order on error
      setItems(items)
    }
  }

  const handleSort = async (sortedItems: ListItem[]) => {
    // Split items into completed and non-completed
    const completedItems = sortedItems.filter(item => item.isCompleted)
    const nonCompletedItems = sortedItems.filter(item => !item.isCompleted)

    // Combine them in order
    const newItems = [...nonCompletedItems, ...completedItems]
    setItems(newItems)

    // Update the order in the database
    try {
      await Promise.all(
        newItems.map((item, index) => 
          listsService.updateListItem(listId!, item.id, { order: index })
        )
      )
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleSort')
      }
      // Revert to previous order on error
      const originalItems = await listsService.getListItems(listId!)
      setItems(originalItems)
    }
  }

  const handleUpdateList = async (updates: Partial<List>) => {
    if (!listId) return
    
    try {
      await listsService.updateList(listId, updates)
      // Update local lists store
      const updatedLists = lists.map(l => 
        l.id === listId ? { ...l, ...updates } : l
      )
      setLists(updatedLists)
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleUpdateList')
      }
    }
  }

  const handleTitleSubmit = async () => {
    if (!listId || !editedTitle.trim() || editedTitle === list?.title) return

    try {
      await listsService.updateList(listId, { title: editedTitle.trim() })
      setLists(lists.map(l => 
        l.id === listId ? { ...l, title: editedTitle.trim() } : l
      ))
      setIsEditingTitle(false)
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleTitleSubmit')
      }
    }
  }

  const handleDescriptionSubmit = async () => {
    if (!listId) return

    try {
      await listsService.updateList(listId, { description: editedDescription.trim() })
      setLists(lists.map(l => 
        l.id === listId ? { ...l, description: editedDescription.trim() } : l
      ))
      setIsEditingDescription(false)
    } catch (err) {
      if (err instanceof Error) {
        showError(err, 'ListDetailPage.handleDescriptionSubmit')
      }
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string)
  }

  const handleDragCancel = () => {
    setActiveId(null)
  }

  // Show loading state while either lists or items are loading
  if (!isInitialized || isListsLoading || isItemsLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen gap-2">
        <PiSpinner size={24} className="animate-spin" style={{ color: colors.textSecondary }} />
        <div className="text-lg" style={{ color: colors.textSecondary }}>
          {t('common.loading')}
        </div>
      </div>
    )
  }

  // Only show not found when lists are loaded and list isn't found
  if (!list) {
    return (
      <motion.div 
        className="text-center py-12"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
      >
        <p style={{ color: colors.textSecondary }}>
          {t('lists.notFound')}
        </p>
      </motion.div>
    )
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className="space-y-6 max-w-3xl mx-auto p-4"
    >
      <div className="space-y-2">
        <motion.div 
          className="flex items-center gap-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => navigate('/lists')}
            className="p-2 rounded-full hover:bg-opacity-10 transition-colors"
            style={{ 
              backgroundColor: `${colors.quaternary}10`,
              color: colors.quaternary
            }}
          >
            <PiArrowLeft className="h-5 w-5" />
          </motion.button>
          
          {isEditingTitle ? (
            <motion.form 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              onSubmit={(e) => {
                e.preventDefault()
                handleTitleSubmit()
              }}
              className="flex-1 flex gap-2"
            >
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="flex-1 px-3 py-2 text-2xl font-semibold rounded border"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border,
                  color: colors.text
                }}
                autoFocus
                onBlur={handleTitleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setIsEditingTitle(false)
                    setEditedTitle(list?.title || '')
                  }
                }}
              />
            </motion.form>
          ) : (
            <motion.div 
              className="flex-1 flex items-center gap-2 group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            >
              <h1 
                className="text-2xl font-semibold"
                style={{ color: colors.primary }}
              >
                {list?.title}
              </h1>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  setEditedTitle(list?.title || '')
                  setIsEditingTitle(true)
                }}
                className="p-1 rounded hover:bg-opacity-10 transition-colors opacity-0 group-hover:opacity-100"
                style={{ color: colors.textSecondary }}
              >
                <PiPencilSimple className="h-4 w-4" />
              </motion.button>
            </motion.div>
          )}
        </motion.div>

        {/* Description Section */}
        <AnimatePresence mode="wait">
          {isEditingDescription ? (
            <motion.form 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              onSubmit={(e) => {
                e.preventDefault()
                handleDescriptionSubmit()
              }}
              className="ml-12"
            >
              <textarea
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                className="w-full px-3 py-2 text-sm rounded border"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border,
                  color: colors.text
                }}
                rows={3}
                placeholder="Add a description..."
                autoFocus
                onBlur={handleDescriptionSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setIsEditingDescription(false)
                    setEditedDescription(list?.description || '')
                  }
                }}
              />
            </motion.form>
          ) : (
            <motion.div 
              className="ml-12 flex items-start gap-2 group"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <p 
                className="text-sm flex-1 cursor-pointer group-hover:text-opacity-75 transition-colors"
                style={{ color: colors.textSecondary }}
                onClick={() => {
                  setEditedDescription(list?.description || '')
                  setIsEditingDescription(true)
                }}
              >
                {list?.description || 'Add a description...'}
              </p>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  setEditedDescription(list?.description || '')
                  setIsEditingDescription(true)
                }}
                className="p-1 rounded hover:bg-opacity-10 transition-colors opacity-0 group-hover:opacity-100"
                style={{ color: colors.textSecondary }}
              >
                <PiPencilSimple className="h-4 w-4" />
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* List items */}
      <motion.div 
        className="space-y-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {items.length === 0 ? (
          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4 }}
            className="text-center py-8 rounded-lg"
            style={{ backgroundColor: colors.surface }}
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <PiList size={32} style={{ color: colors.textSecondary }} className="mx-auto mb-2" />
              <p style={{ color: colors.textSecondary }}>
                {t('lists.items.empty')}
              </p>
            </motion.div>
          </motion.div>
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <AnimatePresence mode="popLayout">
                <motion.div className="space-y-2" layout>
                  {items.map((item, index) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ 
                        delay: index * 0.05,
                        duration: 0.2
                      }}
                      layout
                    >
                      <ListItemComponent
                        item={item}
                        isEditing={editingItemId === item.id}
                        editingText={editingText}
                        onEditingTextChange={setEditingText}
                        onToggle={handleToggleItem}
                        onEdit={handleEditItem}
                        onDelete={handleDeleteItem}
                        onSaveEdit={handleSaveEdit}
                        onCancelEdit={() => {
                          setEditingItemId(null)
                          setEditingText('')
                        }}
                        onUpdateItem={handleUpdateItem}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              </AnimatePresence>
            </SortableContext>

            <DragOverlay>
              {activeId ? (
                <div 
                  className="rounded-lg"
                  style={{ 
                    backgroundColor: colors.surface,
                    border: `1px solid ${colors.border}`,
                    width: '100%',
                    maxWidth: '100%',
                    cursor: 'grabbing',
                    opacity: 1,
                    boxShadow: `0 2px 4px ${colors.shadow}15`
                  }}
                >
                  <ListItemComponent
                    item={items.find(item => item.id === activeId)!}
                    isEditing={false}
                    editingText=""
                    onEditingTextChange={() => {}}
                    onToggle={() => {}}
                    onEdit={() => {}}
                    onDelete={() => {}}
                    onSaveEdit={() => {}}
                    onCancelEdit={() => {}}
                    onUpdateItem={() => {}}
                  />
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        )}
      </motion.div>

      {/* Item limit warning */}
      <AnimatePresence>
        {showItemLimitWarning && items.length < itemLimit && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mb-4 p-4 rounded-lg flex items-center gap-3"
            style={{
              backgroundColor: `${colors.warning}15`,
              border: `1px solid ${colors.warning}`
            }}
          >
            <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
            <div>
              <p className="text-sm" style={{ color: colors.text }}>
                You've used {items.length} of {itemLimit} items in this list.{' '}
                <Link 
                  to="/upgrade"
                  className="font-medium hover:underline"
                  style={{ color: colors.warning }}
                >
                  Upgrade to add more items
                </Link>
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Modify the add item form */}
      <motion.form 
        onSubmit={handleAddItem} 
        className="flex-1 flex gap-2"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <input
          type="text"
          value={newItemText}
          onChange={(e) => setNewItemText(e.target.value)}
          placeholder={items.length >= itemLimit ? 'Upgrade to add more items' : t('lists.addItem')}
          className="flex-1 px-3 py-2 rounded-md border"
          style={{
            backgroundColor: colors.surface,
            borderColor: colors.border,
            color: colors.text
          }}
          disabled={items.length >= itemLimit}
        />
        <motion.button
          type="submit"
          disabled={!newItemText.trim() || items.length >= itemLimit}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="px-4 py-2 rounded-md disabled:opacity-50 whitespace-nowrap flex items-center gap-2"
          style={{
            backgroundColor: colors.primary,
            color: '#fff'
          }}
        >
          <PiPlus size={20} />
          {items.length >= itemLimit ? 'Upgrade to Add More' : t('lists.add')}
        </motion.button>
      </motion.form>

      {/* Add upgrade prompt */}
      <AnimatePresence>
        {showUpgradePrompt && (
          <UpgradePrompt
            title="List Item Limit Reached"
            description={`Free users can add up to ${itemLimit} items per list. Upgrade to Core to add up to 50 items, or Pro for no restrictions.`}
            onClose={() => setShowUpgradePrompt(false)}
            feature="itemsPerList"
            currentTier={userTier}
            requiredTier="core"
            icon={<PiListPlus className="w-6 h-6" />}

          />
        )}
      </AnimatePresence>

      {/* Sort button with animation */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5 }}
      >
        <ListSort items={items} onSort={handleSort} />
      </motion.div>

      {/* Error feedback */}
      <AnimatePresence>
        {error && (
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        )}
      </AnimatePresence>
    </motion.div>
  )
} 