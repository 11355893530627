import { useState } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { 
  PiSortAscending,
  PiSortDescending,
  PiCaretDown,
  PiTextAa,
  PiCalendar,
  PiFlag,
  PiClockCountdown
} from 'react-icons/pi'
import type { ListItem } from '../../types/lists'

export type SortOption = 'manual' | 'status' | 'priority' | 'date' | 'alphabetical'

interface ListSortProps {
  items: ListItem[]
  onSort: (items: ListItem[]) => void
}

export function ListSort({ items, onSort }: ListSortProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [currentSort, setCurrentSort] = useState<SortOption>('manual')

  const sortOptions = {
    manual: {
      label: t('lists.sort.manual'),
      icon: <PiSortAscending className="h-5 w-5" />,
      sort: (a: ListItem, b: ListItem) => a.order - b.order
    },
    status: {
      label: t('lists.sort.status'),
      icon: <PiClockCountdown className="h-5 w-5" />,
      sort: (a: ListItem, b: ListItem) => {
        const statusOrder = { 
          NOT_STARTED: 0, 
          IN_PROGRESS: 1, 
          COMPLETED: 2 
        }
        const aStatus = a.status ? statusOrder[a.status] : 0
        const bStatus = b.status ? statusOrder[b.status] : 0
        return aStatus - bStatus
      }
    },
    priority: {
      label: t('lists.sort.priority'),
      icon: <PiFlag className="h-5 w-5" />,
      sort: (a: ListItem, b: ListItem) => {
        const priorityOrder = { high: 3, medium: 2, low: 1, none: 0 }
        const aPriority = a.priority ? priorityOrder[a.priority.toLowerCase()] : 0
        const bPriority = b.priority ? priorityOrder[b.priority.toLowerCase()] : 0
        return bPriority - aPriority
      }
    },
    date: {
      label: t('lists.sort.date'),
      icon: <PiCalendar className="h-5 w-5" />,
      sort: (a: ListItem, b: ListItem) => {
        if (!a.dueDate && !b.dueDate) return 0
        if (!a.dueDate) return 1
        if (!b.dueDate) return -1
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
      }
    },
    alphabetical: {
      label: t('lists.sort.alphabetical'),
      icon: <PiTextAa className="h-5 w-5" />,
      sort: (a: ListItem, b: ListItem) => a.content.localeCompare(b.content)
    }
  }

  const handleSort = (key: keyof typeof sortOptions) => {
    setCurrentSort(key as SortOption)
    const sortedItems = [...items].sort(sortOptions[key].sort)
    onSort(sortedItems)
    setIsOpen(false)
  }

  return (
    <div className="relative inline-block">
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="px-3 py-2 rounded flex items-center gap-2"
        style={{ color: colors.textSecondary }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {sortOptions[currentSort].icon}
        <span>{t('lists.sort.title')}</span>
        <PiCaretDown className="h-4 w-4" />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <div 
              className="fixed inset-0" 
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              className="absolute right-0 mt-1 py-1 rounded-md shadow-lg z-50 origin-top-right"
              style={{ 
                backgroundColor: colors.surface,
                borderColor: colors.border,
                borderWidth: 1,
                minWidth: '200px',
                top: '100%',
              }}
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scale: 0.95 }}
              transition={{ duration: 0.15 }}
            >
              {Object.entries(sortOptions).map(([key, option]) => (
                <motion.button
                  key={key}
                  onClick={() => handleSort(key as keyof typeof sortOptions)}
                  className="w-full px-4 py-2 text-left flex items-center gap-3"
                  style={{ 
                    color: currentSort === key ? colors.primary : colors.text,
                    backgroundColor: currentSort === key ? `${colors.primary}10` : undefined
                  }}
                  whileHover={{ 
                    backgroundColor: `${colors.quaternary}10`,
                    x: 2
                  }}
                >
                  {option.icon}
                  <span>{option.label}</span>
                </motion.button>
              ))}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
} 