import { create } from 'zustand';
import { notebookService } from '../services/notebookService';
import { auth } from '../utils/firebase';
import type { NotebookStore } from '../types/stores';
import type { Notebook } from '../types/notebook';
import { AppError } from '../utils/errors';

export const useNotebookStore = create<NotebookStore>()((set, get) => ({
  notebooks: [],
  currentNotes: [],
  loading: false,
  error: null,

  exportData: () => {
    const { notebooks, currentNotes } = get();
    return notebooks.map(notebook => ({
      ...notebook,
      createdAt: {
        seconds: notebook.createdAt.seconds,
        nanoseconds: notebook.createdAt.nanoseconds
      },
      updatedAt: {
        seconds: notebook.updatedAt.seconds,
        nanoseconds: notebook.updatedAt.nanoseconds
      },
      notes: currentNotes
        .filter(note => note.notebookId === notebook.id)
        .map(note => ({
          ...note,
          createdAt: {
            seconds: note.createdAt.seconds,
            nanoseconds: note.createdAt.nanoseconds
          },
          updatedAt: {
            seconds: note.updatedAt.seconds,
            nanoseconds: note.updatedAt.nanoseconds
          }
        }))
    }));
  },

  clearAllData: () => {
    set({
      notebooks: [],
      currentNotes: [],
      loading: false,
      error: null
    });
  },

  fetchNotebooks: async () => {
    try {
      set({ loading: true, error: null });
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error('Not authenticated');
      
      const notebooks = await notebookService.getUserNotebooks(userId);
      const formattedNotebooks = notebooks.map(doc => ({
        id: doc.id,
        title: typeof doc.title === 'object' ? doc.title.toString() : String(doc.title),
        userId: doc.userId,
        color: doc.color || null,
        createdAt: new Date(doc.createdAt.seconds * 1000),
        updatedAt: new Date(doc.updatedAt.seconds * 1000)
      }));
      
      set({ notebooks: formattedNotebooks, loading: false });
    } catch (error) {
      console.error('Error fetching notebooks:', error);
      set({ 
        error: 'Failed to fetch notebooks', 
        loading: false 
      });
      throw error;
    }
  },

  fetchNotes: async (notebookId: string) => {
    if (!notebookId) {
      console.error('fetchNotes called without notebookId');
      return;
    }

    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('fetchNotes called without user');
      return;
    }

    try {
      set({ loading: true, error: null });
      const notes = await notebookService.getNotebookNotes(notebookId, userId);
      set(() => ({
        currentNotes: notes,
        loading: false
      }));
    } catch (error) {
      console.error('Error fetching notes:', error);
      set({ 
        error: 'Failed to fetch notes', 
        loading: false 
      });
      throw error;
    }
  },

  createNotebook: async (title: string) => {
    try {
      set({ loading: true, error: null });
      const userId = auth.currentUser?.uid;
      if (!userId) throw new AppError('Not authenticated', 'UNAUTHENTICATED');
      
      const notebookId = await notebookService.createNotebook(userId, title);
      set({ loading: false });
      await get().fetchNotebooks();
      return notebookId;
    } catch (error) {
      console.error('Error creating notebook:', error);
      set({ 
        error: error instanceof AppError ? error.message : 'Failed to create notebook', 
        loading: false 
      });
      throw error;
    }
  },

  createNote: async (notebookId: string, userId: string, title: string, content: string) => {
    try {
      set({ loading: true, error: null });
      const note = await notebookService.createNote(notebookId, userId, title, content);
      set(state => ({
        currentNotes: [...state.currentNotes, note],
        loading: false
      }));
      return note.id;
    } catch (error) {
      console.error('Error creating note:', error);
      if (error instanceof AppError && error.code === 'ACTIVITY_ERROR') {
        set({ loading: false });
      } else {
        set({ 
          error: error instanceof AppError ? error.message : 'Failed to create note', 
          loading: false 
        });
      }
      throw error;
    }
  },

  updateNotebook: async (id: string, updates: Partial<Notebook>) => {
    try {
      set({ loading: true, error: null });
      await notebookService.updateNotebook(id, updates);
      
      // Update local state with the new values
      set(state => ({
        notebooks: state.notebooks.map(notebook => 
          notebook.id === id 
            ? { ...notebook, ...updates, updatedAt: new Date() }
            : notebook
        ),
        loading: false
      }));
    } catch (error) {
      console.error('Error updating notebook:', error);
      set({ 
        error: 'Failed to update notebook', 
        loading: false 
      });
      throw error;
    }
  },

  updateNoteContent: async (noteId: string, content: string) => {
    try {
      set({ loading: true, error: null });
      await notebookService.updateNoteContent(noteId, content);
      set(({ currentNotes }) => ({
        currentNotes: currentNotes.map(note => 
          note.id === noteId 
            ? { 
                ...note, 
                content,
                updatedAt: {
                  seconds: Math.floor(Date.now() / 1000),
                  nanoseconds: (Date.now() % 1000) * 1000000
                }
              }
            : note
        ),
        loading: false
      }));
    } catch (error) {
      console.error('Error updating note content:', error);
      set({ 
        error: 'Failed to update note content', 
        loading: false 
      });
      throw error;
    }
  },

  updateNoteTitle: async (noteId: string, title: string) => {
    try {
      set({ loading: true, error: null });
      await notebookService.updateNoteTitle(noteId, title);
      set(({ currentNotes }) => ({
        currentNotes: currentNotes.map(note => 
          note.id === noteId 
            ? { 
                ...note, 
                title,
                updatedAt: {
                  seconds: Math.floor(Date.now() / 1000),
                  nanoseconds: (Date.now() % 1000) * 1000000
                }
              }
            : note
        ),
        loading: false
      }));
    } catch (error) {
      console.error('Error updating note title:', error);
      set({ 
        error: 'Failed to update note title', 
        loading: false 
      });
      throw error;
    }
  },

  deleteNotebook: async (notebookId: string) => {
    try {
      set({ loading: true, error: null });
      await notebookService.deleteNotebook(notebookId);
      set(state => ({
        currentNotes: state.currentNotes.filter(note => note.notebookId !== notebookId)
      }));
      await get().fetchNotebooks();
      set({ loading: false });
    } catch (error) {
      console.error('Error deleting notebook:', error);
      set({ 
        error: 'Failed to delete notebook', 
        loading: false 
      });
      throw error;
    }
  },

  clearNotebooks: () => {
    set({ 
      notebooks: [], 
      currentNotes: [], 
      loading: false, 
      error: null 
    });
  },

  isLoading: () => {
    return get().loading;
  },

  clearNotebookNotes: (notebookId: string) => {
    set(state => ({
      currentNotes: state.currentNotes.filter(note => note.notebookId !== notebookId)
    }));
  },

  removeNote: async (noteId: string) => {
    try {
      set({ loading: true, error: null });
      await notebookService.deleteNote(noteId);
      set(state => ({
        currentNotes: state.currentNotes.filter(note => note.id !== noteId)
      }));
      set({ loading: false });
    } catch (error) {
      console.error('Error removing note:', error);
      set({ 
        error: 'Failed to remove note', 
        loading: false 
      });
      throw error;
    }
  }
})); 