import { useTheme } from '../../contexts/ThemeContext'

export function Loader() {
  const { colors } = useTheme()
  
  return (
    <div className="flex items-center justify-center min-h-[200px]">
      <div 
        className="animate-spin rounded-full h-12 w-12"
        style={{ 
          borderWidth: '4px',
          borderRightColor: colors.primary,
          borderTopColor: colors.primary,
          borderBottomColor: colors.primary,
          borderLeftColor: 'transparent'
        }}
      />
    </div>
  )
} 