import { useState } from 'react'
import { Editor } from '@tiptap/react'
import { useTheme } from '../../contexts/ThemeContext'
import { useAIStore } from '../../store/useAIStore'
import { useToast } from '../ui/use-toast'
import { Button } from '../ui/button'
import { Textarea } from '../ui/textarea'
import {
  PiLightningBold,
  PiTextAaBold,
  PiTranslateBold,
  PiSparkleBold,
  PiTextColumnsBold,
  PiArrowsDownUpBold,
} from 'react-icons/pi'
import { processEditorText } from '../../services/ai/editorAIService'

interface AIEditorHelperProps {
  editor: Editor
  isVisible: boolean
  onClose: () => void
}

type AIAction = 'improve' | 'rephrase' | 'translate' | 'summarize' | 'expand' | 'tone'

// Add interfaces for the structured options
interface TranslateOption {
  value: string
  label: string
}

interface ToneOption {
  value: string
  label: string
  description: string
}

interface RephraseOption {
  value: string
  label: string
  description: string
}

// Add the options
const translateOptions: TranslateOption[] = [
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'zh', label: 'Chinese' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'en', label: 'English' }
]

const toneOptions: ToneOption[] = [
  { value: 'professional', label: 'Professional', description: 'Formal and business-like' },
  { value: 'casual', label: 'Casual', description: 'Relaxed and friendly' },
  { value: 'academic', label: 'Academic', description: 'Scholarly and technical' },
  { value: 'creative', label: 'Creative', description: 'Imaginative and expressive' },
  { value: 'persuasive', label: 'Persuasive', description: 'Convincing and compelling' }
]

const rephraseOptions: RephraseOption[] = [
  { value: 'simpler', label: 'Simplify', description: 'Make it easier to understand' },
  { value: 'formal', label: 'Formalize', description: 'Make it more professional' },
  { value: 'concise', label: 'Condense', description: 'Make it more concise' },
  { value: 'engaging', label: 'Engage', description: 'Make it more interesting' }
]

// Add after the options arrays
interface AIActionButton {
  id: AIAction
  icon: React.ReactNode
  label: string
  description: string
}

const actions: AIActionButton[] = [
  {
    id: 'improve',
    icon: <PiSparkleBold className="w-4 h-4" />,
    label: 'Improve Writing',
    description: 'Enhance clarity and style'
  },
  {
    id: 'rephrase',
    icon: <PiTextAaBold className="w-4 h-4" />,
    label: 'Rephrase',
    description: 'Choose rewriting style'
  },
  {
    id: 'translate',
    icon: <PiTranslateBold className="w-4 h-4" />,
    label: 'Translate',
    description: 'Convert to another language'
  },
  {
    id: 'summarize',
    icon: <PiLightningBold className="w-4 h-4" />,
    label: 'Summarize',
    description: 'Create concise summary'
  },
  {
    id: 'expand',
    icon: <PiTextColumnsBold className="w-4 h-4" />,
    label: 'Expand',
    description: 'Add more detail'
  },
  {
    id: 'tone',
    icon: <PiArrowsDownUpBold className="w-4 h-4" />,
    label: 'Adjust Tone',
    description: 'Change writing style'
  }
]

export function AIEditorHelper({ editor, isVisible, onClose }: AIEditorHelperProps) {
  const { colors } = useTheme()
  const { toast } = useToast()
  const { apiKey: userApiKey, assistantSettings } = useAIStore()
  // Add built-in API key
  const builtInApiKey = import.meta.env.VITE_OPENAI_API_KEY
  
  // Use user's API key if provided, otherwise use built-in key
  const apiKey = userApiKey || builtInApiKey

  const [selectedAction, setSelectedAction] = useState<AIAction | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [customPrompt, setCustomPrompt] = useState('')
  // Add states for options
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [selectedTone, setSelectedTone] = useState<string>('')
  const [selectedRephrase, setSelectedRephrase] = useState<string>('')
  // Store selection
  const [storedSelection, setStoredSelection] = useState<{ from: number; to: number } | null>(null)

  if (!isVisible) return null

  const getSelectedText = () => {
    if (storedSelection) {
      return editor.state.doc.textBetween(storedSelection.from, storedSelection.to)
    }
    const { from, to } = editor.state.selection
    return editor.state.doc.textBetween(from, to)
  }

  const handleAction = async (action: AIAction) => {
    const { from, to } = editor.state.selection
    const selectedText = editor.state.doc.textBetween(from, to)
    
    if (!selectedText) {
      toast({
        title: 'No Text Selected',
        description: 'Please select some text to use this feature.',
        variant: 'destructive'
      })
      return
    }

    setStoredSelection({ from, to })
    setSelectedAction(action)
  }

  const processText = async () => {
    if (!selectedAction || !storedSelection) {
      return
    }

    try {
      setIsProcessing(true)
      const selectedText = getSelectedText()
      
      if (!selectedText) {
        toast({
          title: 'No Text Selected',
          description: 'Please select some text to use this feature.',
          variant: 'destructive'
        })
        return
      }

      // Get the appropriate prompt based on action type
      let finalPrompt = ''
      switch (selectedAction) {
        case 'translate':
          if (!selectedLanguage) {
            throw new Error('Please select a target language')
          }
          finalPrompt = selectedLanguage
          break
        case 'tone':
          if (!selectedTone) {
            throw new Error('Please select a tone style')
          }
          finalPrompt = selectedTone
          break
        case 'rephrase':
          if (!selectedRephrase) {
            throw new Error('Please select a rephrasing style')
          }
          finalPrompt = selectedRephrase
          break
        default:
          finalPrompt = customPrompt
      }

      const result = await processEditorText({
        text: selectedText,
        action: selectedAction,
        customPrompt: finalPrompt,
        apiKey,
        assistantSettings
      })

      if (!result) {
        throw new Error('Failed to process text')
      }

      // Update the editor content first
      editor
        .chain()
        .focus()
        .deleteRange({ from: storedSelection.from, to: storedSelection.to })
        .insertContent(result)
        .run()

      // Show success message
      toast({
        title: 'Success',
        description: 'Text has been updated successfully.',
      })

      // Close the AI helper
      onClose()
      
    } catch (error) {
      // More specific error handling
      let errorMessage = 'Failed to process text'
      if (error instanceof Error) {
        // Handle specific error types
        if (error.message.includes('permissions')) {
          errorMessage = 'You don\'t have permission to perform this action'
        } else if (error.message.includes('network')) {
          errorMessage = 'Network error occurred. Please try again'
        } else {
          errorMessage = error.message
        }
      }
      
      toast({
        title: 'Processing Error',
        description: errorMessage,
        variant: 'destructive'
      })
    } finally {
      setIsProcessing(false)
      setSelectedAction(null)
      setCustomPrompt('')
      setStoredSelection(null)
      setSelectedLanguage('')
      setSelectedTone('')
      setSelectedRephrase('')
    }
  }

  // Render different content based on selected action
  const renderActionContent = () => {
    switch (selectedAction) {
      case 'translate':
        return (
          <select
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="w-full p-2 rounded-md mb-3"
            style={{
              backgroundColor: colors.background,
              color: colors.text,
              borderColor: colors.border
            }}
          >
            <option value="">Select language...</option>
            {translateOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )

      case 'tone':
        return (
          <div className="space-y-2 mb-3">
            {toneOptions.map((option) => (
              <div
                key={option.value}
                className="flex items-center space-x-2 p-2 rounded-md cursor-pointer"
                style={{
                  backgroundColor: selectedTone === option.value ? `${colors.primary}20` : colors.background,
                  border: `1px solid ${colors.border}`
                }}
                onClick={() => setSelectedTone(option.value)}
              >
                <div className="flex-1">
                  <div style={{ color: colors.text }}>{option.label}</div>
                  <div className="text-sm" style={{ color: colors.textSecondary }}>
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )

      case 'rephrase':
        return (
          <div className="space-y-2 mb-3">
            {rephraseOptions.map((option) => (
              <div
                key={option.value}
                className="flex items-center space-x-2 p-2 rounded-md cursor-pointer"
                style={{
                  backgroundColor: selectedRephrase === option.value ? `${colors.primary}20` : colors.background,
                  border: `1px solid ${colors.border}`
                }}
                onClick={() => setSelectedRephrase(option.value)}
              >
                <div className="flex-1">
                  <div style={{ color: colors.text }}>{option.label}</div>
                  <div className="text-sm" style={{ color: colors.textSecondary }}>
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )

      case 'improve':
        return (
          <Textarea
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            placeholder="Add specific instructions for improvement..."
            className="w-full mb-3"
            rows={3}
            style={{
              backgroundColor: colors.background,
              color: colors.text,
              borderColor: colors.border
            }}
          />
        )

      case 'summarize':
      case 'expand':
        return (
          <div className="mb-3 text-sm" style={{ color: colors.textSecondary }}>
            Click "Apply Changes" to {selectedAction === 'summarize' ? 'create a concise summary' : 'expand the content'}...
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div 
      className="w-80 rounded-lg shadow-lg p-4"
      style={{ 
        backgroundColor: colors.surface,
        border: `1px solid ${colors.border}`
      }}
    >
      {!selectedAction ? (
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <h3 className="font-medium" style={{ color: colors.text }}>
              AI Writing Assistant
            </h3>
            <Button
              variant="ghost"
              size="sm"
              onClick={onClose}
              style={{ color: colors.textSecondary }}
            >
              ×
            </Button>
          </div>
          
          <div className="grid grid-cols-2 gap-2">
            {actions.map((action) => (
              <Button
                key={action.id}
                variant="outline"
                className="flex flex-col items-center justify-center h-24 p-2 gap-1"
                onClick={() => handleAction(action.id)}
                style={{
                  borderColor: colors.border,
                  backgroundColor: colors.background
                }}
              >
                {action.icon}
                <span style={{ color: colors.text }}>{action.label}</span>
                <span 
                  className="text-xs text-center"
                  style={{ color: colors.textSecondary }}
                >
                  {action.description}
                </span>
              </Button>
            ))}
          </div>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setSelectedAction(null)}
              style={{ color: colors.textSecondary }}
            >
              ←
            </Button>
            <h3 className="font-medium" style={{ color: colors.text }}>
              {selectedAction ? actions.find(a => a.id === selectedAction)?.label : 'AI Writing Assistant'}
            </h3>
            <Button
              variant="ghost"
              size="sm"
              onClick={onClose}
              style={{ color: colors.textSecondary }}
            >
              ×
            </Button>
          </div>

          {renderActionContent()}

          <Button
            className="w-full"
            onClick={processText}
            disabled={isProcessing}
            style={{
              backgroundColor: colors.primary,
              color: 'white'
            }}
          >
            {isProcessing ? 'Processing...' : 'Apply Changes'}
          </Button>
        </div>
      )}
    </div>
  )
} 