import { useEffect, useState } from 'react';
import { useNotebookStore } from '../store/useNotebookStore';
import { useStore } from '../store/useStore';
import { useTheme } from '../contexts/ThemeContext';
import { NotebookList } from '../components/notebooks/NotebookList';
import { CreateNotebookModal } from '../components/notebooks/CreateNotebookModal';
import { useTranslation } from 'react-i18next';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { Loader } from '../components/shared/Loader';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PiNotebook, 
  PiPlus, 
  PiSpinner,
  PiBooks,
  PiNotePencil
} from 'react-icons/pi'
import { AppError } from '../utils/errors';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}

export function NotebookPage() {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { user, isInitialized } = useStore();
  const { 
    notebooks,
    fetchNotebooks,
    createNotebook,
    loading,
  } = useNotebookStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();

  useEffect(() => {
    document.title = 'Notebooks | Tydy';
  }, []);

  useEffect(() => {
    async function loadNotebooks() {
      if (!isInitialized || !user) return;
      try {
        await fetchNotebooks();
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'NotebookPage.loadNotebooks');
        }
      }
    }

    loadNotebooks();
  }, [user, isInitialized, fetchNotebooks, showError]);

  const handleCreateNotebook = async () => {
    if (!newTitle.trim() || !user) return;
    try {
      await createNotebook(newTitle.trim());
      setNewTitle('');
      setIsModalOpen(false);
    } catch (err) {
      if (err instanceof AppError && err.code !== 'ACTIVITY_ERROR') {
        showError(err, 'NotebookPage.handleCreateNotebook');
      } else {
        setNewTitle('');
        setIsModalOpen(false);
      }
    }
  };

  if (!isInitialized || loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <PiSpinner className="h-8 w-8" style={{ color: colors.primary }} />
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div 
      className="space-y-6 p-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, y: 20 }}
    >
      <motion.div 
        className="flex items-center justify-between"
        variants={itemVariants}
      >
        <div className="flex items-center gap-2">
          <PiBooks className="h-6 w-6" style={{ color: colors.primary }} />
          <h1 
            className="text-2xl font-semibold"
            style={{ color: colors.primary }}
          >
            {t('notebooks.title')}
          </h1>
        </div>
        <motion.button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 rounded-md transition-colors flex items-center gap-2"
          style={{
            backgroundColor: colors.primary,
            color: 'white'
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <PiPlus className="h-5 w-5" />
          {t('notebooks.new')}
        </motion.button>
      </motion.div>

      {notebooks.length === 0 ? (
        <motion.div 
          className="text-center py-12"
          variants={itemVariants}
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <PiNotePencil className="h-12 w-12 mx-auto mb-4" style={{ color: colors.textSecondary }} />
          </motion.div>
          <p 
            className="text-lg mb-4"
            style={{ color: colors.textSecondary }}
          >
            {t('notebooks.empty')}
          </p>
          <p style={{ color: colors.textSecondary }}>
            {t('notebooks.createFirst')}
          </p>
        </motion.div>
      ) : (
        <motion.div variants={itemVariants}>
          <NotebookList notebooks={notebooks} />
        </motion.div>
      )}

      <AnimatePresence>
        {isModalOpen && (
          <CreateNotebookModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setNewTitle('');
            }}
            onSubmit={handleCreateNotebook}
            title={newTitle}
            onTitleChange={setNewTitle}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && (
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
} 