import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { CalendarEvent } from '../../types/calendar';
import { format, isSameDay, isWithinInterval, startOfDay, endOfDay, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface CalendarProps {
  events?: CalendarEvent[];
  onDateSelect?: (date: Date) => void;
  onEventClick?: (event: CalendarEvent) => void;
  selectedDate?: Date | null;
  isLoading?: boolean;
  onMonthChange?: (date: Date) => void;
}

export function Calendar({ events = [], onDateSelect, onEventClick, selectedDate, isLoading, onMonthChange }: CalendarProps) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(new Date());
  const today = new Date();
  const navigate = useNavigate();

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const monthName = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();

  const handlePrevMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    setCurrentDate(newDate)
    onMonthChange?.(newDate)
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    setCurrentDate(newDate)
    onMonthChange?.(newDate)
  };

  const isToday = (day: number) => {
    return (
      today.getDate() === day &&
      today.getMonth() === currentDate.getMonth() &&
      today.getFullYear() === currentDate.getFullYear()
    );
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getEventsForDay = (date: Date) => {
    return events.filter(event => {
      const eventStart = startOfDay(event.date);
      const eventEnd = event.endDate ? endOfDay(event.endDate) : eventStart;
      const dayDate = startOfDay(date);

      return isWithinInterval(dayDate, { start: eventStart, end: eventEnd });
    });
  };

  const getEventColor = (event: CalendarEvent) => {
    switch (event.type) {
      case 'subscription-renewal':
        return colors.error // Red for upcoming payments
      case 'transaction-upcoming':
        return colors.warning // Orange for upcoming transactions
      case 'todo':
        return colors.info // Blue for todos
      case 'event':
        return colors.primary // Primary color for regular events
      default:
        return colors.textSecondary
    }
  };

  const isEventFirstDay = (event: CalendarEvent, date: Date) => {
    return isSameDay(event.date, date);
  };

  const isEventLastDay = (event: CalendarEvent, date: Date) => {
    return event.endDate ? isSameDay(event.endDate, date) : isSameDay(event.date, date);
  };

  const getEventIndicatorStyle = (event: CalendarEvent) => {
    switch (event.type) {
      case 'todo':
        return {
          background: `linear-gradient(45deg, ${colors.primary}40, ${colors.secondary}40)`,
          borderLeft: `3px solid ${colors.primary}`,
          borderRadius: '0.25rem',
          cursor: 'pointer',
          opacity: event.completed ? 0.5 : 1
        }
      case 'subscription':
        return {
          background: `repeating-linear-gradient(
            45deg,
            ${colors.error},
            ${colors.error} 4px,
            ${colors.error}90 4px,
            ${colors.error}90 8px
          )`,
          borderRadius: '0.25rem',
          cursor: 'pointer',
          opacity: 0.9
        }
      case 'recurring-transaction':
        return {
          background: `repeating-linear-gradient(
            -45deg,
            ${colors.warning},
            ${colors.warning} 4px,
            ${colors.warning}90 4px,
            ${colors.warning}90 8px
          )`,
          borderRadius: '0.25rem',
          cursor: 'pointer'
        }
      default:
        return {
          backgroundColor: event.color || colors.primary,
          borderRadius: '0.25rem',
          cursor: 'pointer'
        }
    }
  }

  const handleEventClick = (event: CalendarEvent, e: React.MouseEvent) => {
    e.stopPropagation()
    onEventClick?.(event)
  }

  const renderEventActions = (event: CalendarEvent) => {
    if (event.source === 'subscription') {
      return (
        <div className="text-sm" style={{ color: colors.textSecondary }}>
          {t('finance.subscriptions.manageInFinance')}
        </div>
      )
    }
    
    if (event.type === 'todo') {
      return (
        <div 
          className="flex flex-col gap-2 p-2 rounded-md cursor-pointer"
          style={{ 
            background: `linear-gradient(45deg, ${colors.primary}10, ${colors.secondary}10)`,
            borderLeft: `3px solid ${colors.primary}`
          }}
          onClick={() => navigate(`/lists?todoId=${event.sourceId}`)}
        >
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium" style={{ color: colors.text }}>
              {event.title}
            </span>
            {event.completed && (
              <span className="text-xs px-2 py-1 rounded" style={{ backgroundColor: `${colors.success}20`, color: colors.success }}>
                Completed
              </span>
            )}
          </div>
          {event.description && (
            <p className="text-xs" style={{ color: colors.textSecondary }}>
              {event.description}
            </p>
          )}
          <div className="flex justify-between items-center mt-1">
            {event.priority && (
              <span 
                className="text-xs px-2 py-1 rounded"
                style={{ 
                  backgroundColor: 
                    event.priority === 'high' ? `${colors.error}20` :
                    event.priority === 'medium' ? `${colors.warning}20` :
                    `${colors.info}20`,
                  color:
                    event.priority === 'high' ? colors.error :
                    event.priority === 'medium' ? colors.warning :
                    colors.info
                }}
              >
                {event.priority.charAt(0).toUpperCase() + event.priority.slice(1)} Priority
              </span>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="flex gap-2">
        <button 
          onClick={() => onEventClick?.(event)}
          className="text-sm"
          style={{ color: colors.primary }}
        >
          {t('common.edit')}
        </button>
      </div>
    )
  }

  // Simplify event label to just show the title
  const getEventLabel = (event: CalendarEvent) => {
    return event.title
  }

  return (
    <div className="w-full max-w-3xl mx-auto">
      {/* Calendar Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 
          className="text-2xl font-semibold"
          style={{ color: colors.primary }}
        >
          {monthName} {year}
        </h2>
        <div className="flex items-center gap-4">
          <button
            onClick={handlePrevMonth}
            className="p-2 rounded-full transition-colors hover:bg-opacity-10"
            style={{ color: colors.textSecondary }}
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          <button
            onClick={() => setCurrentDate(new Date())}
            className="px-3 py-1 rounded-md text-sm"
            style={{ 
              backgroundColor: `${colors.primary}10`,
              color: colors.primary
            }}
          >
            Today
          </button>
          <button
            onClick={handleNextMonth}
            className="p-2 rounded-full transition-colors hover:bg-opacity-10"
            style={{ color: colors.textSecondary }}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Calendar Grid */}
      <div 
        className="rounded-lg p-4"
        style={{ backgroundColor: colors.surface }}
      >
        {/* Week days header */}
        <div className="grid grid-cols-7 mb-2">
          {weekDays.map(day => (
            <div
              key={day}
              className="text-center py-2 text-sm font-medium"
              style={{ color: colors.textSecondary }}
            >
              {day}
            </div>
          ))}
        </div>

        {/* Calendar days */}
        <div className="grid grid-cols-7 gap-1">
          {/* Empty cells for days before the first day of month */}
          {Array.from({ length: firstDayOfMonth }).map((_, index) => (
            <div key={`empty-${index}`} className="aspect-square" />
          ))}

          {/* Days with events */}
          {Array.from({ length: daysInMonth }).map((_, index) => {
            const day = index + 1;
            const date = new Date(year, currentDate.getMonth(), day);
            const isCurrentDay = isToday(day);
            const isSelected = selectedDate && isSameDay(date, selectedDate);
            const dayEvents = getEventsForDay(date);

            return (
              <div
                key={day}
                className="aspect-square p-1"
              >
                <button
                  onClick={() => onDateSelect?.(date)}
                  className={`
                    w-full h-full flex flex-col items-start justify-start 
                    rounded-lg relative transition-all p-1
                    hover:bg-opacity-15 hover:bg-primary
                    ${isSelected ? 'ring-2' : ''}
                  `}
                  style={{
                    backgroundColor: isSelected 
                      ? `${colors.primary}10`
                      : 'transparent',
                    color: colors.text,
                    ringColor: colors.primary
                  }}
                >
                  <div className="w-full flex justify-between items-start">
                    <span className={isSelected ? 'font-semibold' : ''}>
                      {day}
                    </span>
                    {isCurrentDay && (
                      <span 
                        className="text-[8px] md:text-[10px] font-medium px-0.5 md:px-1 rounded"
                        style={{ 
                          backgroundColor: colors.primary,
                          color: '#fff'
                        }}
                      >
                        Today
                      </span>
                    )}
                  </div>
                  
                  {/* Event indicators with click handlers */}
                  {dayEvents.length > 0 && (
                    <div className="absolute bottom-1 flex gap-1 justify-center w-full">
                      {dayEvents.slice(0, 3).map(event => (
                        <div
                          key={event.id}
                          className="w-1.5 h-1.5 rounded-full hover:scale-150 transition-transform cursor-pointer"
                          style={getEventIndicatorStyle(event)}
                          onClick={(e) => handleEventClick(event, e)}
                          title={event.title}
                        />
                      ))}
                      {dayEvents.length > 3 && (
                        <span 
                          className="text-xs leading-none"
                          style={{ color: colors.textSecondary }}
                        >
                          +{dayEvents.length - 3}
                        </span>
                      )}
                    </div>
                  )}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
} 