import { encryptionService } from '../services/encryptionService'
import CryptoJS from 'crypto-js'

// Add more sensitive fields to this array
const SENSITIVE_FIELDS = [
  'amount',
  'description',
  'notes',
  'categoryId'
];

export function withEncryption<T extends Record<string, any>>(
  data: T,
  fields: string[] = SENSITIVE_FIELDS, // Default to all sensitive fields
  key: string,
  isDecrypting: boolean
): T {
  const result = { ...data }

  fields.forEach(field => {
    // Only encrypt/decrypt if the field has a value
    if (result[field] !== undefined && result[field] !== null && result[field] !== '') {
      try {
        if (isDecrypting) {
          if (typeof result[field] === 'string') {
            let decrypted = result[field]
            
            // Handle legacy format
            if (decrypted.startsWith('ENC_V1_')) {
              const legacyJson = decrypted.substring(7)
              const legacyData = JSON.parse(legacyJson)
              decrypted = legacyData.data
            }

            // Decrypt the value
            decrypted = encryptionService.decrypt(decrypted, key)

            // For amounts, parse the number
            if (field === 'amount') {
              const num = Number(decrypted)
              result[field] = isNaN(num) ? 0 : num
            } else {
              result[field] = decrypted
            }
          }
        } else {
          // If encrypting, convert to string first
          const stringValue = result[field].toString()
          if (!encryptionService.isEncrypted(stringValue)) {
            result[field] = encryptionService.encrypt(stringValue, key)
          }
        }
      } catch (error) {
        console.error(`Error processing field ${field}:`, error)
        if (isDecrypting) {
          result[field] = field === 'amount' ? 0 : null
        }
      }
    }
  })

  return result
} 