import { 
  collection, 
  query, 
  where, 
  orderBy, 
  limit, 
  addDoc,
  getDocs,
  Timestamp,
  serverTimestamp,
  FirestoreError,
  writeBatch,
  startAfter,
  doc,
  updateDoc,
  deleteDoc,
  Firestore,
  DocumentReference,
  Query
} from 'firebase/firestore'
import { db, auth } from '../utils/firebase'
import type { Activity, ActivityType, ActivityFilters, FirestoreActivity, ActivityMetadata } from '../types/activity'
import { nanoid } from 'nanoid'
import { AppError } from '../utils/errors'
import { withEncryption } from '../utils/encryption'
import { encryptionService } from './encryptionService'
import { useStore } from '../store/useStore'

import { 
  ACTIVITY_CACHE_LIMIT, 
  ACTIVITY_MAX_AGE_DAYS,
  ACTIVITY_DISPLAY_LIMIT
} from '../types/activity'
import { subDays } from 'date-fns'

const localActivityCache = new Map<string, Activity[]>()

class ActivityError extends Error {
  constructor(message: string, public code?: string) {
    super(message)
    this.name = 'ActivityError'
  }
}

// Helper to remove undefined fields from an object
function removeUndefinedFields<T extends Record<string, any>>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  ) as Partial<T>
}

// Add type assertion
const firestore = db as Firestore

export const activityService = {
  async createActivity(
    type: string,
    title: string,
    description: string,
    metadata: Record<string, unknown>,
    entityId: string,
    entityType: string
  ): Promise<void> {
    try {
      // Get current user ID from auth instead of store
      const userId = auth.currentUser?.uid

      const activityData = {
        type,
        title,
        description,
        metadata,
        entityId,
        entityType,
        userId, // Use the userId from auth
        createdAt: serverTimestamp()
      }

      await addDoc(collection(db, 'activities'), activityData)
    } catch (error) {
      console.warn('Failed to create activity:', error)
      // Don't throw - activity creation is non-critical
    }
  },

  archiveActivity: async (activityId: string): Promise<void> => {
    try {
      if (!auth.currentUser) {
        throw new AppError('Not authenticated', 'auth/not-authenticated', 401)
      }

      const activityRef = doc(firestore, 'activities', activityId)
      await updateDoc(activityRef, {
        isArchived: true,
        updatedAt: serverTimestamp()
      })
    } catch (error) {
      if (error instanceof FirestoreError) {
        throw new AppError(
          'Failed to archive activity: ' + error.message,
          `activities/${error.code}`,
          500
        )
      }
      if (error instanceof AppError) {
        throw error
      }
      throw new AppError('Failed to archive activity', 'activities/unknown', 500)
    }
  },

  deleteActivity: async (activityId: string): Promise<void> => {
    try {
      const userId = auth.currentUser?.uid
      if (!userId) {
        throw new AppError('Not authenticated', 'auth/not-authenticated', 401)
      }

      // Delete directly using document ID
      const activityRef = doc(firestore, 'activities', activityId)
      await deleteDoc(activityRef)

      // Update local cache
      const cachedActivities = localActivityCache.get(userId) || []
      const updatedCache = cachedActivities.filter(activity => activity.id !== activityId)
      localActivityCache.set(userId, updatedCache)

    } catch (error) {
      console.error('Error deleting activity:', error)
      if (error instanceof FirestoreError) {
        throw new AppError(
          'Failed to delete activity: ' + error.message,
          `activities/${error.code}`,
          500
        )
      }
      if (error instanceof AppError) {
        throw error
      }
      throw new AppError('Failed to delete activity', 'activities/unknown', 500)
    }
  },

  cleanupOldActivities: async (): Promise<void> => {
    try {
      const userId = auth.currentUser?.uid
      if (!userId) {
        throw new AppError('Not authenticated', 'auth/not-authenticated', 401)
      }

      // First, delete activities older than max age
      const cutoffDate = subDays(new Date(), ACTIVITY_MAX_AGE_DAYS)
      
      // Get all activities sorted by date
      const activitiesQuery = query(
        collection(firestore, 'activities'),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      )

      const snapshot = await getDocs(activitiesQuery)
      const batch = writeBatch(firestore)
      
      // Delete all activities beyond the first ACTIVITY_DISPLAY_LIMIT
      snapshot.docs.forEach((doc, index) => {
        // Keep only the ACTIVITY_DISPLAY_LIMIT most recent activities
        if (index >= ACTIVITY_DISPLAY_LIMIT) {
          batch.delete(doc.ref)
        }
        
        // Also delete if older than cutoff date
        const createdAt = doc.data().createdAt?.toDate()
        if (createdAt && createdAt <= cutoffDate) {
          batch.delete(doc.ref)
        }
      })

      await batch.commit()

      // Update local cache to match
      const remainingActivities = snapshot.docs
        .slice(0, ACTIVITY_DISPLAY_LIMIT)
        .map(doc => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate(),
        } as Activity))
        .filter(activity => activity.createdAt > cutoffDate)

      localActivityCache.set(userId, remainingActivities)

    } catch (error) {
      console.error('Error cleaning up old activities:', error)
      // Don't throw - this is a background operation
    }
  },

  async getRecentActivities(): Promise<void> {
    // Temporarily disabled
    return;
  },

  restoreActivity: async (activityId: string): Promise<void> => {
    try {
      if (!auth.currentUser) {
        throw new AppError('Not authenticated', 'auth/not-authenticated', 401)
      }

      const activityRef = doc(firestore, 'activities', activityId)
      await updateDoc(activityRef, {
        isArchived: false,
        updatedAt: serverTimestamp()
      })
    } catch (error) {
      if (error instanceof FirestoreError) {
        throw new AppError(
          'Failed to restore activity: ' + error.message,
          `activities/${error.code}`,
          500
        )
      }
      if (error instanceof AppError) {
        throw error
      }
      throw new AppError('Failed to restore activity', 'activities/unknown', 500)
    }
  },

  // One-time cleanup - call this once to reset everything
  resetAllActivities: async (): Promise<void> => {
    try {
      const userId = auth.currentUser?.uid
      if (!userId) return

      const activitiesQuery = query(
        collection(firestore, 'activities'),
        where('userId', '==', userId)
      )

      const snapshot = await getDocs(activitiesQuery)
      const batch = writeBatch(firestore)
      
      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref)
      })

      await batch.commit()
      localActivityCache.clear()
      
    } catch (error) {
      console.error('Error resetting activities:', error)
    }
  },

  async getUserActivities(userId: string, options: { limit?: number } = {}): Promise<Activity[]> {
    try {
      if (!userId) {
        throw new AppError('User ID is required', 'INVALID_PARAMETERS');
      }

      // Create a query against the activities collection
      const activitiesRef = collection(firestore, 'activities');
      const activitiesQuery = query(
        activitiesRef,
        where('userId', '==', userId),
        orderBy('createdAt', 'desc'),
        ...(options.limit ? [limit(options.limit)] : [])
      );

      const snapshot = await getDocs(activitiesQuery);
      
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate()
      })) as Activity[];

    } catch (error) {
      console.error('Error fetching activities:', error);
      throw new AppError('ACTIVITY_FETCH_ERROR', 'Failed to fetch activities');
    }
  }
} 