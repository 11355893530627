import { Achievement } from '../types/gamification';
import { achievementColors } from '../theme/colors';

export const ACHIEVEMENTS: Achievement[] = [
  // Streak Achievements
  {
    id: 'first-streak',
    title: 'Getting Started',
    description: 'Maintain any streak for 3 days',
    category: 'streak',
    icon: 'PiFireSimple',
    requirement: 3,
    color: achievementColors.streak,
    trackerType: 'any',
    xpReward: 50
  },
  {
    id: 'week-warrior',
    title: 'Week Warrior',
    description: 'Maintain any streak for 7 days',
    category: 'streak',
    icon: 'PiFire',
    requirement: 7,
    color: achievementColors.streak,
    trackerType: 'any',
    xpReward: 100
  },
  {
    id: 'fortnight-focus',
    title: 'Fortnight Focus',
    description: 'Maintain any streak for 14 days',
    category: 'streak',
    icon: 'PiFlame',
    requirement: 14,
    color: achievementColors.streak,
    trackerType: 'any',
    xpReward: 200
  },
  {
    id: 'monthly-master',
    title: 'Monthly Master',
    description: 'Maintain any streak for 30 days',
    category: 'streak',
    icon: 'PiFireSimple',
    requirement: 30,
    color: achievementColors.streak,
    trackerType: 'any',
    xpReward: 500
  },
  {
    id: 'streak-legend',
    title: 'Streak Legend',
    description: 'Maintain any streak for 100 days',
    category: 'streak',
    icon: 'PiFlame',
    requirement: 100,
    color: achievementColors.streak,
    trackerType: 'any',
    xpReward: 1000
  },

  // Notes Achievements
  {
    id: 'first-note',
    title: 'First Thoughts',
    description: 'Create your first note',
    category: 'notes',
    icon: 'PiNotePencil',
    requirement: 1,
    color: achievementColors.notes,
    trackerType: 'note',
    xpReward: 25
  },
  {
    id: 'first-notebook',
    title: 'Getting Organized',
    description: 'Create your first notebook',
    category: 'notes',
    icon: 'PiNotebook',
    requirement: 1,
    color: achievementColors.notes,
    trackerType: 'notebook',
    xpReward: 50
  },
  {
    id: 'note-collector',
    title: 'Note Collector',
    description: 'Create 25 notes',
    category: 'notes',
    icon: 'PiNotePencil',
    requirement: 25,
    color: achievementColors.notes,
    trackerType: 'note',
    xpReward: 100
  },
  {
    id: 'notebook-organizer',
    title: 'Notebook Organizer',
    description: 'Create 5 notebooks',
    category: 'notes',
    icon: 'PiNotebook',
    requirement: 5,
    color: achievementColors.notes,
    trackerType: 'notebook',
    xpReward: 150
  },
  {
    id: 'prolific-writer',
    title: 'Prolific Writer',
    description: 'Create 100 notes',
    category: 'notes',
    icon: 'PiNotePencil',
    requirement: 100,
    color: achievementColors.notes,
    trackerType: 'note',
    xpReward: 300
  },
  {
    id: 'notebook-master',
    title: 'Notebook Master',
    description: 'Create 10 notebooks with at least 5 notes each',
    category: 'notes',
    icon: 'PiNotebook',
    requirement: 10,
    color: achievementColors.notes,
    trackerType: 'notebook',
    xpReward: 500
  },
  {
    id: 'daily-writer',
    title: 'Daily Writer',
    description: 'Create notes for 7 consecutive days',
    category: 'notes',
    icon: 'PiNotePencil',
    requirement: 7,
    color: achievementColors.notes,
    trackerType: 'note',
    xpReward: 150
  },

  // Lists Achievements
  {
    id: 'first-list',
    title: 'List Beginner',
    description: 'Create your first list',
    category: 'lists',
    icon: 'PiListPlus',
    requirement: 1,
    color: achievementColors.lists,
    trackerType: 'lists',
    xpReward: 25
  },
  {
    id: 'task-master',
    title: 'Task Master',
    description: 'Complete 50 list items',
    category: 'lists',
    icon: 'PiListChecks',
    requirement: 50,
    color: achievementColors.lists,
    trackerType: 'lists',
    xpReward: 100
  },
  {
    id: 'list-organizer',
    title: 'List Organizer',
    description: 'Create 10 lists with at least 5 items each',
    category: 'lists',
    icon: 'PiListNumbers',
    requirement: 10,
    color: achievementColors.lists,
    trackerType: 'lists',
    xpReward: 200
  },
  {
    id: 'productivity-pro',
    title: 'Productivity Pro',
    description: 'Complete all items in 5 different lists',
    category: 'lists',
    icon: 'PiCheckSquare',
    requirement: 5,
    color: achievementColors.lists,
    trackerType: 'lists',
    xpReward: 300
  },

  // Finance Achievements
  {
    id: 'finance-starter',
    title: 'Finance Starter',
    description: 'Record your first transaction',
    category: 'finance',
    icon: 'PiCurrencyDollar',
    requirement: 1,
    color: achievementColors.finance,
    trackerType: 'finance',
    xpReward: 25
  },
  {
    id: 'transaction-tracker',
    title: 'Transaction Tracker',
    description: 'Record 25 transactions',
    category: 'finance',
    icon: 'PiReceipt',
    requirement: 25,
    color: achievementColors.finance,
    trackerType: 'finance',
    xpReward: 100
  },
  {
    id: 'finance-enthusiast',
    title: 'Finance Enthusiast',
    description: 'Record 100 transactions',
    category: 'finance',
    icon: 'PiTrendUp',
    requirement: 100,
    color: achievementColors.finance,
    trackerType: 'finance',
    xpReward: 250
  },
  {
    id: 'daily-tracker',
    title: 'Daily Tracker',
    description: 'Record transactions for 7 consecutive days',
    category: 'finance',
    icon: 'PiCalendarCheck',
    requirement: 7,
    color: achievementColors.finance,
    trackerType: 'finance',
    xpReward: 150
  },
  {
    id: 'finance-master',
    title: 'Finance Master',
    description: 'Record transactions for 30 consecutive days',
    category: 'finance',
    icon: 'PiPiggyBank',
    requirement: 30,
    color: achievementColors.finance,
    trackerType: 'finance',
    xpReward: 500
  },

  // Mood Achievements
  {
    id: 'first-mood',
    title: 'Mood Observer',
    description: 'Track your first mood',
    category: 'mood',
    icon: 'PiSmiley',
    requirement: 1,
    color: achievementColors.mood,
    trackerType: 'mood',
    xpReward: 25
  },
  {
    id: 'mood-tracker',
    title: 'Mood Tracker',
    description: 'Track your mood for 7 consecutive days',
    category: 'mood',
    icon: 'PiSmileySad',
    requirement: 7,
    color: achievementColors.mood,
    trackerType: 'mood',
    xpReward: 100
  },
  {
    id: 'mood-master',
    title: 'Mood Master',
    description: 'Track your mood for 30 consecutive days',
    category: 'mood',
    icon: 'PiSmileyWink',
    requirement: 30,
    color: achievementColors.mood,
    trackerType: 'mood',
    xpReward: 300
  },
  {
    id: 'mood-insights',
    title: 'Mood Insights',
    description: 'Add notes to your mood tracking 10 times',
    category: 'mood',
    icon: 'PiNotepad',
    requirement: 10,
    color: achievementColors.mood,
    trackerType: 'mood',
    xpReward: 150
  },

  // General Achievements
  {
    id: 'ai-friend',
    title: 'AI Friend',
    description: 'Name your AI Assistant',
    category: 'general',
    icon: 'PiRobot',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 50
  },
  {
    id: 'chat-starter',
    title: 'Chat Starter',
    description: 'Have your first conversation with the AI',
    category: 'general',
    icon: 'PiChats',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 25
  },
  {
    id: 'chat-enthusiast',
    title: 'Chat Enthusiast',
    description: 'Have 50 conversations with the AI',
    category: 'general',
    icon: 'PiChatCircle',
    requirement: 50,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 200
  },
  {
    id: 'personalizer',
    title: 'Personalizer',
    description: 'Change your app theme',
    category: 'general',
    icon: 'PiPaintBrush',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 50
  },
  {
    id: 'theme-creator',
    title: 'Theme Creator',
    description: 'Create a custom theme',
    category: 'general',
    icon: 'PiPalette',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 100
  },
  {
    id: 'night-mode',
    title: 'Night Mode',
    description: 'Switch to dark mode',
    category: 'general',
    icon: 'PiMoon',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 25
  },
  {
    id: 'polyglot',
    title: 'Polyglot',
    description: 'Change the app language',
    category: 'general',
    icon: 'PiTranslate',
    requirement: 1,
    color: achievementColors.general,
    trackerType: 'any',
    xpReward: 50
  },

  // Hidden Achievements
  {
    id: 'night-owl',
    title: 'Night Owl',
    description: 'Use the app after midnight',
    category: 'hidden',
    icon: 'PiMoon',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Some thoughts come best at night...',
    condition: 'Use app between 12 AM and 4 AM',
    xpReward: 100
  },
  {
    id: 'early-bird',
    title: 'Early Bird',
    description: 'Use the app before 6 AM',
    category: 'hidden',
    icon: 'PiSunHorizon',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'The early bird catches the worm',
    condition: 'Use app between 4 AM and 6 AM',
    xpReward: 100
  },
  {
    id: 'mood-swing',
    title: 'Mood Swing',
    description: 'Track drastically different moods within 12 hours',
    category: 'hidden',
    icon: 'PiSmileyXEyes',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'mood',
    secret: true,
    hint: 'Life has its ups and downs',
    condition: 'Track opposite moods (amazing/terrible) within 12 hours',
    xpReward: 150
  },
  {
    id: 'note-spree',
    title: 'Note Spree',
    description: 'Create 10 notes in under an hour',
    category: 'hidden',
    icon: 'PiNotePencil',
    requirement: 10,
    color: achievementColors.hidden,
    trackerType: 'note',
    secret: true,
    hint: 'When inspiration strikes...',
    condition: 'Create 10 notes within 60 minutes',
    xpReward: 200
  },
  {
    id: 'list-lightning',
    title: 'List Lightning',
    description: 'Complete an entire list within 5 minutes of creating it',
    category: 'hidden',
    icon: 'PiListChecks',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'lists',
    secret: true,
    hint: 'Speed and efficiency go hand in hand',
    condition: 'Complete all items in a list (minimum 5 items) within 5 minutes of creation',
    xpReward: 200
  },
  {
    id: 'finance-frenzy',
    title: 'Finance Frenzy',
    description: 'Record 20 transactions in one sitting',
    category: 'hidden',
    icon: 'PiReceipt',
    requirement: 20,
    color: achievementColors.hidden,
    trackerType: 'finance',
    secret: true,
    hint: 'Sometimes bookkeeping needs a marathon session',
    condition: 'Add 20 transactions within 30 minutes',
    xpReward: 250
  },
  {
    id: 'streak-saver',
    title: 'Streak Saver',
    description: 'Log in at 11:55 PM to maintain a streak',
    category: 'hidden',
    icon: 'PiTimer',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Better late than never',
    condition: 'Maintain any streak by logging in between 11:55 PM and midnight',
    xpReward: 150
  },
  {
    id: 'multilingual-master',
    title: 'Multilingual Master',
    description: 'Change the app language 5 times in one day',
    category: 'hidden',
    icon: 'PiTranslate',
    requirement: 5,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Variety is the spice of life',
    condition: 'Switch between different languages 5 times in 24 hours',
    xpReward: 100
  },
  {
    id: 'indecisive-designer',
    title: 'Indecisive Designer',
    description: 'Change your theme color 10 times in 2 minutes',
    category: 'hidden',
    icon: 'PiPaintBrushHousehold',
    requirement: 10,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Sometimes the perfect color is hard to find...',
    condition: 'Change theme colors 10 times within 2 minutes',
    xpReward: 150
  },
  {
    id: 'speed-reader',
    title: 'Speed Reader',
    description: 'Read through all achievement descriptions in under 30 seconds',
    category: 'hidden',
    icon: 'PiEyeglasses',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Knowledge comes to those who seek it quickly',
    condition: 'View all non-hidden achievement details within 30 seconds',
    xpReward: 200
  },
  {
    id: 'conversation-artist',
    title: 'Conversation Artist',
    description: 'Use emojis in 5 consecutive AI chat messages',
    category: 'hidden',
    icon: 'PiSmileySticker',
    requirement: 5,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Even AIs appreciate a little expression 😊',
    condition: 'Send 5 consecutive chat messages containing emojis',
    xpReward: 100
  },
  {
    id: 'time-traveler',
    title: 'Time Traveler',
    description: 'Change your system time while using the app',
    category: 'hidden',
    icon: 'PiClockCounterClockwise',
    requirement: 1,
    color: achievementColors.hidden,
    trackerType: 'any',
    secret: true,
    hint: 'Time is relative...',
    condition: 'Detected system time change during app usage',
    xpReward: 300
  }
];

// Achievement category metadata
export const ACHIEVEMENT_CATEGORIES = {
  streak: {
    label: 'Streaks',
    description: 'Achievements for maintaining daily streaks',
    icon: 'PiFire',
    color: achievementColors.streak
  },
  notes: {
    label: 'Notes',
    description: 'Achievements for note-taking activities',
    icon: 'PiNotebook',
    color: achievementColors.notes
  },
  lists: {
    label: 'Lists',
    description: 'Achievements for list management',
    icon: 'PiListChecks',
    color: achievementColors.lists
  },
  finance: {
    label: 'Finance',
    description: 'Achievements for financial tracking',
    icon: 'PiPiggyBank',
    color: achievementColors.finance
  },
  mood: {
    label: 'Mood',
    description: 'Achievements for mood tracking',
    icon: 'PiSmiley',
    color: achievementColors.mood
  },
  general: {
    label: 'General',
    description: 'General app achievements',
    icon: 'PiStar',
    color: achievementColors.general
  },
  hidden: {
    label: 'Hidden',
    description: 'Secret achievements to discover',
    icon: 'PiQuestion',
    color: achievementColors.hidden
  }
}; 