"use client"

import { useTheme } from "../../contexts/ThemeContext"
import { useAICreditsStore } from "../../store/useAICreditsStore"
import { useStore } from "../../store/useStore"
import { SUBSCRIPTION_LIMITS } from "../../constants/subscriptionLimits"
import { Button } from "../ui/button"
import { AICreditDisplay } from "../ai/AICreditDisplay"
import { PiCreditCard, PiArrowRight, PiChartLineUp, PiCrown, PiSparkle, PiChatCircleBold, PiArrowClockwise } from "react-icons/pi"
import { useTranslation } from "react-i18next"
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AICreditPurchaseModal } from "../ai/AICreditPurchaseModal"
import { Tooltip } from "recharts"
import { Loader } from "../shared/Loader"
import { cn } from "../../lib/utils"

interface CreditUsage {
  date: string
  amount: number
  action: string
  details?: Record<string, any>
}

export function AICreditManagement() {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { credits, usageHistory, fetchUsageHistory, isLoading } = useAICreditsStore()
  const { user } = useStore()
  const userTier = user?.subscription?.tier || "free"
  const monthlyCredits = SUBSCRIPTION_LIMITS.aiCredits.monthly[userTier]
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<{ amount: number; price: number } | null>(null)
  const [showUsageHistory, setShowUsageHistory] = useState(false)

  // Add force refresh function
  const handleRefreshHistory = () => {
    if (user?.id) {
      fetchUsageHistory(user.id, true) // Force refresh
    }
  }

  // Update useEffect to use cache
  useEffect(() => {
    if (user?.id && showUsageHistory) {
      fetchUsageHistory(user.id) // Will use cache if available
    }
  }, [user?.id, showUsageHistory])

  // Get action icon based on the type
  const getActionIcon = (action: string) => {
    switch (action) {
      case 'chat':
        return <PiChatCircleBold />
      case 'purchase':
        return <PiCreditCard />
      case 'monthly_reset':
        return <PiArrowClockwise />
      default:
        return <PiCreditCard />
    }
  }

  const purchaseOptions = [
    { ...SUBSCRIPTION_LIMITS.aiCredits.purchase.small, icon: PiSparkle },
    { ...SUBSCRIPTION_LIMITS.aiCredits.purchase.medium, icon: PiCreditCard },
    { ...SUBSCRIPTION_LIMITS.aiCredits.purchase.large, icon: PiCrown },
  ]

  const handlePurchaseClick = (option: { amount: number; price: number }) => {
    setSelectedOption(option)
  }

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.3 },
  }

  return (
    <motion.div
      className="space-y-6 p-6 rounded-lg shadow-lg"
      style={{ backgroundColor: colors.surface }}
      initial="initial"
      animate="animate"
      variants={fadeInUp}
    >
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold" style={{ color: colors.primary }}>
          {t("settings.ai.credits.title")}
        </h3>
        <Tooltip content={t("settings.ai.credits.monthlyAllowanceTooltip")}>
          <span
            className="text-sm font-medium px-3 py-1 rounded-full"
            style={{ backgroundColor: `${colors.primary}20`, color: colors.primary }}
          >
            {t("settings.ai.credits.monthlyAllowance", { amount: monthlyCredits })}
          </span>
        </Tooltip>
      </div>

      {/* Credits and History Section */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* Credit Display Card */}
        <motion.div
          key="credit-display"
          className="p-4 rounded-lg"
          style={{ 
            backgroundColor: `${colors.primary}08`,
            borderWidth: 1,
            borderColor: `${colors.primary}20`
          }}
        >
          <div className="mb-4">
            <AICreditDisplay />
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowUsageHistory(!showUsageHistory)}
            className="w-full flex items-center justify-center gap-2"
            style={{ color: colors.text }}
          >
            <PiChartLineUp className="w-4 h-4" style={{ color: colors.text }}/>
            {showUsageHistory ? t("settings.ai.credits.hideUsage") : t("settings.ai.credits.showUsage")}
          </Button>
        </motion.div>

        {/* Usage History Card */}
        <AnimatePresence>
          {showUsageHistory ? (
            <motion.div
              key="usage-history"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="p-4 rounded-lg"
              style={{ 
                backgroundColor: colors.background,
                borderWidth: 1,
                borderColor: colors.border
              }}
            >
              <div className="flex items-center justify-between mb-4">
                <h4 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
                  {t("settings.ai.credits.usageHistory")}
                </h4>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleRefreshHistory}
                  disabled={isLoading}
                  className="p-1"
                >
                  <PiArrowClockwise 
                    className={cn(
                      "w-4 h-4",
                      isLoading && "animate-spin"
                    )}
                    style={{ color: colors.text }}
                  />
                </Button>
              </div>
              <div className="space-y-3">
                {isLoading ? (
                  <div className="flex justify-center py-4">
                    <Loader size="sm" />
                  </div>
                ) : usageHistory.length > 0 ? (
                  usageHistory.map((usage, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between p-2 rounded"
                      style={{ backgroundColor: `${colors.primary}08` }}
                    >
                      <div className="flex items-center gap-3">
                        <div 
                          className="w-8 h-8 rounded-full flex items-center justify-center"
                          style={{ backgroundColor: `${colors.primary}15` }}
                        >
                          {getActionIcon(usage.action)}
                        </div>
                        <div>
                          <span style={{ color: colors.text }}>{usage.date}</span>
                          <span 
                            className="text-xs block"
                            style={{ color: colors.textSecondary }}
                          >
                            {t(`settings.ai.credits.actions.${usage.action}`)}
                          </span>
                        </div>
                      </div>
                      <span 
                        className="font-medium"
                        style={{ color: colors.primary }}
                      >
                        {usage.action === 'purchase' ? '+' : '-'}{usage.amount}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-center py-4" style={{ color: colors.textSecondary }}>
                    {t("settings.ai.credits.noHistory")}
                  </p>
                )}
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="usage-history-placeholder"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="p-4 rounded-lg border border-dashed flex items-center justify-center"
              style={{ borderColor: colors.border }}
            >
              <p className="text-sm" style={{ color: colors.textSecondary }}>
                {t("settings.ai.credits.viewHistory")}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Purchase Options Section */}
      <div className="space-y-4 mt-6">
        <h4 className="text-sm font-medium" style={{ color: colors.textSecondary }}>
          {t("settings.ai.credits.purchaseOptions")}
        </h4>
        <div className="grid md:grid-cols-3 gap-3">
          {purchaseOptions.map((option, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="p-4 rounded-lg flex flex-col gap-3 transition-colors text-left"
              style={{
                backgroundColor: colors.surface,
                borderColor: colors.border,
                borderWidth: 1,
              }}
              onClick={() => handlePurchaseClick(option)}
            >
              <div 
                className="w-10 h-10 rounded-full flex items-center justify-center"
                style={{ backgroundColor: `${colors.primary}15` }}
              >
                <option.icon size={24} style={{ color: colors.primary }} />
              </div>
              <div>
                <div className="font-medium mb-1" style={{ color: colors.text }}>
                  {option.amount} {t("settings.ai.credits.credits")}
                </div>
                <div className="text-sm" style={{ color: colors.textSecondary }}>
                  ${option.price}
                </div>
              </div>
            </motion.button>
          ))}
        </div>
      </div>

      {/* Upgrade CTA */}
      {userTier === "free" && (
        <motion.div
          className="p-4 rounded-lg mt-6"
          style={{
            backgroundColor: `${colors.primary}10`,
            borderColor: colors.primary,
            borderWidth: 1,
          }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <p className="text-sm mb-3" style={{ color: colors.text }}>
            {t("settings.ai.credits.upgradeCTA")}
          </p>
          <Button onClick={() => navigate("/upgrade")} className="w-full">
            {t("settings.ai.credits.upgradeButton")}
          </Button>
        </motion.div>
      )}

      {/* Purchase Modal */}
      <AnimatePresence>
        {selectedOption && (
          <AICreditPurchaseModal
            key="purchase-modal"
            isOpen={!!selectedOption}
            onClose={() => setSelectedOption(null)}
            selectedOption={selectedOption}
          />
        )}
      </AnimatePresence>
    </motion.div>
  )
}

