import { useState } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { useStore } from '../../store/useStore'
import { listsService } from '../../services/listsService'
import { useListsStore } from '../../store/useListsStore'
import { IconPicker } from '../shared/pickers/IconPicker'
import { ColorPicker } from '../shared/pickers/ColorPicker'
import { PiListBold, PiPlus, PiX, PiCheck, PiListPlus } from 'react-icons/pi'
import { useErrorFeedback } from '../../hooks/useErrorFeedback'
import { ErrorFeedback } from '../shared/ErrorFeedback'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { SUBSCRIPTION_LIMITS } from '../../constants/subscriptionLimits'
import { UpgradePrompt } from '../shared/UpgradePrompt'
import { createPortal } from 'react-dom'

interface ListCreatorProps {
  onCreate?: (title: string) => void
}

// Create a Portal component for cleaner usage
function Portal({ children }: { children: React.ReactNode }) {
  return createPortal(children, document.body);
}

export function ListCreator({ onCreate }: ListCreatorProps) {
  const { colors } = useTheme()
  const { user } = useStore()
  const { lists, setLists } = useListsStore()
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [icon, setIcon] = useState('PiListBold')
  const [color, setColor] = useState('#3B82F6')
  const { error, showError, clearError, isErrorVisible } = useErrorFeedback()
  const { t } = useTranslation()
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false)
  const userTier = user?.subscription?.tier || 'free'
  const listLimit = SUBSCRIPTION_LIMITS.lists[userTier]
  const currentListCount = lists.length

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!user) return

    try {
      const listId = await listsService.createList(user.id, {
        title,
        description,
        icon,
        color,
        isPinned: false,
        isArchived: false
      })

      // Get the full list object
      const newList = {
        id: listId,
        userId: user.id,
        title,
        description,
        icon,
        color,
        isPinned: false,
        isArchived: false,
        createdAt: new Date(),
        updatedAt: new Date()
      }

      setLists([...lists, newList])
      setIsOpen(false)
      setTitle('')
      setDescription('')
      setIcon('PiListBold')
      setColor('#3B82F6')
      
      // Only call onCreate if it exists
      onCreate?.(title.trim())
    } catch (err) {
      showError(err instanceof Error ? err : new Error('Failed to create list'), 'ListCreator')
    }
  }

  const handleCreateClick = () => {
    if (currentListCount >= listLimit) {
      setShowUpgradePrompt(true)
      return
    }
    setIsOpen(true)
  }

  return (
    <div className="relative">
      {!isOpen ? (
        <motion.button
          onClick={handleCreateClick}
          className="w-full sm:w-auto px-4 py-2 rounded-lg border-2 border-dashed flex items-center justify-center gap-2 group"
          style={{ 
            borderColor: colors.border,
            color: colors.textSecondary
          }}
          whileHover={{ 
            scale: 1.02,
            borderColor: colors.primary,
            color: colors.primary
          }}
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <PiListPlus className="h-5 w-5" />
          <span>{currentListCount >= listLimit ? 'Upgrade to Create More Lists' : t('lists.create')}</span>
        </motion.button>
      ) : (
        <Portal>
          <motion.div
            className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
          >
            <motion.form 
              onSubmit={handleSubmit}
              className="w-full max-w-md rounded-lg space-y-4 p-6"
              style={{ 
                backgroundColor: colors.surface,
                border: `1px solid ${colors.border}`,
                boxShadow: `0 8px 32px ${colors.shadow}25`
              }}
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center">
                <h3 
                  className="text-lg font-semibold"
                  style={{ color: colors.text }}
                >
                  {t('lists.newList')}
                </h3>
                <motion.button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="p-1 rounded-full hover:bg-black/5"
                  style={{ color: colors.textSecondary }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <PiX className="h-5 w-5" />
                </motion.button>
              </div>

              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('lists.titlePlaceholder')}
                className="w-full px-3 py-2 rounded-lg"
                style={{
                  backgroundColor: colors.surfaceAlt,
                  borderColor: colors.border,
                  color: colors.text
                }}
                autoFocus
              />

              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description (optional)"
                className="w-full px-3 py-2 rounded-lg resize-none"
                style={{
                  backgroundColor: colors.surfaceAlt,
                  color: colors.text,
                  border: `1px solid ${colors.border}`
                }}
                rows={3}
              />

              <div className="flex items-center gap-2">
                <IconPicker value={icon} onChange={setIcon} />
                <ColorPicker value={color} onChange={setColor} />
              </div>

              <div className="flex justify-end gap-2 pt-2">
                <motion.button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="px-4 py-2 rounded-lg"
                  style={{ color: colors.textSecondary }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {t('common.cancel')}
                </motion.button>
                <motion.button
                  type="submit"
                  disabled={!title.trim()}
                  className="px-4 py-2 rounded-lg flex items-center gap-2 disabled:opacity-50"
                  style={{
                    backgroundColor: colors.primary,
                    color: '#fff'
                  }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <PiPlus className="h-5 w-5" />
                  {t('common.create')}
                </motion.button>
              </div>
            </motion.form>
          </motion.div>
        </Portal>
      )}

      <AnimatePresence>
        {showUpgradePrompt && (
          <UpgradePrompt
            title="List Limit Reached"
            description={`Free users can create up to ${listLimit} lists. Upgrade to Core to create up to 10 lists, or Pro for no restrictions.`}
            onClose={() => setShowUpgradePrompt(false)}
            feature="lists"
            currentTier={userTier}
            requiredTier="core"
            icon={<PiListPlus className="w-6 h-6" />}
          />
        )}
      </AnimatePresence>

      {error && (
        <ErrorFeedback
          error={error}
          componentName="ListCreator"
          isOpen={isErrorVisible}
          onClose={clearError}
        />
      )}
    </div>
  )
} 