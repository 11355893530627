import './App.css'
import './styles/editor.css'
import './styles/theme.css'
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AppRoutes } from './components/routing/AppRoutes';
import { ErrorBoundary } from './components/shared/ErrorBoundary';
import { Toaster } from 'react-hot-toast';
import { NotificationInitializer } from './components/notifications/NotificationInitializer';
import './i18n/config';
import { useEffect } from 'react';
import { useAuthStore } from './store/useAuthStore';

export function App() {
  const { initializeAuthListener } = useAuthStore();

  useEffect(() => {
    // Initialize auth listener once
    const unsubscribe = initializeAuthListener();
    
    // Cleanup on unmount
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, []); // Remove initializeAuthListener from deps array

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <BrowserRouter>
          <NotificationInitializer />
          <Toaster position="top-right" />
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;