import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { ThemePreset, ThemeColors } from '../../types/theme';
import { HexColorPicker } from 'react-colorful';
import { 
  SwatchIcon, 
  PlusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  StarIcon
} from '@heroicons/react/24/outline';
import { ThemeEditor } from './ThemeEditor';

export const defaultThemePresets: ThemePreset[] = [
  {
    name: 'Modern',
    description: 'Clean, minimal system theme',
    colors: {
      primary: '#007AFF',      // iOS blue
      secondary: '#8E8E93',    // iOS gray
      tertiary: '#5856D6',     // iOS purple
      quaternary: '#34C759',   // iOS green
      accent1: '#6D28D9',
      accent2: '#2bb44d'
    },
    isDefault: true
  },
  {
    name: 'Ocean',
    description: 'Calming blues and teals',
    colors: {
      primary: '#3B82F6',
      secondary: '#60A5FA',
      tertiary: '#0EA5E9',
      quaternary: '#38BDF8',
      accent1: '#2563EB',
      accent2: '#7DD3FC'
    }
  },
  {
    name: 'Forest',
    description: 'Natural greens and earth tones',
    colors: {
      primary: '#059669',
      secondary: '#34D399',
      tertiary: '#047857',
      quaternary: '#10B981',
      accent1: '#065F46',
      accent2: '#059669'
    }
  },
  {
    name: 'Sunset',
    description: 'Warm orange and gold tones',
    colors: {
      primary: '#D97706',
      secondary: '#F59E0B',
      tertiary: '#FBBF24',
      quaternary: '#FCD34D',
      accent1: '#B45309',
      accent2: '#D97706'
    }
  },
  {
    name: 'Royal',
    description: 'Rich purple and violet shades',
    colors: {
      primary: '#7C3AED',
      secondary: '#8B5CF6',
      tertiary: '#A78BFA',
      quaternary: '#C4B5FD',
      accent1: '#6D28D9',
      accent2: '#7C3AED'
    }
  },
  {
    name: 'Nordic',
    description: 'Minimal Scandinavian-inspired palette',
    colors: {
      primary: '#374151',
      secondary: '#4B5563',
      tertiary: '#6B7280',
      quaternary: '#9CA3AF',
      accent1: '#1F2937',
      accent2: '#374151'
    }
  },
  {
    name: 'Superman',
    description: 'Bold, heroic colors inspired by the Man of Steel',
    colors: {
      primary: '#0033AD',      // Superman blue
      secondary: '#CC0000',    // Superman red
      tertiary: '#FFD700',     // Gold accent
      quaternary: '#003DC5',   // Lighter blue
      accent1: '#B20000',      // Darker red
      accent2: '#0033AD'       // Superman blue again
    }
  },
  {
    name: 'Glass',
    description: 'Modern frosted glass aesthetic',
    colors: {
      primary: '#7DD3FC',      // Light blue glass
      secondary: '#93C5FD',    // Soft blue
      tertiary: '#BAE6FD',     // Lighter blue
      quaternary: '#E0F2FE',   // Palest blue
      accent1: '#38BDF8',      // Accent blue
      accent2: '#7DD3FC'       // Highlight blue
    }
  },
  {
    name: 'Ruby',
    description: 'Deep, rich reds and garnets',
    colors: {
      primary: '#DC2626',      // Vibrant red
      secondary: '#B91C1C',    // Darker red
      tertiary: '#991B1B',     // Deep red
      quaternary: '#EF4444',   // Bright red
      accent1: '#7F1D1D',      // Dark garnet
      accent2: '#DC2626'
    }
  },
  {
    name: 'Peach',
    description: 'Soft peach and apricot tones',
    colors: {
      primary: '#ff997a',
      secondary: '#ffb88f',
      tertiary: '#ffcc99',
      quaternary: '#ffe4b3',
      accent1: '#ff997a',
      accent2: '#ff997a'
    }
  },
  {
    name: 'Coffee',
    description: 'Rich brown and mocha tones',
    colors: {
      primary: '#7B3F00',
      secondary: '#8B4513',
      tertiary: '#A0522D',
      quaternary: '#6B4423',
      accent1: '#8B5742',
      accent2: '#D2691E'
    }
  },
  {
    name: 'Candy',
    description: 'Sweet pink and pastel tones',
    colors: {
      primary: '#FF69B4',
      secondary: '#FFB6C1',
      tertiary: '#FFC0CB',
      quaternary: '#FF1493',
      accent1: '#DB7093',
      accent2: '#FFF0F5'
    }
  },
  {
    name: 'Watermelon',
    description: 'Fresh and juicy watermelon inspired colors',
    colors: {
      primary: '#FF3B6F',      // Watermelon pink
      secondary: '#FF7096',    // Light watermelon
      tertiary: '#4CAF50',     // Rind green
      quaternary: '#E8F5E9',   // Pale rind
      accent1: '#FF1744',      // Deep watermelon
      accent2: '#69F0AE'       // Fresh leaf
    }
  },
  {
    name: 'Blueberry',
    description: 'Deep purple-blues with fresh leaf accents',
    colors: {
      primary: '#3949AB',      // Blueberry skin
      secondary: '#5C6BC0',    // Light berry
      tertiary: '#1A237E',     // Deep berry
      quaternary: '#C5CAE9',   // Berry frost
      accent1: '#8C9EFF',      // Berry highlight
      accent2: '#81C784'       // Leaf accent
    }
  },
  {
    name: 'Citrus',
    description: 'Zesty orange and lemon combinations',
    colors: {
      primary: '#FB8C00',      // Orange
      secondary: '#FFB74D',    // Light orange
      tertiary: '#FDD835',     // Lemon yellow
      quaternary: '#FFEB3B',   // Bright lemon
      accent1: '#FF6D00',      // Deep orange
      accent2: '#F4FF81'       // Citrus highlight
    }
  },
  {
    name: 'Dragon Fruit',
    description: 'Exotic pink and green dragon fruit palette',
    colors: {
      primary: '#E91E63',      // Dragon fruit skin
      secondary: '#F48FB1',    // Light pink flesh
      tertiary: '#AD1457',     // Deep pink
      quaternary: '#FCE4EC',   // Pale flesh
      accent1: '#00BFA5',      // Scale green
      accent2: '#64FFDA'       // Leaf highlight
    }
  },
  {
    name: 'Grape',
    description: 'Rich purple grape clusters with vine accents',
    colors: {
      primary: '#6A1B9A',      // Grape skin
      secondary: '#9C27B0',    // Light grape
      tertiary: '#4A148C',     // Deep grape
      quaternary: '#E1BEE7',   // Grape frost
      accent1: '#AA00FF',      // Bright grape
      accent2: '#558B2F'       // Vine green
    }
  }
];

export const ThemeCustomizer = () => {
  const { colors, updateColors } = useTheme();
  const [activeColor, setActiveColor] = useState<keyof ThemeColors | null>(null);
  const [customThemes, setCustomThemes] = useState<ThemePreset[]>([]);
  const [editingTheme, setEditingTheme] = useState<ThemePreset | null>(null);
  const [showThemeEditor, setShowThemeEditor] = useState(false);

  const handleSaveCustomTheme = (theme: ThemePreset) => {
    if (editingTheme) {
      setCustomThemes(prev => 
        prev.map(t => t.name === editingTheme.name ? theme : t)
      );
    } else {
      setCustomThemes(prev => [...prev, theme]);
    }
    setShowThemeEditor(false);
    setEditingTheme(null);
  };

  const handleDeleteTheme = (themeName: string) => {
    setCustomThemes(prev => prev.filter(t => t.name !== themeName));
  };

  const colorSections = [
    {
      title: 'Main Colors',
      colors: ['primary', 'secondary', 'tertiary', 'quaternary']
    },
    {
      title: 'Accent Colors',
      colors: ['accent1', 'accent2']
    }
  ];

  const handleColorChange = (color: string) => {
    if (activeColor) {
      updateColors({ [activeColor]: color });
    }
  };

  return (
    <div className="space-y-6">
      {/* Theme Management Section */}
      <div>
        <div className="flex justify-between items-center mb-3">
          <h1 className="text-2xl font-medium" style={{ color: colors.primary }}>
            Theme Management
          </h1>
          <button
            onClick={() => setShowThemeEditor(true)}
            className="flex items-center gap-2 px-3 py-1 rounded-md"
            style={{
              backgroundColor: `${colors.primary}15`,
              color: colors.primary
            }}
          >
            <PlusCircleIcon className="h-5 w-5" />
            <span>Create Theme</span>
          </button>
        </div>

        {/* Custom Themes Grid - Updated for mobile */}
        {customThemes.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-6">
            {customThemes.map((theme) => (
              <div
                key={theme.name}
                className="p-4 rounded-lg border relative group"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border
                }}
              >
                <div className="flex items-center justify-between mb-2">
                  <span style={{ color: colors.text }}>{theme.name}</span>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setEditingTheme(theme);
                        setShowThemeEditor(true);
                      }}
                      className="opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <PencilSquareIcon 
                        className="h-5 w-5"
                        style={{ color: colors.primary }}
                      />
                    </button>
                    <button
                      onClick={() => handleDeleteTheme(theme.name)}
                      className="opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <TrashIcon 
                        className="h-5 w-5"
                        style={{ color: colors.error }}
                      />
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-1">
                  {Object.values(theme.colors).map((color, i) => (
                    <div
                      key={i}
                      className="h-2 rounded-full"
                      style={{ backgroundColor: color }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Default Presets - Updated for mobile */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          {defaultThemePresets.map((preset) => (
            <button
              key={preset.name}
              onClick={() => updateColors(preset.colors)}
              className="p-4 rounded-lg border hover:shadow-md transition-all relative group"
              style={{
                backgroundColor: colors.surface,
                borderColor: colors.border
              }}
            >
              <div className="flex items-center gap-2 mb-2">
                <SwatchIcon 
                  className="h-5 w-5"
                  style={{ color: preset.colors.primary }}
                />
                <span style={{ color: colors.text }}>
                  {preset.name}
                  {preset.isDefault && (
                    <StarIcon 
                      className="h-4 w-4 inline ml-2 text-yellow-400"
                    />
                  )}
                </span>
              </div>
              <div className="grid grid-cols-6 gap-1">
                {Object.values(preset.colors).map((color, i) => (
                  <div
                    key={i}
                    className="h-2 rounded-full"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
              {preset.description && (
                <p 
                  className="text-xs mt-2"
                  style={{ color: colors.textSecondary }}
                >
                  {preset.description}
                </p>
              )}
            </button>
          ))}
        </div>
      </div>

      {/* Custom Colors */}
      <div>
        <h3 className="text-lg font-medium mb-3" style={{ color: colors.primary }}>
          Custom Colors
        </h3>
        {colorSections.map((section) => (
          <div key={section.title} className="mb-4">
            <h4 
              className="text-sm font-medium mb-2"
              style={{ color: colors.textSecondary }}
            >
              {section.title}
            </h4>
            <div className="grid grid-cols-2 gap-3">
              {section.colors.map((colorKey) => (
                <button 
                  key={colorKey}
                  onClick={() => setActiveColor(colorKey as keyof ThemeColors)}
                  className="flex items-center justify-between p-2 rounded hover:shadow-sm transition-shadow"
                  style={{ backgroundColor: colors.surface }}
                >
                  <span 
                    className="capitalize text-sm"
                    style={{ color: colors.text }}
                  >
                    {colorKey.replace(/([A-Z])/g, ' $1').trim()}
                  </span>
                  <div
                    className="w-8 h-8 rounded border"
                    style={{ 
                      backgroundColor: colors[colorKey as keyof ThemeColors],
                      borderColor: colors.border
                    }}
                  />
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Color Picker Modal */}
      {activeColor && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setActiveColor(null);
            }
          }}
        >
          <div 
            className="rounded-lg p-6 max-w-md w-full"
            style={{ backgroundColor: colors.background }}
          >
            <h3 
              className="text-lg font-medium mb-4"
              style={{ color: colors.primary }}
            >
              Customize {activeColor.replace(/([A-Z])/g, ' $1').trim()}
            </h3>
            <HexColorPicker
              color={colors[activeColor]}
              onChange={handleColorChange}
            />
            <div className="mt-4 flex gap-2">
              <input
                type="text"
                value={colors[activeColor]}
                onChange={(e) => handleColorChange(e.target.value)}
                className="flex-1 px-3 py-2 rounded-md border"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border,
                  color: colors.text
                }}
              />
              <button
                className="px-4 py-2 rounded-md"
                style={{
                  backgroundColor: colors.primary,
                  color: 'white'
                }}
                onClick={() => setActiveColor(null)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Theme Editor Modal */}
      {showThemeEditor && (
        <ThemeEditor
          theme={editingTheme || undefined}
          onSave={handleSaveCustomTheme}
          onClose={() => {
            setShowThemeEditor(false);
            setEditingTheme(null);
          }}
        />
      )}
    </div>
  );
}; 