import { type Editor } from '@tiptap/react'
import { useState, useRef } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { motion, AnimatePresence } from 'framer-motion'
import {
  PiTextBBold,
  PiTextItalic,
  PiTextUnderline,
  PiTextStrikethrough,
  PiImage,
  PiLink,
  PiSparkle,
  PiPaintBrush,
  PiPaintBucket,
  PiTable,
  PiListBullets,
  PiListNumbers,
  PiTrash,
  PiPlus,
  PiMinus,
  PiArrowUp,
  PiArrowDown,
  PiArrowLeft,
  PiArrowRight,
  PiTextH,
  PiTextAa
} from 'react-icons/pi'
import { MenuButton } from './MenuButton'
import { LinkPopup } from './LinkPopup'
import { AIEditorHelper } from './AIEditorHelper'
import { imageService } from '../../services/imageService'

interface EditorToolbarProps {
  editor: Editor | null
  isScrolled: boolean
  onShowAIHelper: (show: boolean) => void
  showAIHelper: boolean
}

const fontFamilies = [
  { name: 'Inter', value: 'Inter' },
  { name: 'Roboto', value: 'Roboto' },
  { name: 'Open Sans', value: 'Open Sans' },
  { name: 'Lato', value: 'Lato' },
  { name: 'Poppins', value: 'Poppins' },
  { name: 'Playfair Display', value: 'Playfair Display' },
  { name: 'Montserrat', value: 'Montserrat' },
  { name: 'Source Code Pro', value: 'Source Code Pro' },
  { name: 'Fira Code', value: 'Fira Code' },
  { name: 'Dancing Script', value: 'Dancing Script' },
] as const

interface FontOptionProps {
  font: { name: string; value: string }
}

function FontOption({ font }: FontOptionProps) {
  return (
    <option value={font.value} style={{ fontFamily: font.value }}>
      {font.name}
    </option>
  )
}

const toolbarVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.2,
      staggerChildren: 0.03
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  }
}

const tableMenuVariants = {
  hidden: { opacity: 0, y: 10, scale: 0.95 },
  visible: { 
    opacity: 1, 
    y: 0, 
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  },
  exit: { 
    opacity: 0, 
    y: 10, 
    scale: 0.95,
    transition: { duration: 0.2 }
  }
}

export function EditorToolbar({ 
  editor, 
  isScrolled,
  onShowAIHelper,
  showAIHelper
}: EditorToolbarProps) {
  const { colors } = useTheme()
  const [showLinkPopup, setShowLinkPopup] = useState(false)
  const [linkSelection, setLinkSelection] = useState<globalThis.Range | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  if (!editor) return null

  const toggleLink = () => {
    if (editor.state.selection.empty) return
    
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run()
      setShowLinkPopup(false)
    } else {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0) {
        setLinkSelection(selection.getRangeAt(0))
        setShowLinkPopup(true)
      }
    }
  }

  const handleImageUpload = async (file: File) => {
    try {
      const url = await imageService.uploadImage(file, 'notes')
      editor.chain()
        .focus()
        .insertContent({
          type: 'image',
          attrs: {
            src: url,
            alt: file.name
          }
        })
        .run()
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to upload image:', error)
      }
    }
  }

  const deleteTable = () => {
    if (editor.isActive('table')) {
      editor.chain()
        .focus()
        .deleteTable()
        .run()
    }
  }

  return (
    <motion.div 
      className="toolbar-container"
      variants={toolbarVariants}
      initial="hidden"
      animate="visible"
    >
      <div className={`toolbar-content ${isScrolled ? 'is-scrolled' : ''}`}>
        <motion.div 
          className="flex items-center gap-2 flex-wrap"
          variants={itemVariants}
        >
          <select
            onChange={(e) => editor.chain().focus().setFontFamily(e.target.value).run()}
            className="font-family-select"
            style={{
              fontFamily: editor.isActive('textStyle', { fontFamily: editor.getAttributes('textStyle').fontFamily })
                ? editor.getAttributes('textStyle').fontFamily
                : 'Inter'
            }}
          >
            {fontFamilies.map((font) => (
              <FontOption key={font.value} font={font} />
            ))}
          </select>
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          {[1, 2, 3, 4, 5, 6].map((level) => (
            <MenuButton
              key={level}
              onClick={() => editor.chain().focus().toggleHeading({ level: level as 1|2|3|4|5|6 }).run()}
              isActive={editor.isActive('heading', { level: level as 1|2|3|4|5|6 })}
              title={`Heading ${level}`}
              style={{
                color: editor.isActive('heading', { level: level as 1|2|3|4|5|6 }) ? colors.primary : colors.text,
                backgroundColor: editor.isActive('heading', { level: level as 1|2|3|4|5|6 }) ? `${colors.primary}15` : 'transparent',
                fontSize: '0.875rem',
                fontWeight: 600
              }}
            >
              H{level}
            </MenuButton>
          ))}
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          <MenuButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
            title="Bold"
            style={{
              backgroundColor: editor.isActive('bold') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('bold') ? colors.primary : colors.text,
            }}
          >
            <PiTextBBold className="w-6 h-6" />
          </MenuButton>

          <MenuButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
            title="Italic"
            style={{
              backgroundColor: editor.isActive('italic') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('italic') ? colors.primary : colors.text,
            }}
          >
            <PiTextItalic className="w-6 h-6" />
          </MenuButton>

          <MenuButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive('underline')}
            title="Underline"
            style={{
              backgroundColor: editor.isActive('underline') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('underline') ? colors.primary : colors.text,
            }}
          >
            <PiTextUnderline className="w-6 h-6" />
          </MenuButton>

          <MenuButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
            title="Strikethrough"
            style={{
              backgroundColor: editor.isActive('strike') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('strike') ? colors.primary : colors.text,
            }}
          >
            <PiTextStrikethrough className="w-6 h-6" />
          </MenuButton>
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          <div className="relative">
            <input
              type="color"
              onChange={(e) => editor.chain().focus().setColor(e.target.value).run()}
                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
              title="Text Color"
            />
            <MenuButton
              onClick={() => {}}
              title="Text Color"
              style={{
                color: colors.text,
                backgroundColor: 'transparent'
              }}
            >
              <PiPaintBrush className="w-6 h-6" />
            </MenuButton>
          </div>

          <MenuButton
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            isActive={editor.isActive('highlight')}
            title="Highlight"
            style={{
              color: editor.isActive('highlight') ? colors.primary : colors.text,
              backgroundColor: editor.isActive('highlight') ? `${colors.primary}15` : 'transparent'
            }}
          >
            <PiPaintBucket className="w-6 h-6" />
          </MenuButton>
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          <MenuButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
            title="Bullet List"
            style={{
              backgroundColor: editor.isActive('bulletList') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('bulletList') ? colors.primary : colors.text,
            }}
          >
            <PiListBullets className="w-6 h-6" />
          </MenuButton>

          <MenuButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
            title="Numbered List"
            style={{
              backgroundColor: editor.isActive('orderedList') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('orderedList') ? colors.primary : colors.text,
            }}
          >
            <PiListNumbers className="w-6 h-6" />
          </MenuButton>
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          <MenuButton
            onClick={toggleLink}
            isActive={editor.isActive('link')}
            title="Insert Link"
            style={{
              backgroundColor: editor.isActive('link') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('link') ? colors.primary : colors.text,
            }}
          >
            <PiLink className="w-6 h-6" />
          </MenuButton>

          <MenuButton
            onClick={() => fileInputRef.current?.click()}
            isActive={editor.isActive('image')}
            title="Insert Image"
          >
            <PiImage className="w-6 h-6" />
          </MenuButton>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/jpeg,image/png,image/gif,image/webp"
            onChange={(e) => {
              const file = e.target.files?.[0]
              if (file) {
                handleImageUpload(file)
                e.target.value = ''
              }
            }}
          />
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1"
          variants={itemVariants}
        >
          <MenuButton
            onClick={() => onShowAIHelper(!showAIHelper)}
            isActive={showAIHelper}
            title="AI Writing Assistant"
            style={{
              backgroundColor: showAIHelper ? `${colors.primary}20` : 'transparent',
              color: showAIHelper ? colors.primary : colors.text,
            }}
          >
            <PiSparkle className="w-6 h-6" />
          </MenuButton>
        </motion.div>

        <div className="toolbar-divider" />

        <motion.div 
          className="flex items-center space-x-1 overflow-visible"
          variants={itemVariants}
        >
          <MenuButton
            onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
            title="Insert Table"
            style={{
              backgroundColor: editor.isActive('table') ? `${colors.primary}20` : 'transparent',
              color: editor.isActive('table') ? colors.primary : colors.text,
            }}
          >
            <PiTable className="w-6 h-6" />
          </MenuButton>
          
          <AnimatePresence>
            {editor.isActive('table') && (
              <motion.div 
                className="absolute top-full left-0 mt-2 bg-surface rounded-lg shadow-lg border p-2 flex flex-wrap gap-1"
                variants={tableMenuVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border
                }}
              >
                <MenuButton
                  onClick={() => editor.chain().focus().addColumnBefore().run()}
                  title="Add Column Before"
                  style={{
                    backgroundColor: editor.isActive('tableHeader') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableHeader') ? colors.primary : colors.text,
                  }}
                >
                  <PiArrowLeft className="w-6 h-6" />
                </MenuButton>
                <MenuButton
                  onClick={() => editor.chain().focus().addColumnAfter().run()}
                  title="Add Column After"
                  style={{
                    backgroundColor: editor.isActive('tableHeader') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableHeader') ? colors.primary : colors.text,
                  }}
                >
                  <PiArrowRight className="w-6 h-6" />
                </MenuButton>
                <MenuButton
                  onClick={() => editor.chain().focus().deleteColumn().run()}
                  title="Delete Column"
                  style={{
                    backgroundColor: editor.isActive('tableCell') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableCell') ? colors.primary : colors.text,
                  }}
                >
                  <PiMinus className="w-6 h-6" />
                </MenuButton>
                <div className="w-full h-px bg-gray-200 dark:bg-gray-700 my-1" />
                <MenuButton
                  onClick={() => editor.chain().focus().addRowBefore().run()}
                  title="Add Row Before"
                  style={{
                    backgroundColor: editor.isActive('tableCell') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableCell') ? colors.primary : colors.text,
                  }}
                >
                  <PiArrowUp className="w-6 h-6" />
                </MenuButton>
                <MenuButton
                  onClick={() => editor.chain().focus().addRowAfter().run()}
                  title="Add Row After"
                  style={{
                    backgroundColor: editor.isActive('tableCell') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableCell') ? colors.primary : colors.text,
                  }}
                >
                  <PiArrowDown className="w-6 h-6" />
                </MenuButton>
                <MenuButton
                  onClick={() => editor.chain().focus().deleteRow().run()}
                  title="Delete Row"
                  style={{
                    backgroundColor: editor.isActive('tableCell') ? `${colors.primary}20` : 'transparent',
                    color: editor.isActive('tableCell') ? colors.primary : colors.text,
                  }}
                >
                  <PiMinus className="w-6 h-6" />
                </MenuButton>
                <div className="w-full h-px bg-gray-200 dark:bg-gray-700 my-1" />
                <MenuButton
                  onClick={deleteTable}
                  title="Delete Table"
                  style={{
                    backgroundColor: `${colors.error}20`,
                    color: colors.error,
                  }}
                >
                  <PiTrash className="w-4 h-4" />
                </MenuButton>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      {showLinkPopup && (
        <LinkPopup 
          editor={editor}
          showLinkPopup={showLinkPopup}
          setShowLinkPopup={setShowLinkPopup}
          linkSelection={linkSelection}
          setLinkSelection={setLinkSelection}
        />
      )}

      {isScrolled && (
        <div className="h-20 transition-all duration-300" />
      )}

      <style>
        {`
          .toolbar-container {
            position: relative;
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
          }

          .toolbar-content {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem;
            background-color: ${colors.surface};
            border: 1px solid ${colors.border};
            border-radius: 0.5rem;
            transition: all 0.2s ease-out;
            width: 100%;
            min-width: min-content;
            white-space: nowrap;
          }

          .toolbar-content-scrolled {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            background-color: ${colors.surface};
            border-bottom: 1px solid ${colors.border};
            min-height: 3rem;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }

          .toolbar-divider {
            width: 1px;
            height: 24px;
            background-color: ${colors.border};
            margin: 0 0.25rem;
          }

          .font-family-select {
            height: 36px;
            min-width: 100px;
            max-width: 140px;
            padding: 0 0.5rem;
            border-radius: 0.375rem;
            font-size: 0.875rem;
            border: 1px solid ${colors.border};
            background-color: transparent;
            transition: all 0.2s ease;
          }

          .font-family-select:focus {
            outline: none;
            border-color: ${colors.primary};
            box-shadow: 0 0 0 1px ${colors.primary};
          }

          .editor-toolbar {
            position: sticky;
            top: 100px;
            z-index: 50;
            transition: transform 0.2s ease-out;
            -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background-color: ${colors.background};
            padding: 0.5rem 0;
          }

          .editor-toolbar.is-scrolled {
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            top: 100px;
          }

          @supports (-webkit-touch-callout: none) {
            .editor-toolbar {
              position: -webkit-sticky;
              transform: translateZ(0);
              will-change: transform;
            }
          }
        `}
      </style>
    </motion.div>
  )
} 