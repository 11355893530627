import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { storage } from '../utils/firebase'
import { auth } from '../utils/firebase'

const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5MB
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']

interface ImageValidationResult {
  isValid: boolean
  error?: string
}

export const imageService = {
  validateImage(file: File): ImageValidationResult {
    if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      return {
        isValid: false,
        error: 'Invalid file type. Please upload a JPEG, PNG, GIF, or WebP image.'
      }
    }

    if (file.size > MAX_IMAGE_SIZE) {
      return {
        isValid: false,
        error: 'Image size must be less than 5MB.'
      }
    }

    return { isValid: true }
  },

  async uploadImage(file: File, path: string): Promise<string> {
    const userId = auth.currentUser?.uid
    if (!userId) throw new Error('Not authenticated')

    const validation = this.validateImage(file)
    if (!validation.isValid) {
      throw new Error(validation.error)
    }

    // Create a unique filename
    const timestamp = Date.now()
    const extension = file.name.split('.').pop()
    const filename = `${timestamp}-${Math.random().toString(36).substring(2)}.${extension}`
    
    // Create the full path including user ID for security
    const fullPath = `users/${userId}/${path}/${filename}`
    
    const storageRef = ref(storage, fullPath)
    
    try {
      await uploadBytes(storageRef, file)
      const url = await getDownloadURL(storageRef)
      return url
    } catch (error) {
      console.error('Error uploading image:', error)
      throw new Error('Failed to upload image')
    }
  },

  async deleteImage(url: string): Promise<void> {
    try {
      const decodedUrl = decodeURIComponent(url);
      const pathMatch = decodedUrl.match(/o\/(.+?)\?/);
      
      if (!pathMatch) throw new Error('Invalid image URL format');
      
      // Preserve original encoding except for the users%2F prefix
      const imagePath = pathMatch[1].replace(/^users%2F/, 'users/');
      const imageRef = ref(storage, imagePath);

      await deleteObject(imageRef);
    } catch (error) {
      console.error('Storage deletion error:', error);
      throw new Error(
        error.code === 'storage/unauthorized' 
          ? 'You don\'t have permission to delete this image'
          : 'Failed to delete image. It may have already been removed.'
      );
    }
  }
} 