interface Environment {
  isProduction: boolean;
  isPreview: boolean;
  isDevelopment: boolean;
  allowAppAccess: boolean;
}

// Get environment based on hostname and env variables
export const getEnvironment = (): Environment => {
  const hostname = window.location.hostname;
  const mode = import.meta.env.MODE;
  
  // Development environment (localhost in dev mode)
  if ((hostname === 'localhost' || hostname === '127.0.0.1') && mode !== 'production') {
    return {
      isProduction: false,
      isPreview: false,
      isDevelopment: true,
      allowAppAccess: true
    };
  }
  
  // Production domains
  const productionDomains = [
    'tydy.app',
    'plnnr-11ee8.web.app',
    'plnnr-11ee8.firebaseapp.com',
    // Consider localhost in production mode as a production domain
    ...(mode === 'production' ? ['localhost', '127.0.0.1'] : [])
  ];
  
  if (productionDomains.includes(hostname)) {
    return {
      isProduction: true,
      isPreview: false,
      isDevelopment: false,
      allowAppAccess: false
    };
  }
  
  // Testing preview domain
  if (hostname === 'plnnr-11ee8--testing-preview-ptntzz1k.web.app') {
    return {
      isProduction: false,
      isPreview: true,
      isDevelopment: false,
      allowAppAccess: true
    };
  }
  
  // Default to production settings
  return {
    isProduction: true,
    isPreview: false,
    isDevelopment: false,
    allowAppAccess: false
  };
};

// Export a singleton instance
export const ENV = getEnvironment();

// Add session storage key
const UNLOCK_KEY = 'app_unlock_state';

// Add debug logging
const DEBUG = true;

const log = (message: string, data?: any) => {
  if (DEBUG) {
    console.log(`[ENV] ${message}`, data || '');
  }
};

// Helper function to check access
export const shouldAllowAccess = (hasUnlocked: boolean): boolean => {
  const sessionUnlock = sessionStorage.getItem(UNLOCK_KEY) === 'true';
  const params = new URLSearchParams(window.location.search);
  const urlUnlock = params.get('unlock') === 'true';

  if (ENV.isDevelopment && !ENV.isProduction) return true;
  if (ENV.isPreview) return true;
  if (sessionUnlock || urlUnlock) return true;

  return false;
};

// Add function to set unlock state
export const setUnlockState = (unlocked: boolean) => {
  if (unlocked) {
    sessionStorage.setItem(UNLOCK_KEY, 'true');
  } else {
    sessionStorage.removeItem(UNLOCK_KEY);
  }
};

// Add a function to handle URL parameters
export const addUnlockParam = (url: string): string => {
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  const unlockParam = params.get('unlock');
  
  if (!unlockParam) return url;
  
  const newUrl = new URL(url, window.location.origin);
  newUrl.searchParams.set('unlock', 'true');
  return newUrl.pathname + newUrl.search;
}; 