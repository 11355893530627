import { db } from '../firebase'
import { collection, getDocs, updateDoc, doc, getDoc, query, where } from 'firebase/firestore'
import { encryptionService } from '../../services/encryptionService'

export async function migrateEncryption(userId: string) {
  try {
    // First get the user's encryption key
    const userRef = doc(db, 'users', userId)
    const userDoc = await getDoc(userRef)
    if (!userDoc.exists()) {
      throw new Error(`User ${userId} not found`)
    }

    const userData = userDoc.data()
    let userKey = userData.encryptionKey

    // If user has no key, generate one and save it
    if (!userKey) {
      userKey = encryptionService.generateUserKey()
      await updateDoc(userRef, {
        encryptionKey: userKey,
        encryptionVersion: 2
      })
      console.log(`Generated new encryption key for user ${userId}`)
    }

    // Get all user's transactions
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId)
    )
    const snapshot = await getDocs(q)

    // Track migration results
    const results = {
      success: 0,
      failed: 0,
      skipped: 0,
      total: snapshot.size
    }

    // Process each transaction
    for (const doc of snapshot.docs) {
      const data = doc.data()
      
      try {
        const updates: Record<string, any> = {}
        let needsUpdate = false

        // Fields that need encryption
        const encryptedFields = ['amount', 'description', 'category', 'note']
        
        for (const field of encryptedFields) {
          if (data[field]) {
            try {
              let decryptedValue: string

              // If field is already encrypted in old format
              if (encryptionService.isEncrypted(data[field]) && data[field].startsWith('ENC_V1_')) {
                // Parse the legacy format
                const legacyJson = data[field].substring(7) // Skip "ENC_V1_"
                const legacyData = JSON.parse(legacyJson)
                
                // Decrypt with master key
                decryptedValue = encryptionService.decrypt(legacyData.data, 'default-master-key')
              } else if (!encryptionService.isEncrypted(data[field])) {
                // If not encrypted, use raw value
                decryptedValue = data[field].toString()
              } else {
                // Skip if already in new format
                continue
              }

              // Re-encrypt with user's key
              updates[field] = encryptionService.encrypt(decryptedValue, userKey)
              needsUpdate = true
              
            } catch (e) {
              console.warn(`Failed to migrate field ${field} for doc ${doc.id}:`, e)
            }
          }
        }

        if (needsUpdate) {
          await updateDoc(doc.ref, updates)
          results.success++
        } else {
          results.skipped++
        }
      } catch (e) {
        console.error(`Failed to migrate doc ${doc.id}:`, e)
        results.failed++
      }
    }

    return results
  } catch (error) {
    console.error(`Migration failed for user ${userId}:`, error)
    throw error
  }
}

// Function to migrate all users
export async function migrateAllUsers() {
  const usersSnapshot = await getDocs(collection(db, 'users'))
  
  const results = {
    totalUsers: usersSnapshot.size,
    success: 0,
    failed: 0,
    errors: {} as Record<string, string>,
    userResults: {} as Record<string, {
      success: number,
      failed: number,
      skipped: number,
      total: number
    }>
  }

  for (const userDoc of usersSnapshot.docs) {
    try {
      const userResults = await migrateEncryption(userDoc.id)
      results.userResults[userDoc.id] = userResults
      results.success++
    } catch (error) {
      results.failed++
      results.errors[userDoc.id] = error instanceof Error ? error.message : 'Unknown error'
    }
  }

  return results
} 