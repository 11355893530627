import type React from "react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "../contexts/ThemeContext"
import { isEmailSignInLink, completeEmailSignIn } from "../utils/firebase"
import { useErrorFeedback } from "../hooks/useErrorFeedback"
import { ErrorFeedback } from "../components/shared/ErrorFeedback"

const LoadingSpinner: React.FC = () => {
  const { colors } = useTheme()
  return (
    <div className="flex justify-center items-center">
      <div
        className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"
        style={{ borderColor: colors.primary }}
      ></div>
    </div>
  )
}

export function EmailLinkSignInPage() {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const [isProcessing, setIsProcessing] = useState(true)
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()

  useEffect(() => {
    const completeSignIn = async () => {
      try {
        const link = window.location.href
        if (!isEmailSignInLink(link)) {
          navigate("/login")
          return
        }

        // Get the email from localStorage
        const email = window.localStorage.getItem("emailForSignIn")
        if (!email) {
          showError(new Error("Please provide your email to complete sign-in"), "EmailLinkSignInPage")
          return
        }

        await completeEmailSignIn(email, link)
        navigate("/home")
      } catch (error) {
        showError(error instanceof Error ? error : new Error("Failed to complete sign-in"), "EmailLinkSignInPage")
      } finally {
        setIsProcessing(false)
      }
    }

    completeSignIn()
  }, [navigate, showError])

  return (
    <div className="min-h-screen flex items-center justify-center p-4" style={{ backgroundColor: colors.background }}>
      <div className="max-w-md w-full space-y-6 p-8 rounded-lg shadow-lg" style={{ backgroundColor: colors.surface }}>
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4" style={{ color: colors.primary }}>
            {isProcessing ? "Completing Sign In..." : "Sign In Complete"}
          </h2>
          {isProcessing ? (
            <LoadingSpinner />
          ) : (
            !error && <p style={{ color: colors.textSecondary }}>You'll be redirected shortly...</p>
          )}
        </div>

        {error && (
          <ErrorFeedback error={error} componentName={componentName} isOpen={isErrorVisible} onClose={clearError} />
        )}
      </div>
    </div>
  )
}

export default EmailLinkSignInPage 