import { type ReactNode, useEffect, useState, useRef, useMemo } from "react"
import {
  HomeIcon,
  UserIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  SwatchIcon,
  TrophyIcon,
  Bars3Icon,
  SparklesIcon,
  ClipboardDocumentListIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import { BookOpenIcon, HeartIcon, BanknotesIcon, ListBulletIcon, CalendarIcon } from "@heroicons/react/24/outline"
import { Link, useLocation, useNavigate, Outlet, Navigate } from "react-router-dom"
import { DarkModeToggle } from "../shared/DarkModeToggle"
import { useStore } from "../../store/useStore"
import { useTheme } from "../../contexts/ThemeContext"
import { useNotebookStore } from "../../store/useNotebookStore"
import { useAuthStore } from "../../store/useAuthStore"
import { useGamification } from '../../store/useGamificationStore'
import { NotificationCenter } from "../notifications/NotificationCenter"
import { getStartOfDay, isSameDay } from "../../utils/dateUtils"
import { useTranslation } from "react-i18next"
import { Logo } from "../shared/Logo"
import type { User } from "../../types/user"
import { defaultUserPreferences } from "../../types/user"
import type { Notebook } from "../../types/notebook"
import { useErrorFeedback } from "../../hooks/useErrorFeedback"
import { ErrorFeedback } from "../shared/ErrorFeedback"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import { adminService } from '../../services/adminService'
import { PiShieldStar, PiCrown } from 'react-icons/pi'
import { usePageTracking } from '../../hooks/usePageTracking'
import { auth } from '../../utils/firebase'
import { Loader } from '../shared/Loader'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useNotificationStore } from "../../store/useNotificationStore"
import { sidebarVariants, overlayVariants, dropdownVariants } from "../../components/animations"

interface MainLayoutProps {
  children: ReactNode
}

const BREAKPOINT_MOBILE = 800
const BREAKPOINT_DESKTOP = 1750

const KONAMI_CODE = [
  'ArrowUp', 'ArrowUp',
  'ArrowDown', 'ArrowDown',
  'ArrowLeft', 'ArrowRight',
  'ArrowLeft', 'ArrowRight',
  'b', 'a'
]

export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { user: storeUser, isDarkMode, setIsDarkMode, initializeUser, setUser, isInitialized } = useStore()
  const { notebooks } = useNotebookStore()
  const [showTrackers, setShowTrackers] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const { updateStreakProduction } = useGamification()
  const { error: errorFeedback, showError, clearError, isErrorVisible, componentName } = useErrorFeedback()
  const { error: storeError } = useStore()
  const initializationAttempted = useRef(false)
  const errorRef = useRef<Error | null>(null)
  const { logout } = useAuthStore()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [secretCode, setSecretCode] = useState<string[]>([])
  const [logoClicks, setLogoClicks] = useState(0)
  const [hasUnlocked, setHasUnlocked] = useLocalStorage('app_unlocked', false)
  const logoAnimation = useAnimation()
  const lastKeyTime = useRef(Date.now())
  const resetTimer = useRef<NodeJS.Timeout>()
  const { addNotification } = useNotificationStore()
  const [isGamificationReady, setIsGamificationReady] = useState(false)

  // Show loading state with timeout
  const [showLoadingTimeout, setShowLoadingTimeout] = useState(false)

  usePageTracking()

  // Define trackerItems first, before any useEffects
  const trackerItems = [
    {
      path: "/notebooks",
      icon: BookOpenIcon,
      label: t("navigation.notebooks"),
      id: "notebooks",
    },
    {
      path: "/lists",
      icon: ListBulletIcon,
      label: t("navigation.lists"),
      id: "lists",
    },
    {
      path: "/mood",
      icon: HeartIcon,
      label: t("navigation.mood"),
      id: "mood",
    },
    {
      path: "/finance",
      icon: BanknotesIcon,
      label: t("navigation.finance"),
      id: "finance",
    },
    {
      path: "/calendar",
      icon: CalendarIcon,
      label: t("navigation.calendar"),
      id: "calendar",
    },
  ].filter((subItem) => {
    const enabledTrackers =
      storeUser?.preferences?.enabledTrackers || defaultUserPreferences.enabledTrackers
    return enabledTrackers.includes(subItem.id)
  })

  // Move the admin check into a separate useEffect
  useEffect(() => {
    const checkAdmin = async () => {
      const adminStatus = await adminService.isAdmin()
      setIsAdmin(adminStatus)
    }
    checkAdmin()
  }, [])

  // Define all navigation items unconditionally
  const navigationItems = [
    {
      path: "/home",
      icon: HomeIcon,
      label: t("navigation.home"),
    },
    {
      label: t("navigation.trackers"),
      icon: ListBulletIcon,
      isDropdown: true,
      items: trackerItems,
    },
    {
      path: "/upgrade",
      icon: PiCrown,
      label: storeUser?.subscription?.tier === 'pro' 
        ? t("navigation.manageSubscription") 
        : t("navigation.upgrade"),
      color: storeUser?.subscription?.tier === 'pro' 
        ? colors.success 
        : colors.primary
    },
    {
      path: "/admin",
      icon: PiShieldStar,
      label: t("navigation.admin"),
      show: isAdmin,
      color: colors.quaternary
    }
  ]

  // Filter navigation items when rendering
  const filteredNavigationItems = useMemo(() => {
    return navigationItems.filter(item => !item.hasOwnProperty('show') || item.show)
  }, [navigationItems, isAdmin])

  // Move getPageTitle inside the component
  const getPageTitle = (pathname: string, user: User | null, notebook?: Notebook | null) => {
    const userName = user?.name || t("common.your")

    if (pathname === "/home") return `${userName}'s ${t("header.planner")}`
    if (pathname === "/notebooks") return `${userName}'s ${t("header.notebooks")}`
    if (pathname.startsWith("/notebooks/") && notebook) {
      return `${userName}'s ${notebook.title} ${t("header.notes")}`
    }
    if (pathname === "/tasks") return `${userName}'s ${t("header.tasks")}`
    if (pathname === "/mood") return `${userName}'s ${t("header.moodTracker")}`
    if (pathname === "/finance") return `${userName}'s ${t("header.finance")}`
    if (pathname === "/calendar") return `${userName}'s ${t("header.calendar")}`
    if (pathname === "/profile") return `${userName}'s ${t("header.profile")}`
    if (pathname === "/settings") return `${userName}'s ${t("header.settings")}`
    if (pathname === "/achievements") return `${userName}'s ${t("header.achievements")}`
    if (pathname === "/upgrade") return t("header.upgrade")

    return `${userName}'s ${t("header.planner")}`
  }

  // Get current notebook if we're in a notebook route
  const notebookId = location.pathname.split("/")[2]
  const currentNotebook = notebooks.find((n) => n.id === notebookId)

  const pageTitle = useMemo(() => {
    return getPageTitle(location.pathname, storeUser, currentNotebook)
  }, [location.pathname, storeUser, currentNotebook, t])

  // Single auth state handler
  useEffect(() => {
    setIsLoading(true)
    
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      try {
        if (firebaseUser) {
          await initializeUser(firebaseUser)
        } else {
          setUser(null)
          navigate('/login')
        }
      } catch (error) {
        console.error('Auth state change error:', error)
        setUser(null)
        navigate('/login')
      } finally {
        setIsLoading(false)
      }
    })

    return () => unsubscribe()
  }, [initializeUser, setUser, navigate])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }, [isDarkMode])

  // Update this effect to handle layout more intelligently
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      const isMobile = width < BREAKPOINT_MOBILE
      const isTablet = width >= BREAKPOINT_MOBILE && width < BREAKPOINT_DESKTOP
      
      if (isMobile || isTablet) {
        setIsSidebarOpen(false)
        setShowTrackers(false)
      } else {
        setIsSidebarOpen(true)
      }
    }

    // Initial layout
    handleResize()

    // Debounced resize listener
    let resizeTimer: NodeJS.Timeout
    const debouncedResize = () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(handleResize, 100)
    }

    window.addEventListener('resize', debouncedResize)
    return () => {
      window.removeEventListener('resize', debouncedResize)
      clearTimeout(resizeTimer)
    }
  }, [])

  // Update location effect to close sidebar on mobile and tablet
  useEffect(() => {
    const isMobile = window.innerWidth < BREAKPOINT_MOBILE
    const isTablet = window.innerWidth >= BREAKPOINT_MOBILE && window.innerWidth < BREAKPOINT_DESKTOP
    
    if (isMobile || isTablet) {
      setIsSidebarOpen(false)
      setShowTrackers(false)
    }
  }, [location])

  // Remove or update the scroll handler to only work on mobile
  useEffect(() => {
    let scrollTimer: NodeJS.Timeout
    
    const handleScroll = () => {
      clearTimeout(scrollTimer)
      
      scrollTimer = setTimeout(() => {
        const isMobile = window.innerWidth < BREAKPOINT_MOBILE
        if (isMobile && isSidebarOpen) {
          setIsSidebarOpen(false)
        }
      }, 150)
    }

    const isMobile = window.innerWidth < BREAKPOINT_MOBILE
    if (isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true })
    }
    
    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(scrollTimer)
    }
  }, [isSidebarOpen])

  // Update the preferences change effect to only close sidebar on mobile
  useEffect(() => {
    let mounted = true
    let isUpdating = false

    const handlePreferencesChange = async () => {
      if (isUpdating || !mounted) return

      try {
        isUpdating = true
        await initializeUser()

        if (!mounted) return

        const isMobile = window.innerWidth < BREAKPOINT_MOBILE
        if (isMobile) {
          setIsSidebarOpen(false)
          setShowTrackers(false)
        }
      } catch (err) {
        if (!mounted) return
        errorRef.current = err instanceof Error ? err : new Error("An unknown error occurred")
      } finally {
        isUpdating = false
      }
    }

    if (storeUser) {
      const unsubscribe = useAuthStore.subscribe((state) => {
        if (state.user !== null) {
          handlePreferencesChange()
        }
      })

      return () => {
        mounted = false
        unsubscribe()
      }
    }

    return () => {
      mounted = false
    }
  }, [initializeUser, storeUser])

  // Update theme when user preferences change
  useEffect(() => {
    if (storeUser?.preferences?.theme) {
      const theme = storeUser.preferences.theme
      const shouldBeDark =
        theme === "dark" || (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches)

      setIsDarkMode(shouldBeDark)
    }
  }, [storeUser?.preferences?.theme, setIsDarkMode])

  // Add this helper function at the top of the component
  const isMobileOrTablet = () => window.innerWidth < BREAKPOINT_DESKTOP

  // Handle keyboard input for Konami code
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const now = Date.now()
      
      // Reset if too much time between keys (3 seconds)
      if (now - lastKeyTime.current > 3000) {
        setSecretCode([])
      }
      
      lastKeyTime.current = now
      
      // Add the new key and check the sequence
      const newCode = [...secretCode, e.key]
      setSecretCode(newCode)

      // Check if we have a match
      if (newCode.join(',') === KONAMI_CODE.join(',')) {
        handleUnlock()
        setSecretCode([])
      }
      
      // Reset if we have too many keys
      if (newCode.length > KONAMI_CODE.length) {
        setSecretCode([])
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [secretCode])

  // Handle logo clicks with timing requirements
  const handleLogoClick = async () => {
    setLogoClicks(prev => prev + 1)
    
    // Clear any existing reset timer
    if (resetTimer.current) {
      clearTimeout(resetTimer.current)
    }
    
    // Reset clicks after 2 seconds of inactivity
    resetTimer.current = setTimeout(() => {
      setLogoClicks(0)
    }, 2000)

    // Animate the logo
    await logoAnimation.start({
      rotate: [0, 360],
      transition: { duration: 0.5 }
    })

    // Check if we've hit the required number of clicks
    if (logoClicks + 1 === 6) {
      handleUnlock()
      setLogoClicks(0)
    }
  }

  const handleUnlock = () => {
    setHasUnlocked(true)
    addNotification({
      type: 'success',
      title: '🎉 Developer Mode Unlocked!',
      message: 'Welcome to the testing playground.',
      duration: 5000
    })
    // Force reload to update app state
    window.location.reload()
  }

  // Add initialization check
  useEffect(() => {
    if (storeUser?.gamification) {
      setIsGamificationReady(true)
    }
  }, [storeUser])

  useEffect(() => {
    // Only show loading spinner after 500ms to avoid flash
    const timer = setTimeout(() => {
      setShowLoadingTimeout(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  // Handle store errors
  useEffect(() => {
    if (errorFeedback) {
      showError(errorFeedback, 'MainLayout')
    }
  }, [errorFeedback, showError])

  // Show loading state
  if ((isLoading || !isInitialized || !isGamificationReady) && showLoadingTimeout) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Loader size="lg" />
        <p className="mt-4 text-sm text-gray-600">
          {isLoading ? 'Loading...' : 'Initializing...'}
        </p>
      </div>
    )
  }

  // Show error state with retry option
  if (errorFeedback || storeError) {
    return (
      <div className="min-h-screen">
        <ErrorFeedback
          error={errorFeedback || storeError}
          componentName={componentName || 'MainLayout'}
          isOpen={true}
          onClose={() => {
            clearError()
            window.location.reload() // Reload on error dismiss
          }}
        />
      </div>
    )
  }

  // Redirect if no user
  if (!storeUser) {
    return <Navigate to="/login" replace />
  }

  const secondaryNavItems = [
    { path: "/profile", icon: UserIcon, label: t("navigation.profile") },
    { path: "/achievements", icon: TrophyIcon, label: t("navigation.achievements") },
    { path: "/settings", icon: Cog6ToothIcon, label: t("navigation.settings") },
    { path: "/theme", icon: SwatchIcon, label: t("navigation.theme") },
  ]

  return (
    <div className="min-h-screen flex flex-col bg-background">
      {/* Header */}
      <header
        className="sticky top-0 z-50 py-4 border-b w-full backdrop-blur-sm bg-background/80"
        style={{ borderColor: colors.border }}
      >
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-md text-primary hover:bg-primary/10 transition-colors"
              aria-label="Toggle sidebar"
            >
              {isSidebarOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
            </button>

            <motion.div
              animate={logoAnimation}
              onClick={handleLogoClick}
              className="cursor-pointer select-none"
            >
              <Logo size={36} />
            </motion.div>
          </div>

          <h1 className="text-lg font-semibold text-primary hidden sm:block">{pageTitle}</h1>

          <div className="flex items-center gap-4">
            <DarkModeToggle />
            <button
              onClick={() => {
                logout()
                navigate("/login")
              }}
              className="px-3 py-1.5 rounded-md text-sm font-medium bg-primary/10 text-primary hover:bg-primary/20 transition-colors"
            >
              {t("auth.logout")}
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <AnimatePresence>
          {isSidebarOpen && (
            <>
              <motion.nav
                variants={sidebarVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="fixed inset-y-0 left-0 w-64 bg-surface z-40 overflow-y-auto sidebar-container"
                style={{ backgroundColor: colors.surface }}
              >
                <div className="h-full pt-20 pb-4 flex flex-col">
                  {/* Primary Navigation */}
                  <div className="flex-1 px-3 py-4 space-y-1">
                    {filteredNavigationItems.map((item) =>
                      item.isDropdown ? (
                        <div key={item.label} className="space-y-1">
                          <button
                            onClick={() => setShowTrackers(!showTrackers)}
                            className="w-full flex items-center px-4 py-3 rounded-lg hover-transition"
                            style={{
                              backgroundColor: showTrackers || location.pathname.startsWith('/lists') || 
                                location.pathname.startsWith('/notebooks') || location.pathname.startsWith('/mood') || 
                                location.pathname.startsWith('/finance') || location.pathname.startsWith('/calendar')
                                ? `${colors.primary}15` 
                                : "transparent",
                              color: showTrackers || location.pathname.startsWith('/lists') || 
                                location.pathname.startsWith('/notebooks') || location.pathname.startsWith('/mood') || 
                                location.pathname.startsWith('/finance') || location.pathname.startsWith('/calendar')
                                ? colors.primary 
                                : colors.text
                            }}
                          >
                            <item.icon className="h-5 w-5 mr-3" />
                            <span className="flex-1 text-left">{item.label}</span>
                            <ChevronUpIcon
                              className={`h-5 w-5 transition-transform duration-200 ${
                                showTrackers ? "" : "rotate-180"
                              }`}
                            />
                          </button>

                          <AnimatePresence>
                            {showTrackers && (
                              <motion.div
                                variants={dropdownVariants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                className="ml-4 space-y-1 overflow-hidden"
                              >
                                {trackerItems
                                  .map((subItem) => (
                                    <Link
                                      key={subItem.path}
                                      to={subItem.path}
                                      className="flex items-center px-4 py-3 rounded-lg hover-transition"
                                      style={{
                                        backgroundColor:
                                          location.pathname.startsWith(subItem.path) 
                                            ? `${colors.primary}15` 
                                            : "transparent",
                                        color: location.pathname.startsWith(subItem.path) 
                                          ? colors.primary 
                                          : colors.text,
                                      }}
                                      onClick={() => {
                                        if (isMobileOrTablet()) {
                                          setIsSidebarOpen(false)
                                          setShowTrackers(false)
                                        }
                                      }}
                                    >
                                      <subItem.icon className="h-5 w-5 mr-3" />
                                      <span>{subItem.label}</span>
                                    </Link>
                                  ))}
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      ) : (
                        <Link
                          key={item.path}
                          to={item.path || ""}
                          className="flex items-center px-4 py-3 rounded-lg hover-transition"
                          style={{
                            backgroundColor: location.pathname.startsWith(item.path || '') 
                              ? `${colors.primary}15` 
                              : "transparent",
                            color: location.pathname.startsWith(item.path || '') 
                              ? colors.primary 
                              : colors.text,
                          }}
                          onClick={() => {
                            if (isMobileOrTablet()) {
                              setIsSidebarOpen(false)
                            }
                          }}
                        >
                          <item.icon className="h-5 w-5 mr-3" />
                          <span>{item.label}</span>
                        </Link>
                      ),
                    )}
                  </div>

                  {/* Divider */}
                  <div className="my-4 mx-3 h-px" style={{ backgroundColor: colors.border }} />

                  {/* Secondary Navigation */}
                  <div className="px-3 py-4 space-y-1">
                    {secondaryNavItems.map((item) => (
                      <Link
                        key={item.label}
                        to={item.path}
                        className="hover-transition flex items-center px-4 py-3 rounded-lg"
                        style={{
                          backgroundColor: location.pathname === item.path ? `${colors.primary}15` : "transparent",
                          color: location.pathname === item.path ? colors.primary : colors.text,
                        }}
                        onClick={() => isMobileOrTablet() && setIsSidebarOpen(false)}
                      >
                        <item.icon className="h-5 w-5 mr-3" />
                        <span>{item.label}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </motion.nav>

              {/* Overlay */}
              <motion.div
                variants={overlayVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
                onClick={() => setIsSidebarOpen(false)}
              />
            </>
          )}
        </AnimatePresence>

        {/* Main Content Area */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <div className="py-6 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">{children}</div>
        </main>
      </div>

      {/* Footer */}
      <footer
        className="py-4 border-t text-center text-sm bg-surface"
        style={{
          borderColor: colors.border,
          color: colors.textSecondary,
          backgroundColor: colors.surface,
        }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
            <div className="flex items-center space-x-2">
              <Logo size={25} />
              <span>© {new Date().getFullYear()} Tydy</span>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://github.com/khareesmith/tydy"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary transition-colors"
              >
                {t("footer.github")}
              </a>
              <a href="#" className="hover:text-primary transition-colors">
                {t("footer.privacy")}
              </a>
              <a href="#" className="hover:text-primary transition-colors">
                {t("footer.terms")}
              </a>
            </div>
          </div>
        </div>
      </footer>

      <NotificationCenter />
    </div>
  )
}

