import { db } from '../../utils/firebase'
import { collection, query, where, getDocs, writeBatch, doc, Timestamp, serverTimestamp } from 'firebase/firestore'
import { encryptionService } from '../../services/encryptionService'
import { withEncryption } from '../../utils/encryption'
import { AppError } from '../../utils/errors'
import type { FirestoreTransaction } from '../../types/finance'

export async function migrateTransactions(userId: string, encryptionKey: string) {
  try {
    console.log('[TransactionMigration] Starting migration for user:', userId)
    
    // Get all user's transactions
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId)
    )
    const snapshot = await getDocs(q)
    const transactions = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as (FirestoreTransaction & { id: string })[]

    console.log(`[TransactionMigration] Found ${transactions.length} transactions to migrate`)

    if (transactions.length === 0) {
      console.log('[TransactionMigration] No transactions to migrate')
      return
    }

    // Process in smaller batches to avoid timeouts
    const BATCH_SIZE = 100 // Reduced from 500
    for (let i = 0; i < transactions.length; i += BATCH_SIZE) {
      try {
        console.log(`[TransactionMigration] Processing batch ${Math.floor(i / BATCH_SIZE) + 1} of ${Math.ceil(transactions.length / BATCH_SIZE)}`)
        
        const batch = writeBatch(db)
        const batchTransactions = transactions.slice(i, i + BATCH_SIZE)

        for (const transaction of batchTransactions) {
          // Skip if already encrypted
          if (
            typeof transaction.amount === 'string' && 
            encryptionService.isEncrypted(transaction.amount)
          ) {
            console.log(`[TransactionMigration] Skipping already encrypted transaction ${transaction.id}`)
            continue
          }

          try {
            // Encrypt sensitive fields
            const encryptedTransaction = withEncryption(
              transaction,
              ['amount', 'description'],
              encryptionKey
            )

            const transactionRef = doc(db, 'transactions', transaction.id)
            batch.update(transactionRef, {
              amount: encryptedTransaction.amount,
              description: encryptedTransaction.description,
              migrated: true,
              migratedAt: serverTimestamp()
            })
          } catch (error) {
            console.error(`[TransactionMigration] Error processing transaction ${transaction.id}:`, error)
            // Continue with other transactions in the batch
          }
        }

        await batch.commit()
        console.log(`[TransactionMigration] Successfully committed batch ${Math.floor(i / BATCH_SIZE) + 1}`)
      } catch (error) {
        console.error(`[TransactionMigration] Error processing batch:`, error)
        // Continue with next batch
      }
    }

    console.log('[TransactionMigration] Successfully completed migration')
  } catch (error) {
    console.error('[TransactionMigration] Migration failed:', error)
    throw new AppError(
      'MIGRATION_FAILED',
      'Failed to migrate transactions',
      error instanceof Error ? error : undefined
    )
  }
} 