import { useState } from 'react'
import { useTheme } from '../../contexts/ThemeContext'
import { MoodEntry, DayPeriod, moodTypes, periodLabels, moodIcons } from '../../types/mood'
import { motion, AnimatePresence } from 'framer-motion'
import { format, isToday, isYesterday } from 'date-fns'
import React from 'react'

interface MoodTimelineProps {
  entries: MoodEntry[]
  selectedDate: Date
  onEditMood: (entry: MoodEntry) => void
  onAddMood: (day: number, period: DayPeriod) => void
}

export function MoodTimeline({ 
  entries, 
  selectedDate,
  onEditMood,
  onAddMood 
}: MoodTimelineProps) {
  const { colors } = useTheme()
  const [expandedDay, setExpandedDay] = useState<number | null>(null)
  const [expandedWeek, setExpandedWeek] = useState<number | null>(null)

  // Group entries by day
  const entriesByDay = new Map<number, MoodEntry[]>()
  const daysInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate()

  // Initialize and group days into weeks
  const weeks = Array.from({ length: Math.ceil(daysInMonth / 7) }, (_, weekIndex) => {
    const weekDays = Array.from({ length: 7 }, (_, dayIndex) => {
      const day = weekIndex * 7 + dayIndex + 1
      if (day <= daysInMonth) {
        entriesByDay.set(day, [])
        return day
      }
      return null
    }).filter((day): day is number => day !== null)
    
    return {
      weekNumber: weekIndex + 1,
      days: weekDays
    }
  })

  // Add this helper function at the top of the component
  const normalizeMood = (mood: string): string => {
    return mood.charAt(0).toUpperCase() + mood.slice(1).toLowerCase()
  }

  // Group entries
  entries.forEach(entry => {
    const entryDate = entry.date instanceof Date 
      ? entry.date 
      : new Date(entry.date.seconds * 1000)
    
    const day = entryDate.getDate()
    
    if (
      entryDate.getMonth() === selectedDate.getMonth() &&
      entryDate.getFullYear() === selectedDate.getFullYear()
    ) {
      const dayEntries = entriesByDay.get(day) || []
      dayEntries.push({
        ...entry,
        date: entryDate,
        mood: normalizeMood(entry.mood) // Normalize the mood case
      })
      dayEntries.sort((a, b) => {
        const periodOrder = ['morning', 'afternoon', 'evening', 'night']
        return periodOrder.indexOf(a.period) - periodOrder.indexOf(b.period)
      })
      entriesByDay.set(day, dayEntries)
    }
  })

  const getWeekLabel = (weekNumber: number, days: number[]) => {
    const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), days[0])
    const lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), days[days.length - 1])
    return `${format(firstDay, 'MMM d')} - ${format(lastDay, 'MMM d')}`
  }

  // Safe mood color getter
  const getMoodColor = (mood: string) => {
    const normalizedMood = normalizeMood(mood)
    return moodTypes[normalizedMood as keyof typeof moodTypes]?.color || colors.primary
  }

  return (
    <motion.div 
      className="space-y-4 max-w-3xl mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {weeks.map(({ weekNumber, days }) => {
        const hasEntries = days.some(day => (entriesByDay.get(day) || []).length > 0)
        const isExpanded = expandedWeek === weekNumber
        const containsToday = days.some(day => isToday(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)))

        return (
          <motion.div
            key={weekNumber}
            className="rounded-lg overflow-hidden"
            style={{ 
              backgroundColor: colors.surface,
              borderColor: colors.border,
              borderWidth: containsToday ? 2 : 1
            }}
          >
            {/* Week Header */}
            <motion.button
              className="w-full p-4 flex items-center justify-between"
              onClick={() => setExpandedWeek(isExpanded ? null : weekNumber)}
              whileHover={{ backgroundColor: `${colors.primary}10` }}
            >
              <div className="flex items-center gap-3">
                <span 
                  className="font-medium"
                  style={{ color: containsToday ? colors.primary : colors.text }}
                >
                  Week {weekNumber}: {getWeekLabel(weekNumber, days)}
                </span>
                {hasEntries && (
                  <span 
                    className="text-xs px-2 py-1 rounded-full"
                    style={{ backgroundColor: `${colors.primary}20`, color: colors.primary }}
                  >
                    Has entries
                  </span>
                )}
              </div>
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: 0.2 }}
                className="text-lg"
                style={{ color: colors.textSecondary }}
              >
                ▼
              </motion.div>
            </motion.button>

            {/* Days in Week */}
            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                >
                  {days.map(day => {
                    const dayEntries = entriesByDay.get(day) || []
                    const isExpandedDay = expandedDay === day
                    const date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)
                    const isCurrentDay = isToday(date)

                    return (
                      <motion.div
                        key={day}
                        className="rounded-lg overflow-hidden"
                        style={{ 
                          backgroundColor: colors.surface,
                          borderColor: colors.border,
                          borderWidth: isCurrentDay ? 2 : 1
                        }}
                      >
                        {/* Day Header */}
                        <motion.button
                          className="w-full p-4 flex items-center justify-between"
                          onClick={() => setExpandedDay(isExpandedDay ? null : day)}
                          whileHover={{ backgroundColor: `${colors.primary}10` }}
                        >
                          <div className="flex items-center gap-3">
                            <span 
                              className="font-medium min-w-[120px]"
                              style={{ color: isCurrentDay ? colors.primary : colors.text }}
                            >
                              {format(date, 'EEE, MMM d')}
                            </span>
                            {/* Show mood icons for the day */}
                            <div className="flex -space-x-2">
                              {dayEntries.map((entry, index) => {
                                const MoodIcon = moodIcons[normalizeMood(entry.mood)]
                                if (!MoodIcon) return null

                                return (
                                  <motion.div
                                    key={index}
                                    className="w-6 h-6 rounded-full flex items-center justify-center"
                                    style={{ 
                                      backgroundColor: getMoodColor(entry.mood),
                                      color: 'white',
                                      zIndex: dayEntries.length - index
                                    }}
                                    whileHover={{ scale: 1.2, zIndex: 10 }}
                                  >
                                    <MoodIcon size={16} />
                                  </motion.div>
                                )
                              })}
                            </div>
                          </div>
                          <motion.div
                            animate={{ rotate: isExpandedDay ? 180 : 0 }}
                            transition={{ duration: 0.2 }}
                            className="text-lg"
                            style={{ color: colors.textSecondary }}
                          >
                            ▼
                          </motion.div>
                        </motion.button>

                        {/* Periods Grid */}
                        <AnimatePresence>
                          {isExpandedDay && (
                            <motion.div
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: 'auto', opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              className="border-t"
                              style={{ borderColor: colors.border }}
                            >
                              <div 
                                className="grid grid-cols-2 md:grid-cols-4 divide-x divide-y"
                                style={{ borderColor: colors.border }}
                              >
                                {Object.entries(periodLabels).map(([period, { label, time }]) => {
                                  // Debug log for entry finding
                                  console.log({
                                    day,
                                    period,
                                    dayEntries: entriesByDay.get(day),
                                    foundEntry: dayEntries.find(e => e.period === period)
                                  })

                                  const entry = dayEntries.find(e => e.period === period)
                                  const MoodIcon = entry ? moodIcons[normalizeMood(entry.mood)] : null

                                  return (
                                    <motion.button
                                      key={period}
                                      className="p-4 flex flex-col items-center gap-2 min-h-[120px] justify-center"
                                      onClick={() => entry ? onEditMood(entry) : onAddMood(day, period as DayPeriod)}
                                      whileHover={{ backgroundColor: `${colors.primary}10` }}
                                    >
                                      <div className="flex flex-col items-center">
                                        <span 
                                          className="text-sm font-medium"
                                          style={{ color: colors.text }}
                                        >
                                          {label}
                                        </span>
                                        <span 
                                          className="text-xs"
                                          style={{ color: colors.textSecondary }}
                                        >
                                          {time}
                                        </span>
                                      </div>
                                      {entry && MoodIcon ? (
                                        <motion.div
                                          className="w-12 h-12 rounded-full flex items-center justify-center mt-2"
                                          style={{ 
                                            backgroundColor: getMoodColor(entry.mood),
                                            color: 'white'
                                          }}
                                          whileHover={{ scale: 1.1 }}
                                        >
                                          <MoodIcon size={28} />
                                          {entry.note && (
                                            <motion.div
                                              className="absolute -top-1 -right-1 w-3 h-3 rounded-full"
                                              style={{ backgroundColor: colors.primary }}
                                            />
                                          )}
                                        </motion.div>
                                      ) : (
                                        <motion.div
                                          className="w-12 h-12 rounded-full flex items-center justify-center mt-2 text-xl"
                                          style={{ 
                                            backgroundColor: `${colors.primary}10`,
                                            color: colors.primary
                                          }}
                                          whileHover={{ scale: 1.1 }}
                                        >
                                          +
                                        </motion.div>
                                      )}
                                    </motion.button>
                                  )
                                })}
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    )
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )
      })}
    </motion.div>
  )
} 