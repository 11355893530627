import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect, Suspense } from 'react';
import { useAuthStore } from '../../store/useAuthStore';
import { ENV, shouldAllowAccess, addUnlockParam } from '../../utils/environment';
import { LandingPage } from '../../pages/LandingPage';
import { LoginPage } from '../../pages/LoginPage';
import { SignUpPage } from '../../pages/SignUpPage';
import { EmailLinkSignInPage } from '../../pages/EmailLinkSignInPage';
import { MainLayout } from '../layout/MainLayout';
import { AuthGuard } from '../auth/AuthGuard';
import { AdminGuard } from '../auth/AdminGuard';
import { AdminDashboard } from '../../pages/AdminDashboard';
import { NotebookPage } from '../../pages/NotebookPage';
import { ProfilePage } from '../../pages/ProfilePage';
import { NotebookDetailPage } from '../../pages/NotebookDetailPage';
import { NotePage } from '../../pages/NotePage';
import { SettingsPage } from '../../pages/SettingsPage';
import { ThemePage } from '../../pages/ThemePage';
import { MoodPage } from '../../pages/MoodPage';
import { CalendarPage } from '../../pages/CalendarPage';
import { AchievementsPage } from '../../pages/AchievementsPage';
import { ListsPage } from '../../pages/ListsPage';
import { ListDetailPage } from '../../pages/ListDetailPage';
import { UpgradePage } from '../../pages/UpgradePage';
import { TrackerGuard } from '../auth/TrackerGuard';
import { lazy } from 'react';
import { Loader } from '../shared/Loader';

const FinancePage = lazy(() => import('../../pages/FinancePage'));
const HomePage = lazy(() => import('../../pages/HomePage'));

export function AppRoutes() {
  const { user, loading, initialized } = useAuthStore();
  const location = useLocation();
  const allowAccess = shouldAllowAccess(!!user);

  if (!initialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public routes */}
        <Route 
          path="/" 
          element={<LandingPage allowAppAccess={allowAccess} />} 
        />
        
        {/* Auth routes */}
        <Route path="/login" element={
          allowAccess ? (
            user ? <Navigate to="/home" replace /> : <LoginPage />
          ) : (
            <Navigate to="/" replace />
          )
        } />
        
        <Route path="/login/email-link" element={
          allowAccess ? (
            user ? <Navigate to="/home" replace /> : <EmailLinkSignInPage />
          ) : (
            <Navigate to="/" replace />
          )
        } />
        
        <Route path="/signup" element={
          allowAccess ? (
            user ? <Navigate to="/home" replace /> : <SignUpPage />
          ) : (
            <Navigate to="/" replace />
          )
        } />
        
        {/* Admin routes */}
        <Route element={
          <AdminGuard>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </AdminGuard>
        }>
          <Route path="/admin" element={<AdminDashboard />} />
        </Route>

        {/* Protected routes */}
        <Route element={
          <AuthGuard allowAppAccess={allowAccess}>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </AuthGuard>
        }>
          <Route path="/home" element={<HomePage />} />
          <Route path="/notebooks" element={
            <TrackerGuard trackerId="notebooks">
              <NotebookPage />
            </TrackerGuard>
          } />
          <Route path="/notebooks/:notebookId" element={
            <TrackerGuard trackerId="notebooks">
              <NotebookDetailPage />
            </TrackerGuard>
          } />
          <Route path="/notebooks/:notebookId/notes/:noteId" element={
            <TrackerGuard trackerId="notebooks">
              <NotePage />
            </TrackerGuard>
          } />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/theme" element={<ThemePage />} />
          <Route path="/mood" element={
            <TrackerGuard trackerId="mood">
              <MoodPage />
            </TrackerGuard>
          } />
          <Route path="/finance" element={
            <TrackerGuard trackerId="finance">
              <FinancePage />
            </TrackerGuard>
          } />
          <Route path="/calendar" element={
            <TrackerGuard trackerId="calendar">
              <CalendarPage />
            </TrackerGuard>
          } />
          <Route path="/achievements" element={<AchievementsPage />} />
          <Route path="/lists" element={
            <TrackerGuard trackerId="lists">
              <ListsPage />
            </TrackerGuard>
          } />
          <Route path="/lists/:listId" element={<ListDetailPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
        </Route>
      </Routes>
    </Suspense>
  );
} 