import { motion } from 'framer-motion';
import { Achievement, UserAchievement } from '../../types/gamification';
import { achievementStates } from '../../theme/colors';
import { cn } from '../../lib/utils';
import * as Icons from 'react-icons/pi';

interface AchievementBadgeProps {
  achievement: Achievement;
  userAchievement?: UserAchievement;
  size?: 'sm' | 'md' | 'lg';
}

const BADGE_SIZES = {
  sm: 'w-10 h-10',
  md: 'w-16 h-16',
  lg: 'w-20 h-20'
} as const;

const ICON_SIZES = {
  sm: 'w-5 h-5',
  md: 'w-8 h-8',
  lg: 'w-10 h-10'
} as const;

export function AchievementBadge({ 
  achievement, 
  userAchievement,
  size = 'md'
}: AchievementBadgeProps) {
  const isUnlocked = !!userAchievement;
  const Icon = Icons[achievement.icon as keyof typeof Icons];

  const getBadgeStyle = () => {
    if (achievement.secret && !isUnlocked) {
      return {
        border: achievementStates.hidden.border,
        background: achievementStates.hidden.background,
        iconColor: achievementStates.hidden.text
      };
    }

    if (!isUnlocked) {
      return {
        border: achievementStates.locked.border,
        background: achievementStates.locked.background,
        iconColor: achievementStates.locked.text
      };
    }

    return {
      border: achievement.color,
      background: `${achievement.color}15`,
      iconColor: achievement.color
    };
  };

  const styles = getBadgeStyle();

  return (
    <motion.div
      className={cn(
        BADGE_SIZES[size],
        "rounded-full flex items-center justify-center",
        "transition-all duration-300 group-hover:scale-105",
        !isUnlocked && "group-hover:border-opacity-75"
      )}
      style={{
        border: `2px solid ${styles.border}`,
        background: styles.background
      }}
    >
      <Icon 
        className={cn(
          ICON_SIZES[size],
          "transition-transform duration-300",
          "group-hover:scale-110 group-hover:rotate-12"
        )}
        style={{ color: styles.iconColor }}
      />
    </motion.div>
  );
} 