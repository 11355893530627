import { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { useMoodStore } from '../../store/useMoodStore';
import { useGamificationStore } from '../../store/useGamificationStore';
import { MoodType, DayPeriod, moodTypes, periodLabels, moodIcons } from '../../types/mood';
import { useStore } from '../../store/useStore';
import { 
  PiX, 
  PiCheck, 
  PiNotepad,
  PiClock,
  PiSmileyWink,
  PiCalendarPlus,
  PiMapPin,
  PiThermometerSimple,
  PiSpinner
} from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import { trackAppAction } from '../../services/analyticsService';
import { useTranslation } from 'react-i18next';
import { useErrorFeedback } from '../../hooks/useErrorFeedback';
import React from 'react';
import { getCurrentWeather } from '../../services/weatherService';
import { WeatherInfo } from '../../types/mood';

interface CurrentMoodLoggerProps {
  onClose: () => void;
  initialPeriod: DayPeriod;
}

export const CurrentMoodLogger = ({ onClose, initialPeriod }: CurrentMoodLoggerProps) => {
  const { colors } = useTheme();
  const { user } = useStore();
  const [selectedMood, setSelectedMood] = useState<MoodType | null>(null);
  const [note, setNote] = useState('');
  const { addEntry } = useMoodStore();
  const { updateStreakProduction } = useGamificationStore();
  const { t } = useTranslation();
  const { error, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const [weatherData, setWeatherData] = useState<WeatherInfo | null>(null);
  const [isLoadingWeather, setIsLoadingWeather] = useState(false);

  useEffect(() => {
    const fetchWeather = async () => {
      setIsLoadingWeather(true);
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const weatherData = await getCurrentWeather(latitude, longitude);

        setWeatherData({
          condition: weatherData.current.condition.text,
          temperature: Math.round(weatherData.current.temp_f),
          location: weatherData.location.name
        });
      } catch (error) {
        console.error('Failed to fetch weather:', error);
      } finally {
        setIsLoadingWeather(false);
      }
    };

    fetchWeather();
  }, []);

  const handleSave = async () => {
    if (!selectedMood || !user?.id) return;

    try {
      await addEntry({
        date: new Date(),
        period: initialPeriod,
        mood: selectedMood,
        note: note || undefined,
        weather: weatherData || undefined
      });

      trackAppAction('mood', 'create', {
        mood: selectedMood,
        period: initialPeriod,
        has_note: Boolean(note),
        has_weather: Boolean(weatherData),
        type: 'current',
        timestamp: Date.now()
      });

      updateStreakProduction('mood');
      onClose();
    } catch (error) {
      console.error('Failed to save mood:', error);
    }
  };

  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="rounded-lg p-6 max-w-md w-full space-y-6"
          style={{ backgroundColor: colors.background }}
        >
          <div className="space-y-2">
            <div className="flex justify-between items-start">
              <h3 
                className="text-lg font-medium flex items-center gap-2"
                style={{ color: colors.primary }}
              >
                <PiSmileyWink size={24} />
                {t("mood.today")}
              </h3>
              <button
                onClick={onClose}
                className="p-1 rounded-lg hover:opacity-80 transition-opacity"
                style={{ color: colors.text }}
              >
                <PiX size={20} />
              </button>
            </div>
            <div className="flex flex-col gap-1">
              <div 
                className="flex items-center gap-2 text-sm"
                style={{ color: colors.textSecondary }}
              >
                <PiCalendarPlus size={16} />
                {new Date().toLocaleDateString('default', { 
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
              <div 
                className="flex items-center gap-2 text-sm"
                style={{ color: colors.textSecondary }}
              >
                <PiClock size={16} />
                {periodLabels[initialPeriod].label} ({periodLabels[initialPeriod].time})
              </div>
            </div>
          </div>

          {/* Weather Information */}
          {weatherData && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="p-4 rounded-lg space-y-2"
              style={{ backgroundColor: colors.surface }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <PiMapPin size={16} style={{ color: colors.textSecondary }} />
                  <span className="text-sm" style={{ color: colors.textSecondary }}>
                    {weatherData.location}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <PiThermometerSimple size={16} style={{ color: colors.textSecondary }} />
                  <span className="text-sm" style={{ color: colors.textSecondary }}>
                    {weatherData.temperature}°F
                  </span>
                </div>
              </div>
              <div className="text-sm" style={{ color: colors.text }}>
                {weatherData.condition}
              </div>
            </motion.div>
          )}

          {isLoadingWeather && (
            <div 
              className="p-4 rounded-lg flex items-center justify-center"
              style={{ backgroundColor: colors.surface }}
            >
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              >
                <PiSpinner size={20} style={{ color: colors.textSecondary }} />
              </motion.div>
            </div>
          )}

          {/* Mood Selection with staggered animation */}
          <motion.div 
            className="grid grid-cols-3 sm:grid-cols-6 gap-4"
          >
            {Object.entries(moodTypes).map(([key, mood], index) => (
              <motion.button
                key={key}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  delay: index * 0.05,
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedMood(key as MoodType)}
                className="p-3 rounded-lg flex flex-col items-center justify-center aspect-square"
                style={{
                  backgroundColor: selectedMood === key 
                    ? `${moodTypes[key as MoodType].color}20`
                    : colors.surface,
                  border: `2px solid ${selectedMood === key 
                    ? moodTypes[key as MoodType].color
                    : 'transparent'}`
                }}
              >
                <div 
                  className="flex items-center justify-center mb-1"
                  style={{ 
                    color: selectedMood === key 
                      ? moodTypes[key as MoodType].color
                      : colors.text 
                  }}
                >
                  {React.createElement(moodIcons[key as MoodType], { 
                    size: 28,
                    weight: selectedMood === key ? "fill" : "regular"
                  })}
                </div>
                <div 
                  className="text-xs font-medium"
                  style={{ 
                    color: selectedMood === key 
                      ? moodTypes[key as MoodType].color
                      : colors.text
                  }}
                >
                  {mood.label}
                </div>
              </motion.button>
            ))}
          </motion.div>

          {/* Note */}
          <div className="space-y-2">
            <label 
              className="text-sm font-medium flex items-center gap-2"
              style={{ color: colors.text }}
            >
              <PiNotepad size={16} />
              {t("mood.notes")}
            </label>
            <textarea
              placeholder="Add a note (optional)"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="w-full p-3 rounded-lg resize-none"
              style={{
                backgroundColor: colors.surface,
                color: colors.text,
                border: `1px solid ${colors.border}`
              }}
              rows={3}
            />
          </div>

          {/* Action Buttons with hover animation */}
          <div className="flex justify-end gap-3">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
              className="px-4 py-2 rounded-lg flex items-center gap-2"
              style={{
                backgroundColor: colors.surface,
                color: colors.text
              }}
            >
              <PiX size={20} />
              {t("common.cancel")}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSave}
              disabled={!selectedMood}
              className="px-4 py-2 rounded-lg flex items-center gap-2"
              style={{
                backgroundColor: colors.primary,
                color: 'white',
                opacity: selectedMood ? 1 : 0.5
              }}
            >
              <PiCheck size={20} />
              {t("common.save")}
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};