import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/useAuthStore';
import { useEffect } from 'react';
import { shouldAllowAccess } from '../../utils/environment';

interface AuthGuardProps {
  children: React.ReactNode;
}

export function AuthGuard({ children }: AuthGuardProps) {
  const { user, loading } = useAuthStore();
  const location = useLocation();
  const navigate = useNavigate();
  const unlocked = sessionStorage.getItem('app_unlock_state') === 'true';
  const allowAppAccess = shouldAllowAccess(!!user);

  useEffect(() => {
    if (!loading && !user && window.location.pathname !== '/login') {
      navigate('/login', { 
        state: { from: location.pathname },
        replace: true // Use replace to prevent history buildup
      });
    }
  }, [user, loading, navigate, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!allowAppAccess && !unlocked) {
    return null;
  }

  return user ? <>{children}</> : null;
} 