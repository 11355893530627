import { useTheme } from '../../contexts/ThemeContext';
import { 
  PiFlame, 
  PiTrophy, 
  PiSparkle,
  PiStar
} from 'react-icons/pi';
import { useGamificationStore } from '../../store/useGamificationStore';
import { motion } from 'framer-motion';

interface StatsOverviewProps {
  achievementCount: number;
  totalXP: number;
  level: number;
}

export const StatsOverview = ({
  achievementCount, 
  totalXP,
  level 
}: StatsOverviewProps) => {
  const { colors, isDarkMode } = useTheme();
  const { streaks } = useGamificationStore();

  // Calculate highest streak from all trackers
  const highestStreak = Math.max(
    ...Object.values(streaks).map(s => s.longestStreak || 0),
    0
  );

  const stats = [
    {
      label: 'Highest Streak',
      value: `${highestStreak} Days`,
      icon: PiFlame,
      color: colors.primary
    },
    {
      label: 'Achievements',
      value: `${achievementCount}`,
      icon: PiTrophy,
      color: colors.secondary
    },
    {
      label: 'Total XP',
      value: totalXP.toLocaleString(),
      icon: PiSparkle,
      color: colors.tertiary
    },
    {
      label: 'Current Level',
      value: level,
      icon: PiStar,
      color: colors.quaternary
    }
  ];

  return (
    <>
      {stats.map((stat, index) => (
        <motion.div
          key={stat.label}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.3,
            delay: index * 0.1,
            ease: "easeOut"
          }}
          className="p-4 rounded-xl flex items-center gap-3"
          style={{
            backgroundColor: isDarkMode ? colors.surfaceAlt : colors.surface,
            border: `1px solid ${stat.color}25`
          }}
        >
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="p-2 rounded-lg"
            style={{ backgroundColor: `${stat.color}10` }}
          >
            <stat.icon 
              className="w-5 h-5 md:w-6 md:h-6" 
              style={{ color: stat.color }}
            />
          </motion.div>
          <div>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 + 0.2 }}
              className="text-xs md:text-sm font-medium"
              style={{ color: colors.textSecondary }}
            >
              {stat.label}
            </motion.div>
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 + 0.3 }}
              className="text-base md:text-lg font-semibold"
              style={{ color: stat.color }}
            >
              {stat.value}
            </motion.div>
          </div>
        </motion.div>
      ))}
    </>
  );
}; 