import { useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../ui/popover'
import { useTheme } from '../../../contexts/ThemeContext'
import { PiPaintBrushBold } from 'react-icons/pi'

interface ColorPickerProps {
  value?: string | null
  onChange: (color: string) => void
  iconSize?: number
  icon?: React.ReactNode
}

export function ColorPicker({ value, onChange, iconSize = 5, icon }: ColorPickerProps) {
  const [open, setOpen] = useState(false)
  const { colors } = useTheme()

  const colorOptions = [
    { value: '#3B82F6', label: 'Blue' },
    { value: '#10B981', label: 'Green' },
    { value: '#FFD300', label: 'Yellow' },
    { value: '#EF4444', label: 'Red' },
    { value: '#FF7F00', label: 'Orange' },
    { value: '#8B5CF6', label: 'Purple' },
    { value: '#EC4899', label: 'Pink' },
    { value: '#6B7280', label: 'Gray' }
  ]

  return (
    <Popover 
      open={open} 
      onOpenChange={setOpen}
    >
      <PopoverTrigger asChild>
        <button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpen(true)
          }}
          className="p-1 rounded hover:bg-opacity-10 transition-colors"
          style={{ 
            color: value || colors.textSecondary,
            backgroundColor: value ? `${value}10` : `${colors.textSecondary}10`
          }}
        >
          {icon || <PiPaintBrushBold className={`h-${iconSize} w-${iconSize}`} />}
        </button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-auto p-2" 
        align="end"
        style={{ 
          backgroundColor: colors.surface,
          borderColor: colors.border,
          borderWidth: 1,
          zIndex: 50
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div 
          className="grid grid-cols-4 gap-2"
          onClick={(e) => e.stopPropagation()}
        >
          {colorOptions.map(({ value: colorValue, label }) => (
            <button
              key={colorValue}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onChange(colorValue)
                setOpen(false)
              }}
              className="p-2 rounded-full transition-colors"
              style={{ 
                backgroundColor: colorValue,
                border: value === colorValue ? `2px solid ${colors.text}` : 'none'
              }}
              title={label}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
} 