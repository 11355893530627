import { useTranslation } from 'react-i18next';
import { useNotificationStore } from '../store/useNotificationStore';
import { useStore } from '../store/useStore';
import { NotificationData } from '../types/notification';
import { NotificationPreferences } from '../types/user';

const REMINDER_KEY = 'last_reminder_timestamp';

export const useNotifications = () => {
  const { t } = useTranslation();
  const { user } = useStore();
  const { addNotification } = useNotificationStore();

  const shouldShowNotification = (type: keyof NotificationPreferences): boolean => {
    return user?.preferences?.notifications?.[type] ?? false;
  };

  const shouldShowDailyReminder = (): boolean => {
    const lastReminder = localStorage.getItem(REMINDER_KEY);
    if (!lastReminder) return true;

    const lastReminderDate = new Date(lastReminder);
    const now = new Date();

    return (
      lastReminderDate.getDate() !== now.getDate() ||
      lastReminderDate.getMonth() !== now.getMonth() ||
      lastReminderDate.getFullYear() !== now.getFullYear()
    );
  };

  const updateLastReminderTimestamp = () => {
    localStorage.setItem(REMINDER_KEY, new Date().toISOString());
  };

  const showDailyReminder = () => {
    if (!shouldShowNotification('reminders')) {
      return;
    }

    const notification: Omit<NotificationData, 'id' | 'timestamp'> = {
      type: 'reminder',
      title: t('notifications.dailyReminder.title'),
      message: t('notifications.dailyReminder.message'),
      icon: 'PiHeart',
      duration: 10000,
      isHTML: true,
    };

    addNotification(notification);
    updateLastReminderTimestamp();
  };

  const initializeDailyReminder = () => {
    if (shouldShowDailyReminder()) {
      showDailyReminder();
    }

    const interval = setInterval(() => {
      if (shouldShowDailyReminder()) {
        showDailyReminder();
      }
    }, 3600000);

    return () => clearInterval(interval);
  };

  return {
    shouldShowNotification,
    showDailyReminder,
    initializeDailyReminder
  };
}; 