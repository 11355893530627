import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { analyticsService } from '../services/analyticsService'

export function usePageTracking() {
  const location = useLocation()
  const lastTrackedPath = useRef<string>('')

  useEffect(() => {
    // Only track if path has changed to avoid double tracking
    if (location.pathname !== lastTrackedPath.current) {
      analyticsService.trackPageView(location.pathname)
      lastTrackedPath.current = location.pathname
    }
  }, [location.pathname])
} 