import { useEffect } from 'react'
import { useAuthStore } from '../store/useAuthStore'
import { authService } from '../services/authService'
import { useLocalStorage } from './useLocalStorage'

const TIMEZONE_CACHE_KEY = 'user_timezone_last_check'
const TIMEZONE_CHECK_INTERVAL = 24 * 60 * 60 * 1000 // 24 hours

export function useTimezoneCheck() {
  const { user } = useAuthStore()
  const [lastCheck, setLastCheck] = useLocalStorage(TIMEZONE_CACHE_KEY, '0')

  useEffect(() => {
    if (!user?.uid) return

    const now = Date.now()
    const timeSinceLastCheck = now - parseInt(lastCheck)

    if (timeSinceLastCheck >= TIMEZONE_CHECK_INTERVAL) {
      authService.ensureUserTimezone(user.uid)
        .then(() => setLastCheck(now.toString()))
        .catch(console.warn)
    }
  }, [user, lastCheck, setLastCheck])
} 