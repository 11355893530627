import { motion, AnimatePresence } from "framer-motion"
import { useTheme } from "../contexts/ThemeContext"
import { useStore } from "../store/useStore"
import { PLANS } from "../constants/subscriptionPlans"
import { subscriptionService } from "../services/subscriptionService"
import { PiStarFour, PiSpinner, PiCrown, PiWarning, PiCheck, PiX } from "react-icons/pi"
import { useState } from "react"
import type { SubscriptionTier } from "../types/subscription"
import { useNavigate } from "react-router-dom"
import { AppError } from "../utils/errors"
import { useNotificationStore } from "../store/useNotificationStore"
import { addMonths } from 'date-fns'

export function UpgradePage() {
  const { colors } = useTheme()
  const { user, updateUser } = useStore()
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const currentTier = user?.subscription?.tier || "free"
  const navigate = useNavigate()
  const { addNotification } = useNotificationStore()

  const handleUpgrade = async (tier: SubscriptionTier) => {
    if (!user?.id) return
    
    setLoading(true)
    try {
      // Process subscription upgrade
      await subscriptionService.upgradeTier(user.id, tier)
      
      // Update user data with new subscription
      await updateUser({
        subscription: {
          tier,
          startDate: new Date(),
          status: 'active',
          currentPeriodEnd: addMonths(new Date(), 1)
        }
      })

      // Show success notification
      addNotification({
        type: 'success',
        title: 'Subscription Updated',
        message: `Successfully upgraded to ${tier} plan`
      })

      // Navigate to settings
      navigate('/settings')
    } catch (error) {
      console.error('Failed to upgrade:', error)
      
      // Show error notification
      addNotification({
        type: 'error',
        title: 'Upgrade Failed',
        message: error instanceof AppError 
          ? error.message 
          : 'Failed to upgrade subscription. Please try again.'
      })
    } finally {
      setLoading(false)
    }
  }

  // Simulate page loading
  useState(() => {
    setTimeout(() => setPageLoading(false), 1000)
  }, [])

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.3 },
  }

  return (
    <AnimatePresence>
      {pageLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex items-center justify-center h-screen"
        >
          <PiSpinner className="w-12 h-12 animate-spin" style={{ color: colors.primary }} />
        </motion.div>
      ) : (
        <motion.div
          key="content"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={fadeInUp}
          className="max-w-7xl mx-auto px-4 py-12"
        >
          {/* Enhanced Header */}
          <motion.div className="text-center mb-16" variants={fadeInUp}>
            <h1 className="text-4xl font-bold mb-4" style={{ color: colors.text }}>
              Elevate Your Experience
            </h1>
            <p className="text-xl mb-8" style={{ color: colors.textSecondary }}>
              Choose the perfect plan to unlock your full potential
            </p>
          </motion.div>

          {/* Plans Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {PLANS.map((plan, index) => {
              const isCurrentPlan = currentTier === plan.tier

              return (
                <motion.div
                  key={plan.tier}
                  variants={fadeInUp}
                  transition={{ delay: index * 0.1 }}
                  className={`p-6 rounded-lg relative shadow-lg hover:shadow-xl transition-shadow duration-300 ${
                    isCurrentPlan ? "ring-2 ring-offset-2" : ""
                  }`}
                  style={{
                    backgroundColor: colors.surface,
                    borderColor: colors.primary,
                  }}
                >
                  {isCurrentPlan && (
                    <div
                      className="absolute -top-3 left-1/2 transform -translate-x-1/2 px-4 py-1 rounded-full text-sm font-medium"
                      style={{
                        backgroundColor: colors.primary,
                        color: "white",
                      }}
                    >
                      Current Plan
                    </div>
                  )}

                  <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold mb-2" style={{ color: colors.text }}>
                      {plan.name}
                    </h2>
                    <p className="text-4xl font-bold" style={{ color: colors.primary }}>
                      {plan.price}
                    </p>
                    <p className="text-sm mt-2" style={{ color: colors.textSecondary }}>
                      per month
                    </p>
                  </div>

                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2" style={{ color: colors.textSecondary }}>
                        <PiStarFour className="w-5 h-5 flex-shrink-0" style={{ color: colors.primary }} />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={() => handleUpgrade(plan.tier)}
                    disabled={loading || isCurrentPlan}
                    className="w-full py-3 rounded-lg font-medium flex items-center justify-center gap-2 transition-colors duration-300"
                    style={{
                      backgroundColor: isCurrentPlan ? `${colors.primary}20` : colors.primary,
                      color: isCurrentPlan ? colors.primary : "white",
                      opacity: loading ? 0.7 : 1,
                    }}
                  >
                    {loading && <PiSpinner className="w-5 h-5 animate-spin" />}
                    {isCurrentPlan
                      ? "Current Plan"
                      : plan.tier === "free"
                        ? "Switch to Free Plan"
                        : `Upgrade to ${plan.name}`}
                  </button>
                </motion.div>
              )
            })}
          </div>

          {/* FAQ Section */}
          <motion.div variants={fadeInUp} className="mt-16">
            <h2 className="text-2xl font-bold mb-8 text-center" style={{ color: colors.text }}>
              Frequently Asked Questions
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                { q: "Can I change my plan later?", a: "Yes, you can upgrade or downgrade your plan at any time." },
                { q: "What payment methods do you accept?", a: "We accept all major credit cards and PayPal." },
                {
                  q: "Is there a long-term contract?",
                  a: "No, all plans are billed monthly and you can cancel anytime.",
                },
              ].map((item, index) => (
                <div key={index} className="p-6 rounded-lg" style={{ backgroundColor: colors.surface }}>
                  <h3 className="text-lg font-semibold mb-2" style={{ color: colors.text }}>
                    {item.q}
                  </h3>
                  <p style={{ color: colors.textSecondary }}>{item.a}</p>
                </div>
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}