import { 
  signInAnonymously as firebaseSignInAnonymously,
  signInWithPopup,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  GoogleAuthProvider,
  Auth,
  UserCredential
} from 'firebase/auth'
import { doc, updateDoc, serverTimestamp, setDoc, getDoc, Timestamp } from 'firebase/firestore'
import { db, auth } from '../utils/firebase'
import { AppError } from '../utils/errors'
import { addMonths } from 'date-fns'
import { FirebaseUser } from '../types/firebase'
import { User, defaultUserPreferences, defaultGamificationState } from '../types/user'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits'
import { encryptionService } from '../services/encryptionService'

// Update the DEFAULT_AI_SETTINGS to be more consistent
const DEFAULT_AI_SETTINGS = {
  name: 'Tydy',
  avatarColor: '/avatars/robot-blue.png',
  personalityPreset: 'friendly-coach',
  apiKey: null
} as const

// Add timezone cache constants
const TIMEZONE_CACHE_KEY = 'user_timezone_last_check'
const TIMEZONE_CHECK_INTERVAL = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

// Helper function to generate default name
function generateDefaultName(email: string | null, isAnonymous: boolean): string {
  if (isAnonymous) return 'Guest User'
  if (!email) return 'Tydy User'
  
  // Use email prefix before @ as name, capitalize first letter
  const namePart = email.split('@')[0]
  return namePart
    .split(/[._-]/) // Split on common email separators
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

// Add this helper function at the top of the file
function getDefaultTimezone(): string {
  try {
    // Get timezone from browser
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone || 'UTC';
  } catch (error) {
    console.warn('Failed to get timezone:', error);
    return 'UTC';
  }
}

async function createUserProfile(userId: string, userData: any) {
  const userRef = doc(db, 'users', userId)
  
  if (!(await getDoc(userRef)).exists()) {
    const encryptionKey = encryptionService.generateUserKey()
    const encryptedKey = encryptionService.encrypt(
      encryptionKey,
      auth.currentUser!.refreshToken
    )

    const now = new Date()
    const defaultName = generateDefaultName(userData.email, userData.isAnonymous)
    
    const newUser = {
      id: userId,
      email: userData.email || null,
      name: userData.displayName || defaultName,
      displayName: userData.displayName || defaultName,
      photoURL: userData.photoURL || null,
      isAnonymous: userData.isAnonymous || false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastLogin: serverTimestamp(),
      preferences: {
        ...defaultUserPreferences,
        security: {
          ...defaultUserPreferences.security,
          encryptionKey: encryptedKey,
          lastPasswordChange: Timestamp.fromDate(now)
        }
      },
      version: 1,
      accountStatus: 'active' as const,
      emailVerified: userData.emailVerified || false,
      language: navigator.language.split('-')[0] || 'en', // Use browser language
      timezone: getDefaultTimezone(), // Use browser timezone
      role: 'user' as const,
      bio: null,
      location: null,
      website: null,
      aiSettings: DEFAULT_AI_SETTINGS,
      subscription: {
        tier: 'free' as const,
        startDate: serverTimestamp(),
        currentPeriodEnd: serverTimestamp(),
        status: 'active' as const,
        features: {
          finance: false,
          calendar: false,
          lists: false,
          mood: false,
          notebooks: false
        }
      }
    }

    await setDoc(userRef, newUser)
  }
}

// Update the ensureUserTimezone function
async function ensureUserTimezone(userId: string) {
  try {
    // Check local storage for last update time
    const lastCheck = localStorage.getItem(TIMEZONE_CACHE_KEY)
    const now = Date.now()
    
    if (lastCheck) {
      const timeSinceLastCheck = now - parseInt(lastCheck)
      // If checked within last 24 hours, skip
      if (timeSinceLastCheck < TIMEZONE_CHECK_INTERVAL) {
        return
      }
    }

    const userRef = doc(db, 'users', userId)
    const userDoc = await getDoc(userRef)
    
    if (userDoc.exists()) {
      const userData = userDoc.data()
      const browserTimezone = getDefaultTimezone()
      
      // Only update if timezone is UTC or different from browser timezone
      if (userData.timezone === 'UTC' || userData.timezone !== browserTimezone) {
        await updateDoc(userRef, {
          timezone: browserTimezone,
          updatedAt: serverTimestamp()
        })
      }

      // Update last check time
      localStorage.setItem(TIMEZONE_CACHE_KEY, now.toString())
    }
  } catch (error) {
    console.warn('Failed to update timezone:', error)
  }
}

// Update the updateLastLogin function to also check timezone
async function updateLastLogin(userId: string) {
  try {
    const userRef = doc(db, 'users', userId)
    await updateDoc(userRef, {
      lastLogin: serverTimestamp(),
      updatedAt: serverTimestamp()
    })
    
    // Check and update timezone if needed
    await ensureUserTimezone(userId)
  } catch (error) {
    console.error('Error updating last login:', error)
    // Non-critical error, don't throw
  }
}

export const ensureUserDocument = async (user: FirebaseUser): Promise<void> => {
  const userRef = doc(db, 'users', user.uid)
  const userDoc = await getDoc(userRef)

  if (!userDoc.exists()) {
    const now = new Date()
    const nextReset = addMonths(now, 1)
    const monthlyCredits = SUBSCRIPTION_LIMITS.aiCredits.monthly.free
    const defaultName = generateDefaultName(user.email, user.isAnonymous)

    const newUser = {
      id: user.uid,
      email: user.email || null,
      name: user.displayName || defaultName,
      displayName: user.displayName || defaultName,
      photoURL: user.photoURL || null,
      emailVerified: user.emailVerified || false,
      isAnonymous: user.isAnonymous || false,
      subscription: {
        tier: 'free' as const,
        status: 'active' as const,
        currentPeriodEnd: Timestamp.fromDate(addMonths(new Date(), 1)),
        startDate: Timestamp.fromDate(now),
        features: {
          finance: false,
          calendar: false,
          lists: false,
          mood: false,
          notebooks: false
        }
      },
      credits: {
        remaining: monthlyCredits,
        used: 0,
        lastReset: Timestamp.fromDate(now),
        nextReset: Timestamp.fromDate(nextReset)
      },
      preferences: defaultUserPreferences,
      createdAt: Timestamp.fromDate(now),
      updatedAt: Timestamp.fromDate(now),
      lastLogin: Timestamp.fromDate(now),
      aiSettings: DEFAULT_AI_SETTINGS,
      gamification: defaultGamificationState,
      language: navigator.language.split('-')[0] || 'en',
      timezone: getDefaultTimezone(),
      role: 'user' as const,
      accountStatus: 'active' as const,
      bio: null,
      location: null,
      website: null,
      version: 1,
      security: {
        encryptionKey: null,
        encryptedFields: [],
        twoFactorEnabled: false,
        lastPasswordChange: Timestamp.fromDate(now)
      }
    }

    await setDoc(userRef, newUser)
  } else {
    // Ensure AI settings exist in existing user document
    const userData = userDoc.data()
    if (!userData.aiSettings) {
      await updateDoc(userRef, {
        aiSettings: DEFAULT_AI_SETTINGS,
        updatedAt: serverTimestamp()
      })
    }
  }
}

// Update all sign-in methods to use the new updateLastLogin
export const authService = {
  signInAnonymously: async (): Promise<UserCredential> => {
    try {
      const result = await firebaseSignInAnonymously(auth)
      await createUserProfile(result.user.uid, {
        isAnonymous: true,
        email: null,
        displayName: 'Guest User',
        photoURL: '/icons/default-avatar.png'
      })
      await updateLastLogin(result.user.uid) // This will now also check timezone
      await ensureUserDocument(result.user)
      return result
    } catch (error) {
      console.error('[AuthService] Anonymous sign in failed:', error)
      throw new AppError('ANONYMOUS_SIGN_IN_FAILED', 'Failed to sign in as guest')
    }
  },

  signInWithGoogle: async () => {
    try {
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      try {
        await createUserProfile(result.user.uid, result.user)
      } catch (error) {
        if (!(error instanceof AppError && error.code === 'USER_EXISTS')) {
          throw error
        }
      }
      await updateLastLogin(result.user.uid) // This will now also check timezone
      await ensureUserDocument(result.user)
      return result
    } catch (error) {
      console.error('[AuthService] Google sign in failed:', error)
      throw new AppError('GOOGLE_SIGN_IN_FAILED', 'Failed to sign in with Google')
    }
  },

  signInWithEmailAndPassword: async (email: string, password: string) => {
    try {
      const result = await firebaseSignInWithEmailAndPassword(auth, email, password)
      await updateLastLogin(result.user.uid) // This will now also check timezone
      await ensureUserDocument(result.user)
      return result
    } catch (error) {
      console.error('[AuthService] Email/password sign in failed:', error)
      throw new AppError('EMAIL_SIGN_IN_FAILED', 'Failed to sign in with email/password')
    }
  },

  ensureUserDocument,
  ensureUserTimezone // Export the new function
} 