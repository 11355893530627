export const SUBSCRIPTION_LIMITS = {
  notebooks: {
    free: 2,
    core: 10,
    pro: Infinity
  },
  notesPerNotebook: {
    free: 3,
    core: 15,
    pro: Infinity
  },


  noteCharacterLimit: {
    free: 5000,
    core: Infinity,
    pro: Infinity


  },
  lists: {
    free: 3,
    core: 10,
    pro: Infinity
  },


  itemsPerList: {
    free: 15,
    core: 50,
    pro: Infinity
  },


  features: {
    aiEditor: ['core', 'pro'] as const,
    budgets: ['core', 'pro'] as const,
    assets: ['core', 'pro'] as const,
    calendar: ['core', 'pro'] as const,
    moodInsights: ['core', 'pro'] as const,
    monthlyOverview: ['core', 'pro'] as const

  },
  // We can easily add more limits here as needed
  transactions: {
    monthsHistory: {
      free: 3,
      core: 12,
      pro: Infinity
    }


  },
  moodHistory: {
    monthsHistory: {
      free: 2,
      core: 12,
      pro: Infinity
    }


  },
  budgets: {
    free: 3,
    core: 10,
    pro: Infinity
  },


  aiCredits: {
    monthly: {
      free: 30,
      core: 200,
      pro: 500
    },
    purchase: {
      small: {
        amount: 100,
        price: 3
      },
      medium: {
        amount: 500,
        price: 10
      },

      large: {
        amount: 1000,
        price: 18
      }

    },
    lowThreshold: 0.2,
    warningThreshold: 0.4
  }
} as const;

// Type helper for accessing limits
export type FeatureLimits = typeof SUBSCRIPTION_LIMITS; 