import { Timestamp } from 'firebase/firestore'

export type ActivityMetadata = {
  notebookId?: string
  noteId?: string
  moodId?: string
  habitId?: string
  financeId?: string
  listId?: string
  eventId?: string
  themeId?: string
  [key: string]: string | undefined
}

export type ActivityType = 
  | 'notebook_created'
  | 'notebook_updated'
  | 'notebook_deleted'
  | 'note_created'
  | 'note_updated'
  | 'note_deleted'
  | 'mood_tracked'
  | 'habit_created'
  | 'habit_completed'
  | 'finance_entry_created'
  | 'list_created'
  | 'list_completed'
  | 'calendar_event_created'
  | 'calendar_event_updated'
  | 'calendar_event_deleted'
  | 'calendar_events_imported'
  | 'profile_updated'
  | 'theme_updated'
  | 'finance_transaction_created'
  | 'finance_transaction_updated'
  | 'finance_transaction_deleted'
  | 'finance_budget_created'
  | 'finance_budget_updated'
  | 'finance_budget_deleted'
  | 'finance_todo_created'
  | 'finance_todo_updated'
  | 'finance_todo_completed'
  | 'finance_todo_deleted'
  | 'list_updated'
  | 'list_deleted'
  | 'list_item_created'
  | 'list_item_updated'
  | 'list_item_completed'
  | 'list_item_deleted'
  | 'mood_entry_created'
  | 'mood_entry_updated'
  | 'mood_entry_deleted'
  | 'preferences_updated'
  | 'theme_created'
  | 'theme_deleted'
  | 'ai_insights_generated'

export interface Activity {
  id: string
  userId: string
  type: ActivityType
  title: string
  description?: string
  metadata?: ActivityMetadata
  createdAt: Date
  relatedItemId?: string
  relatedItemType?: string
  updatedAt?: Date
}

export interface FirestoreActivity extends Omit<Activity, 'createdAt' | 'updatedAt'> {
  createdAt: Timestamp
  updatedAt?: Timestamp
}

export const ACTIVITY_DISPLAY_LIMIT = 7  // Maximum number of activities to keep/show
export const ACTIVITY_CACHE_LIMIT = 7    // Keep same as display limit
export const ACTIVITY_MAX_AGE_DAYS = 7   // Reduced to 7 days for more frequent cleanup

export interface ActivityFilters {
  type?: ActivityType[]
  startDate?: Date
  endDate?: Date
  limit?: number
  excludeIds?: string[]  // For pagination/infinite scroll
} 