import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '../../contexts/ThemeContext'
import { List } from '../../types/lists'
import { 
  PiPencilSimple,
  PiTrash,
  PiCheckCircle,
  PiClockCountdown,
  PiList,
  PiX,
  PiDotsThreeVertical
} from 'react-icons/pi'
import * as Pi from 'react-icons/pi'
import { IconPicker } from '../shared/pickers/IconPicker'
import { ColorPicker } from '../shared/pickers/ColorPicker'
import { motion, AnimatePresence } from 'framer-motion'

interface ListCardProps {
  list: List
  itemCount: number
  completedCount: number
  onDelete?: (id: string) => void
  onUpdateList?: (id: string, updates: Partial<List>) => void
}

export function ListCard({ 
  list, 
  itemCount,
  completedCount,
  onDelete, 
  onUpdateList 
}: ListCardProps) {
  const { colors } = useTheme()
  const [isHovered, setIsHovered] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editTitle, setEditTitle] = useState(list.title)
  const [editDescription, setEditDescription] = useState(list.description || '')
  const [editIcon, setEditIcon] = useState(list.icon || 'PiList')
  const [editColor, setEditColor] = useState(list.color || '#3B82F6')

  const IconComponent = list.icon && Pi[list.icon as keyof typeof Pi]
  const Icon = IconComponent || PiList

  const handleSave = () => {
    if (!onUpdateList) return

    onUpdateList(list.id, {
      title: editTitle,
      description: editDescription || undefined,
      icon: editIcon,
      color: editColor
    })
    setIsEditing(false)
  }

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete && window.confirm('Are you sure you want to delete this list?')) {
      onDelete(list.id)
    }
  }

  if (isEditing) {
    return (
      <motion.div 
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="p-4 rounded-lg"
        style={{ 
          backgroundColor: colors.surface,
          border: `1px solid ${colors.border}`
        }}
      >
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <motion.input
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              type="text"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              className="flex-1 px-2 py-1 rounded"
              style={{
                backgroundColor: colors.surfaceAlt,
                color: colors.text,
                border: `1px solid ${colors.border}`
              }}
            />
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsEditing(false)}
              className="p-1 rounded-full hover:bg-opacity-10 transition-colors ml-2"
              style={{ 
                color: colors.error,
                backgroundColor: `${colors.error}10`
              }}
            >
              <PiX className="w-4 h-4" />
            </motion.button>
          </div>

          <motion.textarea
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1 }}
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            className="w-full px-2 py-1 rounded"
            style={{
              backgroundColor: colors.surfaceAlt,
              color: colors.text,
              border: `1px solid ${colors.border}`
            }}
            rows={2}
          />

          <motion.div 
            className="flex items-center gap-2"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <IconPicker value={editIcon} onChange={setEditIcon} />
            <ColorPicker value={editColor} onChange={setEditColor} />
            <div className="flex-1" />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSave}
              className="px-3 py-1 rounded"
              style={{
                backgroundColor: colors.primary,
                color: '#FFFFFF'
              }}
            >
              Save
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
    )
  }

  return (
    <Link 
      to={`/lists/${list.id}`}
      className="block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div 
        className="p-4 rounded-lg transition-all duration-200"
        style={{ 
          backgroundColor: `${list.color || colors.surface}10`,
          border: `1px solid ${list.color || colors.border}`,
        }}
        whileHover={{ 
          y: -4,
          boxShadow: `0 4px 6px ${colors.shadow}15`
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <motion.div 
            className="flex items-center gap-2"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            <Icon 
              className="h-6 w-6" 
              style={{ color: list.color || colors.primary }}
            />
            <h3 
              className="font-medium" 
              style={{ color: list.color || colors.text }}
            >
              {list.title}
            </h3>
          </motion.div>
          
          <AnimatePresence>
            {isHovered && (
              <motion.div 
                className="flex items-center gap-2"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
              >
                {onUpdateList && (
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.preventDefault()
                      setIsEditing(true)
                    }}
                    className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
                    style={{ 
                      color: list.color || colors.textSecondary,
                      backgroundColor: `${list.color || colors.textSecondary}10`
                    }}
                  >
                    <PiPencilSimple className="w-4 h-4" />
                  </motion.button>
                )}
                
                {onDelete && (
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleDelete}
                    className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
                    style={{ 
                      color: colors.error,
                      backgroundColor: `${colors.error}10`
                    }}
                  >
                    <PiTrash className="w-4 h-4" />
                  </motion.button>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {list.description && (
          <motion.p 
            className="text-sm mb-4"
            style={{ color: list.color || colors.textSecondary }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            {list.description}
          </motion.p>
        )}

        <motion.div 
          className="flex items-center gap-4"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div 
            className="flex items-center gap-1 text-sm"
            style={{ color: colors.success }}
          >
            <PiCheckCircle className="w-4 h-4" />
            <span>{completedCount} completed</span>
          </div>
          
          <div 
            className="flex items-center gap-1 text-sm"
            style={{ color: colors.warning }}
          >
            <PiClockCountdown className="w-4 h-4" />
            <span>{itemCount - completedCount} pending</span>
          </div>
        </motion.div>
      </motion.div>
    </Link>
  )
} 