import { useTheme } from '../../contexts/ThemeContext';
import { PieChart as RechartsPieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

interface PieChartProps {
  data: Record<string, number>;
  title: string;
}

export function PieChart({ data, title }: PieChartProps) {
  const { colors } = useTheme();
  
  const chartData = Object.entries(data).map(([name, value]) => ({
    name,
    value
  }));

  const COLORS = [
    colors.primary,
    colors.secondary,
    colors.tertiary,
    colors.quaternary,
    colors.accent1,
    colors.accent2,
  ];

  return (
    <div>
      <h3 className="text-lg font-medium mb-4" style={{ color: colors.text }}>
        {title}
      </h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <RechartsPieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill={colors.primary}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[index % COLORS.length]} 
                />
              ))}
            </Pie>
            <Tooltip 
              contentStyle={{ 
                backgroundColor: colors.surface,
                border: `1px solid ${colors.border}`,
                borderRadius: '8px'
              }}
              labelStyle={{ color: colors.text }}
            />
          </RechartsPieChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        {chartData.map((entry, index) => (
          <div key={entry.name} className="flex items-center">
            <div 
              className="w-3 h-3 rounded-full mr-2"
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            />
            <span className="text-sm" style={{ color: colors.textSecondary }}>
              {entry.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
} 