import { useTheme } from '../../contexts/ThemeContext';
import { MoodEntry, DayPeriod, moodTypes, periodLabels } from '../../types/mood';
import { useState, useEffect, useRef } from 'react';
import { PiPencilSimple } from 'react-icons/pi';
import { motion } from 'framer-motion';
import { analyticsService, AnalyticsEventTypes } from '../../services/analyticsService';
import { trackAppAction } from '../../services/analyticsService';

interface MoodCircleProps {
  selectedDate: Date;
  moodEntries?: MoodEntry[];
  onEditMood?: (entry: MoodEntry) => void;
  onSegmentSelect: (day: number, period: DayPeriod, e: React.MouseEvent) => void;
  selectedPeriod: DayPeriod | null;
}

export const MoodCircle = ({ 
  selectedDate, 
  moodEntries = [],
  onEditMood,
  onSegmentSelect,
  selectedPeriod
}: MoodCircleProps) => {
  const { colors } = useTheme();
  const daysInMonth = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();

  // Constants for circle dimensions
  const centerX = 300;
  const centerY = 300;
  const outerRadius = 220;
  const numberRadius = 250;
  const ringRadii = [
    outerRadius,    // 260
    outerRadius * 0.75, // 195
    outerRadius * 0.5,  // 130
    outerRadius * 0.25  // 65
  ];

  // Add state for hover tracking
  const [hoveredSegment, setHoveredSegment] = useState<{
    day: number;
    period?: DayPeriod;
  } | null>(null);

  const hasTrackedInitialView = useRef(false);

  // Track initial render and date changes
  useEffect(() => {
    if (!hasTrackedInitialView.current) {
      try {
        trackAppAction('mood_circle', 'view', {
          date: selectedDate.toISOString(),
          total_entries: moodEntries.length
        });
        hasTrackedInitialView.current = true;
      } catch (error) {
        console.warn('Failed to track mood circle view:', error);
      }
    }
  }, [selectedDate, moodEntries.length]);

  // Reset the ref when date changes
  useEffect(() => {
    hasTrackedInitialView.current = false;
  }, [selectedDate]);

  // Generate the circular grid
  const generateCircleGrid = () => {
    const paths: string[] = [];
    
    // Add rings (concentric circles)
    ringRadii.forEach(radius => {
      paths.push(`M ${centerX - radius} ${centerY} a ${radius} ${radius} 0 1 0 ${radius * 2} 0 a ${radius} ${radius} 0 1 0 ${-radius * 2} 0`);
    });

    // Add radial lines for each day, starting from top (270 degrees) and going clockwise
    for (let day = 1; day <= daysInMonth; day++) {
      const angle = ((day - 1) * 360) / daysInMonth - 90; // Subtract 90 degrees to start from top
      const radian = (angle * Math.PI) / 180;
      const outerX = centerX + outerRadius * Math.cos(radian);
      const outerY = centerY + outerRadius * Math.sin(radian);
      paths.push(`M ${centerX} ${centerY} L ${outerX} ${outerY}`);
    }

    return paths;
  };

  // Get the path for a specific section of a day's segment
  const getDaySegmentPath = (day: number, period: DayPeriod): string => {
    // Adjust angles to start from top
    const startAngle = ((day - 1) * 360) / daysInMonth - 90;
    const endAngle = (day * 360) / daysInMonth - 90;
    
    let innerR: number;
    let outerR: number;
    
    switch(period) {
      case 'morning': // Innermost
        innerR = 0;           // Start from center
        outerR = ringRadii[3]; // 65
        break;
      case 'afternoon': // Second from center
        innerR = ringRadii[3]; // 65
        outerR = ringRadii[2]; // 130
        break;
      case 'evening': // Third from center
        innerR = ringRadii[2]; // 130
        outerR = ringRadii[1]; // 195
        break;
      case 'night': // Outermost
        innerR = ringRadii[1]; // 195
        outerR = ringRadii[0]; // 260
        break;
    }

    const startRadians = (startAngle * Math.PI) / 180;
    const endRadians = (endAngle * Math.PI) / 180;

    // Calculate the four corners of the segment
    const innerStartX = centerX + innerR * Math.cos(startRadians);
    const innerStartY = centerY + innerR * Math.sin(startRadians);
    const innerEndX = centerX + innerR * Math.cos(endRadians);
    const innerEndY = centerY + innerR * Math.sin(endRadians);
    const outerStartX = centerX + outerR * Math.cos(startRadians);
    const outerStartY = centerY + outerR * Math.sin(startRadians);
    const outerEndX = centerX + outerR * Math.cos(endRadians);
    const outerEndY = centerY + outerR * Math.sin(endRadians);

    // Create a path that goes around the segment
    return `
      M ${innerStartX} ${innerStartY}
      L ${outerStartX} ${outerStartY}
      A ${outerR} ${outerR} 0 0 1 ${outerEndX} ${outerEndY}
      L ${innerEndX} ${innerEndY}
      A ${innerR} ${innerR} 0 0 0 ${innerStartX} ${innerStartY}
      Z
    `;
  };

  // Get moods for a specific day
  const getMoodsByDay = (day: number) => {
    const date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    return moodEntries.filter(entry => {
      const entryDate = new Date(entry.date);
      return (
        entryDate.getDate() === date.getDate() &&
        entryDate.getMonth() === date.getMonth() &&
        entryDate.getFullYear() === date.getFullYear()
      );
    });
  };

  // Enhanced segment click handler with analytics
  const handleSegmentClick = (day: number, period: DayPeriod, existingEntry?: MoodEntry, e: React.MouseEvent) => {
    // Stop the event from bubbling up
    if (e) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
    }

    try {
      if (existingEntry && onEditMood) {
        trackAppAction('mood_circle', 'edit_mood', {
          day,
          period,
          mood: existingEntry.mood
        });
        onEditMood(existingEntry);
      } else {
        trackAppAction('mood_circle', 'add_mood', {
          day,
          period,
          date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day).toISOString()
        });
        onSegmentSelect(day, period, e);
      }
    } catch (error) {
      console.warn('Failed to track mood circle interaction:', error);
      if (existingEntry && onEditMood) {
        onEditMood(existingEntry);
      } else {
        onSegmentSelect(day, period, e);
      }
    }
  };

  // Simplified hover handler without analytics
  const handleSegmentHover = (day: number, period: DayPeriod) => {
    setHoveredSegment({ day, period });
  };

  const gridPaths = generateCircleGrid();

  const pathVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: { 
      pathLength: 1, 
      opacity: 1,
      transition: { 
        duration: 1.5,
        ease: "easeInOut" 
      }
    }
  };

  // Update the getMoodColor function to handle undefined/null values
  const getMoodColor = (mood: string | undefined) => {
    if (!mood) return 'transparent'
    
    // Try direct lookup first
    if (moodTypes[mood as keyof typeof moodTypes]?.color) {
      return moodTypes[mood as keyof typeof moodTypes].color
    }
    
    // Try case-insensitive lookup
    const normalizedMood = Object.keys(moodTypes).find(
      key => key.toLowerCase() === mood.toLowerCase()
    )
    return normalizedMood ? moodTypes[normalizedMood as keyof typeof moodTypes].color : 'transparent'
  }

  return (
    <div className="relative w-full max-w-[600px] mx-auto">
      <div className="aspect-square">
        <motion.svg 
          viewBox="0 0 600 600" 
          className="w-full h-full"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {/* Base grid with animation */}
          {gridPaths.map((path, index) => (
            <motion.path
              key={index}
              d={path}
              fill="none"
              stroke={colors.border}
              strokeWidth="1.5"
              strokeLinecap="round"
              variants={pathVariants}
              initial="initial"
              animate="animate"
              transition={{ 
                delay: index * 0.08,
                duration: 1.5,
                ease: "easeInOut"
              }}
            />
          ))}

          {/* Day numbers */}
          {Array.from({ length: daysInMonth }, (_, i) => i + 1).map(day => {
            const angle = ((day - 0.5) * 360) / daysInMonth - 90;
            const radian = (angle * Math.PI) / 180;
            const x = centerX + numberRadius * Math.cos(radian);
            const y = centerY + numberRadius * Math.sin(radian);
            const isSelectedDay = day === selectedDate.getDate();

            return (
              <motion.g 
                key={`number-${day}`}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ 
                  delay: 0.8 + (day * 0.02),
                  duration: 0.2
                }}
              >
                <motion.circle
                  cx={x}
                  cy={y}
                  r="12"
                  fill={isSelectedDay ? `${colors.primary}20` : 'transparent'}
                  stroke={isSelectedDay ? colors.primary : 'transparent'}
                  whileHover={{ scale: 1.2 }}
                />
                <motion.text
                  x={x}
                  y={y}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  className="text-xs font-medium"
                  fill={isSelectedDay ? colors.primary : colors.text}
                  whileHover={{ scale: 1.2 }}
                >
                  {day}
                </motion.text>
              </motion.g>
            );
          })}

          {/* Day segments */}
          {Array.from({ length: daysInMonth }, (_, i) => i + 1).map(day => {
            const dayMoods = getMoodsByDay(day);
            const periods: DayPeriod[] = ['morning', 'afternoon', 'evening', 'night'];

            return (
              <g key={day}>
                {periods.map(period => {
                  const existingEntry = dayMoods.find(entry => entry.period === period);
                  const isHovered = hoveredSegment?.day === day && hoveredSegment?.period === period;
                  const isSelected = selectedDate.getDate() === day && selectedPeriod === period;
                  
                  return (
                    <g key={period}>
                      <motion.path
                        d={getDaySegmentPath(day, period)}
                        fill={existingEntry ? getMoodColor(existingEntry.mood) : 'transparent'}
                        fillOpacity={existingEntry ? 0.8 : 0}
                        stroke={isHovered || isSelected ? colors.primary : 'transparent'}
                        strokeWidth={isHovered || isSelected ? 2 : 1}
                        className="cursor-pointer"
                        whileHover={{ scale: 1.02 }}
                        onMouseEnter={() => handleSegmentHover(day, period)}
                        onMouseLeave={() => setHoveredSegment(null)}
                        onClick={(e) => {
                          // Handle click event here with additional event prevention
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          e.preventDefault();
                          handleSegmentClick(day, period, existingEntry, e);
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ 
                          delay: 1.0 + ((day * 4 + ['morning', 'afternoon', 'evening', 'night'].indexOf(period)) * 0.01),
                          duration: 0.2
                        }}
                      />
                      {(isHovered || isSelected) && existingEntry && (
                        <motion.g
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ 
                            type: "spring", 
                            stiffness: 200,
                            damping: 15
                          }}
                        >
                          <motion.circle
                            cx={centerX}
                            cy={centerY}
                            r={10}
                            fill={colors.primary}
                            className="cursor-pointer"
                            whileHover={{ scale: 1.2 }}
                          />
                          <motion.g
                            whileHover={{ rotate: 180 }}
                            style={{ originX: centerX, originY: centerY }}
                          >
                            <PiPencilSimple
                              x={centerX - 6}
                              y={centerY - 6}
                              size={12}
                              color="white"
                              className="cursor-pointer"
                            />
                          </motion.g>
                        </motion.g>
                      )}
                    </g>
                  );
                })}
              </g>
            );
          })}
        </motion.svg>
      </div>
    </div>
  );
}; 