import { motion } from 'framer-motion'
import { useTheme } from '../../contexts/ThemeContext'
import { PiCircle, PiListBullets } from 'react-icons/pi'

interface MoodViewToggleProps {
  view: 'circle' | 'timeline'
  onChange: (view: 'circle' | 'timeline') => void
}

export function MoodViewToggle({ view, onChange }: MoodViewToggleProps) {
  const { colors } = useTheme()

  return (
    <div 
      className="inline-flex rounded-lg p-1"
      style={{ 
        backgroundColor: colors.surface,
        border: `1px solid ${colors.border}`
      }}
    >
      <motion.button
        className="px-3 py-1.5 rounded flex items-center gap-2"
        style={{ 
          backgroundColor: view === 'circle' ? colors.primary : 'transparent',
          color: view === 'circle' ? 'white' : colors.textSecondary
        }}
        whileHover={{ opacity: 0.9 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => onChange('circle')}
      >
        <PiCircle size={18} />
        <span className="text-sm">Circle</span>
      </motion.button>
      <motion.button
        className="px-3 py-1.5 rounded flex items-center gap-2"
        style={{ 
          backgroundColor: view === 'timeline' ? colors.primary : 'transparent',
          color: view === 'timeline' ? 'white' : colors.textSecondary
        }}
        whileHover={{ opacity: 0.9 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => onChange('timeline')}
      >
        <PiListBullets size={18} />
        <span className="text-sm">Timeline</span>
      </motion.button>
    </div>
  )
} 