import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { useMoodStore } from '../../store/useMoodStore';
import { MoodEntry, MoodType, moodTypes, moodIcons } from '../../types/mood';
import { 
  PiTrash, 
  PiX, 
  PiCheck,
  PiNotepad,
  PiWarning,
  PiPencilSimple
} from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import { trackAppAction } from '../../services/analyticsService';
import React from 'react';


interface MoodEditModalProps {
  entry: MoodEntry;
  onClose: () => void;
}


export const MoodEditModal = ({ entry, onClose }: MoodEditModalProps) => {
  const { colors } = useTheme();
  const { updateEntry, deleteEntry } = useMoodStore();
  const [selectedMood, setSelectedMood] = useState<MoodType>(entry.mood);
  const [note, setNote] = useState(entry.note || '');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSave = async () => {
    try {
      await updateEntry(entry.id, {
        mood: selectedMood,
        note: note || undefined
      });

      trackAppAction('mood', 'update', {
        entry_id: entry.id,
        original_mood: entry.mood,
        new_mood: selectedMood,
        has_note: Boolean(note),
        date: entry.date,
        period: entry.period
      });

      onClose();
    } catch (error) {
      console.error('Failed to update mood:', error);
    }
  };

  const handleDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);
      return;
    }
    
    try {
      await deleteEntry(entry.id);
      
      trackAppAction('mood', 'delete', {
        entry_id: entry.id,
        mood: entry.mood,
        date: entry.date,
        period: entry.period
      });

      onClose();
    } catch (error) {
      console.error('Failed to delete mood:', error);
      setIsDeleting(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <motion.div 
          initial={{ scale: 0.9, opacity: 0, y: 20 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          exit={{ scale: 0.9, opacity: 0, y: 20 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="rounded-lg p-6 max-w-md w-full space-y-6"
          style={{ backgroundColor: colors.background }}
        >
          <motion.div 
            className="space-y-2"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <div className="flex justify-between items-start">
              <h3 
                className="text-lg font-medium flex items-center gap-2"
                style={{ color: colors.primary }}
              >
                <PiPencilSimple size={24} />
                Edit Mood Entry
              </h3>
              <button
                onClick={onClose}
                className="p-1 rounded-lg hover:opacity-80 transition-opacity"
                style={{ color: colors.text }}
              >
                <PiX size={20} />
              </button>
            </div>
            <div 
              className="text-sm space-y-1" 
              style={{ color: colors.textSecondary }}
            >
              <div className="flex items-center gap-1">
                Created: {new Date(entry.createdAt).toLocaleString()}
              </div>
              {entry.updatedAt && 
               new Date(entry.updatedAt).getTime() !== new Date(entry.createdAt).getTime() && (
                <div className="flex items-center gap-1">
                  Last modified: {new Date(entry.updatedAt).toLocaleString()}
                </div>
              )}
            </div>
          </motion.div>

          {/* Mood Selection with staggered animation */}
          <motion.div 
            className="grid grid-cols-3 sm:grid-cols-6 gap-4"
          >
            {Object.entries(moodTypes).map(([key, mood], index) => (
              <motion.button
                key={key}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  delay: index * 0.05,
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedMood(key as MoodType)}
                className="p-3 rounded-lg flex flex-col items-center justify-center aspect-square"
                style={{
                  backgroundColor: selectedMood === key 
                    ? `${moodTypes[key as MoodType].color}20`
                    : colors.surface,
                  border: `2px solid ${selectedMood === key 
                    ? moodTypes[key as MoodType].color
                    : 'transparent'}`
                }}
              >
                <div 
                  className="flex items-center justify-center mb-1"
                  style={{ 
                    color: selectedMood === key 
                      ? moodTypes[key as MoodType].color
                      : colors.text 
                  }}
                >
                  {React.createElement(moodIcons[key as MoodType], { 
                    size: 28,
                    weight: selectedMood === key ? "fill" : "regular"
                  })}
                </div>
                <div 
                  className="text-xs font-medium"
                  style={{ 
                    color: selectedMood === key 
                      ? moodTypes[key as MoodType].color
                      : colors.text
                  }}
                >
                  {mood.label}
                </div>
              </motion.button>
            ))}
          </motion.div>

          {/* Note section with animation */}
          <motion.div 
            className="space-y-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <label 
              className="text-sm font-medium flex items-center gap-2"
              style={{ color: colors.text }}
            >
              <PiNotepad size={16} />
              Note
            </label>
            <textarea
              placeholder="Add a note (optional)"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="w-full p-3 rounded-lg resize-none"
              style={{
                backgroundColor: colors.surface,
                color: colors.text,
                border: `1px solid ${colors.border}`
              }}
              rows={3}
            />
          </motion.div>

          {/* Action buttons with special delete animation */}
          <motion.div 
            className="flex justify-end gap-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={isDeleting ? {
                scale: [1, 1.1, 1],
                backgroundColor: colors.error,
                color: 'white',
                transition: { duration: 0.3 }
              } : {}}
              onClick={handleDelete}
              className="px-4 py-2 rounded-lg flex items-center gap-2"
              style={{
                backgroundColor: isDeleting ? colors.error : `${colors.error}20`,
                color: isDeleting ? 'white' : colors.error
              }}
            >
              <motion.span
                animate={{ rotate: isDeleting ? 360 : 0 }}
                transition={{ duration: 0.3 }}
              >
                {isDeleting ? <PiWarning size={20} /> : <PiTrash size={20} />}
              </motion.span>
              {isDeleting ? 'Confirm Delete' : 'Delete'}
            </motion.button>
            <button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
              className="px-4 py-2 rounded-lg flex items-center gap-2"
              style={{
                backgroundColor: colors.surface,
                color: colors.text
              }}
            >
              <PiX size={20} />
              Cancel
            </button>
            <button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSave}
              className="px-4 py-2 rounded-lg flex items-center gap-2"
              style={{
                backgroundColor: colors.primary,
                color: 'white'
              }}
            >
              <PiCheck size={20} />
              Save Changes
            </button>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}; 