import { ThemeColors } from '../types/theme';
import Color from 'color';

// Add these constants at the top of the file
const MODERN_THEME_COLORS = {
  light: {
    background: '#FFFFFF',    // Pure white
    surface: '#F8F8F8',      // Very light grey
    surfaceAlt: '#F2F2F2',   // Light grey
    text: '#111827',         // Near black
    textSecondary: '#4B5563' // Dark grey
  },
  dark: {
    background: '#121212',    // Material dark grey
    surface: '#1E1E1E',      // Slightly lighter dark grey
    surfaceAlt: '#2A2A2A',   // Even lighter dark grey
    text: '#FFFFFF',         // White
    textSecondary: '#9CA3AF' // Light grey
  }
} as const;

// Define modern theme values separately
const MODERN_THEME = {
  primary: '#007AFF',      // Modern iOS blue
  secondary: '#8E8E93',    // Modern grey
  tertiary: '#5856D6',     // Modern purple
  quaternary: '#34C759',   // Modern green
  accent1: '#6D28D9',      // Modern violet
  accent2: '#2bb44d'       // Modern light green
} as const;

// Define logo colors separately
const LOGO_COLORS = {
  primary: '#54a066',    // Modern theme green
  secondary: '#007aff',  // Modern theme blue
  tertiary: '#616e81',   // Modern theme grey
  accent: '#2f784c',     // Modern theme dark green
  highlight: '#c3cfb5'   // Modern theme light green
};

export const generateThemeVariants = (
  baseColors: Partial<ThemeColors>,
  isDark: boolean
): ThemeColors => {
  const {
    primary = MODERN_THEME.primary,
    secondary = MODERN_THEME.secondary,
    tertiary = MODERN_THEME.tertiary,
    quaternary = MODERN_THEME.quaternary,
    accent1 = MODERN_THEME.accent1,
    accent2 = MODERN_THEME.accent2,
    logo: customLogo,
  } = baseColors;

  // Determine if this is the modern theme
  const isModernTheme = (
    primary === MODERN_THEME.primary &&
    secondary === MODERN_THEME.secondary &&
    tertiary === MODERN_THEME.tertiary &&
    quaternary === MODERN_THEME.quaternary
  );

  let backgroundBase;
  let surfaceColor;
  let surfaceAltColor;
  let textColor;
  let textSecondaryColor;

  if (isModernTheme) {
    const themeColors = isDark ? MODERN_THEME_COLORS.dark : MODERN_THEME_COLORS.light;
    backgroundBase = Color(themeColors.background);
    surfaceColor = Color(themeColors.surface);
    surfaceAltColor = Color(themeColors.surfaceAlt);
    textColor = Color(themeColors.text);
    textSecondaryColor = Color(themeColors.textSecondary);
  } else {
    // For custom themes, use theme-influenced colors
    const themeBase = Color(primary);
    const secondaryInfluence = Color(secondary);
    
    if (isDark) {
      // Dark mode - create rich, deep backgrounds
      backgroundBase = themeBase
        .darken(0.8)        // Make very dark
        .desaturate(0.3)    // Reduce saturation more
        .mix(Color('#121212'), 0.7); // Mix with dark base for consistency
      
      surfaceColor = backgroundBase
        .lighten(0.1)      // Subtle lift from background
        .saturate(0.1);    // Slight saturation boost
        
      surfaceAltColor = surfaceColor
        .lighten(0.15);    // More noticeable contrast for alt surface
        
      textColor = Color('#FFFFFF');           // White text for dark mode
      textSecondaryColor = Color('#9CA3AF');  // Light grey for secondary text
    } else {
      // Light mode - create distinct but elegant theme influence
      const baseInfluence = themeBase
        .lighten(0.25)     // Lighten less to preserve more color
        .desaturate(0.4)   // Keep more saturation for distinction
        .mix(Color('#FFFFFF'), 0.6); // Less white mixing for more color
      
      const secondaryMix = secondaryInfluence
        .lighten(0.25)
        .desaturate(0.4)
        .mix(Color('#FFFFFF'), 0.6);
      
      // Calculate color characteristics for dynamic mixing
      const hue = themeBase.hue();
      const saturation = themeBase.saturationl();
      const brightness = themeBase.lightness();
      
      // Adjust mix ratios based on color characteristics
      const whiteRatio = Math.min(0.7, Math.max(0.4, 
        // More white mixing for very saturated or dark colors
        (saturation / 100 * 0.3) + (brightness / 100 * 0.4)
      ));
      
      const secondaryRatio = Math.min(0.4, Math.max(0.2,
        // More secondary mixing for similar hues
        Math.abs(hue - secondaryInfluence.hue()) / 360 * 0.4
      ));
      
      // Create a more distinct background color
      backgroundBase = baseInfluence
        .mix(secondaryMix, secondaryRatio)
        .mix(Color('#FFFFFF'), whiteRatio);
      
      // Create more distinct surface layers
      surfaceColor = backgroundBase
        .lighten(0.04)     // More noticeable surface distinction
        .saturate(0.05);   // Slight saturation boost
        
      surfaceAltColor = surfaceColor
        .lighten(0.06);    // Even more distinct alternative surface
        
      textColor = Color('#111827');           // Near black text for light mode
      textSecondaryColor = Color('#4B5563');  // Dark grey for secondary text
    }
  }

  // Create glowing border effect
  const glowBorder = isDark
    ? Color(primary).lighten(0.2).saturate(0.3).alpha(0.7)
    : Color(primary).darken(0.1).saturate(0.4).alpha(0.3);

  return {
    // Theme colors - keep consistent in both modes
    primary,
    secondary,
    tertiary,
    quaternary,
    accent1: accent1 || primary,
    accent2: accent2 || secondary,
    
    // UI colors - use modern theme values or generated values
    background: isModernTheme 
      ? MODERN_THEME_COLORS[isDark ? 'dark' : 'light'].background 
      : backgroundBase.toString(),
    surface: isModernTheme 
      ? MODERN_THEME_COLORS[isDark ? 'dark' : 'light'].surface
      : surfaceColor.toString(),
    surfaceAlt: isModernTheme 
      ? MODERN_THEME_COLORS[isDark ? 'dark' : 'light'].surfaceAlt
      : surfaceAltColor.toString(),
    text: isModernTheme 
      ? MODERN_THEME_COLORS[isDark ? 'dark' : 'light'].text
      : textColor.toString(),
    textSecondary: isModernTheme 
      ? MODERN_THEME_COLORS[isDark ? 'dark' : 'light'].textSecondary
      : textSecondaryColor.toString(),
    border: glowBorder.toString(),
    
    // Status colors - keep consistent
    success: '#059669',
    error: '#dc2626',
    warning: '#d97706',
    info: '#3b82f6',
    shadow: isDark ? '#000000' : '#00000080',
    
    // Logo colors - always use LOGO_COLORS for modern theme
    logo: isModernTheme 
      ? LOGO_COLORS
      : customLogo || {
          primary,
          secondary,
          tertiary,
          accent: accent1 || primary,
          highlight: accent2 || secondary
        },
  };
};

export const getContrastText = (backgroundColor: string): string => {
  const color = Color(backgroundColor);
  return color.isLight() ? '#111827' : '#F9FAFB';
}; 