import { motion } from 'framer-motion'
import { useTheme } from '../../contexts/ThemeContext'
import { useStore } from '../../store/useStore'
import { Link } from 'react-router-dom'
import { PiCrown, PiArrowRight, PiStarFour } from 'react-icons/pi'
import { PLANS } from '../../constants/subscriptionPlans'
import { SubscriptionBadge } from '../shared/SubscriptionBadge'

export function SubscriptionSection() {
  const { colors } = useTheme()
  const { user } = useStore()
  const currentTier = user?.subscription?.tier || 'free'
  const currentPlan = PLANS.find(p => p.tier === currentTier)

  return (
    <motion.div
      className="bg-surface rounded-lg p-6 shadow-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h2 className="text-2xl font-semibold mb-4 flex items-center gap-2" style={{ color: colors.primary }}>
        <PiCrown className="w-6 h-6" />
        Subscription
      </h2>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <label className="text-sm font-medium" style={{ color: colors.textSecondary }}>
              Current Plan
            </label>
            <div className="flex items-center gap-2">
              <div className="text-xl font-semibold" style={{ color: colors.text }}>
                {currentPlan?.name || 'Free'}
              </div>
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm" style={{ color: colors.textSecondary }}>
              Monthly Price
            </div>
            <div className="text-xl font-bold" style={{ color: colors.primary }}>
              {currentPlan?.price || '$0'}
            </div>
          </div>
        </div>

        <div className="py-4">
          <div className="text-sm font-medium mb-2" style={{ color: colors.textSecondary }}>
            Features
          </div>
          <ul className="space-y-2">
            {currentPlan?.features.map((feature, index) => (
              <li
                key={index}
                className="flex items-center gap-2"
                style={{ color: colors.text }}
              >
                <PiStarFour className="w-4 h-4" style={{ color: colors.primary }} />
                {feature}
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-2">
          <Link
            to="/upgrade"
            className="flex items-center justify-between p-4 rounded-lg transition-colors"
            style={{
              backgroundColor: `${colors.primary}15`,
              color: colors.primary
            }}
          >
            <span className="font-medium">
              {currentTier === 'unlimited' 
                ? 'Manage Subscription' 
                : 'Upgrade Your Plan'}
            </span>
            <PiArrowRight className="w-5 h-5" />
          </Link>
          {currentTier !== 'unlimited' && (
            <p className="text-sm text-center" style={{ color: colors.textSecondary }}>
              Upgrade to unlock more features and enhance your experience
            </p>
          )}
        </div>
      </div>
    </motion.div>
  )
} 