import { ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthStore } from '../../store/useAuthStore'
import { adminService } from '../../services/adminService'
import { Loader } from '../shared/Loader'

interface AdminGuardProps {
  children: ReactNode
}

export function AdminGuard({ children }: AdminGuardProps) {
  const { user } = useAuthStore()
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null)

  useEffect(() => {
    async function checkAdminStatus() {
      if (!user?.uid) {
        setIsAdmin(false)
        return
      }

      const adminStatus = await adminService.isAdmin(user.uid)
      setIsAdmin(adminStatus)
    }

    checkAdminStatus()
  }, [user])

  // Show loading state while checking admin status
  if (isAdmin === null) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    )
  }

  // Redirect if not admin
  if (!isAdmin) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
} 