import { 
  doc, 
  getDoc, 
  updateDoc,
  serverTimestamp,
  DocumentSnapshot,
  Timestamp
} from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import { activityService } from './activityService';
import { User, UserPreferences } from '../types/user';
import { ThemeColors, ThemePreset } from '../types/theme';
import { AppError } from '../utils/errors';
import { migrateUserStructure } from '../utils/migrations/userMigrations'
import { defaultUserPreferences } from '../types/user';
import { migrateEncryption } from '../utils/migrations/encryptionMigration';
import { encryptionService } from './encryptionService';

export const userService = {

  async getUser(userId: string): Promise<User> {
    try {
      if (!userId) {
        throw new AppError('USER_NOT_FOUND', 'Invalid user ID');
      }

      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new AppError('USER_NOT_FOUND', 'User profile not found');
      }

      const userData = userDoc.data();
      
      // Basic user data without migration
      const basicUserData: User = {
        id: userId,
        email: userData?.email || null,
        emailVerified: userData?.emailVerified || false,
        name: userData?.name || userData?.displayName || 'User',
        displayName: userData?.displayName || userData?.name || 'User',
        photoURL: userData?.photoURL || null,
        bio: userData?.bio || null,
        location: userData?.location || null,
        website: userData?.website || null,
        language: userData?.language || 'en',
        timezone: userData?.timezone || 'UTC',
        role: userData?.role || 'user',
        accountStatus: userData?.accountStatus || 'active',
        preferences: {
          ...defaultUserPreferences,
          ...(userData?.preferences || {})
        },
        createdAt: userData?.createdAt?.toDate() || new Date(),
        updatedAt: userData?.updatedAt?.toDate() || new Date(),
        lastLogin: userData?.lastLogin?.toDate() || null,
        aiSettings: userData?.aiSettings || {
          name: 'Tydy',
          avatarColor: '/avatars/robot-blue.png',
          personalityPreset: 'friendly-coach',
          apiKey: null
        },
        subscription: userData?.subscription || {
          tier: 'free',
          startDate: Timestamp.now(),
          currentPeriodEnd: Timestamp.now(),
          status: 'active',
          features: {
            finance: false,
            calendar: false,
            lists: false,
            mood: false,
            notebooks: false
          }
        },
        gamification: userData?.gamification || defaultGamificationState
      };

      try {
        // Attempt migration if needed
        const migratedUser = await migrateUserStructure(userDoc, basicUserData);
        return migratedUser || basicUserData;
      } catch (error) {
        console.warn('[UserService] Migration failed, returning basic user data:', error);
        return basicUserData;
      }
    } catch (error) {
      console.error('[UserService] Failed to get user:', error);
      throw error;
    }
  },

  async updateUser(userId: string, data: Partial<User>): Promise<void> {
    try {
      if (!userId) throw new AppError('USER_ID_REQUIRED', 'User ID is required');
      
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { ...data });
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  },

  getCurrentUser: async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return null;

    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return null;

    const userData = userDoc.data();

    return {
      ...userData,
      id: userId,
      name: userData.name || userData.displayName || 'User',
      displayName: userData.displayName || userData.name || 'User',
      email: userData.email || null,
      emailVerified: userData.emailVerified || false,
      isAnonymous: userData.isAnonymous || false,
      photoURL: userData.photoURL || null,
      preferences: {
        ...defaultUserPreferences,
        ...(userData.preferences || {})
      },
      createdAt: userData.createdAt?.toDate() || new Date(),
      updatedAt: userData.updatedAt?.toDate() || new Date()
    } as User;
  },

  updateProfile: async (updates: Partial<User>) => {
    if (!auth.currentUser) throw new Error('Not authenticated');
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    
    const cleanUpdates = {
      ...updates,
      updatedAt: serverTimestamp()
    };

    await updateDoc(userRef, cleanUpdates);

    // Track the activity
    await activityService.createActivity(
      'profile_updated',
      'Updated profile settings',
      '',
      { userId: auth.currentUser.uid },
      auth.currentUser.uid,
      'user_profile'
    );
  },

  updatePreferences: async (preferences: UserPreferences) => {
    if (!auth.currentUser) throw new Error('Not authenticated');
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const currentDoc = await getDoc(userRef);
    const currentPrefs = currentDoc.data()?.preferences as UserPreferences;
    
    try {
      const updates = {
        'preferences': preferences,
        'updatedAt': serverTimestamp()
      };

      await updateDoc(userRef, updates);

      // Track specific preference changes
      const changes: string[] = [];
      
      // Theme mode changes - make this more specific
      if (currentPrefs?.theme !== preferences.theme) {
        await activityService.createActivity(
          'theme_updated',
          `Changed theme to ${preferences.theme} mode`,
          '',
          { userId: auth.currentUser.uid },
          auth.currentUser.uid,
          'user_theme'
        );
        return; // Return early to avoid creating multiple activities
      }

      // Only track other preference changes if theme didn't change
      const notifChanges = Object.entries(preferences.notifications || {})
        .filter(([key, value]) => currentPrefs?.notifications?.[key] !== value)
        .map(([key]) => `${key} notifications`);
      
      if (notifChanges.length > 0) {
        changes.push(`Updated ${notifChanges.join(', ')}`);
      }

      // Privacy changes
      const privacyChanges = Object.entries(preferences.privacy || {})
        .filter(([key, value]) => currentPrefs?.privacy?.[key] !== value)
        .map(([key]) => key.replace(/([A-Z])/g, ' $1').toLowerCase());
      
      if (privacyChanges.length > 0) {
        changes.push(`Updated privacy settings: ${privacyChanges.join(', ')}`);
      }

      // Tracker changes
      if (JSON.stringify(currentPrefs?.enabledTrackers) !== JSON.stringify(preferences.enabledTrackers)) {
        changes.push('Updated enabled trackers');
      }

      // Create activity only for non-theme changes
      if (changes.length > 0) {
        await activityService.createActivity(
          'preferences_updated',
          `Updated preferences: ${changes[0]}${changes.length > 1 ? ` and ${changes.length - 1} more` : ''}`,
          changes.join('\n'),
          { userId: auth.currentUser.uid },
          auth.currentUser.uid,
          'user_preferences'
        );
      }
      
    } catch (error) {
      console.error('Failed to update preferences:', error);
      throw error;
    }
  },

  updateThemeColors: async (colors: ThemeColors) => {
    if (!auth.currentUser) throw new Error('Not authenticated');
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    
    await updateDoc(userRef, {
      'preferences.themeColors': colors,
      updatedAt: serverTimestamp()
    });

    // Track theme color update with specific changes
    const changedColors = Object.entries(colors)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    await activityService.createActivity(
      'theme_updated',
      'Updated theme colors',
      changedColors,
      { userId: auth.currentUser.uid },
      auth.currentUser.uid,
      'user_theme'
    );
  },

  saveCustomTheme: async (theme: ThemePreset) => {
    if (!auth.currentUser) throw new Error('Not authenticated');
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    
    const customThemes = [...(userData?.preferences?.customThemes || [])];
    const existingIndex = customThemes.findIndex((t: ThemePreset) => t.name === theme.name);
    
    if (existingIndex !== -1) {
      customThemes[existingIndex] = theme;
    } else {
      customThemes.push(theme);
    }
    
    await updateDoc(userRef, {
      'preferences.customThemes': customThemes,
      updatedAt: serverTimestamp()
    });

    // Track the activity
    await activityService.createActivity(
      'theme_created',
      `Created custom theme: ${theme.name}`,
      `Base: ${theme.base}\nColors: ${Object.entries(theme.colors)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')}`,
      { themeId: theme.name },
      theme.name,
      'user_theme'
    );
  },

  deleteCustomTheme: async (themeName: string) => {
    if (!auth.currentUser) throw new Error('Not authenticated');
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    
    const customThemes = (userData?.preferences?.customThemes || [])
      .filter((theme: ThemePreset) => theme.name !== themeName);
    
    await updateDoc(userRef, {
      'preferences.customThemes': customThemes,
      updatedAt: serverTimestamp()
    });

    // Track the activity
    await activityService.createActivity(
      'theme_deleted',
      `Deleted custom theme: ${themeName}`,
      '',
      { themeId: themeName },
      themeName,
      'user_theme'
    );
  },

  updateUserPreferences: async (userId: string, preferences: UserPreferences) => {
    try {
      console.log('Updating preferences for user:', userId);
      console.log('New preferences:', preferences);
      
      // Make sure all required fields are present
      const validatedPreferences = {
        ...defaultUserPreferences,
        ...preferences,
        // Ensure theme colors are properly structured
        themeColors: {
          ...defaultUserPreferences.themeColors,
          ...preferences.themeColors,
          // Ensure logo is preserved
          logo: preferences.themeColors?.logo || defaultUserPreferences.themeColors.logo
        }
      };

      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        preferences: validatedPreferences,
        updatedAt: serverTimestamp()
      });

      return validatedPreferences;
    } catch (error) {
      console.error('Error updating user preferences:', error);
      throw new AppError('Failed to update preferences');
    }
  },

  async ensureUserTrackers(userId: string): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentTrackers = userData?.preferences?.enabledTrackers || [];
        const defaultTrackers = defaultUserPreferences.enabledTrackers;
        
        // Check if any default trackers are missing
        const missingTrackers = defaultTrackers.filter(
          tracker => !currentTrackers.includes(tracker)
        );
        
        if (missingTrackers.length > 0) {
          await updateDoc(userRef, {
            'preferences.enabledTrackers': [...currentTrackers, ...missingTrackers]
          });
        }
      }
    } catch (error) {
      console.error('Error ensuring user trackers:', error);
      throw error;
    }
  },

  async migrateUserEncryption(userId: string) {
    // Generate new key
    const newKey = encryptionService.generateUserKey()
    
    // Run migration
    const results = await migrateEncryption(userId, newKey)
    
    // Update user's encryption key
    await updateDoc(doc(db, 'users', userId), {
      encryptionKey: newKey,
      encryptionVersion: 2
    })

    return results
  }
};