import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { SubscriptionTier } from '../../types/subscription';
import { useStore } from '../../store/useStore';
import { subscriptionService } from '../../services/subscriptionService';
import { PiStarFour, PiX, PiSpinner } from 'react-icons/pi';
import { toast } from 'react-hot-toast';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { PiCrown } from 'react-icons/pi';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  requiredTier: SubscriptionTier;
  returnTo?: string;
}

interface PlanDetails {
  tier: SubscriptionTier;
  name: string;
  price: string;
  features: string[];
}

const PLANS: PlanDetails[] = [
  {
    tier: 'core',
    name: 'Core',
    price: '$5/month',
    features: [
      'Extended Notes (10 notebooks)',
      'Advanced Lists (10 lists)',
      'Full Mood History & Insights',
      'Full Budget Controls',
      'Calendar Integration',
      '50 AI Credits Monthly'
    ]
  },
  {
    tier: 'pro',
    name: 'Pro',
    price: '$10/month',
    features: [
      'Unlimited Notes & Lists',
      'Advanced Analytics',
      'Monthly Financial Overview',
      'Priority Support',
      '250 AI Credits Monthly',
      'Early Access Features'
    ]
  }
];

export function UpgradeModal({ isOpen, onClose, requiredTier, returnTo }: UpgradeModalProps) {
  const { colors } = useTheme();
  const { user, updateUser } = useStore();
  const [loading, setLoading] = useState(false);
  const [selectedTier, setSelectedTier] = useState<SubscriptionTier>(requiredTier);
  const navigate = useNavigate();

  const handleUpgrade = async () => {
    if (!user?.id) return;
    
    try {
      setLoading(true);
      const { userData } = await subscriptionService.upgradeSubscription(user.id, selectedTier);
      
      if (userData) {
        updateUser(userData);
        navigate(returnTo || '/profile');
      }

      toast.success(`Successfully upgraded to ${selectedTier}`);
      onClose();
    } catch (error) {
      toast.error('Failed to upgrade subscription');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 overflow-hidden"
          style={{ 
            zIndex: 100,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onClick={(e) => {
            // Close modal when clicking overlay
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-lg max-w-2xl w-full p-6"
            style={{ backgroundColor: colors.surface }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking modal content
          >
            {/* Modal content */}
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold" style={{ color: colors.text }}>
                Upgrade Your Plan
              </h2>
              <button onClick={onClose}>
                <PiX className="w-6 h-6" style={{ color: colors.textSecondary }} />
              </button>
            </div>

            {/* Plans */}
            <div className="grid md:grid-cols-2 gap-6 mb-6">
              {PLANS.map((plan) => (
                <div
                  key={plan.tier}
                  className={`p-6 rounded-lg cursor-pointer transition-all ${
                    selectedTier === plan.tier ? 'ring-2' : ''
                  }`}
                  style={{
                    backgroundColor: colors.surfaceAlt,
                    borderColor: colors.border,
                    ringColor: colors.primary
                  }}
                  onClick={() => setSelectedTier(plan.tier)}
                >
                  <h3 className="text-xl font-semibold mb-2" style={{ color: colors.text }}>
                    {plan.name}
                  </h3>
                  <p className="text-2xl font-bold mb-4" style={{ color: colors.primary }}>
                    {plan.price}
                  </p>
                  <ul className="space-y-2">
                    {plan.features.map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-center gap-2"
                        style={{ color: colors.textSecondary }}
                      >
                        <PiStarFour className="w-4 h-4" style={{ color: colors.primary }} />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-4">
              <button
                onClick={onClose}
                className="px-4 py-2 rounded-lg"
                style={{
                  backgroundColor: `${colors.error}20`,
                  color: colors.error
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => handleUpgrade()}
                disabled={loading || selectedTier === 'free'}
                className="px-4 py-2 rounded-lg flex items-center gap-2"
                style={{
                  backgroundColor: selectedTier === 'free' ? `${colors.primary}20` : colors.primary,
                  color: selectedTier === 'free' ? colors.primary : 'white',
                  opacity: loading ? 0.7 : 1
                }}
              >
                {loading && <PiSpinner className="w-4 h-4 animate-spin" />}
                {selectedTier === 'free' ? 'Switch to Free Plan' : `Upgrade to ${selectedTier.charAt(0).toUpperCase() + selectedTier.slice(1)}`}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
} 