import { create } from 'zustand'
import type { AICredits, AIUsageLog } from '../types/ai'
import { aiCreditService } from '../services/aiCreditService'
import type { User } from '../types/user'
import { Timestamp } from 'firebase/firestore'

interface CreditUsage {
  date: string
  amount: number
  action: string
  details?: Record<string, any>
}

interface AICreditsStore {
  credits: {
    remaining: number
    used: number
  } | null
  creditUsageHistory: CreditUsage[]
  usageHistory: CreditUsage[]
  isLoading: boolean
  error: Error | null
  lastHistoryFetch: number | null
  historyTTL: number // Time in milliseconds that the cache is valid
  
  fetchCredits: (userId: string) => Promise<void>
  useCredits: (userId: string, amount: number) => Promise<void>
  addCredits: (userId: string, amount: number, source: string) => Promise<void>
  setCredits: (credits: User['credits']) => void
  initializeFromUser: (userData: User) => void
  fetchUsageHistory: (userId: string, force?: boolean) => Promise<void>
}

function convertTimestampToDate(timestamp: Timestamp | Date | unknown): Date {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate()
  }
  if (timestamp instanceof Date) {
    return timestamp
  }
  return new Date()
}

export const useAICreditsStore = create<AICreditsStore>((set, get) => ({
  credits: null,
  creditUsageHistory: [],
  usageHistory: [],
  isLoading: false,
  error: null,
  lastHistoryFetch: null,
  historyTTL: 5 * 60 * 1000, // 5 minutes cache

  setCredits: (credits) => {
    if (!credits) return
    
    const normalizedCredits = {
      remaining: credits.remaining,
      used: credits.used,
      lastReset: convertTimestampToDate(credits.lastReset),
      nextReset: convertTimestampToDate(credits.nextReset)
    }
    
    set({ credits: normalizedCredits })
  },

  initializeFromUser: (userData) => {
    if (userData.credits) {
      const credits = {
        remaining: userData.credits.remaining,
        used: userData.credits.used,
        lastReset: convertTimestampToDate(userData.credits.lastReset),
        nextReset: convertTimestampToDate(userData.credits.nextReset)
      }
      set({ credits })
    }
  },

  fetchCredits: async (userId: string) => {
    try {
      set({ isLoading: true, error: null })
      const credits = await aiCreditService.getUserCredits(userId)
      set({ credits, isLoading: false })
      return credits
    } catch (error) {
      set({ error: error as Error, isLoading: false })
      throw error
    }
  },

  useCredits: async (userId: string, amount: number) => {
    try {
      if (!userId) {
        console.error('[useAICreditsStore] No userId provided for credit update')
        throw new Error('No userId provided')
      }

      console.log('[useAICreditsStore] Starting credit update:', { userId, amount })
      set({ isLoading: true, error: null })
      
      const updatedCredits = await aiCreditService.useCredits(userId, amount)
      console.log('[useAICreditsStore] Credits updated in service:', updatedCredits)
      
      if (!updatedCredits) {
        throw new Error('No credits returned from service')
      }
      
      set({ credits: updatedCredits, isLoading: false })
      
      // After successful credit use, invalidate the history cache
      set({ lastHistoryFetch: null })
      
      return updatedCredits
    } catch (error) {
      console.error('[useAICreditsStore] Error in useCredits:', error)
      set({ error: error as Error, isLoading: false })
      throw error
    }
  },

  addCredits: async (userId: string, amount: number, source: string) => {
    try {
      set({ isLoading: true, error: null })
      await aiCreditService.addCredits(userId, amount, source)
      
      // After successful credit addition, invalidate the history cache
      set({ lastHistoryFetch: null })
      
      await get().fetchCredits(userId)
    } catch (error) {
      set({ error: error as Error, isLoading: false })
      throw error
    }
  },

  fetchUsageHistory: async (userId: string, force: boolean = false) => {
    try {
      const now = Date.now()
      const lastFetch = get().lastHistoryFetch
      const ttl = get().historyTTL

      // Return cached data if within TTL and not forced
      if (!force && lastFetch && (now - lastFetch < ttl)) {
        return
      }

      set({ isLoading: true })
      const history = await aiCreditService.getUsageHistory(userId)
      set({ 
        usageHistory: history, 
        isLoading: false,
        lastHistoryFetch: now
      })
    } catch (error) {
      console.error('Failed to fetch usage history:', error)
      set({ isLoading: false })
    }
  }
})) 