import { useAIStore } from '../../store/useAIStore'
import { personalityPresets } from '../../constants/ai-presets'
import { useTheme } from '../../contexts/ThemeContext'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { useTranslation } from 'react-i18next'
import { AvatarPicker } from '../shared/pickers/AvatarPicker'
import { useState, useEffect } from 'react'

export function AIPersonalizationSection() {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { assistantSettings, updateAssistantSettings } = useAIStore()
  const [inputName, setInputName] = useState(assistantSettings?.name || t('settings.ai.assistant.name'))
  const avatarColor = assistantSettings?.avatarColor || '/avatars/robot-blue.png'

  // Update local state when settings change
  useEffect(() => {
    setInputName(assistantSettings?.name || t('settings.ai.assistant.name'))
  }, [assistantSettings?.name, t])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value
    setInputName(newName)
    
    // Only update settings if the name is not empty after trimming
    if (newName.trim()) {
      updateAssistantSettings({ name: newName })
    }
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-6">
        {/* Avatar Picker */}
        <AvatarPicker
          value={avatarColor}
          onChange={(value) => updateAssistantSettings({ avatarColor: value })}
          size="lg"
        />

        {/* Name Input */}
        <div className="flex-1">
          <Input
            value={inputName}
            onChange={handleNameChange}
            placeholder={t('settings.ai.assistant.namePlaceholder')}
            className="w-full"
            style={{ color: colors.text }}
          />
          <p className="text-sm mt-1" style={{ color: colors.textSecondary }}>
            {t('settings.ai.assistant.nameHelp')}
          </p>
        </div>
      </div>

      {/* Personality Presets */}
      <div className="space-y-3">
        <label className="text-sm font-medium" style={{ color: colors.textSecondary }}>
          {t('settings.ai.assistant.personality')}
        </label>
        <div className="grid grid-cols-1 gap-2">
          {personalityPresets.map((preset) => (
            <Button
              key={`personality-${preset.id}`}
              onClick={() => updateAssistantSettings({ personalityPreset: preset.id })}
              variant="outline"
              className="justify-start text-left h-auto py-3 transition-all duration-200"
              style={{ 
                borderColor: assistantSettings?.personalityPreset === preset.id ? colors.primary : colors.border,
                borderWidth: assistantSettings?.personalityPreset === preset.id ? 2 : 1,
                backgroundColor: colors.surface
              }}
            >
              <div>
                <div className="font-medium" style={{ color: colors.text }}>
                  {t(`settings.ai.assistant.personalityPresets.${preset.id}`)}
                </div>
                <div className="text-sm" style={{ color: colors.textSecondary }}>
                  {t(`settings.ai.assistant.personalityPresets.${preset.id}-description`)}
                </div>
              </div>
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
} 