import { 
  collection, 
  doc, 
  getDoc, 
  updateDoc, 
  addDoc,
  increment, 
  serverTimestamp,
  Timestamp,
  query,
  where,
  orderBy,
  limit,
  setDoc,
  runTransaction,
  getDocs
} from 'firebase/firestore'
import { db } from '../utils/firebase'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits'
import { AppError } from '../utils/errors'
import type { AICredits, AIUsageLog, AITransaction } from '../types/ai'
import { addMonths } from 'date-fns'
import type { User } from '../types/user'
import type { SubscriptionTier } from '../types/subscription'

export const aiCreditService = {
  async getUserCredits(userId: string): Promise<User['credits']> {
    try {
      const userRef = doc(db, 'users', userId)
      const userDoc = await getDoc(userRef)

      if (!userDoc.exists()) {
        throw new AppError('User not found', 'USER_NOT_FOUND')
      }

      const userData = userDoc.data() as User
      return userData.credits
    } catch (error) {
      console.error('Failed to fetch AI credits:', error)
      throw error
    }
  },

  async initializeUserCredits(userId: string, tier: SubscriptionTier = 'free'): Promise<User['credits']> {
    try {
      const now = new Date()
      const nextReset = addMonths(now, 1)
      const monthlyCredits = SUBSCRIPTION_LIMITS.aiCredits.monthly[tier]

      const credits = {
        remaining: monthlyCredits,
        used: 0,
        lastReset: Timestamp.fromDate(now),
        nextReset: Timestamp.fromDate(nextReset)
      }

      await updateDoc(doc(db, 'users', userId), {
        credits
      })

      return {
        ...credits,
        lastReset: now,
        nextReset
      }
    } catch (error) {
      console.error('Failed to initialize credits:', error)
      return {
        remaining: SUBSCRIPTION_LIMITS.aiCredits.monthly.free,
        used: 0,
        lastReset: new Date(),
        nextReset: addMonths(new Date(), 1)
      }
    }
  },

  async useCredits(userId: string, amount: number): Promise<User['credits']> {
    try {
      if (!userId) throw new AppError('User ID is required', 'USER_ID_REQUIRED')
      if (amount <= 0) throw new AppError('Amount must be positive', 'INVALID_AMOUNT')

      console.log('[aiCreditService] Starting useCredits with:', { userId, amount })
      
      const userRef = doc(db, 'users', userId)
      
      // Use a transaction to ensure atomic updates
      const updatedCredits = await runTransaction(db, async (transaction) => {
        console.log('[aiCreditService] Starting transaction')
        const userDoc = await transaction.get(userRef)
        
        if (!userDoc.exists()) {
          console.error('[aiCreditService] User document not found')
          throw new AppError('User not found', 'USER_NOT_FOUND')
        }

        const userData = userDoc.data() as User
        console.log('[aiCreditService] Current user data:', {
          userId,
          credits: userData.credits,
          docId: userDoc.id
        })
        
        if (!userData.credits || userData.credits.remaining < amount) {
          console.error('[aiCreditService] Insufficient credits:', userData.credits)
          throw new AppError('Insufficient credits', 'AI_CREDITS_INSUFFICIENT')
        }

        const newCredits = {
          remaining: Number(userData.credits.remaining) - Number(amount),
          used: Number(userData.credits.used) + Number(amount),
          lastReset: userData.credits.lastReset,
          nextReset: userData.credits.nextReset
        }
        
        console.log('[aiCreditService] Attempting credit update:', {
          userId,
          currentCredits: userData.credits,
          newCredits
        })

        // Update only the numeric fields
        transaction.update(userRef, {
          'credits.remaining': newCredits.remaining,
          'credits.used': newCredits.used
        })

        return newCredits
      })

      console.log('[aiCreditService] Transaction completed successfully:', {
        userId,
        updatedCredits
      })

      // Log usage
      await this.logUsage(userId, amount, 'chat')

      return updatedCredits
    } catch (error) {
      console.error('[aiCreditService] Error using credits:', {
        userId,
        amount,
        error
      })
      if (error instanceof AppError) throw error
      throw new AppError('Failed to use credits', 'AI_CREDITS_USE_FAILED')
    }
  },

  async resetMonthlyCredits(userId: string, tier: SubscriptionTier = 'free'): Promise<User['credits']> {
    try {
      const now = new Date()
      const nextReset = addMonths(now, 1)
      const monthlyCredits = SUBSCRIPTION_LIMITS.aiCredits.monthly[tier]

      const credits = {
        remaining: monthlyCredits,
        used: 0,
        lastReset: Timestamp.fromDate(now),
        nextReset: Timestamp.fromDate(nextReset)
      }

      await updateDoc(doc(db, 'users', userId), {
        credits
      })

      return {
        ...credits,
        lastReset: now,
        nextReset
      }
    } catch (error) {
      console.error('Failed to reset monthly credits:', error)
      return {
        remaining: SUBSCRIPTION_LIMITS.aiCredits.monthly.free,
        used: 0,
        lastReset: new Date(),
        nextReset: addMonths(new Date(), 1)
      }
    }
  },

  async addCredits(userId: string, amount: number, type: 'purchase' | 'bonus' = 'purchase'): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId)
      const userDoc = await getDoc(userRef)

      if (!userDoc.exists()) {
        throw new AppError('User not found', 'USER_NOT_FOUND')
      }

      const userData = userDoc.data() as User
      
      await updateDoc(userRef, {
        'credits.remaining': (userData.credits?.remaining || 0) + amount
      })

      await this.logTransaction(userId, amount, type)
    } catch (error) {
      throw new AppError('Failed to add credits', 'AI_CREDITS_ADD_FAILED')
    }
  },

  async logUsage(
    userId: string, 
    creditsUsed: number, 
    action: AIUsageLog['action'],
    details?: Record<string, any>
  ): Promise<void> {
    try {
      const usage = {
        userId,
        creditsUsed,
        action,
        timestamp: serverTimestamp(),
        ...(details ? { details } : {}) // Only include details if they exist
      }

      await addDoc(collection(db, 'aiUsageLogs'), usage)
    } catch (error) {
      console.error('Failed to log AI usage:', error)
      // Don't throw - just log the error
    }
  },

  async logTransaction(
    userId: string,
    amount: number,
    type: AITransaction['type'],
    details?: Record<string, any>
  ): Promise<void> {
    try {
      const transaction = {
        userId,
        amount,
        type,
        timestamp: serverTimestamp(),
        ...(details ? { details } : {}) // Only include details if they exist
      }

      await addDoc(collection(db, 'aiTransactions'), transaction)
    } catch (error) {
      console.error('Failed to log AI transaction:', error)
      // Don't throw - just log the error
    }
  },

  async checkAndResetMonthlyCredits(userId: string, tier: SubscriptionTier): Promise<void> {
    try {
      const creditsRef = doc(db, 'aiCredits', userId)
      const creditsDoc = await getDoc(creditsRef)

      if (!creditsDoc.exists()) {
        await this.initializeUserCredits(userId, tier)
        return
      }

      const credits = creditsDoc.data() as AICredits
      const now = new Date()
      
      // Check if we need to reset credits
      if (now >= credits.nextReset) {
        const monthlyAmount = SUBSCRIPTION_LIMITS.aiCredits.monthly[tier]
        const nextReset = addMonths(now, 1)

        await updateDoc(creditsRef, {
          remaining: monthlyAmount,
          used: 0,
          lastReset: Timestamp.fromDate(now),
          nextReset: Timestamp.fromDate(nextReset)
        })

        // Log the reset
        await this.logTransaction(userId, monthlyAmount, 'monthly_reset')
      }
    } catch (error) {
      console.error('Error checking/resetting monthly credits:', error)
      // Don't throw - we don't want to block the user if this fails
    }
  },

  async syncCreditsWithSubscription(userId: string, tier: SubscriptionTier): Promise<void> {
    try {
      const creditsRef = doc(db, 'aiCredits', userId)
      const creditsDoc = await getDoc(creditsRef)

      if (!creditsDoc.exists()) {
        await this.initializeUserCredits(userId, tier)
        return
      }

      const credits = creditsDoc.data() as AICredits
      const monthlyCredits = SUBSCRIPTION_LIMITS.aiCredits.monthly[tier]

      // If upgrading, add the difference in credits
      if (credits.remaining < monthlyCredits) {
        const creditDifference = monthlyCredits - credits.remaining
        await this.addCredits(userId, creditDifference, 'bonus')
      }
    } catch (error) {
      console.error('Error syncing credits with subscription:', error)
    }
  },

  async getUsageHistory(userId: string): Promise<CreditUsage[]> {
    try {
      const usageQuery = query(
        collection(db, 'aiUsageLogs'),
        where('userId', '==', userId),
        orderBy('timestamp', 'desc'),
        limit(10)
      )
      
      const usageDocs = await getDocs(usageQuery)
      
      return usageDocs.docs.map(doc => {
        const data = doc.data()
        return {
          date: data.timestamp.toDate().toLocaleDateString(),
          amount: data.creditsUsed,
          action: data.action,
          details: data.details
        }
      })
    } catch (error) {
      console.error('Failed to fetch usage history:', error)
      return []
    }
  }
} 