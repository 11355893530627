import { SubscriptionTier } from '../types/subscription'

interface PlanDetails {
  tier: SubscriptionTier
  name: string
  price: string
  features: string[]
}

export const PLANS: PlanDetails[] = [
  {
    tier: 'free',
    name: 'Free',
    price: '$0/month',
    features: [
      'Basic Notes (2 notebooks, 3 notes each)',
      'Simple Lists (3 lists, 15 items each)',
      'Basic Mood Tracking (2 months history)',
      'Basic Transaction Tracking (3 months history)',
      '5 AI Credits Monthly'
    ]
  },
  {
    tier: 'core',
    name: 'Core',
    price: '$10/month',
    features: [

      'Extended Notes (10 notebooks)',
      'Advanced Lists (10 lists, 50 items each)',
      'Full Mood History & Insights',
      'Full Budget Controls & Asset Tracking',
      'Calendar Integration',
      'AI Assistant (50 credits monthly)',
      'Email Support'
    ]
  },
  {
    tier: 'pro',
    name: 'Pro',
    price: '$15/month',
    features: [

      'Unlimited Notes & Notebooks',
      'Unlimited Lists & List Items',
      'Advanced Analytics & Insights',
      'Monthly Financial Overview',
      'Priority Support',
      'AI Assistant (250 credits monthly)',
      'Early Access Features'
    ]
  }
] 