import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotebookStore } from '../store/useNotebookStore';
import { useThemedStyles } from '../hooks/useThemedStyles';
import { useTheme } from '../contexts/ThemeContext';
import { useStore } from '../store/useStore';
import { readFileContent } from '../utils/fileUtils';
import { useErrorFeedback } from '../hooks/useErrorFeedback';
import { ErrorFeedback } from '../components/shared/ErrorFeedback';
import { Loader } from '../components/shared/Loader';
import { useTranslation } from 'react-i18next';
import { FileFormatInfo } from '../components/notebooks/FileFormatInfo';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PiArrowLeft,
  PiTrash,
  PiPencilSimple,
  PiPlus,
  PiUpload,
  PiSpinner,
  PiNotebook,
  PiX,
  PiCheck,
  PiXCircle,
  PiWarning
} from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';
import { AppError } from '../utils/errors';

const formatDate = (timestamp: any) => {
  if (!timestamp) return 'Never';
  
  // Handle Firestore Timestamp
  if (timestamp.seconds) {
    return new Date(timestamp.seconds * 1000).toLocaleString();
  }
  
  // Handle string dates
  const date = new Date(timestamp);
  return !isNaN(date.getTime()) ? date.toLocaleString() : 'Invalid Date';
};

// Add supported file types
const SUPPORTED_FILE_TYPES = '.txt,.docx,.pdf,.rtf,.md,.markdown,.html,.htm'

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}

const noteVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  hover: { 
    scale: 1.02,
    transition: { duration: 0.2 }
  },
  tap: { 
    scale: 0.98,
    transition: { duration: 0.1 }
  }
}

const getWarningThreshold = (limit: number) => {
  if (limit <= 3) return 0.67;  // Show warning at 2/3 for very small limits
  if (limit <= 7) return 0.7;   // Show warning at 70% for small limits
  if (limit <= 15) return 0.75; // Show warning at 75% for medium limits
  return 0.8;                   // Show warning at 80% for larger limits
};

export function NotebookDetailPage() {
  const { notebookId } = useParams();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { user } = useStore();
  const { 
    notebooks,
    currentNotes,
    fetchNotes,
    createNote,
    deleteNotebook,
    loading,
    updateNotebook,
    fetchNotebooks
  } = useNotebookStore();
  const [isCreating, setIsCreating] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const styles = useThemedStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isImporting, setIsImporting] = useState(false);
  const { error, showError, clearError, isErrorVisible, componentName } = useErrorFeedback();
  const { t } = useTranslation();
  const userTier = user?.subscription?.tier || 'free';
  const noteLimit = SUBSCRIPTION_LIMITS.notesPerNotebook[userTier];

  const warningThreshold = getWarningThreshold(noteLimit);

  useEffect(() => {
    async function loadData() {
      if (!notebookId || !user?.id) {
        console.log('Missing required IDs:', { notebookId, userId: user?.id });
        return;
      }

      try {
        // First ensure notebooks are loaded if they aren't already
        if (notebooks.length === 0) {
          await fetchNotebooks();
        }
        // Then fetch the notes
        await fetchNotes(notebookId);
      } catch (err) {
        if (err instanceof Error) {
          showError(err, 'NotebookDetailPage.loadData');
        }
      }
    }

    loadData();
  }, [notebookId, user?.id, fetchNotebooks, fetchNotes, notebooks.length, showError]);

  const notebook = notebooks.find(n => n.id === notebookId);

  const handleCreateNote = async () => {
    if (!notebookId || !user || !newTitle.trim()) return;
    try {
      const noteId = await createNote(notebookId, user.id, newTitle.trim(), '');
      navigate(`/notebooks/${notebookId}/notes/${noteId}`);
      setNewTitle('');
      setIsCreating(false);
    } catch (err) {
      if (err instanceof AppError && err.code !== 'ACTIVITY_ERROR') {
        showError(err, 'NotebookDetailPage.handleCreateNote');
      } else {
        // Still navigate if only activity tracking failed
        if (err instanceof AppError && err.code === 'ACTIVITY_ERROR') {
          navigate(`/notebooks/${notebookId}`);
          setNewTitle('');
          setIsCreating(false);
        }
      }
    }
  };

  const handleFileImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user?.id || !notebookId) return;

    try {
      setIsImporting(true);
      const content = await readFileContent(file);
      
      // Use the filename (without extension) as the note title
      const title = file.name.replace(/\.[^/.]+$/, "");
      
      await createNote(notebookId, user.id, title, content);
      await fetchNotes(notebookId);
      
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'NotebookDetailPage.handleFileImport');
      } else {
        showError('Failed to import file. Please try a different format or file.', 'NotebookDetailPage.handleFileImport');
      }
    } finally {
      setIsImporting(false);
    }
  };

  const handleDelete = async () => {
    if (!notebookId) return;
    try {
      await deleteNotebook(notebookId);
      navigate('/notebooks');
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'NotebookDetailPage.handleDelete');
      } else {
        showError('An unknown error occurred', 'NotebookDetailPage.handleDelete');
      }
    }
  };

  const handleTitleSubmit = async () => {
    if (!notebookId || !editedTitle.trim() || editedTitle === notebook?.title) return;

    try {
      await updateNotebook(notebookId, { title: editedTitle.trim() });
      setIsEditingTitle(false);
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'NotebookDetailPage.handleTitleSubmit');
      }
    }
  };

  // Add note limit warning
  const renderNoteLimitWarning = () => {
    if (currentNotes.length >= noteLimit * warningThreshold) {
      const remainingCount = noteLimit - currentNotes.length;
      return (
        <motion.div
          className="mb-4 p-4 rounded-lg flex items-center gap-3"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          style={{
            backgroundColor: `${colors.warning}15`,
            border: `1px solid ${colors.warning}`
          }}
        >
          <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
          <div>
            <p className="text-sm" style={{ color: colors.text }}>
              {currentNotes.length === noteLimit ? (
                <span>You've reached your note limit of {noteLimit}. </span>
              ) : remainingCount === 1 ? (
                <span>You can create 1 more note in this notebook. </span>
              ) : (
                <span>You can create {remainingCount} more notes in this notebook. </span>
              )}
              <Link 
                to="/upgrade"
                className="font-medium hover:underline"
                style={{ color: colors.warning }}
              >
                Upgrade for more notes per notebook
              </Link>
            </p>
          </div>
        </motion.div>
      );
    }
    return null;
  };

  // Disable create note button when at limit
  const isAtNoteLimit = currentNotes.length >= noteLimit;

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <PiSpinner className="h-8 w-8" style={{ color: colors.primary }} />
        </motion.div>
      </div>
    );
  }

  if (!notebook) {
    return (
      <div className="text-center py-12">
        <p style={{ color: colors.textSecondary }}>
          {t('notebooks.notFound')}
        </p>
      </div>
    );
  }

  return (
    <motion.div 
      className="space-y-6 max-w-3xl mx-auto p-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="space-y-2"
        variants={itemVariants}
      >
        <div className="flex items-center gap-4">
          <motion.button
            onClick={() => navigate('/notebooks')}
            className="p-2 rounded-full hover:bg-opacity-10 transition-colors"
            style={{ 
              backgroundColor: `${colors.quaternary}10`,
              color: colors.quaternary
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <PiArrowLeft className="h-5 w-5" />
          </motion.button>
          
          {isEditingTitle ? (
            <motion.form 
              onSubmit={(e) => {
                e.preventDefault();
                handleTitleSubmit();
              }}
              className="flex-1 flex gap-2"
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
            >
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="flex-1 px-3 py-2 text-2xl font-semibold rounded border"
                style={{
                  backgroundColor: colors.surface,
                  borderColor: colors.border,
                  color: colors.text
                }}
                autoFocus
                onBlur={handleTitleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    setIsEditingTitle(false);
                    setEditedTitle(notebook?.title || '');
                  }
                }}
              />
            </motion.form>
          ) : (
            <div className="flex-1 flex items-center gap-2 group">
              <h1 
                className="text-2xl font-semibold"
                style={{ color: colors.primary }}
              >
                {notebook?.title}
              </h1>
              <motion.button
                onClick={() => {
                  setEditedTitle(notebook?.title || '');
                  setIsEditingTitle(true);
                }}
                className="p-1 rounded hover:bg-opacity-10 transition-colors opacity-0 group-hover:opacity-100"
                style={{ color: colors.textSecondary }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <PiPencilSimple className="h-4 w-4" />
              </motion.button>
            </div>
          )}

          <motion.button
            onClick={() => setShowDeleteConfirm(true)}
            className="p-2 rounded-full transition-colors hover:bg-opacity-20"
            style={{ 
              color: colors.error,
              backgroundColor: `${colors.error}10`
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <PiTrash className="h-5 w-5" />
          </motion.button>
        </div>
      </motion.div>

      <motion.div 
        className="flex justify-between items-center"
        variants={itemVariants}
      >
        <p style={styles.text.muted} className="text-sm">
          {currentNotes.length} {t('notebooks.notes.noteCount')}
        </p>
        <div className="flex gap-2">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileImport}
            accept={SUPPORTED_FILE_TYPES}
            className="hidden"
          />
          <motion.button
            className="inline-flex items-center gap-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md"
            style={{
              backgroundColor: isImporting ? `${colors.accent2}80` : colors.accent2,
              color: 'white'
            }}
            onClick={() => fileInputRef.current?.click()}
            disabled={isImporting}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <PiUpload className="h-4 w-4" />
            {isImporting ? t('notebooks.importing') : t('notebooks.importFile')}
            <FileFormatInfo />
          </motion.button>
          <motion.button
            onClick={() => setIsCreating(true)}
            disabled={isAtNoteLimit}
            className="flex items-center gap-2 px-4 py-2 rounded-lg transition-colors"
            style={{
              backgroundColor: isAtNoteLimit ? `${colors.primary}50` : colors.primary,
              color: 'white',
              cursor: isAtNoteLimit ? 'not-allowed' : 'pointer'
            }}
            whileHover={isAtNoteLimit ? {} : { scale: 1.05 }}
            title={isAtNoteLimit ? 'Upgrade to create more notes' : 'Create new note'}
          >
            <PiPlus className="h-5 w-5" />
            <span>{t('notebooks.notes.new')}</span>
          </motion.button>
        </div>
      </motion.div>

      <AnimatePresence mode="wait">
        {isCreating && (
          <motion.div 
            className="rounded-lg shadow p-4"
            style={{
              ...styles.layout.card,
              borderLeft: `4px solid ${colors.tertiary}`
            }}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
          >
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Note title"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2"
              style={{
                backgroundColor: colors.surface,
                borderColor: colors.border,
                color: colors.text,
              }}
              autoFocus
            />
            <div className="mt-3 flex justify-end space-x-3">
              <motion.button
                onClick={() => {
                  setIsCreating(false);
                  setNewTitle('');
                }}
                className="px-3 py-2 text-sm font-medium rounded-md flex items-center gap-2"
                style={{
                  backgroundColor: `${colors.quaternary}10`,
                  color: colors.quaternary
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <PiXCircle className="h-4 w-4" />
                {t('common.cancel')}
              </motion.button>
              <motion.button
                onClick={handleCreateNote}
                disabled={!newTitle.trim()}
                className="px-3 py-2 text-sm font-medium rounded-md flex items-center gap-2"
                style={{
                  backgroundColor: colors.accent1,
                  color: 'white',
                  opacity: newTitle.trim() ? 1 : 0.5
                }}
                whileHover={newTitle.trim() ? { scale: 1.05 } : {}}
                whileTap={newTitle.trim() ? { scale: 0.95 } : {}}
              >
                <PiPlus className="h-4 w-4" />
                {t('common.create')}
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div 
        className="space-y-2"
        variants={itemVariants}
      >
        {currentNotes.length === 0 ? (
          <motion.div 
            className="text-center py-8 rounded-lg"
            style={{ backgroundColor: colors.surface }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2 }}
          >
            <PiNotebook className="h-12 w-12 mx-auto mb-4" style={{ color: colors.textSecondary }} />
            <p style={{ color: colors.textSecondary }}>
              {t('notebooks.notes.empty')}
            </p>
          </motion.div>
        ) : (
          <motion.div 
            className="space-y-2"
            variants={containerVariants}
          >
            {currentNotes.map((note) => (
              <motion.div
                key={note.id}
                variants={noteVariants}
                whileHover="hover"
                whileTap="tap"
                onClick={() => navigate(`/notebooks/${notebookId}/notes/${note.id}`)}
                className="rounded-lg p-4 transition-all cursor-pointer"
                style={{ 
                  backgroundColor: `${colors.quaternary}10`,
                  borderLeft: `4px solid ${colors.accent2}`
                }}
                layout
              >
                <h3 
                  className="text-lg font-medium"
                  style={{ color: colors.tertiary }}
                >
                  {note.title}
                </h3>
                <p 
                  className="mt-1"
                  style={{ color: colors.textSecondary }}
                >
                  {t('notebooks.notes.lastUpdated')}: {formatDate(note.updatedAt)}
                </p>
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>

      <AnimatePresence>
        {showDeleteConfirm && (
          <motion.div 
            className="fixed inset-0 bg-black/50 flex items-center justify-center p-4"
            style={{ zIndex: 100 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="rounded-lg p-6 max-w-sm w-full"
              style={{
                backgroundColor: colors.surface,
                borderLeft: `4px solid ${colors.error}`
              }}
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
            >
              <h3 
                className="text-lg font-semibold mb-2"
                style={{ color: colors.text }}
              >
                {t('notebooks.delete')}
              </h3>
              <p 
                className="mb-4"
                style={{ color: colors.textSecondary }}
              >
                {t('notebooks.confirmDelete')}
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 rounded transition-colors hover:bg-opacity-20"
                  style={{ 
                    color: colors.textSecondary,
                    backgroundColor: `${colors.quaternary}10`
                  }}
                >
                  {t('common.cancel')}
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 rounded transition-colors hover:opacity-90"
                  style={{ 
                    backgroundColor: colors.error,
                    color: 'white'
                  }}
                >
                  {t('common.delete')}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && (
          <ErrorFeedback
            error={error}
            componentName={componentName}
            isOpen={isErrorVisible}
            onClose={clearError}
          />
        )}
      </AnimatePresence>

      {renderNoteLimitWarning()}
    </motion.div>
  );
} 