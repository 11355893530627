import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../utils/firebase';
import { GamificationStateData } from '../types/gamification';
import { defaultGamificationState } from '../types/user';
import { AppError } from '../utils/errors';
import { useGamificationStore } from '../store/useGamificationStore';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';
import { startOfDay, endOfDay } from 'date-fns';
import { listsService } from './listsService';
import { financeService } from './financeService';
import { debounce } from 'lodash';
import { moodService } from './moodService';
import { userService } from './userService';
import { ACHIEVEMENTS } from '../constants/achievements';

// Helper to get only the data fields from the state
const getStateData = (state: Partial<GamificationStateData>): GamificationStateData => ({
  achievements: state.achievements || [],
  streaks: state.streaks || {
    login: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
    mood: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
    notebook: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
    finance: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
    lists: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null }
  },
  level: state.level || 1,
  xp: state.xp || 0,
  lastActivity: state.lastActivity || null
});

// Create the debounced function outside the object
const debouncedCheck = debounce(
  async (userId: string, service: typeof gamificationService) => {
    try {
      await service.checkAllAchievements(userId, true);
    } catch (error) {
      console.error('Debounced achievement check failed:', error);
    }
  },
  5000, // Wait 5 seconds between checks
  { leading: true, trailing: false }
);

// Add a new type for achievement triggers
type AchievementTrigger = 
  | 'manual'        // Triggered by explicit user action
  | 'historical'    // Triggered by historical data check
  | 'automatic';    // Triggered by system events

// Move helper functions outside the service object
const validateUserPreferenceAchievement = (achievementId: string, userData: any): boolean => {
  switch (achievementId) {
    case 'ai-friend':
      return !!userData.aiAssistant?.name;
    case 'chat-starter':
      return !!userData.chatHistory?.length;
    case 'personalizer':
      return !!userData.preferences?.theme;
    case 'theme-creator':
      return !!userData.preferences?.customThemes?.length;
    case 'night-mode':
      return userData.preferences?.theme === 'dark';
    case 'polyglot':
      return !!userData.preferences?.language && userData.preferences.language !== 'en';
    default:
      return false;
  }
};

const validateTimeBasedAchievement = (achievementId: string): boolean => {
  const now = new Date();
  switch (achievementId) {
    case 'night-owl':
      return now.getHours() >= 0 && now.getHours() < 4;
    case 'early-bird':
      return now.getHours() >= 4 && now.getHours() < 6;
    case 'streak-saver':
      return now.getHours() === 23 && now.getMinutes() >= 55;
    default:
      return false;
  }
};

export const gamificationService = {
  async initializeGamification(userId: string): Promise<GamificationStateData> {
    try {
      // Get user document from Firebase
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new AppError('USER_NOT_FOUND', 'User not found');
      }

      const userData = userDoc.data();
      const gamificationData = userData.gamification;

      // If user has existing gamification data, use it
      if (gamificationData) {
        // Convert any Timestamps to Dates
        const convertedData = {
          ...gamificationData,
          lastActivity: gamificationData.lastActivity?.toDate() || null,
          streaks: Object.entries(gamificationData.streaks).reduce((acc, [key, streak]) => ({
            ...acc,
            [key]: {
              ...streak,
              lastUpdateDate: streak.lastUpdateDate?.toDate() || null,
              streakStartDate: streak.streakStartDate?.toDate() || null
            }
          }), {}),
          achievements: gamificationData.achievements.map((achievement: UserAchievement) => ({
            ...achievement,
            unlockedAt: achievement.unlockedAt?.toDate() || new Date()
          }))
        };

        return convertedData;
      }

      // If no existing data, return default state
      return {
        achievements: [],
        streaks: {
          login: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
          mood: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
          notebook: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
          finance: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null },
          lists: { currentStreak: 0, longestStreak: 0, lastUpdateDate: null, streakStartDate: null }
        },
        level: 1,
        xp: 0,
        lastActivity: null
      };
    } catch (error) {
      console.error('Error initializing gamification:', error);
      throw error;
    }
  },

  async updateGamificationState(userId: string, state: Partial<GamificationStateData>): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        'gamification': {
          ...state,
          updatedAt: serverTimestamp()
        }
      });
    } catch (error) {
      console.error('Error updating gamification state:', error);
      throw error;
    }
  },

  async resetGamification(): Promise<void> {
    if (!auth.currentUser?.uid) return;
    
    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        gamification: defaultGamificationState,
        updatedAt: serverTimestamp()
      });
      
      useGamificationStore.getState().resetState();
    } catch (error) {
      console.error('Error resetting gamification:', error);
    }
  },

  async updateStreakProduction(): Promise<void> {
    // Do nothing
    return;
  },

  async checkAchievements(): Promise<void> {
    // Do nothing
    return;
  },

  async validateAchievement(): Promise<boolean> {
    return false;
  },

  async checkListAchievements(userId: string, skipStreakUpdate = false) {
    try {
      const store = useGamificationStore.getState();
      if (!store) {
        console.warn('Gamification store not initialized');
        return;
      }
      
      // Initialize streaks if missing
      if (!store.streaks?.lists) {
        store.initializeFromUser({
          ...store,
          streaks: {
            ...store.streaks,
            lists: {
              currentStreak: 0,
              longestStreak: 0,
              lastUpdateDate: null,
              streakStartDate: null
            }
          }
        });
      }

      // Get all data first
      const lists = await listsService.getLists(userId).catch(err => {
        console.error('Error fetching lists:', err);
        return [];
      });

      const [completedItemCount, listsWithCounts, completedListCount] = await Promise.all([
        listsService.getCompletedItemCount(userId).catch(() => 0),
        listsService.getListsWithItemCounts(userId).catch(() => []),
        listsService.getCompletedListCount(userId).catch(() => 0)
      ]);

      // Check achievements
      try {
        if (lists.length >= 1) {
          store.addAchievement('first-list');
        }

        if (completedItemCount >= 50) {
          store.addAchievement('task-master');
        }

        const listsWithFiveItems = listsWithCounts.filter(l => l.itemCount >= 5);
        if (listsWithFiveItems.length >= 10) {
          store.addAchievement('list-organizer');
        }

        if (completedListCount >= 5) {
          store.addAchievement('productivity-pro');
        }

        // Check hidden achievement
        const hasListLightning = await listsService.checkListLightning(userId).catch(() => false);
        if (hasListLightning) {
          store.addAchievement('list-lightning');
        }
      } catch (achievementError) {
        console.error('Error unlocking achievements:', achievementError);
      }

      // Only update streak if not skipped and there's activity
      if (!skipStreakUpdate) {
        try {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          
          const hasRecentActivity = await listsService.hasActivityOnDate(userId, today).catch(() => false);
          if (hasRecentActivity) {
            await store.updateStreakProduction('lists');
          }
        } catch (streakError) {
          console.error('Error updating streak:', streakError);
        }
      }
    } catch (error) {
      console.error('Error checking list achievements:', error);
      throw new AppError(
        'Failed to check list achievements',
        'ACHIEVEMENT_CHECK_FAILED',
        error instanceof Error ? error : undefined
      );
    }
  },

  async checkFinanceAchievements(userId: string): Promise<void> {
    try {
      // Check if user has finance data first
      const hasFinanceData = await financeService.hasUserData(userId);
      if (!hasFinanceData) {
        // Skip finance achievements for new users
        return;
      }

      // Check specific achievements
      try {
        await financeService.checkFinanceFrenzy(userId);
      } catch (error) {
        console.warn('[GamificationService] Error checking finance frenzy:', error);
        // Continue with other checks
      }

      // ... other finance achievement checks ...

    } catch (error) {
      console.error('[GamificationService] Error checking finance achievements:', error);
      throw new AppError('FINANCE_ACHIEVEMENTS_CHECK_FAILED', 'Failed to check finance achievements');
    }
  },

  async checkAllAchievements(userId: string, skipStreakUpdate = false): Promise<void> {
    try {
      const store = useGamificationStore.getState();
      if (!store?.streaks) {
        console.warn('Gamification state not initialized');
        return;
      }

      // Run achievement checks sequentially to avoid race conditions
      await this.checkFinanceAchievements(userId);
      await this.checkListAchievements(userId, skipStreakUpdate);
      // Add other achievement checks here
    } catch (error) {
      console.error('Error checking all achievements:', error);
      throw new AppError(
        'Failed to check achievements',
        'ACHIEVEMENT_CHECK_FAILED',
        error instanceof Error ? error : undefined
      );
    }
  },

  // Update the force check to bypass debounce
  async forceCheckAchievements(userId: string): Promise<void> {
    try {
      // Cancel any pending debounced checks
      debouncedCheck.cancel();
      
      // Run check immediately
      await this.checkAllAchievements(userId, false);
      console.log('Force checked all achievements');
    } catch (error) {
      console.error('Error force checking achievements:', error);
      throw new AppError('Failed to force check achievements', 'ACHIEVEMENT_CHECK_FAILED');
    }
  },

  async checkMoodAchievements(userId: string, skipStreakUpdate = false) {
    try {
      const store = useGamificationStore.getState();
      if (!store?.streaks?.mood) {
        console.warn('Mood streak data not initialized');
        return;
      }

      // Get all mood data
      const entryCount = await moodService.getMoodEntryCount(userId);
      const consecutiveDays = await moodService.getConsecutiveDays(userId);
      const entriesWithNotes = await moodService.getMoodEntriesWithNotes(userId);
      const hasMoodSwing = await moodService.checkMoodSwing(userId);

      // Check achievements
      const checkAndUnlock = (id: string, condition: boolean) => {
        if (!store.achievements.some(a => a.achievementId === id) && condition) {
          store.addAchievement(id);
        }
      };

      // Regular achievements
      checkAndUnlock('first-mood', entryCount >= 1);
      checkAndUnlock('mood-tracker', consecutiveDays >= 7);
      checkAndUnlock('mood-master', consecutiveDays >= 30);
      checkAndUnlock('mood-insights', entriesWithNotes >= 10);

      // Hidden achievement
      checkAndUnlock('mood-swing', hasMoodSwing);

      // Update streak if not skipped and there's activity today
      if (!skipStreakUpdate) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const hasRecentActivity = await moodService.getUserMoodEntries(userId, {
          startDate: today,
          endDate: new Date()
        }).then(entries => entries.length > 0);

        if (hasRecentActivity) {
          await store.updateStreakProduction('mood');
        }
      }
    } catch (error) {
      console.error('Error checking mood achievements:', error);
      throw new AppError(
        'Failed to check mood achievements',
        'ACHIEVEMENT_CHECK_FAILED',
        error instanceof Error ? error : undefined
      );
    }
  },

  async checkAchievementProgress(userId: string, achievementId: string): Promise<number> {
    try {
      const achievement = ACHIEVEMENTS.find(a => a.id === achievementId);
      if (!achievement) return 0;

      switch (achievement.id) {
        // List Achievements
        case 'first-list': {
          const lists = await listsService.getLists(userId);
          return Math.min(lists.length, achievement.requirement);
        }
        case 'task-master': {
          const count = await listsService.getCompletedItemCount(userId);
          return Math.min(count, achievement.requirement);
        }
        case 'list-organizer': {
          const lists = await listsService.getListsWithItemCounts(userId);
          const bigLists = lists.filter(l => l.itemCount >= 5);
          return Math.min(bigLists.length, achievement.requirement);
        }
        case 'productivity-pro': {
          const count = await listsService.getCompletedListCount(userId);
          return Math.min(count, achievement.requirement);
        }
        case 'list-lightning': {
          const hasAchieved = await listsService.checkListLightning(userId);
          return hasAchieved ? 1 : 0;
        }

        // Finance Achievements
        case 'finance-starter':
        case 'transaction-tracker':
        case 'finance-enthusiast': {
          const count = await financeService.getTransactionCount(userId);
          return Math.min(count, achievement.requirement);
        }
        case 'finance-frenzy': {
          const hasAchieved = await financeService.checkFinanceFrenzy(userId);
          return hasAchieved ? 1 : 0;
        }

        // Mood Achievements
        case 'first-mood':
        case 'mood-tracker':
        case 'mood-master': {
          const count = await moodService.getMoodEntryCount(userId);
          return Math.min(count, achievement.requirement);
        }
        case 'mood-insights': {
          const count = await moodService.getMoodEntriesWithNotes(userId);
          return Math.min(count, achievement.requirement);
        }
        case 'mood-swing': {
          const hasAchieved = await moodService.checkMoodSwing(userId);
          return hasAchieved ? 1 : 0;
        }

        default:
          return 0;
      }
    } catch (error) {
      console.error('Error checking achievement progress:', error);
      return 0;
    }
  }
}; 