import { AppError } from './errors'

export function validateAsset(asset: any) {
  // Required fields
  if (!asset.name?.trim()) {
    throw new AppError('Asset name is required', 'VALIDATION_ERROR')
  }
  
  if (!asset.type) {
    throw new AppError('Asset type is required', 'VALIDATION_ERROR')
  }
  
  if (typeof asset.amount !== 'number' || asset.amount < 0) {
    throw new AppError('Asset amount must be a positive number', 'VALIDATION_ERROR')
  }

  // Optional fields validation
  if (asset.quantity !== undefined && (typeof asset.quantity !== 'number' || asset.quantity < 0)) {
    throw new AppError('Quantity must be a positive number', 'VALIDATION_ERROR')
  }

  if (asset.pricePerUnit !== undefined && (typeof asset.pricePerUnit !== 'number' || asset.pricePerUnit < 0)) {
    throw new AppError('Price per unit must be a positive number', 'VALIDATION_ERROR')
  }

  // Clean undefined values
  const cleanAsset = Object.fromEntries(
    Object.entries(asset).filter(([_, value]) => value !== undefined)
  )

  return cleanAsset
} 