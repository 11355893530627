import { useState, useEffect } from 'react'
import { useTheme } from '../contexts/ThemeContext'
import { Calendar } from '../components/calendar/Calendar'
import { useListsStore } from '../store/useListsStore'
import { listsService } from '../services/listsService'
import { calendarService } from '../services/calendarService'
import { CalendarEvent, todoToCalendarEvent } from '../types/calendar'
import { format, isSameDay, startOfMonth, endOfMonth, startOfDay, endOfDay, addMonths, isWithinInterval } from 'date-fns'
import { useErrorFeedback } from '../hooks/useErrorFeedback'
import { useStore } from '../store/useStore'
import { EventModal } from '../components/calendar/EventModal'
import { PlusIcon } from '@heroicons/react/24/outline'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { financeService } from '../services/financeService'
import { subscriptionToCalendarEvent, recurringTransactionToCalendarEvent } from '../types/calendar'
import { UpgradePrompt } from '../components/shared/UpgradePrompt'
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits'
import { PiCalendar } from 'react-icons/pi'
import { useSearchParams, useNavigate } from "react-router-dom"

export function CalendarPage() {
  const { colors } = useTheme()
  const { lists } = useListsStore()
  const { user } = useStore()
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { showError } = useErrorFeedback()
  const { t } = useTranslation()
  const [visibleMonth, setVisibleMonth] = useState(new Date())
  const userTier = user?.subscription?.tier || 'free'
  const canAccessCalendar = ['core', 'pro'].includes(userTier)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  if (!canAccessCalendar) {
    return (
      <div className="p-4">
        <UpgradePrompt
          title="Unlock Calendar Features"
          description="Upgrade to Core or Pro to access the calendar, create events, and sync with your other trackers. Keep everything organized in one place."
          feature="calendar"
          currentTier={userTier}
          requiredTier="core"
          icon={<PiCalendar className="w-6 h-6" style={{ color: colors.primary }}/>}
        />
      </div>
    )
  }

  const fetchEvents = async (date: Date) => {
    if (!user?.id) return

    try {
      setIsLoading(true)
      
      const start = startOfMonth(date)
      const end = endOfMonth(addMonths(date, 2)) // Fetch 3 months worth of data

      // First fetch the lists to ensure we have the latest data
      const userLists = await listsService.getLists(user.id)

      // Fetch both calendar events and todos
      const [calendarEvents, todoEvents] = await Promise.all([
        calendarService.getEvents(user.id, start, end),
        Promise.all(userLists.map(async list => {
          const items = await listsService.getListItems(list.id)
          return items
            .filter(item => {
              const hasDate = Boolean(item.dueDate)
              const isInRange = item.dueDate && 
                isWithinInterval(new Date(item.dueDate), { start, end })
              return hasDate && isInRange
            })
            .map(item => ({
              ...todoToCalendarEvent({
                ...item,
                listId: list.id,
                content: item.content
              }),
              color: list.color
            }))
        }))
      ])

      // Combine and sort events
      const allEvents = [
        ...calendarEvents,
        ...todoEvents.flat()
      ].sort((a, b) => a.date.getTime() - b.date.getTime())

      setEvents(allEvents)
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'CalendarPage.fetchEvents')
      }
    } finally {
      setIsLoading(false)
    }
  }

  // Update events when visible month changes or lists change
  useEffect(() => {
    fetchEvents(visibleMonth)
  }, [user?.id, visibleMonth]) // Remove lists dependency since we're fetching directly

  // Simplify URL parameter handling to only handle date
  useEffect(() => {
    const dateParam = searchParams.get('date')
    
    if (dateParam) {
      // Parse the date parts
      const [year, month, day] = dateParam.split('-').map(Number)
      // Create date using local timezone (month is 0-based in JS Date)
      const date = new Date(year, month - 1, day)
      
      if (!isNaN(date.getTime())) {
        setSelectedDate(date)
        setVisibleMonth(date)
      }
    }
  }, [searchParams])

  const handleEventClick = (event: CalendarEvent) => {
    // For todo events, navigate to lists page
    if (event.type === 'todo') {
      const route = event.listId 
        ? `/lists/${event.listId}?todoId=${event.sourceId}`
        : `/lists?todoId=${event.sourceId}`
      navigate(route)
      return
    }

    // For subscription events, navigate to finance page
    if (event.type === 'subscription') {
      navigate('/finance?view=subscriptions')
      return
    }

    // For regular events, open the modal
    setSelectedEvent(event)
    setIsModalOpen(true)
  }

  const handleSaveEvent = async (eventData: Partial<CalendarEvent>) => {
    if (!user?.id) return

    try {
      if (selectedEvent?.id) {
        // Update existing event
        await calendarService.updateEvent(selectedEvent.id, {
          ...eventData,
          userId: user.id
        })
      } else {
        // Create new event
        await calendarService.createEvent({
          ...eventData,
          userId: user.id
        } as Omit<CalendarEvent, 'id' | 'createdAt' | 'updatedAt'>)
      }

      // Refresh events
      const now = new Date()
      await fetchEvents(startOfMonth(now))
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'CalendarPage.handleSaveEvent')
      }
    }
  }

  const handleDeleteEvent = async (eventId: string) => {
    try {
      // If this is a recurring instance, confirm deletion of all instances
      if (eventId.includes('-')) {
        const confirmDelete = window.confirm(
          t('calendar.confirmRecurringDelete')
        )
        if (!confirmDelete) return
      }

      await calendarService.deleteEvent(eventId)
      
      // Close modal if open
      setIsModalOpen(false)
      setSelectedEvent(null)
      
      // Refresh events
      await fetchEvents(visibleMonth)
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'CalendarPage.handleDeleteEvent')
      }
    }
  }

  const handleMonthChange = (date: Date) => {
    setVisibleMonth(date)
  }

  // Update the todo details section at the bottom
  const renderEventDetails = (event: CalendarEvent) => {
    // For todo events, navigate to specific list
    if (event.type === 'todo') {
      return (
        <div 
          key={event.id}
          className="p-4 rounded-lg cursor-pointer"
          style={{ backgroundColor: `${colors.primary}10` }}
          onClick={() => {
            // Navigate to specific list if listId exists, otherwise fallback to general lists route
            const route = event.listId 
              ? `/lists/${event.listId}?todoId=${event.sourceId}`
              : `/lists?todoId=${event.sourceId}`
            navigate(route)
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div
                className="w-2 h-2 rounded-full"
                style={{ 
                  background: `linear-gradient(45deg, ${colors.primary}40, ${colors.secondary}40)`,
                }}
              />
              <span 
                className="font-medium"
                style={{ 
                  color: colors.text,
                  textDecoration: event.completed ? 'line-through' : 'none'
                }}
              >
                {event.title}
              </span>
              {event.completed && (
                <span 
                  className="text-xs px-2 py-0.5 rounded"
                  style={{ backgroundColor: `${colors.success}20`, color: colors.success }}
                >
                  Completed
                </span>
              )}
            </div>
            <span 
              className="text-sm"
              style={{ color: colors.textSecondary }}
            >
              {event.isAllDay 
                ? t('calendar.allDay')
                : format(event.date, 'h:mm a')}
            </span>
          </div>

          {/* Additional details */}
          <div className="ml-5 space-y-1">
            {event.description && (
              <p 
                className="text-sm line-clamp-2"
                style={{ color: colors.textSecondary }}
              >
                {event.description}
              </p>
            )}
            <div 
              className="text-sm flex items-center gap-1"
              style={{ color: colors.textSecondary }}
            >
              <span className="px-2 py-0.5 rounded text-xs" style={{
                backgroundColor: `${colors.info}20`,
                color: colors.info
              }}>
                Todo
              </span>
              {event.priority && (
                <span className="px-2 py-0.5 rounded text-xs" style={{
                  backgroundColor: 
                    event.priority === 'high' ? `${colors.error}20` :
                    event.priority === 'medium' ? `${colors.warning}20` :
                    `${colors.info}20`,
                  color:
                    event.priority === 'high' ? colors.error :
                    event.priority === 'medium' ? colors.warning :
                    colors.info
                }}>
                  {event.priority.charAt(0).toUpperCase() + event.priority.slice(1)} Priority
                </span>
              )}
            </div>
          </div>
        </div>
      )
    }

    // For subscription events
    if (event.type === 'subscription') {
      return (
        <div 
          key={event.id}
          className="p-4 rounded-lg cursor-pointer hover:bg-opacity-80 transition-colors"
          style={{ backgroundColor: `${colors.primary}10` }}
          onClick={() => navigate('/finance?view=subscriptions')}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div
                className="w-2 h-2 rounded-full"
                style={{ 
                  background: `repeating-linear-gradient(
                    45deg,
                    ${colors.error},
                    ${colors.error} 4px,
                    ${colors.error}90 4px,
                    ${colors.error}90 8px
                  )`
                }}
              />
              <span 
                className="font-medium"
                style={{ color: colors.text }}
              >
                {event.title}
              </span>
              <span 
                className="text-xs px-2 py-0.5 rounded"
                style={{ 
                  backgroundColor: `${colors.error}20`,
                  color: colors.error
                }}
              >
                Subscription Renewal
              </span>
            </div>
            <span 
              className="text-sm"
              style={{ color: colors.textSecondary }}
            >
              {event.isAllDay 
                ? t('calendar.allDay')
                : format(event.date, 'h:mm a')}
            </span>
          </div>

          {/* Additional details */}
          <div className="ml-5 space-y-1">
            {event.description && (
              <p 
                className="text-sm line-clamp-2"
                style={{ color: colors.textSecondary }}
              >
                {event.description}
              </p>
            )}
            <div 
              className="text-sm flex items-center gap-1"
              style={{ color: colors.textSecondary }}
            >
              <span className="px-2 py-0.5 rounded text-xs" style={{
                backgroundColor: `${colors.error}20`,
                color: colors.error
              }}>
                {t('finance.subscriptions.title')}
              </span>
              <span className="text-sm" style={{ color: colors.textSecondary }}>
                {t('finance.subscriptions.manageInFinance')}
              </span>
            </div>
          </div>
        </div>
      )
    }

    // For regular events, make the entire card clickable
    return (
      <div 
        key={event.id}
        className="p-4 rounded-lg cursor-pointer hover:bg-opacity-80 transition-colors"
        style={{ backgroundColor: `${colors.primary}10` }}
        onClick={() => handleEventClick(event)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: event.color || colors.primary }}
            />
            <span 
              className="font-medium"
              style={{ color: colors.text }}
            >
              {event.title}
            </span>
            {/* Add continuation indicator if event spans multiple days */}
            {(!isSameDay(event.date, selectedDate) || (event.endDate && !isSameDay(event.date, event.endDate))) && (
              <span 
                className="text-xs px-2 py-0.5 rounded"
                style={{ 
                  backgroundColor: `${colors.primary}20`,
                  color: colors.primary
                }}
              >
                {isSameDay(event.date, selectedDate) 
                  ? 'Starts today'
                  : event.endDate && isSameDay(event.endDate, selectedDate)
                  ? 'Ends today'
                  : 'Continues'}
              </span>
            )}
          </div>
          <span 
            className="text-sm"
            style={{ color: colors.textSecondary }}
          >
            {event.isAllDay 
              ? t('calendar.allDay')
              : isSameDay(event.date, selectedDate)
              ? format(event.date, 'h:mm a')
              : event.endDate && isSameDay(event.endDate, selectedDate)
              ? format(event.endDate, 'h:mm a')
              : t('calendar.allDay')}
          </span>
        </div>

        {/* Additional details */}
        <div className="ml-5 space-y-1">
          {event.description && (
            <p 
              className="text-sm line-clamp-2"
              style={{ color: colors.textSecondary }}
            >
              {event.description}
            </p>
          )}
          {event.location && (
            <div 
              className="text-sm flex items-center gap-1"
              style={{ color: colors.textSecondary }}
            >
              <MapPinIcon className="h-4 w-4" />
              <span>{event.location}</span>
            </div>
          )}
          <div 
            className="text-sm flex items-center gap-1"
            style={{ color: colors.textSecondary }}
          >
            <span className="px-2 py-0.5 rounded text-xs" style={{
              backgroundColor: `${colors.primary}20`,
              color: colors.primary
            }}>
              {t(`calendar.source.${event.source}`)}
            </span>
            {event.recurrence !== 'none' && (
              <span className="px-2 py-0.5 rounded text-xs" style={{
                backgroundColor: `${colors.secondary}20`,
                color: colors.secondary
              }}>
                {t(`calendar.recurrence.${event.recurrence}`)}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="p-4 space-y-6">
      <div className="flex items-center justify-between">
        <h1 
          className="text-2xl font-semibold"
          style={{ color: colors.primary }}
        >
          Calendar
        </h1>

        <button
          onClick={() => {
            setSelectedEvent(null)
            setSelectedDate(new Date())
            setIsModalOpen(true)
          }}
          className="flex items-center gap-2 px-4 py-2 rounded-lg"
          style={{ backgroundColor: colors.primary, color: '#fff' }}
        >
          <PlusIcon className="h-5 w-5" />
          <span>New Event</span>
        </button>
      </div>

      <Calendar 
        events={events}
        onDateSelect={setSelectedDate}
        onEventClick={handleEventClick}
        selectedDate={selectedDate}
        isLoading={isLoading}
        onMonthChange={handleMonthChange}
      />

      <EventModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setSelectedEvent(null)
        }}
        onSave={handleSaveEvent}
        event={selectedEvent || undefined}
        selectedDate={selectedDate || undefined}
      />

      {/* Selected date events */}
      {selectedDate && (
        <div 
          className="rounded-lg p-4"
          style={{ backgroundColor: colors.surface }}
        >
          <h2 
            className="text-lg font-medium mb-4"
            style={{ color: colors.primary }}
          >
            {format(selectedDate, 'MMMM d, yyyy')}
          </h2>
          
          <div className="space-y-3">
            {events
              .filter(event => {
                if (event.recurrence && event.recurrence !== 'none') {
                  return isSameDay(event.date, selectedDate)
                } else {
                  const eventStart = startOfDay(event.date)
                  const eventEnd = event.endDate ? endOfDay(event.endDate) : eventStart
                  const selectedDateTime = startOfDay(selectedDate)
                  return selectedDateTime >= eventStart && selectedDateTime <= eventEnd
                }
              })
              .map(renderEventDetails)}
          </div>
        </div>
      )}
    </div>
  )
}