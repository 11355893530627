import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { User, UserPreferences } from '../types/user';
import { defaultUserPreferences } from '../types/user';
import { auth, db } from '../utils/firebase';
import type { User as FirebaseUser } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { aiCreditService } from '../services/aiCreditService';
import { addMonths } from 'date-fns';
import { AppError } from '../utils/errors';
import { SUBSCRIPTION_LIMITS } from '../constants/subscriptionLimits';
import { useAICreditsStore } from './useAICreditsStore';
import { Timestamp } from 'firebase/firestore';
import { userService } from '../services/userService';
import { defaultGamificationState } from '../types/user';
import { useGamificationStore } from './useGamificationStore';
import { gamificationService } from '../services/gamificationService';
import { authService } from '../services/authService';

// Helper functions for user data
async function getUserData(userId: string): Promise<User | null> {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() ? userDoc.data() as User : null;
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
}

async function setUserData(userId: string, userData: Partial<User>): Promise<void> {
  try {
    await setDoc(doc(db, 'users', userId), {
      ...userData,
      updatedAt: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Error setting user data:', error);
    throw new AppError('Failed to save user data', 'USER_SAVE_FAILED');
  }
}

async function fetchProfile() {
  if (!auth.currentUser?.uid) {
    throw new AppError('Not authenticated', 'AUTH_REQUIRED')
  }

  try {
    const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid))
    
    if (!userDoc.exists()) {
      throw new AppError('Profile not found', 'PROFILE_NOT_FOUND')
    }

    const userData = userDoc.data()
    // Convert Firestore Timestamp to Date
    const createdAt = userData.createdAt instanceof Timestamp 
      ? userData.createdAt.toDate() 
      : new Date(userData.createdAt)

    return {
      ...userData,
      id: userDoc.id,
      createdAt: createdAt,
      // Ensure other timestamp fields are converted
      updatedAt: userData.updatedAt instanceof Timestamp 
        ? userData.updatedAt.toDate() 
        : new Date(userData.updatedAt)
    }
  } catch (error) {
    console.error('Error fetching profile:', error)
    throw new AppError(
      'Failed to fetch profile',
      'PROFILE_FETCH_FAILED',
      { cause: error }
    )
  }
}

export type AppState = {
  isDarkMode: boolean;
  userName: string;
  user: User | null;
  isInitialized: boolean;
  toggleDarkMode: () => void;
  setUserName: (name: string) => void;
  updateUser: (updates: Partial<User>) => void;
  updatePreferences: (preferences: UserPreferences) => Promise<void>;
  initializeUser: (firebaseUser: FirebaseUser) => Promise<void>;
  setIsDarkMode: (isDark: boolean) => void;
  fetchProfile: () => Promise<User | null>;
  setIsInitialized: (isInitialized: boolean) => void;
  setUser: (user: User | null) => void;
  logout: () => Promise<void>;
  isLoading: boolean;
  error: Error | null;
  clearUser: () => void;
  setError: (error: Error | null) => void;
};

export const useStore = create<AppState>()(
  persist(
    (set, get) => ({
      user: null,
      isInitialized: false,
      isDarkMode: false,
      userName: '',
      toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
      setUserName: (name: string) => set({ userName: name }),
      updateUser: async (updates) => {
        const currentUser = get().user;
        if (!currentUser?.id) {
          throw new Error('No user logged in');
        }

        try {
          set({ isLoading: true });
          
          // Update in database
          await userService.updateUser(currentUser.id, updates);
          
          // Update local state
          set({ 
            user: { ...currentUser, ...updates },
            isLoading: false 
          });
        } catch (error) {
          set({ error: error as Error, isLoading: false });
          throw error;
        }
      },
      initializeUser: async (firebaseUser: FirebaseUser) => {
        try {
          set({ isLoading: true, error: null });
          
          if (!firebaseUser?.uid) {
            set({ 
              user: null, 
              isInitialized: true,
              error: new AppError('No user found', 'USER_NOT_FOUND')
            });
            return;
          }

          // Use the function from authService
          await authService.ensureUserDocument(firebaseUser);

          // Initialize with empty gamification state
          const gamificationData = await gamificationService.initializeGamification(firebaseUser.uid);
          
          const userData = await getUserData(firebaseUser.uid);
          
          if (!userData) {
            throw new AppError('Failed to initialize user', 'USER_INIT_FAILED');
          }

          const userWithId = {
            ...userData,
            id: firebaseUser.uid,
            gamification: gamificationData,
          };
          
          set({ 
            user: userWithId, 
            isInitialized: true,
            error: null 
          });
          
          useGamificationStore.getState().initializeFromUser(gamificationData);

        } catch (error) {
          console.error('Error initializing user:', error);
          set({ 
            user: null, 
            isInitialized: true,
            error: error instanceof Error ? error : new Error('Failed to initialize user'),
            isLoading: false
          });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },
      fetchProfile: async () => {
        try {
          if (!auth.currentUser) {
            await new Promise<void>((resolve) => {
              const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                if (user) {
                  resolve();
                } else {
                  throw new AppError('Not authenticated', 'auth/not-authenticated', 401);
                }
              });
            });
          }

          const profile = await fetchProfile();
          
          if (!profile) {
            const currentUser = get().user;
            if (!currentUser) {
              throw new AppError('No user profile found', 'profile/not-found', 404);
            }
            return currentUser;
          }

          set({ user: profile });
          return profile;
        } catch (error) {
          if (error instanceof AppError) throw error;
          throw new AppError(
            'Failed to fetch profile',
            'profile/fetch-failed',
            500
          );
        }
      },
      updatePreferences: async (preferences) => {
        try {
          const user = get().user;
          if (!user?.id) throw new AppError('No user found');

          // Merge with existing preferences
          const mergedPreferences = {
            ...defaultUserPreferences,
            ...user.preferences,
            ...preferences
          };

          // Update local state first
          set(state => ({
            user: state.user ? {
              ...state.user,
              preferences: mergedPreferences
            } : null
          }));

          // Then update in database
          await userService.updateUserPreferences(user.id, mergedPreferences);
        } catch (error) {
          // Revert local state on error
          set(state => ({
            user: state.user ? {
              ...state.user,
              preferences: state.user.preferences
            } : null
          }));
          throw error;
        }
      },
      setIsDarkMode: (isDark) => set({ isDarkMode: isDark }),
      setIsInitialized: (isInitialized: boolean) => set({ isInitialized }),
      setUser: (user: User | null) => set({ user }),
      logout: async () => {
        try {
          await auth.signOut();
          set({ user: null });
        } catch (error) {
          console.error('Error logging out:', error);
          throw new AppError('Failed to logout', 'LOGOUT_FAILED');
        }
      },
      onRehydrateStorage: () => (state: AppState | undefined) => {
        if (!state) return;

        // Get system preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        
        // Priority: user preference > system
        const userTheme = state.user?.preferences?.theme;
        const shouldBeDark = userTheme === 'dark' || 
          ((!userTheme || userTheme === 'system') && prefersDark);
        
        set({ isDarkMode: shouldBeDark });
        
        // Update theme attribute
        document.documentElement.setAttribute(
          'data-theme',
          shouldBeDark ? 'dark' : 'light'
        );
      },
      isLoading: false,
      error: null,
      clearUser: () => {
        set({ user: null, isInitialized: false, isLoading: false, error: null });
      },
      setError: (error) => set({ error }),
    }),
    {
      name: 'app-storage',
      partialize: (state) => ({
        isDarkMode: state.isDarkMode,
        user: state.user,
        // Don't persist error state
      })
    }
  )
);

async function initializeUser(userId: string) {
  try {
    // Get user data first
    const userData = await userService.getUser(userId);
    
    // Initialize basic user state
    set({ 
      user: userData,
      isInitialized: true 
    });

    // Initialize gamification state
    if (userData.gamification) {
      const gamificationStore = useGamificationStore.getState();
      gamificationStore.initializeFromUser(userData.gamification);
    }

    // Delay achievement checks to avoid race conditions
    setTimeout(async () => {
      try {
        await gamificationService.checkAchievements(userId);
      } catch (error) {
        console.warn('[Store] Error checking achievements:', error);
      }
    }, 1000);

  } catch (error) {
    console.error('[Store] Error initializing user:', error);
    set({ error: error as Error });
  }
}