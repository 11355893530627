export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export function formatTimezone(timezone: string): string {
  try {
    return timezone
      .split('/')
      .pop()!
      .replace(/_/g, ' ')
  } catch {
    return timezone
  }
}

export function formatLanguage(code: string): string {
  try {
    return new Intl.DisplayNames([code], {
      type: 'language'
    }).of(code) || code
  } catch {
    // Fallback mapping for common codes
    const languageMap: Record<string, string> = {
      en: 'English',
      es: 'Spanish',
      fr: 'French',
      de: 'German',
      it: 'Italian',
      pt: 'Portuguese',
      ru: 'Russian',
      zh: 'Chinese',
      ja: 'Japanese',
      ko: 'Korean'
    }
    return languageMap[code] || code
  }
} 