import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import ImageNodeComponent from './ImageNodeComponent'

const ImageNode = Node.create({
  name: 'image',
  group: 'block',
  inline: false,
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null
      },
      alt: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
        getAttrs: dom => ({
          src: (dom as HTMLImageElement).getAttribute('src'),
          alt: (dom as HTMLImageElement).getAttribute('alt')
        })
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', HTMLAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageNodeComponent)
  }
})

export default ImageNode 