import { useState, useEffect } from 'react'
import { CalendarIcon, XCircleIcon } from '@heroicons/react/24/outline'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../ui/popover'
import { useTheme } from '../../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import { format, addMonths, subMonths, isValid } from 'date-fns'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

interface DatePickerProps {
  date?: Date
  onSelect: (date: Date | undefined) => void
  iconSize?: number
}

export function DatePicker({ date, onSelect, iconSize = 5 }: DatePickerProps) {
  const [open, setOpen] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(() => {
    // Ensure we start with a valid date
    return isValid(date) ? date : new Date()
  })
  const { colors } = useTheme()
  const { t } = useTranslation()

  // Update current month when date changes
  useEffect(() => {
    if (date && isValid(date)) {
      setCurrentMonth(date)
    }
  }, [date])

  const iconClass = `h-${iconSize} w-${iconSize}`
  const isValidDate = date instanceof Date && isValid(date)

  const handleClearDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    onSelect(undefined)
  }

  const getDaysInMonth = (date: Date) => {
    try {
      const year = date.getFullYear()
      const month = date.getMonth()
      const daysInMonth = new Date(year, month + 1, 0).getDate()
      const firstDayOfMonth = new Date(year, month, 1).getDay()
      
      const days: (Date | null)[] = []
      // Add empty cells for days before the first day of the month
      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(null)
      }
      // Add the days of the month
      for (let i = 1; i <= daysInMonth; i++) {
        const newDate = new Date(year, month, i)
        if (isValid(newDate)) {
          days.push(newDate)
        }
      }
      return days
    } catch (error) {
      console.error('Error generating days:', error)
      return []
    }
  }

  const days = getDaysInMonth(currentMonth)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          onClick={(e) => e.stopPropagation()}
          className="relative p-1 rounded hover:bg-opacity-10 transition-colors group"
          style={{ color: isValidDate ? colors.primary : colors.textSecondary }}
        >
          <CalendarIcon className={iconClass} />
          {isValidDate && (
            <div 
              className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleClearDate}
            >
              <XCircleIcon className="h-4 w-4" style={{ color: colors.error }} />
            </div>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-auto p-3" 
        align="end"
        style={{ 
          backgroundColor: colors.surface,
          borderColor: colors.border,
          borderWidth: 1,
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          width: '280px'
        }}
      >
        <div className="space-y-4">
          {/* Month navigation */}
          <div className="flex items-center justify-between">
            <button
              onClick={() => setCurrentMonth(prev => subMonths(prev, 1))}
              className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
              style={{ color: colors.textSecondary }}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <span style={{ color: colors.text }}>
              {format(currentMonth, 'MMMM yyyy')}
            </span>
            <button
              onClick={() => setCurrentMonth(prev => addMonths(prev, 1))}
              className="p-1 rounded-full hover:bg-opacity-10 transition-colors"
              style={{ color: colors.textSecondary }}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Weekday headers */}
          <div className="grid grid-cols-7 gap-1 text-center text-sm mb-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
              <div 
                key={day}
                style={{ color: colors.textSecondary }}
              >
                {day}
              </div>
            ))}
          </div>

          {/* Calendar grid */}
          <div className="grid grid-cols-7 gap-1">
            {days.map((day, index) => {
              if (!day) {
                return <div key={`empty-${index}`} />
              }

              const isSelected = date && isValid(date) && 
                date.getDate() === day.getDate() && 
                date.getMonth() === day.getMonth() && 
                date.getFullYear() === day.getFullYear()

              const isToday = new Date().toDateString() === day.toDateString()

              return (
                <button
                  key={day.toISOString()}
                  onClick={() => {
                    onSelect(day)
                    setOpen(false)
                  }}
                  className="h-8 w-8 rounded-full flex items-center justify-center text-sm transition-colors hover:bg-opacity-10"
                  style={{
                    backgroundColor: isSelected ? colors.primary : isToday ? `${colors.primary}20` : 'transparent',
                    color: isSelected ? '#fff' : isToday ? colors.primary : colors.text,
                  }}
                >
                  {day.getDate()}
                </button>
              )
            })}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
} 