import { 
  collection, 
  doc, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  getDocs,
  serverTimestamp,
  Timestamp,
  writeBatch,
  getDoc
} from 'firebase/firestore'
import { db } from '../utils/firebase'
import type { CalendarEvent, ICalendarEvent } from '../types/calendar'
import { icsToCalendarEvent } from '../types/calendar'
import { addDays, addWeeks, addMonths, addYears, isBefore, isWithinInterval, startOfDay } from 'date-fns'
import { format } from 'date-fns'
import { activityService } from './activityService'
import { financeService } from './financeService'
import { subscriptionToCalendarEvent, recurringTransactionToCalendarEvent } from '../types/calendar'
import { subscriptionService } from './subscriptionService'
import { AppError } from '../utils/errors'

export const calendarService = {
  // Create a new event
  createEvent: async (event: Omit<CalendarEvent, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      // Check subscription access first
      await subscriptionService.checkAccess(event.userId, 'core')

      const eventsRef = collection(db, 'events')
      const eventData = {
        ...event,
        date: Timestamp.fromDate(event.date),
        endDate: event.endDate ? Timestamp.fromDate(event.endDate) : null,
        recurrenceEndDate:
            event.recurrence !== 'none' && event.recurrenceEndDate
                ? Timestamp.fromDate(event.recurrenceEndDate)
                : null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const docRef = await addDoc(eventsRef, eventData)

      // Track the activity
      await activityService.createActivity(
        'calendar_event_created',
        `Created event "${event.title}"`,
        event.description || '',
        { eventId: docRef.id },
        docRef.id,
        'calendar_event'
      )

      return docRef.id
    } catch (error) {
      if (error instanceof AppError) throw error
      throw new AppError(
        'Failed to create event',
        'EVENT_CREATE_FAILED',
        { cause: error }
      )
    }
  },

  // Update an existing event
  updateEvent: async (eventId: string, updates: Partial<CalendarEvent>) => {
    try {
      // Get event to check userId
      const eventRef = doc(db, 'events', eventId)
      const eventDoc = await getDoc(eventRef)
      if (!eventDoc.exists()) throw new AppError('Event not found', 'EVENT_NOT_FOUND')
      
      const event = eventDoc.data()
      await subscriptionService.checkAccess(event.userId, 'core')


      const updateData = {
        ...updates,
        updatedAt: serverTimestamp()
      }

      // Convert dates to Timestamps
      if (updates.date) {
        updateData.date = Timestamp.fromDate(updates.date)
      }
      if (updates.endDate) {
        updateData.endDate = Timestamp.fromDate(updates.endDate)
      }
      if (updates.recurrenceEndDate) {
        updateData.recurrenceEndDate = Timestamp.fromDate(updates.recurrenceEndDate)
      }
      

      await updateDoc(eventRef, updateData)

      // Track the activity
      await activityService.createActivity(
        'calendar_event_updated',
        `Updated event "${updates.title || 'Untitled'}"`,
        '',
        { eventId },
        eventId,
        'calendar_event'
      )
    } catch (error) {
      if (error instanceof AppError) throw error
      throw new AppError(
        'Failed to update event',
        'EVENT_UPDATE_FAILED',
        { cause: error }
      )
    }
  },

  // Delete an event
  deleteEvent: async (eventId: string) => {
    try {
      // If this is a recurring instance, extract the original event ID
      const originalId = eventId.includes('-') ? eventId.split('-')[0] : eventId
      
      const eventRef = doc(db, 'events', originalId)
      
      // Get event data before deletion for activity tracking
      const eventSnap = await getDoc(eventRef)
      const eventData = eventSnap.data() as CalendarEvent
      
      await deleteDoc(eventRef)

      // Track the activity
      await activityService.createActivity(
        'calendar_event_deleted',
        `Deleted event "${eventData?.title || 'Untitled'}"`,
        '',
        { eventId: originalId },
        originalId,
        'calendar_event'
      )
    } catch (error) {
      console.error('Error deleting event:', error)
      throw error
    }
  },

  // Import ICS events
  importIcsEvents: async (icsEvents: ICalendarEvent[], userId: string) => {
    try {
      const events = icsEvents.map(icsEvent => icsToCalendarEvent(icsEvent, userId))
      
      // Batch create events
      const batch = writeBatch(db)
      const eventsRef = collection(db, 'events')
      
      events.forEach(event => {
        const docRef = doc(eventsRef)
        batch.set(docRef, {
          ...event,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        })
      })
      
      await batch.commit()

      // Track the bulk import activity
      await activityService.createActivity(
        'calendar_events_imported',
        `Imported ${icsEvents.length} calendar events`,
        '',
        { count: icsEvents.length.toString() },
        userId,
        'calendar_events'
      )
    } catch (error) {
      console.error('Error importing ICS events:', error)
      throw error
    }
  },

  // Get events for a date range
  getEvents: async (userId: string, startDate: Date, endDate: Date) => {
    try {
      // Check subscription access first
      await subscriptionService.checkAccess(userId, 'core')

      // Get calendar events
      const calendarEventsSnapshot = await getDocs(
        query(
          collection(db, 'events'),
          where('userId', '==', userId),
          where('date', '>=', Timestamp.fromDate(startDate)),
          where('date', '<=', Timestamp.fromDate(endDate))
        )
      )

      // Get subscription events
      const subscriptions = await financeService.getUserSubscriptions(userId)

      const subscriptionEvents = subscriptions
        .filter(subscription => {
          const renewalDate = subscription.subscriptionDetails?.renewalDate
          if (!renewalDate) return false
          
          // Convert to start of day for comparison
          const renewalStartOfDay = startOfDay(renewalDate)
          const startDateStartOfDay = startOfDay(startDate)
          const endDateStartOfDay = startOfDay(endDate)

          const isInRange = isWithinInterval(renewalStartOfDay, { 
            start: startDateStartOfDay, 
            end: endDateStartOfDay 
          })
          
          
          return isInRange
        })
        .map(subscription => {
          const event = subscriptionToCalendarEvent(subscription)
          return event
        })

      // Convert Firestore documents to CalendarEvents
      const calendarEvents = calendarEventsSnapshot.docs
        .map(doc => {
          const data = doc.data()
          try {
            return {
              id: doc.id,
              ...data,
              date: data.date?.toDate() || new Date(),
              endDate: data.endDate?.toDate() || null,
              recurrenceEndDate: data.recurrenceEndDate?.toDate() || null,
              createdAt: data.createdAt?.toDate() || new Date(),
              updatedAt: data.updatedAt?.toDate() || new Date()
            } as CalendarEvent
          } catch (error) {
            console.warn('Error converting event dates:', { id: doc.id, error })
            return null
          }
        })
        .filter(Boolean) as CalendarEvent[]

      // Combine and sort all events
      const allEvents = [...calendarEvents, ...subscriptionEvents]
        .sort((a, b) => a.date.getTime() - b.date.getTime())

      return allEvents

    } catch (error) {
      if (error instanceof AppError) throw error
      throw new AppError(
        'Failed to fetch events',
        'EVENT_FETCH_FAILED',
        { cause: error }
      )
    }
  }
} 