import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ImageNode from './ImageNode';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import ListItem from '@tiptap/extension-list-item';
import Highlight from '@tiptap/extension-highlight';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import { useState, useRef, useEffect, CSSProperties, ReactNode } from 'react';
import type { EditorOptions } from '@tiptap/core';
import { useTheme } from '../../contexts/ThemeContext';
import { useGamificationStore } from '../../store/useGamificationStore';
import { EditorToolbar } from './EditorToolbar';
import { imageService } from '../../services/imageService'
import { useErrorFeedback } from '../../hooks/useErrorFeedback'
import { ImageToolbar } from './ImageToolbar'
import { motion, AnimatePresence } from 'framer-motion'
import { useCallback } from 'react'
import { AIEditorHelper } from './AIEditorHelper'
import { UpgradePrompt } from '../shared/UpgradePrompt';
import { useStore } from '../../store/useStore';
import { SUBSCRIPTION_LIMITS } from '../../constants/subscriptionLimits';
import { PiWarning, PiSparkle } from 'react-icons/pi';

interface RichTextEditorProps {
  content: string;
  onChange: (content: string) => void;
  placeholder?: string;
  headerOffset?: number;
  readOnly?: boolean;
}

interface MenuButtonProps {
  isActive?: boolean;
  onClick: () => void;
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
  className?: string;
}

export const MenuButton = ({ 
  isActive, 
  onClick, 
  children, 
  title, 
  style,
  className
}: MenuButtonProps) => {
  const { colors } = useTheme();
  
  return (
    <button
      onClick={onClick}
      title={title}
      className={`p-1.5 rounded-lg transition-colors duration-200 hover:bg-opacity-10 ${className || ''}`}
      style={{
        backgroundColor: isActive ? `${colors.primary}15` : 'transparent',
        color: isActive ? colors.primary : colors.text,
        ...style
      }}
    >
      {children}
    </button>
  );
};

interface CustomEditorOptions extends EditorOptions {
  // Add any custom options here
}

const editorVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15
    }
  }
}

export function RichTextEditor({ 
  content, 
  onChange, 
  placeholder = 'Start writing...',
  readOnly = false
}: RichTextEditorProps) {
  const { colors } = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showAIHelper, setShowAIHelper] = useState(false);
  const { updateStreakProduction } = useGamificationStore();
  const hasUpdatedStreakRef = useRef(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const { showError } = useErrorFeedback()
  const [selectedImage, setSelectedImage] = useState<{
    url: string
    position: {
      top: number
      left: number
      width: number
      height: number
    } | null
  } | null>(null)
  const { user } = useStore();
  const userTier = user?.subscription?.tier || 'free';
  const characterLimit = SUBSCRIPTION_LIMITS.noteCharacterLimit[userTier];
  const canUseAI = SUBSCRIPTION_LIMITS.features.aiEditor.includes(userTier);
  const [isAtLimit, setIsAtLimit] = useState(false);

  const handleEditorChange = (value: string) => {
    const textContent = editor?.state.doc.textContent || '';
    
    if (userTier === 'free' && textContent.length >= characterLimit) {
      setIsAtLimit(true);
      // Only allow deletions when at limit
      if (textContent.length > content.length) {
        editor?.commands.undo();
        return;
      }
    } else {
      setIsAtLimit(false);
    }
    
    onChange(value);
    
    if (!hasUpdatedStreakRef.current && value.trim().length > 0) {
      updateStreakProduction('notebook');
      hasUpdatedStreakRef.current = true;
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      const url = await imageService.uploadImage(file, 'notes')
      editor?.chain()
        .focus()
        .insertContent({
          type: 'image',
          attrs: {
            src: url,
            alt: file.name
          }
        })
        .run()
    } catch (error) {
      if (error instanceof Error) {
        showError(error, 'RichTextEditor.handleImageUpload')
      }
    }
  }

  const handleImageSelect = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.tagName === 'IMG') {
      const figure = target.closest('figure')
      if (figure) {
        // Remove selected class from all other figures
        editor?.view.dom.querySelectorAll('figure.selected').forEach(f => {
          if (f !== figure) f.classList.remove('selected')
        })
        figure.classList.add('selected')
        
        // Add the toolbar inside the figure
        const toolbar = figure.querySelector('.image-toolbar')
        if (!toolbar) {
          const imageUrl = target.getAttribute('src')
          if (imageUrl) {
            setSelectedImage({
              url: imageUrl,
              position: {
                top: 0,
                left: 0,
                width: target.offsetWidth,
                height: target.offsetHeight
              }
            })
          }
        }
      }
    }
  }

  const handleAIHelperToggle = (show: boolean) => {
    setShowAIHelper(show);
    // If user can't use AI, show the upgrade prompt
    if (!canUseAI && show) {
      setShowAIHelper(true); // This will trigger the UpgradePrompt
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4, 5, 6]
        },
        listItem: false,
        strike: false
      }),
      ImageNode.configure({
        HTMLAttributes: {
          class: 'editor-image',
        },
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'cursor-pointer',
          style: `color: ${colors.primary}; text-decoration: underline;`,
          rel: 'noopener noreferrer nofollow',
          target: '_blank'
        }
      }),
      Placeholder.configure({
        placeholder,
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          class: 'border-collapse table-auto w-full relative group',
        },
      }),
      TableRow,
      TableCell,
      TableHeader,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      ListItem,
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: 'editor-highlight',
          style: `background-color: ${colors.primary}15; color: ${colors.primary}`
        }
      }),
      Strike,
      Underline,
      TextStyle,
      Color,
      FontFamily
    ],
    content,
    onUpdate: ({ editor }) => {
      handleEditorChange(editor.getHTML());
    },
    editable: !readOnly,
    editorProps: {
      attributes: {
        class: 'prose dark:prose-invert max-w-none focus:outline-none',
        style: `color: ${colors.text}`,
      },
      handleDrop: (view, event, slice, moved) => {
        if (!moved && event.dataTransfer?.files.length) {
          const file = event.dataTransfer.files[0]
          if (file && file.type.startsWith('image/')) {
            handleImageUpload(file)
            return true
          }
        }
        return false
      },
      handlePaste: (view, event) => {
        const items = Array.from(event.clipboardData?.items || [])
        const imageItem = items.find(item => item.type.startsWith('image/'))
        
        if (imageItem) {
          event.preventDefault()
          const file = imageItem.getAsFile()
          if (file) {
            handleImageUpload(file)
            return true
          }
        }
        return false
      },
      handleClick: (view, pos, event) => {
        const target = event.target as HTMLElement
        if (target.tagName === 'IMG') {
          handleImageSelect(event)
          return true
        }
        // Remove selected class from all figures when clicking elsewhere
        view.dom.querySelectorAll('figure.selected').forEach(figure => {
          figure.classList.remove('selected')
        })
        setSelectedImage(null)
        return false
      },
    },
  });

  const focusEditor = useCallback(() => {
    editor?.commands.focus()
  }, [editor])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        focusEditor()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [focusEditor])

  useEffect(() => {
    const handleScroll = () => {
      const editorElement = editorRef.current;
      if (!editorElement) return;
      
      const rect = editorElement.getBoundingClientRect();
      const headerOffset = 80;
      const shouldStick = rect.top <= headerOffset;
      
      if (shouldStick !== isScrolled) {
        setIsScrolled(shouldStick);
      }
    };

    // Handle both scroll and selection change events
    const handleSelectionChange = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    document.addEventListener('selectionchange', handleSelectionChange);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [isScrolled]);

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  useEffect(() => {
    return () => {
      setSelectedImage(null)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (!target.closest('.editor-image') && !target.closest('.image-toolbar')) {
        setSelectedImage(null)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  if (!editor) return null;

  return (
    <div className="rich-text-editor relative" ref={editorRef}>
      <style>
        {`
          .ProseMirror {
            min-height: 200px;
            padding: 1rem 0;
            position: relative;
            color: ${colors.text};
          }

          .editor-toolbar {
            position: sticky;
            top: 62px;
            z-index: 20;
            transition: transform 0.2s ease-out;
            -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background-color: ${colors.background};
            padding: 0.5rem 0;
          }

          .editor-toolbar.is-scrolled {
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            top: 40px;
          }

          @supports (-webkit-touch-callout: none) {
            .editor-toolbar {
              position: -webkit-sticky;
              transform: translateZ(0);
              will-change: transform;
            }
          }
        `}
      </style>

      {!isScrolled && <div className="h-4" />}

      {isAtLimit && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 p-4 rounded-lg flex items-center gap-3"
          style={{
            backgroundColor: `${colors.warning}15`,
            border: `1px solid ${colors.warning}`
          }}
        >
          <PiWarning className="w-5 h-5" style={{ color: colors.warning }} />
          <div>
            <p className="text-sm" style={{ color: colors.text }}>
              You've reached the {characterLimit.toLocaleString()} character limit for free users.{' '}
              <Link 
                to="/upgrade"
                className="font-medium hover:underline"
                style={{ color: colors.warning }}
              >
                Upgrade to write longer notes
              </Link>
            </p>
          </div>
        </motion.div>
      )}

      <div className={`editor-toolbar ${isScrolled ? 'is-scrolled' : ''} relative`}>
        <EditorToolbar 
          editor={editor}
          isScrolled={isScrolled}
          showAIHelper={showAIHelper}
          onShowAIHelper={handleAIHelperToggle}
        />
        
        <AnimatePresence>
          {showAIHelper && canUseAI && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute right-4 top-full mt-2 z-50"
              style={{ transform: 'translateY(8px)' }}
            >
              <AIEditorHelper
                editor={editor}
                isVisible={showAIHelper}
                onClose={() => setShowAIHelper(false)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Show upgrade message when clicking AI button as free user */}
      <AnimatePresence>
        {!canUseAI && showAIHelper && (
          <UpgradePrompt
            title="AI Writing Assistant"
            description="Upgrade to Core to access AI-powered writing features including improvements, translations, and more."
            onClose={() => setShowAIHelper(false)}
            feature="aiEditor"
            currentTier={userTier}
            requiredTier="core"
            icon={<PiSparkle className="w-6 h-6" />}
          />
        )}
      </AnimatePresence>

      {isScrolled && (
        <div className="h-12 transition-all duration-300" />
      )}

      <motion.div 
        className="prose dark:prose-invert max-w-none px-4"
        variants={editorVariants}
        initial="hidden"
        animate="visible"
      >
        <EditorContent 
          editor={editor}
          className="prose dark:prose-invert max-w-none"
          style={{
            ['--tw-prose-body' as string]: colors.text,
            ['--tw-prose-headings' as string]: colors.text,
            ['--tw-prose-links' as string]: colors.primary,
            ['--tw-prose-bold' as string]: colors.text,
            ['--tw-prose-counters' as string]: colors.textSecondary,
            ['--tw-prose-bullets' as string]: colors.textSecondary,
            ['--tw-prose-quotes' as string]: colors.textSecondary,
            ['--tw-prose-code' as string]: colors.text,
            ['--tw-prose-hr' as string]: colors.border,
            ['--tw-prose-th-borders' as string]: colors.border,
            ['--tw-prose-td-borders' as string]: colors.border,
          }}
        />
      </motion.div>

      {selectedImage && (
        <ImageToolbar
          editor={editor}
          imageUrl={selectedImage.url}
          position={selectedImage.position}
        />
      )}
    </div>
  );
}